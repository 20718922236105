import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CalendarOutlined, InfoCircleOutlined, ShopOutlined, TagsOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Descriptions, Skeleton, Tag } from 'antd';

import VendorRequestDetailAttachment from './VendorRequestDetailAttachment';
import { LabelWithIcon } from '../../components/partials/StyledText';
import { useGetAllVendorsQuery, useGetRequestDocumentsQuery, useGetRequestQuery, useLazyGetVendorQuery } from '../../slice/APISlice';
import { GetVendorUseCase } from '../../use-case/getVendors/getVendorUseCase';
import { GetVendorRequestsController } from '../../use-case/vendorRequests/getVendorRequestsController';
import { UserState } from '../../@types/zcvp_state';
import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import { ApiVendorRequestType } from '../../@types/zcvp_api_enum';
import '../../styles/zcvp_vendor_details.scss';

const getDocumentFilter = (recordId: number) => ({
    filterModel: {
        type: { filter: 'Request', type: 'equals', filterType: 'text' },
        recordId: { filter: recordId, type: 'equals', filterType: 'number' }
    }
});

const VendorRequestHeader = ({ record, onVendorDetailClick }: { record: TableRecord, onVendorDetailClick: Function }) => {
    const { id: requestId } = record;
    const isVendor = useSelector((state: { user: UserState }) => state.user.isVendor);
    const [getVendor] = useLazyGetVendorQuery({});

    const { data: vendorsList } = useGetAllVendorsQuery({});
    const { data: request } = useGetRequestQuery({ requestId }, { skip: !requestId });
    const { data: attachments } = useGetRequestDocumentsQuery(getDocumentFilter(record?.id as unknown as number), { skip: !requestId });

    const vendorRequest = useMemo(() => new GetVendorRequestsController(vendorsList).executeGetRequest(
        request as unknown as TableRecord, isVendor
    ), [vendorsList, request]);

    const onVendorClick = useCallback(() => {
        (new GetVendorUseCase({
            setRecord: onVendorDetailClick,
            getVendor
        })).execute({
            id: record?.vendorId
        });
    }, [record, onVendorDetailClick, getVendor]);

    const COLUMN_COUNT = 1;
    const items = [
        {
            label: 'Status',
            labelIcon: <TagsOutlined />,
            value: <Tag color={vendorRequest.color as string}>{vendorRequest.statusText}</Tag>
        },
        {
            label: 'Vendor',
            labelIcon: <ShopOutlined />,
            value: <Button type='link' onClick={onVendorClick}>{vendorRequest.vendorName as string}</Button>
        },
        {
            label: 'Requested on',
            labelIcon: <CalendarOutlined />,
            value: vendorRequest.requestedOn
        },
        {
            label: 'Requested by',
            labelIcon: <UserOutlined />,
            value: (vendorRequest.createdBy as unknown as TableRecord)?.name
        },
        {
            label: 'Description',
            labelIcon: <InfoCircleOutlined />
        }
    ].map(({ label, labelIcon, value }, index) => ({
        key: index,
        label: <LabelWithIcon
            leftIcon={labelIcon}
            label={label} />,
        children: value
    }));

    return <Skeleton loading={!vendorRequest?.title}>
        <Descriptions items={items} column={COLUMN_COUNT} className={'zcvp-request-header'} bordered />
        {vendorRequest.description
            ? vendorRequest.type === ApiVendorRequestType.RECORD_CHANGE
                ? <div className='pl-10 pr-10 pt-2 pb-2' dangerouslySetInnerHTML={{ __html: vendorRequest.description }} />
                : <div className='pl-10 pr-10 pt-2 pb-2'> {vendorRequest.description}</div>
            : null}
        <VendorRequestDetailAttachment record={record} attachments={attachments} />
        <Descriptions items={[]} />
    </Skeleton>;
}

export default VendorRequestHeader;