import { FormFieldDefinition } from './zcvp_form';

export enum Field {
    ID = 'id',
    COMPANY_NAME = 'companyName',
    COMPANY_URL = 'companyUrl',
    FULL_NAME = 'name',
    FIRST_NAME = 'firstName',
    LAST_NAME = 'lastName',
    EMAIL = 'email',
    COMPANY_SIZE = 'size',
    PHONE = 'phone',
    RANDOM_KEY = 'randomKey',
    PASSWORD = 'password',
    CONFIRM_PASSWORD = 'confirmPassword',
    CAPTCHA_TOKEN = 'captchaToken',
}


export type FieldKey = `${Field}`

export type submitFields = Record<FieldKey, string>;

export interface ConfigurationDataGateway {
    editableFields: FieldKey[];
    viewFields: FieldKey[];
    logoFields: FieldKey[];
    getField: (fieldId: string) => FormFieldDefinition;
}

export interface ConfigurationData {
    editableValues?: RecordData;
    editableFields: FormFieldDefinition[][];
    viewFields: FormFieldDefinition[];
    logoFields: FormFieldDefinition[];
    id: string;
}

export type RecordData = Record<FieldKey, string>
export type RecordQueryData = {
    data?: RecordData
};

export interface VendorOnboardingActionGateway {
    isActive: () => boolean;
    isPendingApprovalToOnboard: () => boolean;
    isPendingApprovalAfterOnboard: () => boolean;
    isPendingInvitation: () => boolean;
}