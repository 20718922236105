import {
    Field,
    FieldKey,
    IConfigurationDataGateway,
    RecordQueryData
} from '../../@types/zcvp_configuration';

export class GetConfigurationDataGateway implements IConfigurationDataGateway {

    private configuration: RecordQueryData;

    constructor(configuration: RecordQueryData) {
        this.configuration = configuration;
    }

    public viewFields: FieldKey[] = [
        Field.COMPANY_NAME,
        Field.NAME,
        Field.ADDRESS,
        Field.SENDER_SUPPORT_EMAIL
    ];

    public editableFields: FieldKey[] = [
        Field.SUBSIDIARY,
    ];

    public logoFields: FieldKey[] = [
        Field.LOGO_URL,
    ];



}