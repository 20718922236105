import { GoogleOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import {
    FieldGroupKeys,
    FieldGroupingsIds,
    fieldsByGroupsType, userProfileEndpoint
} from '../../@types/user-profile/zcvp_user_profile_enum';
import { ShowMessageTypes } from '../../@types/zcvp_custom_hooks';
import { RoutesUrls, StorageKey } from '../../@types/zcvp_routes_enum';
import { UserState } from '../../@types/zcvp_state';
import { TabItem } from '../../@types/zcvp_tabbed_page';
import { RoleType } from '../../@types/zcvp_user_enums';
import TabbedContentLayout from '../../components/layout/TabbedContentLayout';
import ChangePasswordModal from '../../components/modals/ChangePasswordModal';
import CloseAccountModal from '../../components/modals/CloseAccountModal';
import GenericModal from '../../components/modals/GenericModal';
import TabPageContent from '../../components/page/TabPageContent';
import CardComponent from '../../components/partials/CardComponent';
import i18n from '../../i18n';
import { showMessage, useUserRoleType } from '../../lib/CustomHooks';
import { TranslationNS, getCommonT } from '../../lib/TranslationHelper';
import {
    useEditUserMutation, useEditVendorContactMutation,
    useGetInfoQuery,
    useGetUserQuery,
    useGetVendorContactQuery
} from '../../slice/APISlice';
import { openTutorial } from '../../slice/TutorialSlice';
import '../../styles/user_profile.scss';
import { hasTutorial } from '../../use-case/tutorial/portalTutorialUseCase';
import UserProfilePermissionsPresenter from '../../use-case/updateUserProfile/userProfilePermissionsPresenter';
import { UserProfileForm } from './UserProfileForm';


export default function UserProfile() {
    const items: TabItem[] = [
        {
            key: 'my_profile',
            label: i18n.t('user_profile.page_title', { ns: TranslationNS.USER }),
            children: UserProfileMyProfileTab(),
        },
    ];

    return (
        <>
            <TabbedContentLayout
                title={i18n.t('user_profile.settings', { ns: TranslationNS.USER })}
                items={items}
                actions={[]}
            />
        </>
    );
}

function UserProfileMyProfileTab() {
    const { t } = useTranslation(TranslationNS.USER, { keyPrefix: 'user_profile' });
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();
    const userId = useSelector((state: { user: UserState }) => state.user.userId);
    const roleName = useSelector((state: { user: UserState }) => state.user.roleName)
    const roleType: `${RoleType}` = useUserRoleType();
    const {
        data: userData = {}
    } = useGetUserQuery({ userId: localStorage.getItem(StorageKey.USER_ID) }, { skip: !localStorage.getItem(StorageKey.USER_ID) });
    const {
        data: {
            vendorContactId
        } = {}
    } = useGetInfoQuery({});

    const {
        data: vendorContactData
    } = useGetVendorContactQuery(vendorContactId, { skip: !vendorContactId });
    const [editUser, { isLoading: userIsLoading, isError: userIsError, isSuccess: userIsSuccess }] = useEditUserMutation();
    const [editVendorContact, { isLoading: vendorIsLoading, isError: vendorIsError, isSuccess: vendorIsSuccess }] = useEditVendorContactMutation();
    const { fieldGroupings, fieldConfig } = useMemo(() => new UserProfilePermissionsPresenter().convert(roleType as RoleType), [roleType]);

    const formConfig = useMemo(() => fieldConfig[roleType] as fieldsByGroupsType, [roleType, fieldConfig]);
    const showSuccessMessage = useCallback(() => showMessage(ShowMessageTypes.SUCCESS, '', messageApi), [messageApi]);
    const showErrorMessage = useCallback(() => showMessage(ShowMessageTypes.ERROR, '', messageApi), [messageApi]);

    useEffect(() => {
        ((userIsSuccess && !vendorIsError) || (vendorIsSuccess && !userIsError)) && showSuccessMessage();
    }, [userIsSuccess, vendorIsSuccess, userIsError, vendorIsError, showSuccessMessage]);
    useEffect(() => {
        (userIsError || vendorIsError) && showErrorMessage();
    }, [userIsError, vendorIsError, showErrorMessage]);


    return <TabPageContent
        items={
            <>
                {contextHolder}
                {(Object.keys(formConfig) as FieldGroupKeys[]).map((groupId: FieldGroupKeys) => {
                    const formField = formConfig[groupId];
                    const formGroup = fieldGroupings[groupId];
                    const profileParams = formField.endpoint === userProfileEndpoint.USER ?
                        { formData: userData, updateFunction: editUser, isLoading: userIsLoading, isSuccess: userIsSuccess } :
                        { formData: { ...vendorContactData, vendorContactId }, updateFunction: editVendorContact, isLoading: vendorIsLoading, isSuccess: vendorIsSuccess };

                    if (formField.invalid) { return null; }

                    return <UserProfileForm
                        key={groupId}
                        formField={formField}
                        formGroup={formGroup}
                        {...profileParams}
                    />;
                })}
                {formConfig[FieldGroupingsIds.ADDITIONAL_LOGIN_OPTIONS]?.invalid ? null : (
                    <CardComponent
                        title={t('additional_login_options_card_title')}
                    >
                        <GenericModal
                            triggerButton={{
                                content: <><GoogleOutlined /> {t('google_authentication')}</>,
                            }}
                            title={t('google_authentication_settings')}
                            onOkCallback={() => 'done'} //todo write real functionality
                            submitButtonLabel={getCommonT('save')}
                            id={'google'}
                            successMessageCallback={showSuccessMessage}
                        >Add content</GenericModal>
                    </CardComponent>
                )}
                {hasTutorial(roleName!) ? <CardComponent
                    title={t('accessibility_options_card_title')}
                >
                    <div className='space-x-2'>
                        <Button key='trigger-tutorial' name='tutorial-btn' onClick={() => dispatch(openTutorial())}>
                            <Link to={RoutesUrls.MAIN}>{t('trigger_tutorial_button')}</Link>
                        </Button>
                    </div>
                </CardComponent> : null}
                <CardComponent
                    title={t('account_actions_card_title')}
                >
                    <div className='space-x-2'>
                        <ChangePasswordModal />
                        <CloseAccountModal userId={userId} />
                    </div>
                </CardComponent>
            </>
        }
    />;
}