import { CheckOutlined, EditOutlined, PlayCircleOutlined, StopOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Row, Tag, Upload } from 'antd';
import { MessageInstance } from 'antd/es/message/interface';
import React, { useCallback, useMemo } from 'react';

import GenericForm from '../../components/form/GenericForm';
import InlineField from '../../components/form/form-field/InlineField';
import TabbedContentLayout from '../../components/layout/TabbedContentLayout';
import CardComponent from '../../components/partials/CardComponent';
import ApprovalConfiguration from './ApprovalConfiguration';
import ChangeKeyModal, { ModalKeySpec } from './ChangeKeyModal';

import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { TutorialStepKey } from '../../@types/tutorial/zcvp_tutorial_enum';
import { ZCVPApiResponse } from '../../@types/zcvp_api';
import { submitFields } from '../../@types/zcvp_configuration';
import { ShowMessageTypes } from '../../@types/zcvp_custom_hooks';
import { disableSubmitTrigger } from '../../@types/zcvp_form';
import { ActionType } from '../../@types/zcvp_form_enum';
import { TabItem } from '../../@types/zcvp_tabbed_page';
import { ConfigurationTabKey } from '../../@types/zcvp_tabbed_page_enum';
import TabPageContent from '../../components/page/TabPageContent';
import { showMessage, useHasMultipleSubsidiaries } from '../../lib/CustomHooks';
import { TranslationNS, getCommonT } from '../../lib/TranslationHelper';
import {
    useGetAllSubsidiariesQuery,
    useGetCompanyDataQuery,
    useGetIntegrationDataForCompanyQuery,
    useUpdateCompanyDataMutation,
    useUpdateCompanyLogoMutation,
    useUpdateIntegrationDataMutation
} from '../../slice/APISlice';
import '../../styles/user_profile.scss';
import GetConfigurationDataController from '../../use-case/getConfiguration/getConfigurationDataController';
import { getTutorialStepRef, useGoNextTutorialStep } from '../portal/PortalTutorial';

export default function Configuration() {
    const { t } = useTranslation(TranslationNS.USER, { keyPrefix: TranslationNS.CONFIGURATION });
    const goNextTutorialStep = useGoNextTutorialStep();
    const items: TabItem[] = [
        {
            key: ConfigurationTabKey.GENERAL,
            label: t('general_tab_title'),
            children: [ConfigurationGeneralTab()],
        },
        {
            key: ConfigurationTabKey.APPROVALS,
            label: <span ref={getTutorialStepRef(TutorialStepKey.APPROVALS)} onClick={() => goNextTutorialStep(TutorialStepKey.APPROVALS)}>{t('approvals_tab_title')}</span>,
            children: [ApprovalConfiguration()],
        },
        {
            key: ConfigurationTabKey.INTEGRATIONS,
            label: <span ref={getTutorialStepRef(TutorialStepKey.INTEGRATIONS)} onClick={() => goNextTutorialStep(TutorialStepKey.INTEGRATIONS)}>{t('integration_tab_title')}</span>,
            children: [ConfigurationIntegrationTab()],
        }
    ];

    return (
        <TabbedContentLayout
            title={t('page_title')}
            items={items}
            actions={[]}
        />
    );
}


function ConfigurationGeneralTab() {
    const { t } = useTranslation(TranslationNS.USER, { keyPrefix: TranslationNS.CONFIGURATION });
    const [errorMessage, setErrorMessage] = React.useState<string>('');
    const { messageApi } = useOutletContext<{ messageApi: MessageInstance }>();

    const [updateCompany] = useUpdateCompanyDataMutation();

    const [uploadLogo] = useUpdateCompanyLogoMutation();

    const data = useGetCompanyDataQuery({});
    const companyFilter = { "filterModel": { "companyId": { "values": [Number(data.data?.id)], "filterType": "set" } } };
    const subsidiaries = useGetAllSubsidiariesQuery(companyFilter);
    const {
        editableFields,
        editableValues,
        viewFields,
        viewValues,
        logoFields,
        logoValues,
        id,
    } = useMemo(() => new GetConfigurationDataController().execute(data, subsidiaries), [data, subsidiaries]);
    const hasMultipleSubsidiaries = useHasMultipleSubsidiaries();

    /**
     * Updates the editable fields on the backend
     * On success shows success message; on error shows error message
     * @param fields
     */
    const updateEditableFields = (fields: submitFields) => {
        setErrorMessage('');

        updateCompany({
            ...fields,
            ...{ id: id }
        }).then((response: ZCVPApiResponse) => {
            if (typeof response.error !== 'undefined') {
                showMessage(ShowMessageTypes.ERROR, response.error.message, messageApi, setErrorMessage);
            } else {
                showMessage(ShowMessageTypes.SUCCESS, '', messageApi);
            }
        });
    };

    return (
        <TabPageContent
            key={'configuration_general_tab_content_wrapper'}
            items={
                [<div key={'configuration_general_tab_content'}>
                    <CardComponent
                        title={t('company_details_tab_title')}
                        titlePopover={{ content: getCommonT('go_to_netsuite_to_edit') }}
                    >
                        {viewFields && viewFields.map((field) => (
                            <InlineField
                                label={field[0].label as string}
                                key={`field_line_wrapper_${field[0].label}`}
                                value={viewValues![field[0].name]} />
                        ))}
                    </CardComponent>
                    {hasMultipleSubsidiaries ? <CardComponent
                        title={t('company_account_card_title')}
                    >
                        {errorMessage && (
                            <Alert
                                message={getCommonT('error') as unknown as string}
                                description={errorMessage}
                                type="error"
                                showIcon
                                className='mb-3'
                            />
                        )}
                        <Row>
                            <Col xl={12} lg={16} md={18} sm={20} xs={24}>
                                <GenericForm
                                    formProps={
                                        {
                                            onFinish: (values: submitFields) => updateEditableFields(values),
                                        }
                                    }
                                    fieldValues={editableValues}
                                    id={'company_account_form'}
                                    disableSubmitTrigger={disableSubmitTrigger.ON_ANY_FIELD_CHANGE}
                                    fields={editableFields}
                                    actions={[{
                                        type: ActionType.SUBMIT,
                                        definition: {
                                            label: getCommonT('save_changes') as unknown as string,
                                            key: 'save_company_account',
                                            name: 'save_company_account',
                                        }
                                    }]} />
                            </Col>
                        </Row>
                    </CardComponent> : null}
                    <CardComponent
                        title={t('company_logo_card_title')}
                    >
                        <div className={'flex flex-row items-center'}>
                            {logoFields.map((logoField, index) => {
                                const value = logoValues[logoField[0].name];
                                return (
                                    <Row key={`logo_upload_${index}`} align={'middle'}>
                                        {value &&
                                            <img src={value as string} alt={'logo'} className={'max-w-xs max-h-xs'} />
                                        }
                                        <Upload
                                            name={'logo'}
                                            maxCount={1}
                                            customRequest={({ file }) => {
                                                file = file as Blob;
                                                uploadLogo({
                                                    data: file,
                                                    headers: {
                                                        'Content-Type': file.type
                                                    }
                                                });
                                            }}
                                        >
                                            <Button type={'default'} className={'ml-3'}><EditOutlined /> {getCommonT('edit')}</Button>
                                        </Upload>
                                    </Row>
                                );
                            })}

                        </div>
                    </CardComponent>
                </div>]
            }
        />
    );
}

function ConfigurationIntegrationTab() {
    const integrationContent = useMemo(() => <IntegrationTabContent key='configuration_integration_tab_content' />, []);
    return <TabPageContent
        key={'configuration_integration_tab_content_wrapper'}
        items={[integrationContent]}
    />;
}

const IntegrationTabContent = () => {
    const { t } = useTranslation(TranslationNS.USER, { keyPrefix: TranslationNS.INTEGRATION });
    const { messageApi } = useOutletContext<{ messageApi: MessageInstance }>();
    const [errorMessage, setErrorMessage] = React.useState<string>('');

    const [updateIntegration] = useUpdateIntegrationDataMutation();

    const integrationData = useGetIntegrationDataForCompanyQuery({});

    /** MODALS */
    const [openChangeKey, setOpenChangeKey] = React.useState<boolean>(false);
    const [selectedKey, setSelectedKey] = React.useState<ModalKeySpec>({ id: 0, keyName: '', label: '' });

    const {
        integrationFields,
        editableValues,
    } = useMemo(() => new GetConfigurationDataController().executeIntegration(integrationData), [integrationData]);
    const integrationDetailsTutorial = useMemo(() => getTutorialStepRef(TutorialStepKey.INTEGRATION_DETAILS), []);

    const updateIntegrationDetails = (fields: submitFields) => {
        // removed undefined values from fields
        const fieldsToUpdate = Object.fromEntries(
            Object.entries(fields).filter(([_, v]) => v !== null && v !== undefined)
        );

        updateIntegration({
            id: integrationData.data[0]!.id,
            maxConnectionCount: fieldsToUpdate.maxConnectionCount,
            details: {
                url: fieldsToUpdate.url,
            },
        }).then((response: ZCVPApiResponse) => {
            if (typeof response.error !== 'undefined') {
                showMessage(ShowMessageTypes.ERROR, response.error.message, messageApi, setErrorMessage);
            } else {
                showMessage(ShowMessageTypes.SUCCESS, '', messageApi);
            }
        });
    };

    /**
     * Enables/disables integration by setting the active flag to the opposite of the current value
     */
    const handleToggleIntegrationClick = () => {
        updateIntegration({
            id: integrationData.data[0]!.id,
            active: !integrationData.data[0]!.active,
        }).then((response: ZCVPApiResponse) => {
            if (typeof response.error !== 'undefined') {
                showMessage(ShowMessageTypes.ERROR, response.error.message, messageApi, setErrorMessage);
            } else {
                integrationData.refetch();
                showMessage(ShowMessageTypes.SUCCESS, '', messageApi);
            }
        });
    };

    /** 
     * Opens the change key modal
     */
    const handleChangeKeyClick = useCallback((id: number, keyName: string, keyLabel: string) => {
        setSelectedKey({ id: id, keyName: keyName, label: keyLabel });
        setOpenChangeKey(true);
    }, []);

    return <div key={'configuration_integration_tab_content'}>
        <CardComponent
            title={t('integration_card_title')}
            titlePopover={{ content: getCommonT('go_to_netsuite_to_edit') }}
        >
            <InlineField
                label={t('account_id_label')}
                key={'account_id'}
                value={integrationData?.data?.[0]?.details?.realm ?? ''}
            />
            <InlineField
                label={t('status_label')}
                key={'active'}
                value={integrationData?.data?.[0]?.active ? getCommonT('active') : getCommonT('inactive')}
            />
        </CardComponent>
        <CardComponent
            title={t('details_card_title')}
        >
            <Row>
                <Col xl={12} lg={16} md={18} sm={20} xs={24}>
                    <GenericForm
                        formProps={
                            {
                                onFinish: (values: submitFields) => updateIntegrationDetails(values),
                            }
                        }
                        fieldValues={editableValues}
                        fields={integrationFields}
                        id={'integration_form'}
                        disableSubmitTrigger={disableSubmitTrigger.ON_ANY_FIELD_CHANGE}
                        actions={[{
                            type: ActionType.SUBMIT,
                            definition: {
                                label: getCommonT('save_changes'),
                                key: 'save_integration',
                                name: 'save_integration',
                            }
                        }]} />
                </Col>
            </Row>
        </CardComponent>
        <CardComponent
            title={'Integration parameters'}
            titlePopover={{ content: t('integration_parameters_card_title_popover') }}
            elementRef={integrationDetailsTutorial}
        >
            {openChangeKey && <ChangeKeyModal keySpec={selectedKey} open={openChangeKey} onClose={() => setOpenChangeKey(false)} />}
            <Row className='mb-2' align={'middle'}>
                <Col xl={4} lg={6} md={8}>{t('authentication_id_label')}</Col>
                <Col xl={3} lg={5} md={7}>
                    <Tag icon={<CheckOutlined />} color='success'> {integrationData?.data?.[0]?.details?.consumerKeyIsDefined ? t('status_set') : t('status_not_set')}</Tag>
                </Col>
                <Col>
                    <Button type={'default'} icon={<EditOutlined />} onClick={() => handleChangeKeyClick(integrationData?.data?.[0]?.id, 'consumerKey', 'Authentication ID')}>{getCommonT('change')}</Button>
                </Col>
            </Row>
            <Row className='mb-2' align={'middle'}>
                <Col xl={4} lg={6} md={8}>{t('authentication_secret_label')}</Col>
                <Col xl={3} lg={5} md={7}>
                    <Tag icon={<CheckOutlined />} color='success'> {integrationData?.data?.[0]?.details?.consumerSecretIsDefined ? t('status_set') : t('status_not_set')}</Tag>
                </Col>
                <Col>
                    <Button type={'default'} icon={<EditOutlined />} onClick={() => handleChangeKeyClick(integrationData?.data?.[0]?.id, 'consumerSecret', 'Authentication Secret')}>{getCommonT('change')}</Button>
                </Col>
            </Row>
            <Row className='mb-2' align={'middle'}>
                <Col xl={4} lg={6} md={8}>{t('access_token_label')}</Col>
                <Col xl={3} lg={5} md={7}>
                    <Tag icon={<CheckOutlined />} color='success'> {integrationData?.data?.[0]?.details?.tokenKeyIsDefined ? t('status_set') : t('status_not_set')}</Tag>
                </Col>
                <Col>
                    {integrationData?.data?.[0]?.details?.tokenKeyIsDefined &&
                        <Button type={'default'} icon={<EditOutlined />} onClick={() => handleChangeKeyClick(integrationData?.data?.[0]?.id, 'tokenKey', 'Access Token ID')}>{getCommonT('change')}</Button>}
                </Col>
            </Row>
            <Row className='mb-2' align={'middle'}>
                <Col xl={4} lg={6} md={8}>{t('access_token_secret_label')}</Col>
                <Col xl={3} lg={5} md={7}>
                    <Tag icon={<CheckOutlined />} color='success'> {integrationData?.data?.[0]?.details?.tokenSecretIsDefined ? t('status_set') : t('status_not_set')}</Tag>
                </Col>
                <Col>
                    {integrationData?.data?.[0]?.details?.tokenSecretIsDefined &&
                        <Button type={'default'} icon={<EditOutlined />} onClick={() => handleChangeKeyClick(integrationData?.data?.[0]?.id, 'tokenSecret', 'Access Token Secret')}>{getCommonT('change')}</Button>}
                </Col>
            </Row>
        </CardComponent>
        <CardComponent
            title={t('integration_actions_card_title')}
            titlePopover={{ content: t('integration_actions_card_title_popover') }}>
            <Row>
                <Col xl={12} lg={16} md={18} sm={20} xs={24}>
                    {integrationData?.data?.[0].active
                        ? <Button danger icon={<StopOutlined />} onClick={handleToggleIntegrationClick}>{t('disable_integration_button')}</Button>
                        : <Button icon={<PlayCircleOutlined />} onClick={handleToggleIntegrationClick}>{t('enable_integration_button')}</Button>
                    }
                </Col>
            </Row>
        </CardComponent>
    </div>;
}