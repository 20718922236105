import { GetUserRolesUseCase } from './getUserRolesUseCase';
import { GetUserRolesGateway } from './getUserRolesGateway';
import { GetUserRolesPresenter } from './getUserRolesPresenter';
import { CheckAppRolePermissionController } from '../checkRolePermission/checkAppRolePermissionController';
import { GetUserRolesControllerParams } from '../../@types/role-permissions/zcvp_role_permissions';
import { ApiRecord } from '../../@types/zcvp_api_enum';

export class GetUserRolesController {
    private roles;
    private user;
    constructor({roles, user}: GetUserRolesControllerParams) {
        this.roles = roles?.data || [];
        this.user = user;
    }
    public execute() {
        const {roles, user} = this;
        const gateway = new GetUserRolesGateway({roles, user});
        const presenter = new GetUserRolesPresenter();
        const useCase = new GetUserRolesUseCase({
            gateway,
            presenter,
            getPermissionChecker: this.getPermissionChecker
        });
        return useCase.execute();
    }
    private getPermissionChecker(role: ApiRecord.AppRole): CheckAppRolePermissionController {
        return new CheckAppRolePermissionController(role);
    }
}