import store from '../../appStore';
import { RolePermissionGateway } from '../../@types/role-permissions/zcvp_role_permissions';
import { FeaturePermissionDefinition, FeaturePermissionMap, PermissionLevelMap } from '../../@types/role-permissions/zcvp_role_permissions';
import { PortalFeature, PortalPermissionLevel } from '../../@types/role-permissions/zcvp_role_permissions_enum';
import { RoleType } from '../../@types/zcvp_user_enums';
import { UserState } from '../../@types/zcvp_state';
import { ApiPermission } from '../../@types/zcvp_api_enum';

export class CheckRolePermissionGateway implements RolePermissionGateway {
    private user: UserState;
    private portalFeaturePermissionMap: FeaturePermissionMap = {
        [PortalFeature.VENDOR_LIST]: [{
            permission: ApiPermission.Target.VENDOR,
            level: PortalPermissionLevel.READ
        }],
        [PortalFeature.CREATE_VENDOR]: [{
            permission: ApiPermission.Target.VENDOR,
            level: PortalPermissionLevel.CREATE
        }],
        [PortalFeature.EDIT_VENDOR]: [{
            permission: ApiPermission.Target.VENDOR,
            level: PortalPermissionLevel.EDIT
        }],
        [PortalFeature.VIEW_VENDOR_DETAIL]: [{
            permission: ApiPermission.Target.VENDOR,
            level: PortalPermissionLevel.READ
        }, {
            permission: ApiPermission.Target.VENDOR_CONTACT,
            level: PortalPermissionLevel.READ
        }, {
            permission: ApiPermission.Target.BANK_ACCOUNT,
            level: PortalPermissionLevel.READ
        }],
        [PortalFeature.INVITE_VENDOR]: [{
            permission: ApiPermission.Target.INVITE_VENDOR,
            level: PortalPermissionLevel.GENERAL
        }],
        [PortalFeature.EDIT_PROFILE]: [{
            permission: ApiPermission.Target.USER,
            level: PortalPermissionLevel.EDIT
        }],
        [PortalFeature.EDIT_BANK_DETAILS]: [{
            permission: ApiPermission.Target.BANK_ACCOUNT,
            level: PortalPermissionLevel.EDIT
        }],
        [PortalFeature.EDIT_VENDOR]: [{
            permission: ApiPermission.Target.VENDOR,
            level: PortalPermissionLevel.EDIT
        }],
        [PortalFeature.EDIT_CONTACT]: [{
            permission: ApiPermission.Target.VENDOR_CONTACT,
            level: PortalPermissionLevel.EDIT
        }],
        [PortalFeature.CONTACT_LIST]: [{
            permission: ApiPermission.Target.VENDOR_CONTACT,
            level: PortalPermissionLevel.READ
        }],
        [PortalFeature.INVITE_CONTACT]: [{
            permission: ApiPermission.Target.INVITE_VENDOR_CONTACT,
            level: PortalPermissionLevel.GENERAL
        }],
        [PortalFeature.EDIT_COMPANY]: [{
            permission: ApiPermission.Target.COMPANY,
            level: PortalPermissionLevel.EDIT
        }],
        [PortalFeature.FULL_COMPANY]: [{
            permission: ApiPermission.Target.COMPANY,
            level: PortalPermissionLevel.FULL
        }],
        [PortalFeature.ACTIVE_USERS_LIST]: [{
            permission: ApiPermission.Target.USER,
            level: PortalPermissionLevel.READ
        }],
        [PortalFeature.EDIT_USER]: [{
            permission: ApiPermission.Target.USER,
            level: PortalPermissionLevel.EDIT
        }],
        [PortalFeature.EMPLOYEE_USERS_LIST]: [{
            permission: ApiPermission.Target.EMPLOYEE,
            level: PortalPermissionLevel.READ
        }],
        [PortalFeature.INVITE_EMPLOYEE]: [{
            permission: ApiPermission.Target.INVITE_EMPLOYEE,
            level: PortalPermissionLevel.GENERAL
        }],
        [PortalFeature.APPROVE_TO_ONBOARD]: [{
            permission: ApiPermission.Target.APPROVE_TO_ONBOARD,
            level: PortalPermissionLevel.GENERAL
        }],
        [PortalFeature.APPROVE_AFTER_ONBOARD]: [{
            permission: ApiPermission.Target.APPROVE_AFTER_ONBOARD,
            level: PortalPermissionLevel.GENERAL
        }],
        [PortalFeature.REQUEST_LIST]: [{
            permission: ApiPermission.Target.REQUEST,
            level: PortalPermissionLevel.READ
        }],
        [PortalFeature.CREATE_REQUEST]: [{
            permission: ApiPermission.Target.REQUEST,
            level: PortalPermissionLevel.CREATE
        }],
        [PortalFeature.APPROVE_REQUEST]: [{
            permission: ApiPermission.Target.APPROVE_REQUEST,
            level: PortalPermissionLevel.GENERAL
        }],
        [PortalFeature.SUBMIT_REQUEST]: [{
            permission: ApiPermission.Target.SUBMIT_REQUEST,
            level: PortalPermissionLevel.GENERAL
        }]
    }
    private portalPermissionLevelMap: PermissionLevelMap  = {
        [PortalPermissionLevel.READ]: [ApiPermission.Action.READ, ApiPermission.Action.FULL],
        [PortalPermissionLevel.CREATE]: [ApiPermission.Action.CREATE, ApiPermission.Action.WRITE, ApiPermission.Action.FULL],
        [PortalPermissionLevel.EDIT]: [ApiPermission.Action.EDIT, ApiPermission.Action.WRITE, ApiPermission.Action.FULL],
        [PortalPermissionLevel.GENERAL]: [ApiPermission.Action.GENERAL],
        [PortalPermissionLevel.FULL]: [ApiPermission.Action.FULL]
    }
    constructor(user: UserState) {
        this.user = user || store.getState().user;
    }
    getRoleType(): RoleType {
        return this.user.isVendor ? RoleType.VENDOR: RoleType.CUSTOMER;
    }
    public getPermissions(): ApiPermission.Permission[] {
        return this.user.perms;
    }
    public getPortalFeaturePermissions(feature: PortalFeature): FeaturePermissionDefinition[] {
        return this.portalFeaturePermissionMap[feature];
    }
    public getPortalPermissionLevels(permissionLevel: PortalPermissionLevel): ApiPermission.Action[] {
        return this.portalPermissionLevelMap[permissionLevel];
    }
}