import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import { VendorOnboardingActionGateway } from '../../@types/onboarding/zcvp_onboarding';
import { ApiVendorStatus } from '../../@types/zcvp_api_enum';

export class GetVendorOnboardingActionGateway implements VendorOnboardingActionGateway {
    private record: TableRecord;
    constructor(record: TableRecord) {
        this.record = record || {};
    }
    public isActive() {
        return this.record.status === ApiVendorStatus.ACTIVE;
    }
    public isPendingApprovalToOnboard() {
        return this.record.status === ApiVendorStatus.PENDING_APPROVAL_TO_ONBOARD;
    }
    public isPendingApprovalAfterOnboard() {
        return this.record.status === ApiVendorStatus.PENDING_APPROVAL_AFTER_ONBOARD;
    }
    public isPendingInvitation() {
        return this.record.status === ApiVendorStatus.PENDING_INVITATION;
    }
}