import { FormActionProps, FormElementDefinition, FormFieldDefinition } from '../zcvp_form';
import { RoleType } from '../zcvp_user_enums';
import { ZCVPCardTitlePopover } from '../zcvp_layout';

export enum ThemeValues {
    LIGHT = 'L',
    DARK = 'D',
    BROWSER = 'B',
}

/**
 * The frontend field IDs used in the form
 */
export enum UserField {
    APP_NOTIFICATION = 'notification-app',
    EMAIL_NOTIFICATION = 'notification-email',
    THEME = 'theme',
    FULL_NAME = 'full-name',
    EMAIL = 'email',
    COMPANY_EMAIL = 'company-email',
    CHANGE_PASSWORD = 'change-password',
    FIRST_NAME = 'first-name',
    LAST_NAME = 'last-name',
    PHONE_NUMBER = 'phone-number',
    GOOGLE_LOGIN = 'google-login',
}

export type RecordQueryData = {

}
export type FieldGrouping = {
    title: string,
    subtitle?: string,
    titlePopover?: ZCVPCardTitlePopover,
    layout?: 'horizontal' | 'vertical',
    saveButton?: boolean,
};


export enum FieldGroupingsIds {
    PERSONAL_DETAILS = 'personalDetails',
    NOTIFICATIONS = 'notifications',
    THEME = 'theme',
    ADDITIONAL_LOGIN_OPTIONS = 'additionalLoginOptions',
}

export type FieldGroupKeys = `${FieldGroupingsIds}`;

export type fieldsByGroupsType = {
    [key in FieldGroupKeys] : {
        invalid? : boolean,
        fields?: FormFieldDefinition[][] | FormElementDefinition[][],
        actions?: FormActionProps[],
        endpoint? : userProfileEndpoint,
    }
};

export type fieldsAndGroupsByRoleType = {[key in RoleType]? : fieldsByGroupsType}
export enum userProfileEndpoint {
    USER = 'user',
    VENDOR_CONTACT = 'vendor_contact'
}

export interface InitializeProfileGateway {
    fieldConfig: fieldsAndGroupsByRoleType;
    getField: (fieldId: string) => FormFieldDefinition;
}