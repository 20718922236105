import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Tabs } from 'antd';

import NotificationList from './NotificationList';
import { TranslationNS } from '../../lib/TranslationHelper';
import { useMarkNotificationArchivedMutation, useMarkNotificationReadMutation } from '../../slice/APISlice';
import { NotificationType } from '../../use-case/getNotifications/getNotificationsUseCase';
import { GetTabActionsController } from '../../use-case/getTabActions/getTabActionsController';
import { GetNotificationsController } from '../../use-case/getNotifications/getNotificationsController';
import { NotificationMap } from '../../@types/notifications/zcvp_notifications';
import '../../styles/zcvp_card.scss'
import '../../styles/zcvp_notifications.scss';

enum TabKey {
    INBOX = 'inbox',
    ARCHIVE = 'archive'
}

const NotificationPopoverContent = ({ notifications }: { notifications: NotificationMap }) => {
    const { t } = useTranslation(TranslationNS.USER, { keyPrefix: TranslationNS.NOTIFICATION });
    const [activeTabKey, setActiveTabKey] = useState(TabKey.INBOX as string);
    const notificationController = useMemo(() => new GetNotificationsController(), []);

    const [markNotificationRead] = useMarkNotificationReadMutation();
    const [markNotificationArchived] = useMarkNotificationArchivedMutation();

    const onMarkAsRead = useCallback((ids: number[]) =>
        () => markNotificationRead({ ids })
    , [markNotificationRead]);

    const onMarkAsArchived = useCallback((ids: number[]) => 
        () => markNotificationArchived({ ids })
    , [markNotificationArchived]);

    const onMarkAllRead = useCallback(() => {
        onMarkAsRead(notificationController.executeGetUnreadNotificationIds(notifications[NotificationType.INBOX]))();
    }, [onMarkAsRead, notificationController, notifications]);

    const onMarkAllArchived = useCallback(() => {
        onMarkAsArchived(notificationController.executeGetNotificationIds(notifications[NotificationType.INBOX]))();
    }, [onMarkAsArchived, notificationController, notifications]);

    const items = [{
        key: TabKey.INBOX as string,
        label: t('inbox'),
        children: <NotificationList
            notifications={notifications[NotificationType.INBOX]}
            enableActions={true}
            onMarkAsRead={onMarkAsRead}
            onMarkAsArchived={onMarkAsArchived} />
    }, {
        key: TabKey.ARCHIVE as string,
        label: t('archived'),
        children: <NotificationList
            notifications={notifications[NotificationType.ARCHIVED]}
            onMarkAsRead={onMarkAsRead}
            onMarkAsArchived={onMarkAsArchived} />
    }];

    const actions = useMemo(() => [{
        applyToTabs: [TabKey.INBOX],
        element: <React.Fragment key='actions'>
            <Button size='small' onClick={onMarkAllRead}>{t('mark_all_read')}</Button>
            <Button size='small' onClick={onMarkAllArchived}>{t('archive_all')}</Button>
        </React.Fragment>
    }], [t, onMarkAllRead, onMarkAllArchived]);
    const tabActions = useMemo(() => new GetTabActionsController().execute(actions, activeTabKey!), [actions, activeTabKey]);

    const onTabClick = useCallback((key: string) => setActiveTabKey(key), []);
    return <Tabs
        items={items}
        tabBarExtraContent={{
            right: tabActions
        }}
        onTabClick={onTabClick}/>;
};

export default NotificationPopoverContent;