import { OptionsGateway } from "../../@types/get-options/zcvp_get_options";
import { FormFieldOption } from "../../@types/zcvp_form";

export class GetOptionsUseCase {
    private gateway;
    constructor(gateway: OptionsGateway) {
        this.gateway = gateway;
    }
    execute(nameProp: string): FormFieldOption[] {
       return this.gateway.getOptions(nameProp);
    }
}