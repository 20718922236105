import { Button, Modal } from 'antd';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { GenericModalProps } from '../../@types/zcvp_modal';
import { getCommonT } from '../../lib/TranslationHelper';
import '../../styles/user_profile.scss';
import '../../styles/zcvp_modal.scss';
import SubmitButton from '../form/form-action/SubmitButton';

export default function GenericModal({ title, id, submitButtonLabel, triggerButton, onOkCallback, onCancelCallback, onOpenCallback, successMessageCallback, includeFooter = true, open, children }: PropsWithChildren<GenericModalProps>) {
    const [loadingModal, setLoadingModal] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const saveModal = () => {
        setLoadingModal(true);
        onOkCallback(); ///todo build this out for real, should probably return a promise and then do the below when the promise resolves
        setLoadingModal(false);
        setOpenModal(false);
        successMessageCallback && successMessageCallback();
    };

    const footer = includeFooter ? [
        <Button
            key="back"
            onClick={() => {
                onCancelCallback && onCancelCallback();
                setOpenModal(false);
            }}>
            {getCommonT('cancel')}
        </Button>,
        <SubmitButton
            loading={loadingModal}
            key="submit"
            onClick={saveModal}
            label={submitButtonLabel}
            name={`${id}_modal`} />,
    ] : [];

    useEffect(() => {
        setOpenModal(open);
    }, [open]);

    //15-17,31-39

    return (
        <>
            <Button
                type={'default'}
                {...triggerButton.props}
                onClick={(event) => {
                    onOpenCallback && onOpenCallback(event);
                    setOpenModal(true);
                }}>{triggerButton.content}</Button>
            {openModal ? <Modal
                open={openModal}
                title={title}
                onOk={saveModal}
                onCancel={() => {
                    onCancelCallback && onCancelCallback();
                    setLoadingModal(false);
                    setOpenModal(false);
                }}
                footer={footer}
                destroyOnClose
            >
                {children}
            </Modal> : null}
        </>
    );
}
