import { UserAddOutlined } from '@ant-design/icons';
import React, { useCallback, useMemo, useState } from 'react';

import GenericActionButton from '../../components/form/form-action/GenericActionButton';
import TabbedContentLayout from '../../components/layout/TabbedContentLayout';
import ListPageContent from '../../components/page/ListPageContent';

import ActiveUsersTable from './ActiveUsersTable';
import NetSuiteUserDetails from './NetSuiteUserDetails';
import NetSuiteUsersTable from './NetSuiteUsersTable';
import UserDetails from './UserDetails';

import { useTranslation } from 'react-i18next';
import { TutorialStepKey } from '../../@types/tutorial/zcvp_tutorial_enum';
import { UserTabKey } from '../../@types/zcvp_tabbed_page_enum';
import { TranslationNS } from '../../lib/TranslationHelper';
import '../../styles/zcvp_tabbed_page.scss';
import { getTutorialStepRef, useGoNextTutorialStep } from '../portal/PortalTutorial';

const UserList: React.FC = () => {
    const { t } = useTranslation(TranslationNS.USER, { keyPrefix: TranslationNS.USER_MANAGEMENT });
    const goNextTutorialStep = useGoNextTutorialStep();
    const [activeTabKey, setActiveTabKey] = useState<string>();
    const onTabClick = useCallback((key: string) => setActiveTabKey(key), []);

    const activeUsersTab = <ListPageContent
        pageId={UserTabKey.ACTIVE}
        key={UserTabKey.ACTIVE}
        DataTable={ActiveUsersTable}
        SubPage={UserDetails}
        title={t('active_users_tab_title_long')}
        searchPlaceHolder={t('find_user_placeholder')} />;

    const netsuiteUsersTab = <ListPageContent
        pageId={UserTabKey.NETSUITE_USERS}
        key={UserTabKey.NETSUITE_USERS}
        DataTable={NetSuiteUsersTable}
        SubPage={NetSuiteUserDetails}
        title={t('netsuite_users_tab_title')}
        searchPlaceHolder={t('find_user_placeholder')}
        infoDetails={{
            message: t('netsuite_users_alert'),
            type: 'info'
        }}
        alertElementRef={getTutorialStepRef(TutorialStepKey.NETSUITE_USERS_DETAILS)} />;

    const inviteUsersBtn = useMemo(() => <GenericActionButton
        key='invite-users-btn'
        name={'zvcp-invite-users'}
        label={t('invite_users_button')}
        tooltip={''}
        icon={<UserAddOutlined />}
        onClick={() => onTabClick(UserTabKey.NETSUITE_USERS)} />
        , []);

    const items = [{
        label: t('active_users_tab_title'),
        key: UserTabKey.ACTIVE,
        children: [activeUsersTab]
    }, {
        label: <span ref={getTutorialStepRef(TutorialStepKey.NETSUITE_USERS)} onClick={() => goNextTutorialStep(TutorialStepKey.NETSUITE_USERS)}>{t('netsuite_users_tab_title')}</span>,
        key: UserTabKey.NETSUITE_USERS,
        children: [netsuiteUsersTab]
    }];
    const actions = [{
        applyToTabs: [UserTabKey.ACTIVE],
        element: inviteUsersBtn
    }];

    return <TabbedContentLayout
        title={t('users_page_title')}
        items={items}
        actions={actions}
        activeKey={activeTabKey}
        onTabClick={onTabClick}
    />;
};

export default UserList;