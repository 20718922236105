import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import { PortalFeature } from '../../@types/role-permissions/zcvp_role_permissions_enum';
import { UserState } from '../../@types/zcvp_state';
import { CheckRolePermissionController } from '../checkRolePermission/checkRolePermissionController';
import { GetVendorOnboardingActionGateway } from './getVendorOnboardingActionGateway';
import { GetVendorOnboardingActionUseCase, VendorOnboardingActionParams } from './getVendorOnboardingActionUseCase';

export class GetVendorOnboardingActionController {
    private record: TableRecord;
    private user: UserState|undefined;
    constructor(record: TableRecord, user?: UserState) {
        this.record = record;
        this.user = user;
    }
    public execute() {
        const permissions: VendorOnboardingActionParams = this.getPermissions();
        const gateway = new GetVendorOnboardingActionGateway(this.record);
        const useCase = new GetVendorOnboardingActionUseCase(gateway);
        return useCase.execute(permissions);
    }
    public executeHasValidActionStatus() {
        const gateway = new GetVendorOnboardingActionGateway(this.record);
        const useCase = new GetVendorOnboardingActionUseCase(gateway);
        return useCase.hasValidActionStatus();
    }
    private getPermissions(): VendorOnboardingActionParams {
        const checkPermissionController = new CheckRolePermissionController(this.user!);
        const canApproveToOnboard = checkPermissionController.executeFeatureCheck(PortalFeature.APPROVE_TO_ONBOARD);
        const canApproveAfterOnboard = checkPermissionController.executeFeatureCheck(PortalFeature.APPROVE_AFTER_ONBOARD);
        const canInviteVendor = checkPermissionController.executeFeatureCheck(PortalFeature.INVITE_VENDOR);
        const canCreateRequests = checkPermissionController.executeFeatureCheck(PortalFeature.CREATE_REQUEST);
        return {
            canApproveToOnboard, canApproveAfterOnboard, canInviteVendor, canCreateRequests
        };
    }
}