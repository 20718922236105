import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import VendorRequestTable from './VendorRequestTable';
import ListPageContent from '../../components/page/ListPageContent';
import TabbedContentLayout from '../../components/layout/TabbedContentLayout';
import { TranslationNS } from '../../lib/TranslationHelper';
import { ListId } from '../../@types/list-details/zcvp_get_list_details_enum';
import '../../styles/zcvp_tabbed_page.scss';
import VendorRequestModal from './VendorRequestModal';
import { CheckRolePermissionController } from '../../use-case/checkRolePermission/checkRolePermissionController';
import { useSelector } from 'react-redux';
import { UserState } from '../../@types/zcvp_state';
import { PortalFeature } from '../../@types/role-permissions/zcvp_role_permissions_enum';

const VendorRequestList: React.FC = () => {
    const { t } = useTranslation(TranslationNS.VENDOR, {
        keyPrefix: TranslationNS.VENDOR_REQUEST
    });

    const user = useSelector((state: { user: UserState }) => state.user);
    const canCreateRequests = useMemo(() => new CheckRolePermissionController(user).executeFeatureCheck(PortalFeature.CREATE_REQUEST), [user]);

    const requestTab  = <ListPageContent
        title={t('requests')}
        pageId={ListId.VENDOR_REQUEST}
        key={ListId.VENDOR_REQUEST}
        DataTable={VendorRequestTable}
        searchPlaceHolder={t('find_request')} />;

    const items = [{
        label: '',
        key: ListId.VENDOR_REQUEST,
        children: [requestTab]
    }];

    const actions = canCreateRequests ? [{
        applyToTabs: [ListId.VENDOR_REQUEST],
        element: <VendorRequestModal key={'task-modal'}/>
    }] : [];

    return <TabbedContentLayout
        title={t('requests')}
        items={items}
        actions={actions}
        activeKey={ListId.VENDOR_REQUEST}
    />;
};

export default VendorRequestList;