import { DoubleLeftOutlined, DoubleRightOutlined, MenuFoldOutlined, MenuOutlined } from '@ant-design/icons';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
    Collapse,
    Ripple,
    initTE,
} from 'tw-elements';
import { AppState } from '../../@types/zcvp_state';
import { selectIsSubPortalPage, updateLayoutThemeState } from '../../slice/LayoutThemeSlice';
import PageTitle from '../partials/PageTitle';

initTE({ Collapse, Ripple });

const VerticalNavToggleTrigger = ({ title, isCaret = false }: {
    title?: string,
    isCaret?: boolean
}) => {
    //todo: set this to work with the collapsing caret functionality in Josef's video
    const dispatch = useDispatch();

    const location = useLocation();
    const isPortalPage = useSelector((state: AppState) => selectIsSubPortalPage(state, { pathname: location.pathname }));
    const menuTrigger = useSelector((state: AppState) => state.layoutTheme.menuTrigger);
    const open = useSelector((state: AppState) => state.layoutTheme.menuIsOpen);

    const toggleDrawer = () => {
        dispatch(updateLayoutThemeState({
            menuIsOpen: menuTrigger === 'click' ? !open : true,
            menuTrigger: menuTrigger === 'click' ? 'hover' : 'click'
        }));
    };

    /*Don't allow show/hide if we're on a portal page */
    if (!isPortalPage && !isCaret) {
        return <PageTitle title={title} />;
    }

    if (isCaret) {
        if (!isPortalPage) { return null; }

        return (
            <div data-testid={'zcvp-nav-mode-toggle'} className={'zcvp-collapse-caret'} onClick={toggleDrawer}>
                {open && menuTrigger === 'click' ? (
                    <DoubleLeftOutlined />
                ) : (
                    <DoubleRightOutlined />
                )}
            </div>
        );
    }

    return (
        <div className={'flex'}>
            <div className={'ml-3 my-3 p-2 mr-2 cursor-pointer zcvp-vertical-nav-toggle-button flex items-center'}
                onClick={toggleDrawer}
                data-testid={'zcvp-nav-drawer-toggle'}
            >
                {open && menuTrigger === 'click' ? (
                    <MenuFoldOutlined />
                ) : (
                    <MenuOutlined />
                )}
            </div>
            <PageTitle title={title} />
        </div>
    );
};


export default VerticalNavToggleTrigger;