import { TableRecord, TableRowObjectValue } from "../../@types/list-details/zcvp_get_list_details";

type Role = {
    id: string;
    text: string;
}
export const getCustomerRoleList = (roleList: TableRecord[]) => {
    return roleList?.filter(role => !role.isVendor) || [];
}
export const getActiveUsers = (usersList: TableRecord[], roleList: TableRecord[]): TableRecord[] => {
    const customerRoleIds = getCustomerRoleList(roleList).map(role => role.id);
    return usersList?.filter(user => (user.roles as unknown as Role[])?.some(role => customerRoleIds.includes(role.id))) || [];
}
export const canRemoveUser = (record?: TableRecord): boolean => {
    return !!(record?.id && (record?._system as unknown as TableRowObjectValue)?.canDelete);
}