import dayjs from 'dayjs';
import fromNow from 'dayjs/plugin/relativeTime';
dayjs.extend(fromNow);

export const formatDate = (dateString: string): string => {
    return dateString ? new Date(dateString).toLocaleDateString() : dateString;
};

export const formatDateTime = (dateString: string): string => {
    return dateString ? new Date(dateString).toLocaleString() : dateString;
};

export const formatFromNow = (dateString: string): string => {
    return dayjs(dateString).fromNow();
}