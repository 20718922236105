import { Form, Input } from 'antd';
import React from 'react';
import { GenericFieldProps } from '../../../@types/zcvp_form';

/**
 * Creates a numeric field with name, label, default value, disabled, required, onChange properties.
 * This field only accepts digits.
 *
 * @param {GenericFieldProps} props numeric field properties
 * @returns {React.FC<GenericFieldProps>} a numeric field inside a form item
 */
const NumericField: React.FC<GenericFieldProps> = (props: GenericFieldProps) => <Form.Item
    name={props.name}
    label={props.label}
    className={props.className}
    rules={[{
        required: props.required,
        pattern: /^\d*$/,
        message: 'Please enter a valid number'
    }]}
>
    <Input
        placeholder={props.placeholder}
        disabled={props.disabled}
        onChange={props.onChange}
        maxLength={props.maxLength}
    />
</Form.Item>;

export default NumericField;