import React from 'react';
import { Checkbox, Form } from 'antd';
import { CheckboxFieldProps } from '../../../@types/zcvp_form';

/**
 * Creates a checkbox field with name, label, default value, disabled, required, and onChange properties
 *
 * @param {CheckboxFieldProps} props checkbox field properties
 * @returns {React.FC<CheckboxFieldProps>} a checkbox field inside a form item
 */
const CheckboxField: React.FC<CheckboxFieldProps> = (props: CheckboxFieldProps) => <Form.Item
    label={props.label}
    name={props.name}
    required={props.required}
    valuePropName="checked">
    <Checkbox
        defaultChecked={props.defaultValue}
        disabled={props.disabled}
        onChange={props.onChange}
    >
        {props.checkboxLabel}
    </Checkbox>
</Form.Item>;

export default CheckboxField;