export const validateEmail = (allEmail) => {
    if(!allEmail) return Promise.resolve(); //resolve so this doesn't return two error messages
    const emailRegexPattern = new RegExp(/\S+@\S+\.\S+/);
    const emailList = allEmail
        .split(',')
        .filter(email => !!email)
        .some(email => !emailRegexPattern.test(email.trim()))
    if (emailList) {
        return Promise.reject();
    }
    return Promise.resolve();
}