import { Col, Form, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import FormAction from './form-action/FormAction';
import FormField from './form-field/FormField';

import { FormInstance } from 'antd/lib';
import {
    FormActionProps,
    FormElementDefinition,
    FormFieldDefinition,
    FormProps,
    disableSubmitTrigger
} from '../../@types/zcvp_form';
import { FormElementType, FormFieldType } from '../../@types/zcvp_form_enum';
import FormElement from './FormElement';

/**
 * Creates a generic form accepting two properties:
 * - fields: 2D array of field definitions grouped by row
 * - actions: array of action buttons
 *
 * @param {ActionButtonDefinition} props action properties
 * @returns {React.FC} submit button
 */
const GenericForm: React.FC<FormProps> = (props: FormProps) => {
    const formProp = props.formProps?.form;
    const [formInstance] = Form.useForm();
    const form = formProp || formInstance; //if a form is provided, use that one, otherwise use the one created by the component

    const [disableSubmitButton, setDisableButton] = useState(!!props.disableSubmitTrigger);
    if (props.disableSubmitTrigger && !form) { throw new Error('Form must be provided when using disableSubmitTrigger'); }
    /**
     * On field change, check if the form should still be disabled
     */
    const onFieldsChange = () => {
        if (!props.disableSubmitTrigger || !form) { return; }
        //no fields are touched
        if (props!.disableSubmitTrigger === disableSubmitTrigger.ON_ANY_FIELD_CHANGE) {
            setDisableButton(!form.isFieldsTouched());
        }
    };

    const onFinish = (values: any) => {
        if (props.formProps?.onFinish) {
            props.formProps!.onFinish(values);
            setDisableButton(true);
        }
    }

    /**
     * Set initial values of the form
     */
    useEffect(() => {
        if (!props.fieldValues || !form) { return; }
        form.setFieldsValue(props.fieldValues);
    }, [form, props.fieldValues]);

    return (
        <Form layout='vertical'
            autoComplete='off'
            validateTrigger={props.validateTrigger}
            form={form}
            {...props.formProps}
            onFinish={onFinish}
            {...(props.disableSubmitTrigger ? { onFieldsChange } : {})}
        >
            {getFormFields(props.fields, props.id, props.fieldValues, form)}
            {getFormActions(props.actions, disableSubmitButton)}
        </Form>
    );
};

/**
 * Creates a list of form fields based on the 2D array of fields
 *
 * @param {FormFieldDefinition[][]|FormElementDefinition[][]} fieldRows 2D list of fields
 * @param {string} formId unique id of the form
 * @returns {React.FC} form fields
 */
const getFormFields = (fieldRows: FormFieldDefinition[][] | FormElementDefinition[][] = [], formId: string = '', values: any, form: FormInstance) => {
    const MAX_SPAN = 24;
    const COL_SPACES = 12;

    return <>
        {fieldRows.map((fieldRow, rowIndex) => <Row gutter={COL_SPACES} key={`row-gutter-${formId}_${rowIndex}`}>
            {fieldRow.map((fieldDefinition, colIndex) => {
                const colSpan = Math.ceil(MAX_SPAN / fieldRow.length);
                if (Object.values(FormElementType).includes(fieldDefinition.type as FormElementType)) {
                    const formElementDefinition = fieldDefinition as FormElementDefinition;

                    return <Col md={formElementDefinition.colSpan || colSpan} sm={24} key={`col-element-${rowIndex}-${colIndex}`} className={formElementDefinition.colClassName}>
                        <FormElement key={`colitem-element-${rowIndex}-${colIndex}`} {...formElementDefinition} />
                    </Col>;
                } else {
                    if (fieldDefinition.type === FormFieldType.ADDRESS) {
                        return <Col md={fieldDefinition.colSpan || colSpan} sm={24} key={`col-field-${rowIndex}-${colIndex}`} className={fieldDefinition.colClassName}>
                            <FormField key={`colitem-field-${rowIndex}-${colIndex}`} {...fieldDefinition as FormFieldDefinition} form={form} value={values ? values[fieldDefinition.name] : undefined} />
                        </Col>;
                    }
                    return <Col md={fieldDefinition.colSpan || colSpan} sm={24} key={`col-field-${rowIndex}-${colIndex}`} className={fieldDefinition.colClassName}>
                        <FormField key={`colitem-field-${rowIndex}-${colIndex}`} {...fieldDefinition as FormFieldDefinition} />
                    </Col>;
                }
            })}
        </Row>)}
    </>;
};

/**
 * Creates a list of form actions based on the array of actions
 *
 * @param {FormActionProps[]} actions list of actions
 * @param {boolean} disableSubmitButton whether to disable the submit button
 * @returns {React.FC} form actions
 */
const getFormActions = (actions: FormActionProps[] = [], disableSubmitButton: boolean) => (
    actions.length ? <Form.Item  >
        <div className='zcvp-form-footer'>
            {actions.map((actionDefinition, index) => <FormAction key={`formAction-${actionDefinition.type}-${index}`} {...{ ...actionDefinition, ...{ disableSubmitButton } }} />)}
        </div>
    </Form.Item> : null
);
export default GenericForm;