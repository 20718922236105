import { Typography } from 'antd';
import React from 'react';

const PageTitle = ({ title }) => {
    return (
        <Typography.Title level={4}
            className={'text-primary zcvp-page-title'} data-testid={'zcvp-nav-toggle-trigger-disabled'}>{title}</Typography.Title>
    );
}

export default PageTitle;