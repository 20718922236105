import { UpdateResult } from "../../@types/user-profile/zcvp_user_profile";

export class UpdateProfileGateway {
    private editUser: Function;
    constructor(editUser: Function) {
        this.editUser = editUser;
    }
    updateProfile(updateValues : {}): UpdateResult {
        return this.editUser(updateValues);
    }

}