import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import { VendorRequestsGateway } from '../../@types/vendor-requests/zcvp_vendor_requests';

export class GetVendorRequestsGateway implements VendorRequestsGateway {
    private vendorsList: TableRecord[]|undefined;
    constructor(vendorsList?: TableRecord[]) {
        this.vendorsList = vendorsList
    }
    public getVendorsList() {
        return this.vendorsList || [];
    }
}