import { Layout, Table, TableProps } from 'antd';
import React, { useEffect, useMemo } from 'react';
import ActionGroup, { ActionDefinition } from './ActionGroup';

import { GetComponentProps } from 'rc-table/lib/interface';
import { TableColumnType, TableRecord, TableRowValue } from '../../@types/list-details/zcvp_get_list_details';
import '../../styles/zcvp_table_card.scss';

const { Content } = Layout;

type TableCardProps = {
    columns: TableColumnType[];
    actions: ActionDefinition[];
    dataSource: TableRecord[];
    tableProps?: TableProps;
    children?: React.ReactNode;
    allowPropagation?: boolean;
    onRow?: GetComponentProps<TableRecord>;
    onSelect?: (selectedRows: TableRecord[]) => void;
    onSelectAll?: (selected: boolean, selectedRows: TableRecord[], changeRows: TableRecord[]) => void;
    renderCell?: (checked: boolean, record: TableRecord, index: number, _originNode: React.ReactNode) => React.ReactNode;
}
const TableCard: React.FC<TableCardProps> = ({ columns, actions, dataSource, setFilters, tableProps = {}, onRow, onSelect, children, renderCell, onSelectAll }: TableCardProps) => {
    const enableSelection = useMemo(() => onSelect || onSelectAll, [onSelect, onSelectAll]);
    const actionColumn = useMemo<TableColumnType>(() => ({
        title: '',
        dataIndex: 'action',
        key: 'action',
        render: (_: TableRowValue, data: TableRecord) => <ActionGroup data={data} actions={actions} />
    }), [actions]);

    const columnsWithActions: TableColumnType[] = useMemo(() => actions && actions.length ? ([
        ...columns,
        actionColumn
    ]) : columns, [actions, columns, actionColumn]);

    const updatedColumns = useMemo(() => columnsWithActions.map(column => ({
        ...column,
        filteredValue: setFilters ? column.filteredValue : undefined,
        filters: !setFilters ? column.filters : undefined,
    })), [columnsWithActions, setFilters]);

    useEffect(() => {
        const columnsWithFilters = columns.filter(column => column.filters?.length);
        setFilters && setFilters(columnsWithFilters);
    }, [columns, setFilters]);

    return (
        <Content className={'zcvp-table-card'}>
            <Table
                columns={updatedColumns}
                dataSource={dataSource}
                bordered
                rowSelection={enableSelection ? {
                    type: 'checkbox',
                    onSelect: (_record, _selected, selectedRows) => onSelect && onSelect(selectedRows),
                    onSelectAll: (selected, selectedRows, changeRows) => onSelectAll && onSelectAll(selected, selectedRows, changeRows),
                    renderCell: (_checked, _record, _index, _originNode) => renderCell ? renderCell(_checked, _record, _index, _originNode) : _originNode,
                } : undefined}
                onRow={onRow}
                scroll={{ y: 'calc(100vh - 401px)' }}
                {...tableProps}/>
            {children}
        </Content>
    );
};

export default TableCard;