import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import { VendorRequestsPresenter } from '../../@types/vendor-requests/zcvp_vendor_requests';
import { ApiRequestStatus } from '../../@types/zcvp_api_enum';
import { StatusColor } from '../../@types/zcvp_base_types_enum';
import i18n from '../../i18n';
import { TranslationNS } from '../../lib/TranslationHelper';
import { formatDate } from '../util/dateUtil';

export class GetVendorRequestsPresenter implements VendorRequestsPresenter {
    public convertRequests(requests: TableRecord[], isVendor: boolean): TableRecord[] {
        return requests.map(request => this.convertRequest(request, isVendor));
    }
    public convertRequest(request: TableRecord, isVendor: boolean): TableRecord {
        return (request ? {
            ...request,
            color: this.getColor(request.status as string),
            statusText: this.getStatusText(request.status as string, isVendor),
            requestTypeText: this.formatText(request.type as string),
            requestedOn: formatDate(request.createdAt as string)
        } : {}) as TableRecord;
    }
    private getColor(requestStatus: string): string | undefined {
        const statusColorMap: {[key: string]: string} = {
            [ApiRequestStatus.NEW]: StatusColor.BLUE,
            [ApiRequestStatus.REVIEW]: StatusColor.GOLD,
            [ApiRequestStatus.APPROVED]: StatusColor.GREEN,
            [ApiRequestStatus.REJECTED]: StatusColor.RED,
            [ApiRequestStatus.CANCELLED]: StatusColor.RED,
        };
        return statusColorMap[requestStatus];
    }
    private getStatusText(value: string = '', isVendor: boolean): string {
        const pendingStatusKey = isVendor ? 'pending_review' : 'to_review';
        const statusTextMap: {[key: string]: string} = {
            [ApiRequestStatus.REVIEW]: i18n.t(`${TranslationNS.VENDOR_REQUEST}.${pendingStatusKey}`, { ns: TranslationNS.VENDOR }),
            [ApiRequestStatus.NEW]: i18n.t(`${TranslationNS.VENDOR_REQUEST}.pending`, { ns: TranslationNS.VENDOR })
        };
        return statusTextMap[value] || this.formatText(value);
    }
    private formatText(value: string = ''): string {
        const valueToFormat = value?.replace(/_/g, ' ');
        return `${valueToFormat.slice(0, 1).toUpperCase()}${valueToFormat.slice(1).toLowerCase()}`;
    }
}