import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Skeleton } from 'antd';

import DashboardCardTitle from './DashboardCardTitle';
import VendorTable from '../vendor-management/VendorTable';
import CardComponent from '../../components/partials/CardComponent';
import { TranslationNS } from '../../lib/TranslationHelper';
import { useGetAllVendorsQuery } from '../../slice/APISlice';
import { CheckRolePermissionController } from '../../use-case/checkRolePermission/checkRolePermissionController';
import { UserState } from '../../@types/zcvp_state';
import { RoutesUrls } from '../../@types/zcvp_routes_enum';
import { PortalFeature } from '../../@types/role-permissions/zcvp_role_permissions_enum';
import { ApiRecord, ApiRequest, ApiVendorStatus } from '../../@types/zcvp_api_enum';
import { getOnboardingVendorStatusFilterOptions } from '../../use-case/getVendors/getVendorsUseCase';

export const PENDING_VENDOR_APPROVAL_FILTER: ApiRequest.FilterModel = {
    filterModel: {
        [ApiRecord.VendorField.STATUS]: {
            values: [ApiVendorStatus.PENDING_ONBOARDING, ApiVendorStatus.PENDING_APPROVAL_TO_ONBOARD, ApiVendorStatus.PENDING_APPROVAL_AFTER_ONBOARD],
            filterType: ApiRequest.FilterType.SET
        }
    }
};

export const PendingVendorListCard = () => {
    const { t } = useTranslation(TranslationNS.DASHBOARD);
    const user = useSelector((state: { user: UserState }) => state.user);
    const canSeeVendors = useMemo(() => new CheckRolePermissionController(user).executeFeatureCheck(PortalFeature.VENDOR_LIST), [user]);

    const { data: vendors, isLoading: isLoadingVendors } = useGetAllVendorsQuery(PENDING_VENDOR_APPROVAL_FILTER);
    const vendorCount = useMemo(() => vendors?.length || 0, [vendors]);

    const lookup = useMemo(() => ({
        [ApiRecord.VendorField.STATUS]: getOnboardingVendorStatusFilterOptions(),
    }), []);

    return (
        canSeeVendors ? <Skeleton loading={isLoadingVendors}>
            <CardComponent
                title={<DashboardCardTitle
                    title={t('pending_vendors', { count: vendorCount })}
                    titleDetails={t('active_count', { count: vendorCount })}
                />}
                subtitle={t('pending_vendors_description', { count: vendorCount })}
                titleNotifications={[
                    <Link to={RoutesUrls.VENDORS} key='see-vendors-btn'>{t('see_all_vendors')}</Link>
                ]}
                className={'grow zcvp-dash-card-list zcvp-dash-card-parent'}
            >
                {vendorCount
                    ? <VendorTable
                        filter={PENDING_VENDOR_APPROVAL_FILTER}
                        lookup={lookup}
                        tableProps={{ size: 'small'}} />
                    : null}
            </CardComponent>
        </Skeleton> : null
    );
};

export default PendingVendorListCard;