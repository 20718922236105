import { GetUserRolesGatewayParams, UserRolesGateway } from '../../@types/role-permissions/zcvp_role_permissions';
import { ApiRecord } from '../../@types/zcvp_api_enum';

export class GetUserRolesGateway implements UserRolesGateway {
    private roles;
    private user;
    constructor({roles, user}: GetUserRolesGatewayParams) {
        this.roles = roles;
        this.user = user;
    }
    public getRoleById(roleId: number): ApiRecord.AppRole {
        return this.roles?.find(role => role.id === roleId) || {} as ApiRecord.AppRole;
    }
    public getCurrentUserProfile(): ApiRecord.UserProfile {
        return this.user as unknown as ApiRecord.UserProfile;
    }
    public getOtherProfiles() {
        return this.user.otherProfiles;
    }
}
