import { CurrencyQueryData, IVendorCompanyData, IVendorCompanyDataPresenter, VendorCategoryQueryData, VendorCompanyFieldKey } from '../../@types/vendor-company/zcvp_vendor_company';
import { VendorCompanyField } from '../../@types/vendor-company/zcvp_vendor_company_enum';
import { BaseFieldKey, FieldDefinitionType } from '../../@types/zcvp_form';
import { FormFieldType } from '../../@types/zcvp_form_enum';
import i18n from '../../i18n';
import { COMPANY_SIZE_OPTIONS, CompanyType } from '../../lib/Constants';
import { TranslationNS, getCommonT } from '../../lib/TranslationHelper';
import { BaseDataPresenter } from '../BaseDataPresenter';

export class VendorCompanyPresenter extends BaseDataPresenter<VendorCompanyFieldKey> implements IVendorCompanyDataPresenter {
    public vendorCategories: VendorCategoryQueryData|undefined;
    public currencies: CurrencyQueryData|undefined;

    constructor(vendorCategories?: VendorCategoryQueryData, currencies?: CurrencyQueryData) {
        super();
        this.vendorCategories = vendorCategories;
        this.currencies = currencies;

        if (this.vendorCategories?.data) {
            this.fieldDefinition[VendorCompanyField.CATEGORY].options = this.vendorCategories.data!.map((vendorCategory) => ({
                label: vendorCategory.name,
                value: vendorCategory.id.toString(),
                id: vendorCategory.id,
            }));
        }

        if (this.currencies?.data) {
            this.fieldDefinition[VendorCompanyField.PRIMARY_CURRENCY].options = this.currencies.data!.map((currency) => ({
                label: currency.name,
                value: currency.id.toString(),
                id: currency.id,
            }));
        }
    }

    public fieldDefinition: FieldDefinitionType = {
        [VendorCompanyField.COMPANY_NAME]: {
            name: 'companyName',
            type: FormFieldType.TEXT,
            label: i18n.t('company_details.company_name_label', { ns: TranslationNS.VENDOR }),
            required: true
        },
        [VendorCompanyField.COMPANY_SIZE]: {
            name: 'size',
            type: FormFieldType.SELECT,
            label: i18n.t('company_details.company_size_label', { ns: TranslationNS.VENDOR }),
            options: COMPANY_SIZE_OPTIONS
        },
        [VendorCompanyField.CATEGORY]: {
            name: 'vendorCategoryId',
            type: FormFieldType.SELECT,
            label: i18n.t('company_details.category_label', { ns: TranslationNS.VENDOR }),
            required: true
        },
        [VendorCompanyField.BILLING_ADDRESS]: {
            name: 'billing_address',
            type: FormFieldType.ADDRESS,
            label: i18n.t('company_details.billing_address_label', { ns: TranslationNS.VENDOR }),
        },
        [VendorCompanyField.SHIPPING_ADDRESS]: {
            name: 'shipping_address',
            type: FormFieldType.ADDRESS,
            // label: i18n.t('company_details.shipping_address_label', { ns: TranslationNS.VENDOR }),
        },
        [VendorCompanyField.ADDRESSES_EQUAL]: {
            name: VendorCompanyField.ADDRESSES_EQUAL,
            type: FormFieldType.CHECKBOX,
            label: i18n.t('company_details.shipping_address_label', { ns: TranslationNS.VENDOR }),
            checkboxLabel: i18n.t('company_details.shipping_same_as_billing_label', { ns: TranslationNS.VENDOR }),
        },
        [VendorCompanyField.COMPANY_EMAIL]: {
            name: VendorCompanyField.COMPANY_EMAIL,
            type: FormFieldType.EMAIL,
            label: i18n.t('company_details.company_email_label', { ns: TranslationNS.VENDOR }),
            required: true
        },
        [VendorCompanyField.COMPANY_URL]: {
            name: VendorCompanyField.COMPANY_URL,
            type: FormFieldType.WEB_ADDRESS,
            label: i18n.t('company_details.company_url_label', { ns: TranslationNS.VENDOR }),
        },
        [VendorCompanyField.PHONE_NUMBER]: {
            name: VendorCompanyField.PHONE_NUMBER,
            type: FormFieldType.PHONE,
            label: i18n.t('company_details.company_phone_label', { ns: TranslationNS.VENDOR }),
        },
        [VendorCompanyField.PHONE_NUMBER_ALT]: {
            name: VendorCompanyField.PHONE_NUMBER_ALT,
            type: FormFieldType.PHONE,
            label: i18n.t('company_details.company_alt_phone_label', { ns: TranslationNS.VENDOR }),
        },
        // Legal Info
        [VendorCompanyField.LEGAL_NAME]: {
            name: VendorCompanyField.LEGAL_NAME,
            type: FormFieldType.TEXT,
            label: i18n.t('legal_info.legal_name_label', { ns: TranslationNS.VENDOR })
        },
        [VendorCompanyField.SUBSIDIARY_ID]: {
            name: 'subsidiaryId',
            type: FormFieldType.SELECT,
            label: getCommonT('subsidiary'),
            required: true,
        },
        [VendorCompanyField.TAX_ID]: {
            name: 'taxId',
            type: FormFieldType.TEXT,
            label: i18n.t('legal_info.tax_id_label', { ns: TranslationNS.VENDOR }),
        },
        // Currently not supported
        /* [VendorCompanyField.TAX_REG_NUMBER]: {
            name: 'taxRegNumber',
            type: FormFieldType.TEXT,
            label: 'Tax Reg. Number',
        }, */
        [VendorCompanyField.PRIMARY_CURRENCY]: {
            name: 'currencyId',
            type: FormFieldType.SELECT,
            label: i18n.t('legal_info.primary_currency_label', { ns: TranslationNS.VENDOR }),
        },
        [VendorCompanyField.CREDIT_LIMIT]: {
            name: 'creditLimit',
            type: FormFieldType.NUMBER,
            label: i18n.t('legal_info.credit_limit_label', { ns: TranslationNS.VENDOR }),
        },
        [VendorCompanyField.TERMS]: {
            name: 'termId',
            type: FormFieldType.NUMBER,
            label: i18n.t('legal_info.terms_label', { ns: TranslationNS.VENDOR }),
        },
        [VendorCompanyField.COMPANY_AVATAR]: {
            name: 'companyAvatar',
            type: FormFieldType.TEXT,
            label: i18n.t('company_avatar_label', { ns: TranslationNS.VENDOR }),
        },
    };

    public companyDetailsFieldsLayout: BaseFieldKey<VendorCompanyFieldKey>[][] = [
        [VendorCompanyField.COMPANY_NAME, VendorCompanyField.COMPANY_SIZE],
        [VendorCompanyField.CATEGORY],
        [VendorCompanyField.BILLING_ADDRESS],
        [VendorCompanyField.ADDRESSES_EQUAL],
        [VendorCompanyField.SHIPPING_ADDRESS],
        [VendorCompanyField.COMPANY_EMAIL, VendorCompanyField.COMPANY_URL],
        [VendorCompanyField.PHONE_NUMBER, VendorCompanyField.PHONE_NUMBER_ALT],
    ];

    public legalInformationFieldsLayout: BaseFieldKey<VendorCompanyFieldKey>[][] = [
        [VendorCompanyField.LEGAL_NAME],
        [VendorCompanyField.TAX_ID, /* VendorCompanyField.TAX_REG_NUMBER */],
        [VendorCompanyField.PRIMARY_CURRENCY]
    ];

    public avatarFieldsLayout: VendorCompanyFieldKey[][] = [
        [VendorCompanyField.COMPANY_AVATAR],
    ];

    public convert({ isShippingSameAsBilling, isCompanyDetailDisabled, isIndividual, values }): IVendorCompanyData {
        const response: IVendorCompanyData = {
            companyDetailsFields: [],
            legalInformationFields: [],
            avatarFields: [],
        };
        if (values) {
            this.updateFields(isShippingSameAsBilling, isCompanyDetailDisabled, isIndividual);
            response.companyDetailsFields = this.convertFields(this.companyDetailsFieldsLayout);
            response.legalInformationFields = this.convertFields(this.legalInformationFieldsLayout);
            response.avatarFields = this.convertFields(this.avatarFieldsLayout);
        }
        return response;
    }
    private updateFields(isShippingSameAsBilling: boolean, isCompanyDetailDisabled: boolean, isIndividual: boolean): void {
        this.fieldDefinition[VendorCompanyField.SHIPPING_ADDRESS].disabled = isShippingSameAsBilling || isCompanyDetailDisabled;
        this.fieldDefinition[VendorCompanyField.BILLING_ADDRESS].disabled = isCompanyDetailDisabled;
        if (isIndividual) {
            this.fieldDefinition[VendorCompanyField.COMPANY_SIZE].disabled = true;
        } else {
            this.fieldDefinition[VendorCompanyField.COMPANY_SIZE].options = COMPANY_SIZE_OPTIONS
                .filter(size => size.id !== CompanyType.INDIVIDUAL.toString());
        }
    }
}
