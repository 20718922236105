import { InitialValues, InviteUserData, InviteUserDataGateway, RoleDetail } from '../../@types/invite-users/zcvp_invite_users';
import { FieldValue, FormFieldDefinition, FormFieldOption } from '../../@types/zcvp_form';
import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';

export enum Field {
    FIRST_NAME = 'firstname',
    LAST_NAME = 'lastname',
    FULL_NAME = 'fullname',
    ROLE = 'role'
}

enum Role {
    ADMIN = 'admin',
    USER = 'user',
    APPROVER = 'vendor-approver'
}

export class GetInviteUserDataUseCase {
    private gateway: InviteUserDataGateway;
    constructor(gateway: InviteUserDataGateway) {
        this.gateway = gateway;
    }
    execute(user?: TableRecord, userIds?: FieldValue[], selectedRole?: string): InviteUserData {
        const nameFields = this.getNameFields(user, userIds);
        const initialValues = this.getInitialValues(nameFields);
        return {
            nameFields,
            initialValues,
            roleField: this.getRoleField(selectedRole),
            roleDetails: this.getAllRoleDetails()
        };
    }
    private getNameFields(user?: TableRecord, userIds?: FieldValue[]): FormFieldDefinition[] {
        const nameFields: FormFieldDefinition[] = [];
        const users = this.getUsers();
        if (users && users.length) {
            nameFields.push(this.getFullNameField(users, userIds));
        } else if (user) {
            nameFields.push(this.getFirstNameField(user.firstName!));
            nameFields.push(this.getLastNameField(user.lastName!));
        }
        
        return nameFields;
    }
    private getUsers(): TableRecord[] {
        return this.gateway.getUsers()?.filter(user => !user.registered) as TableRecord[];
    }
    private getRoleField(selectedRole?: string): FormFieldDefinition {
        const roles = this.getRoleOptions();
        const roleField = this.gateway.getField(Field.ROLE);
        const roleDescription = this.getRoleDescription(selectedRole);
        
        return {
            ...roleField,
            subText: roleDescription,
            options: roles,
            value: selectedRole
        };
    }
    private getRoleOptions() {
        const CUSTOMER_ROLES = [Role.ADMIN, Role.USER, Role.APPROVER];
        const roles = this.gateway.getRoles();
        return roles.filter(role => CUSTOMER_ROLES.includes(role.name as Role));
    }
    private getAllRoleDetails(): RoleDetail[] {
        return this.gateway.getAllRoleDetails();
    }
    private getFullNameField(users: TableRecord[], userIds?: FieldValue[]): FormFieldDefinition {
        const fullNameField = this.gateway.getField(Field.FULL_NAME);

        return {
            ...fullNameField,
            options: this.getValidUsers(users),
            value: userIds
        };
    }
    private getFirstNameField(firstName: string): FormFieldDefinition {
        const firstNameField = this.gateway.getField(Field.FIRST_NAME);
        
        return {
            ...firstNameField,
            value: firstName
        };
    }
    private getLastNameField(lastName: string): FormFieldDefinition {
        const lastNameField = this.gateway.getField(Field.LAST_NAME);
        
        return {
            ...lastNameField,
            value: lastName
        };
    }
    private getRoleDescription(selectedRole?: string): string|undefined {
        const roleDetails = this.gateway.getRoleDetails(selectedRole);
        
        return roleDetails ? `${roleDetails?.name} - ${roleDetails?.description}` : undefined;
    }
    private getInitialValues(nameFields: FormFieldDefinition[]): InitialValues {
        return {
            ...this.getNameFieldValues(nameFields),
            [Field.ROLE]: this.getUserRole()
        };
    }
    private getNameFieldValues(nameFields: FormFieldDefinition[]): InitialValues {
        return nameFields.filter(nameField => nameField.value).reduce((_initialValues, fieldValue) => {
            _initialValues[fieldValue.name] = fieldValue.value;
            return _initialValues;
        }, {} as InitialValues);
    }
    private getUserRole(): number {
        return this.gateway.getRoles().find(role => role.name === Role.USER)?.id as number;
    }
    private getValidUsers(users: TableRecord[]): FormFieldOption[] {
        return users.filter(user => !!user.fullName && !!user.email) as unknown as FormFieldOption[];
    }
}