import { Form } from 'antd';
import { MessageInstance } from 'antd/lib/message/interface';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import { Feature } from '../../@types/submit-record/zcvp_submit_record_enum';
import { ApiRecord } from '../../@types/zcvp_api_enum';
import { FormElementDefinition, FormFieldDefinition } from '../../@types/zcvp_form';
import { ActionType, FormElementType, FormFieldType } from '../../@types/zcvp_form_enum';
import { getCommonT, TranslationNS } from '../../lib/TranslationHelper';
import { useChangePasswordMutation } from '../../slice/APISlice';
import { SubmitRecordController } from '../../use-case/submitRecord/submitRecordController';
import GenericForm from '../form/GenericForm';
import GenericModal from './GenericModal';

export default function ChangePasswordModal() {
    const { t } = useTranslation(TranslationNS.USER, { keyPrefix: TranslationNS.USER_PROFILE });
    const { messageApi } = useOutletContext<{ messageApi: MessageInstance }>();
    const [form] = Form.useForm();
    const [changePassword] = useChangePasswordMutation();
    const changePasswordFormFields = [
        [{
            name: ApiRecord.PasswordChangeField.OLD_PASSWORD,
            label: t('current_password_label'),
            type: FormFieldType.PASSWORD,
            hideHint: true,
            skipValidationRule: true
        }],
        [{
                type: FormElementType.DIVIDER,
                colClassName: 'grow'
        }],
        [{
                name: ApiRecord.PasswordChangeField.NEW_PASSWORD,
                label: t('new_password_label'),
                type: FormFieldType.PASSWORD,
                hideHint: true,
        }],
        [{
            name: 'confirm_new_password',
            label: t('confirm_new_password_label'),
            type: FormFieldType.PASSWORD,
            otherProps: {
                matchToField: {
                    name: ApiRecord.PasswordChangeField.NEW_PASSWORD,
                    form: form
                }
            }
        }]
    ] as FormFieldDefinition[][] | FormElementDefinition[][];

    const [open, setIsOpen] = useState(false);
    const onClose = useCallback(() => {
        form.resetFields();
        setIsOpen(false);
    }, [form]);

    const onSubmit = useCallback((values: TableRecord) => {
        new SubmitRecordController({
            onSubmit: changePassword,
            onClose,
            messageApi
        }).execute(Feature.PASSWORD_CHANGE, {
            [ApiRecord.PasswordChangeField.OLD_PASSWORD]: values[ApiRecord.PasswordChangeField.OLD_PASSWORD],
            [ApiRecord.PasswordChangeField.NEW_PASSWORD]: values[ApiRecord.PasswordChangeField.NEW_PASSWORD]
        });
        onClose();
    }, [changePassword, onClose, messageApi, form]);

    return (
        <GenericModal
            open={open}
            triggerButton={{
                content: t('change_password_button'),
                props: { type: 'primary' }
            }}
            title={t('change_password_modal_title')}
            onOpenCallback={() => setIsOpen(true)}
            onCancelCallback={onClose}
            includeFooter={false}
            id={'change_password'}
        >
            <GenericForm
                formProps={{
                    form,
                    onFinish: onSubmit
                }}
                fields={changePasswordFormFields}
                actions={[{
                    type: ActionType.CANCEL,
                    definition: {
                        label: getCommonT('cancel'),
                        key: 'cancel-btn',
                        name: 'cancel-btn',
                        onClick: onClose
                    }
                }, {
                    type: ActionType.SUBMIT,
                    definition: {
                        label: t('change_password_button'),
                        key: 'change-password-btn',
                        name: 'change-password-btn'
                    }
                }]} />
        </GenericModal>
    );
}