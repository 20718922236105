import { PortalTutorialStep, PortalTutorialStepData, TutorialButtonOnClick } from "../../@types/tutorial/zcvp_tutorial";
import { GetTutorialStepsPresenter } from "./getTutorialStepsPresenter";
import { GetTutorialStepsUseCase } from "./getTutorialStepsUseCase";

export class PortalTutorialController {
    private goNext: TutorialButtonOnClick;
    private goPrevious: TutorialButtonOnClick;
    constructor(goNext: TutorialButtonOnClick, goPrevious: TutorialButtonOnClick) {
        this.goNext = goNext;
        this.goPrevious = goPrevious;
    }
    public execute(tutorialSteps: PortalTutorialStep[], userName: string): PortalTutorialStepData[] {
        const presenter = new GetTutorialStepsPresenter(this.goNext, this.goPrevious);
        return new GetTutorialStepsUseCase(presenter).execute(tutorialSteps, userName);
    }
}