import { Card, Popover } from 'antd';
import { PropsWithChildren } from 'react';
import '../../styles/zcvp_card.scss';
import { InfoCircleOutlined } from '@ant-design/icons';
import { ZCVPCardTitlePopover } from '../../@types/zcvp_layout';

type ZCVPCardProps = {
    title: string|JSX.Element;
    className?: string;
    titlePopover?: ZCVPCardTitlePopover;
    titleNotifications?: Array<string|JSX.Element>;
    actions?: Array<string|JSX.Element>;
    subtitle?:string;
    loading?:boolean;
    elementRef?: React.RefObject<HTMLInputElement>;
}
const buildCardHeader = (title: ZCVPCardProps['title'], subtitle?: string, titlePopover?:ZCVPCardTitlePopover, titleNotifications?: ZCVPCardProps['titleNotifications']) => {
    if (!title) {return null;}

    return (
        <>
            <div className={'flex flex-row items-center'}>
                <div className={'grow'}>{title}{buildTitlePopover(titlePopover)}</div>
                {titleNotifications ? <div className={'shrink zcvp-card-title-notifications'}>{titleNotifications.map((titleNotification) => titleNotification)}</div> : null}
            </div>
            {subtitle ? <div className={'zcvp-card-subtitle'}>{subtitle}</div> : null}
        </>
    );
};

const buildTitlePopover = ({ title, icon = <InfoCircleOutlined />, content = '', trigger = 'click' }: ZCVPCardTitlePopover = { content: '' }) => {
    if (!content){
        return null;
    }
    
    return (
        <Popover content={content} title={title} trigger={trigger} className={'ml-2'}>
            {icon}
        </Popover>
    );
};
const CardComponent = ({ title, subtitle, titlePopover, titleNotifications, actions, className = '', loading = false, elementRef, children }: PropsWithChildren<ZCVPCardProps>) => (
    <Card ref={elementRef} title={buildCardHeader(title, subtitle, titlePopover, titleNotifications)} className={`zcvp-card ${className}`} loading={loading} actions={actions}>
        {children}
    </Card>
);

export default CardComponent;