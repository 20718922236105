import { FilterMap, ListDetailField, ListDetailRecord, ListDetailsGateway, ListDetailsPresenter } from '../../@types/list-details/zcvp_get_list_details';

/**
 * Contains the business logic for getting the list of columns and rows given a list id
 */
export class GetListDetailsUseCase {
    private gateway: ListDetailsGateway;
    private presenter: ListDetailsPresenter;
    private postProcessor: Function|undefined;
    constructor(gateway: ListDetailsGateway, presenter: ListDetailsPresenter, postProcessor?: Function) {
        this.gateway = gateway;
        this.presenter = presenter;
        this.postProcessor = postProcessor;
    }
    public execute(listId: string, filterValue?: string, filters: FilterMap = {}) {
        const allFields = this.gateway.getFields(listId);
        const records = this.getRecords();

        const fields = this.getFieldsToDisplay(listId, allFields);
        const filteredRecords = this.applySearchFilter(listId, records, filterValue);
        const fieldsWithFilters = this.addFilterOptions(listId, fields, filters);

        return this.presenter.convert(listId, fieldsWithFilters, filteredRecords);
    }
    private getRecords() {
        const records = this.gateway.getRecords();
        if(this.postProcessor) {
            return this.postProcessor(records);
        }
        return records;
    }
    private getFieldsToDisplay(listId:string, fields: ListDetailField[]): ListDetailField[] {
        const displayedFields = this.gateway.getDisplayedFields(listId);
        const additionalFields = this.gateway.getAdditionalFields(listId);
        const allFields = additionalFields.concat(fields);
        return displayedFields
            .map(displayedField => allFields.find(field => field.fieldId === displayedField)!)
            .filter(displayedField => !!displayedField);
    }
    private applySearchFilter(listId: string, records: ListDetailRecord[], filterValue?: string): ListDetailRecord[] {
        if(filterValue) {
            const searchableFields = this.gateway.getSearchableFields(listId);
            return this.filterRecords(records, filterValue, searchableFields);
        }
        return records;
    }
    private addFilterOptions(listId: string, fields: ListDetailField[], filters: FilterMap): ListDetailField[] {
        return fields.map(field => {
            const isFilterSearchable = this.gateway.isFilterSearchable(listId, field.fieldId);
            const filterList = this.gateway.isFilterable(listId, field.fieldId) ? this.gateway.getList(field.fieldId) : undefined;
            return { ...field, filters: filterList, isFilterSearchable, filterValue: filters[field.fieldId] };
        });
    }
    private filterRecords(records: ListDetailRecord[], value: string, searchableFields: string[]) {
        return records.filter(record => searchableFields.some(searchableField => {
            const recordValue = record[searchableField]?.toString().toLowerCase() || '';
            const filterValue = value.toLowerCase()
            return recordValue.includes(filterValue);
        }));
    }
}