import { AuthenticationPresenter, AuthenticationUseCaseParams } from "../../@types/authentication/zcvp_authentication";

export enum AuthenticationCode {
    AUTHORIZED_WITH_API_ERROR = 'authorizedwithapierror',
    AUTHORIZED_INVITATION_PAGE = 'authorizedinvitation',
    AUTHORIZED_INVALID_PAGE = 'authorizedinvalid',
    UNAUTHORIZED = 'unauthorized',
    DEFAULT = 'default'
}

export class ManageAuthenticationUseCase {
    private presenter: AuthenticationPresenter;
    constructor(presenter: AuthenticationPresenter) {
        this.presenter = presenter;
    }
    public execute(authenticationData: AuthenticationUseCaseParams): void {
        const authenticationCode = this.getAuthenticationCode(authenticationData);
        return this.presenter.convert(authenticationCode, authenticationData.pathName);
    }
    private getAuthenticationCode({ isAuthorized, hasApiError, page, unauthorizedPages, invitationPages }: AuthenticationUseCaseParams): string {
        if(invitationPages.includes(page)) {
            return AuthenticationCode.AUTHORIZED_INVITATION_PAGE;
        } else if (hasApiError && isAuthorized) {
            return AuthenticationCode.AUTHORIZED_WITH_API_ERROR;
        } else if (isAuthorized && unauthorizedPages.includes(page)) {
            return AuthenticationCode.AUTHORIZED_INVALID_PAGE;
        } else if (!isAuthorized && !unauthorizedPages.includes(page)) {
            return AuthenticationCode.UNAUTHORIZED;
        } else {
            return AuthenticationCode.DEFAULT;
        }
    }
}