import React from 'react';
import { useSelector } from 'react-redux';
import { AppState, UserState } from '../../@types/zcvp_state';
import { ZCVPMenuEntryConfigKeys, ZCVPMenuEntryConfigLookup } from '../../components/navigation/NavigationConfig';
import { DashboardVendor } from './DashboardVendor';
import { DashboardCustomer } from './DashboardCustomer';

export const Dashboard = ({}) => {
    //returns the subpage set as home for this role
    const defaultSubportal = useSelector(
        (state: AppState) => state?.layoutTheme?.verticalSubPageMenu?.find((menuId: ZCVPMenuEntryConfigKeys) => ZCVPMenuEntryConfigLookup[menuId].path === '/')
    );
    const isVendor = useSelector((state: { user: UserState }) => state.user.isVendor);

    //to prevent a flash of the wrong page
    if (!defaultSubportal) {return null;}

    if (isVendor) {
        return  <DashboardVendor />;
    }

    return <DashboardCustomer />;
};