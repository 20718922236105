import { AuthenticationView } from '../../@types/authentication/zcvp_authentication';
import { RoutesUrls, StorageKey } from "../../@types/zcvp_routes_enum";
import i18next from '../../i18n';
import { AuthenticationCode } from './manageAuthenticationUseCase';

export class ManageAuthenticationView implements AuthenticationView {
    private logout: Function;
    private setRedirecting: Function;
    constructor(logout: Function, setRedirecting: Function) {
        this.logout = logout;
        this.setRedirecting = setRedirecting;
    }
    public render(authenticationCode: string, pathName: string) {
        switch (authenticationCode) {
            case AuthenticationCode.AUTHORIZED_WITH_API_ERROR:
                this.logout();
                this.setRedirecting(true);

                //tried to do this with navigate, but I couldn't figure it out
                // note B: I tried using apiState.util.resetApiState() here, but it didn't work consistently
                // because we're keeping the state, the API queries do not get reset, so once one fails there's
                // always a failing one. this totally wipes the state
                this.setLocationUrl(RoutesUrls.LOGIN);
                this.setLoginPageMessage(i18next.t('authentication:session_expired'));
                break;
            case AuthenticationCode.AUTHORIZED_INVITATION_PAGE:
                this.logout();
                break;
            case AuthenticationCode.AUTHORIZED_INVALID_PAGE:
                // doing this to clear server errors from a bad login attempt
                this.setLoginPageMessage('');
                this.setLocationUrl(RoutesUrls.DASHBOARD);
                break;
            case AuthenticationCode.UNAUTHORIZED:
                this.setRedirecting(true);
                this.setDeepLink(pathName);
                this.setLocationUrl(RoutesUrls.LOGIN);
                this.setLoginPageMessage('');
                break;
            default:
                this.setRedirecting(false);
        }
    }
    private setLocationUrl(locationUrl: string) {
        window.location.href = (`/${locationUrl}`);
    }
    private setLoginPageMessage(loginPageMessage: string) {
        localStorage.setItem(StorageKey.LOGIN_PAGE_MESSAGE, loginPageMessage);
    }
    private setDeepLink(deepLink: string) {
        localStorage.setItem(StorageKey.DEEP_LINK, deepLink);
    }
}