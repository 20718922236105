import { DashboardGateway } from './dashboardGateway';
import { DashboardPresenter } from './dashboardPresenter';
import { DashboardUseCase } from './dashboardUseCase';
import { ApiRecord } from '../../@types/zcvp_api_enum';
import { PortalFeature } from '../../@types/role-permissions/zcvp_role_permissions_enum';

export class DashboardController {
    public executeHasInvitedContacts(vendors?: ApiRecord.VendorContactRecord[]): boolean {
        return this.getUseCase().hasInvitedContacts(vendors);
    }
    public executeHasCompletedProfile(vendorData?: ApiRecord.VendorRecord): boolean {
        return this.getUseCase().hasCompletedProfile(vendorData);
    }
    public executeValidateDashboardFeature(hasPermission: boolean, feature: PortalFeature): string {
        return this.getUseCase().validateDashboardFeature(hasPermission, feature);
    }
    private getUseCase() {
        const gateway = new DashboardGateway();
        const presenter = new DashboardPresenter();
        return new DashboardUseCase(gateway, presenter);
    }
}