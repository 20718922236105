import { AuthenticationView } from '../../@types/authentication/zcvp_authentication';

export class ManageAuthenticationPresenter {
    private view;
    constructor(view: AuthenticationView) {
        this.view = view;
    }
    public convert(authenticationCode: string, pathName: string) {
        this.view.render(authenticationCode, pathName)
    }
}