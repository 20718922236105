export enum UserRoles {
    ADMIN = 'admin',
    CONTACT = 'contact',
    OWNER = 'owner',
    USER = 'user'
}

export enum RoleType {
    CUSTOMER = 'customer',
    COMPANY = 'company',
    VENDOR = 'vendor'
}

export enum RoleUserType {
    ADMIN = 'admin',
    USER = 'user'
}