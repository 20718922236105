import { ManageAuthenticationPresenter } from "./manageAuthenticationPresenter";
import { ManageAuthenticationUseCase } from "./manageAuthenticationUseCase";
import { ManageAuthenticationView } from "./manageAuthenticationView";
import { getMainPath } from "../../lib/Utils";
import { invitationRoutesList, routesListUnauthorized } from "../../lib/RoutesConfig";

type AuthenticationControllerParams = {
    isAuthorized: boolean;
    hasApiError: boolean;
    pathName: string;
}

export class ManageAuthenticationController {
    private logout: Function;
    private setRedirecting;
    constructor(logout: Function, setRedirecting: Function) {
        this.logout = logout;
        this.setRedirecting = setRedirecting;
    }
    public execute({ isAuthorized, hasApiError, pathName }: AuthenticationControllerParams) {
        const view = new ManageAuthenticationView(this.logout, this.setRedirecting);
        const presenter = new ManageAuthenticationPresenter(view);
        const useCase = new ManageAuthenticationUseCase(presenter);
        useCase.execute({
            isAuthorized,
            hasApiError,
            pathName,
            page: getMainPath(pathName),
            unauthorizedPages: routesListUnauthorized,
            invitationPages: invitationRoutesList
        });
    }
}