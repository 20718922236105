import { Col, Row, Select, Skeleton, message } from 'antd';
import { SelectValue } from 'antd/es/select';
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import GenericActionButton from '../../components/form/form-action/GenericActionButton';
import InlineField from '../../components/form/form-field/InlineField';
import CloseAccountModal from '../../components/modals/CloseAccountModal';
import CardComponent from '../../components/partials/CardComponent';
import { showErrorMessage, showSuccessMessage } from '../../lib/CustomHooks';
import { TranslationNS, getCommonT } from '../../lib/TranslationHelper';
import { useGetAllRolesQuery, useGetUserQuery, useUpdateUserRoleMutation } from '../../slice/APISlice';
import { canRemoveUser, getCustomerRoleList } from '../../use-case/activeUsers/getActiveUsersUseCase';
import { GetOptionsController } from '../../use-case/getOptions/getOptionsController';

type UserDetailsProps = {
    record?: TableRecord;
}
const UserDetails: React.FC<UserDetailsProps> = ({ record = {} }) => {
    const { t } = useTranslation(TranslationNS.USER, { keyPrefix: TranslationNS.USER_MANAGEMENT });
    const role = useMemo(() => record.roles && record.roles[0] ? { value: record.roles[0].id, label: record.roles[0].text } : undefined, [record.roles]);
    const user = useSelector((state: any) => state.user);

    const rawRoles = useGetAllRolesQuery({});
    const roles = useMemo(() => new GetOptionsController(rawRoles).execute(), [rawRoles]);

    const [messageApi, contextHolder] = message.useMessage();
    const [allowRoleChange, setAllowRoleChange] = useState(false);
    const [selectedRole, setSelectedRole] = useState(record.role as unknown as SelectValue);
    const { data: currentUser, isLoading } = useGetUserQuery({ userId: record.id });

    const [updateUserRole] = useUpdateUserRoleMutation();

    const onRoleChange = useCallback((value: SelectValue) => {
        setAllowRoleChange(true);
        setSelectedRole(value);
    }, []);

    const onRoleChangeSave = useCallback(() => {
        const submitObj = {
            userId: record.id,
            companyId: user.companyId,
            roleIds: [selectedRole],
        };

        updateUserRole(submitObj).then((response: any) => {
            if (typeof response.error !== 'undefined') {
                showErrorMessage(response.error.message, messageApi);
            } else {
                showSuccessMessage(t('role_change_success'), messageApi);
                setAllowRoleChange(false);
            }
        });
    }, [messageApi, selectedRole, updateUserRole, user.companyId, record.id]);

    return <Skeleton loading={isLoading}>
        {contextHolder}
        <CardComponent
            title={t('personal_details_card_title')}
            titlePopover={{ content: getCommonT('go_to_netsuite_to_edit') }}
        >
            <InlineField label={t('full_name_label')} value={record.fullName as string} />
            <InlineField label={getCommonT('email')} value={record.email as string} />
        </CardComponent>
        <CardComponent title={t('user_role_card_title')}>
            <Row gutter={10}>
                <Col>
                    <Select
                        className='w-48'
                        defaultValue={role}
                        options={getCustomerRoleList(roles as unknown as TableRecord[])}
                        onChange={onRoleChange}
                    />
                </Col>
                <Col>
                    <GenericActionButton key='save-role-btn' name='save-role-btn' type='primary' label={getCommonT('save_changes')} disabled={!allowRoleChange} onClick={onRoleChangeSave} />
                </Col>
            </Row>
        </CardComponent>
        <CardComponent title={t('account_actions_card_title')}>
            <div className='space-x-2'>
                <GenericActionButton key='reset-password-btn' name='reset-password-btn' type='primary' label={t('reset_password_button')} />
                <CloseAccountModal userId={record.id as unknown as number} disabled={!canRemoveUser(currentUser)} />
            </div>
        </CardComponent>
    </Skeleton>;
};
export default UserDetails;