import React from 'react';
import { Button, Tooltip } from 'antd';
import { ActionButtonDefinition } from '../../../@types/zcvp_form';

/**
 * Creates a submit button
 *
 * @param {ActionButtonDefinition} props action properties
 * @returns {React.FC} submit button
 */
const SubmitButton = ({ name, label, icon = null, tooltip = '', onClick, disableSubmitButton, loading = false, size='middle', block }: ActionButtonDefinition) => (
    <Tooltip title={tooltip}>
        <Button
            name={name}
            htmlType='submit'
            type='primary'
            className='my-0'
            icon={icon}
            onClick={onClick}
            loading={loading}
            disabled={disableSubmitButton}
            size={size}
            block={block}
        >
            {label}
        </Button>
    </Tooltip>
);

export default SubmitButton;