import { Modal } from 'antd';
import { MessageInstance } from 'antd/lib/message/interface';
import React, { useCallback } from 'react';
import { useOutletContext } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { FormFieldValues } from '../../@types/invite/zcvp_invite';
import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import { ApiRecord } from '../../@types/zcvp_api_enum';
import { disableSubmitTrigger } from '../../@types/zcvp_form';
import { ActionType, FormFieldType } from '../../@types/zcvp_form_enum';
import GenericForm from '../../components/form/GenericForm';
import { TranslationNS, getCommonT } from '../../lib/TranslationHelper';
import { useLazyGetInfoQuery, useLazyInviteContactQuery } from '../../slice/APISlice';
import '../../styles/zcvp_modal.scss';
import '../../styles/zcvp_vendor_details.scss';
import { SendInvitationController } from '../../use-case/invitation/sendInvitationController';
import { SendVendorInvitationUseCase } from '../../use-case/inviteVendors/sendVendorInvitationUseCase';

type InviteContactModalProps = {
    open: boolean;
    record?: TableRecord;
    onClose: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}
const InviteContactModal: React.FC<InviteContactModalProps> = ({ open, onClose, record }: InviteContactModalProps) => {
    const { t } = useTranslation(TranslationNS.VENDOR);
    const { messageApi } = useOutletContext<{ messageApi: MessageInstance }>();
    const [inviteContact] = useLazyInviteContactQuery();
    const [getInfo] = useLazyGetInfoQuery();

    const onSubmit = useCallback((values: TableRecord) => {
        getInfo({}).then(response => {
            (new SendInvitationController({ messageApi, inviteCallback: inviteContact, sendUseCase: SendVendorInvitationUseCase })
                .execute({
                    ...values,
                    vendorId: response?.data?.vendorId
                }));
        });
    }, [messageApi, getInfo, inviteContact]);

    return <Modal
        key='invite-vendor-btn'
        open={open}
        title={record ? t('invite_contact_modal_title') : t('invite_new_contact_modal_title')}
        footer={[]}
        onCancel={onClose}
        destroyOnClose
    >
        <GenericForm
            disableSubmitTrigger={!record ? disableSubmitTrigger.ON_ANY_FIELD_CHANGE : undefined}
            formProps={{
                onFinish: (values: FormFieldValues) => {
                    onSubmit(values);
                    onClose();
                },
                disabled: false
            }}
            fieldValues={record}
            fields={[
                [{
                    name: ApiRecord.VendorContactField.FIRST_NAME,
                    label: t('first_name_label'),
                    type: FormFieldType.TEXT,
                    required: true
                }, {
                    name: ApiRecord.VendorContactField.LAST_NAME,
                    label: t('last_name_label'),
                    type: FormFieldType.TEXT,
                    required: true
                }],
                [{
                    name: ApiRecord.VendorContactField.EMAIL,
                    label: t('email_label'),
                    type: FormFieldType.EMAIL,
                    required: true
                }]
            ]}
            actions={[{
                type: ActionType.CANCEL,
                definition: {
                    label: getCommonT('cancel'),
                    key: 'cancel-invite',
                    name: 'cancel-invite',
                    onClick: () => onClose()
                }
            }, {
                type: ActionType.SUBMIT,
                definition: {
                    label: t('invite_contact_button'),
                    key: 'invite-vendor-contact',
                    name: 'invite-vendor-contact',
                }
            }]} />
    </Modal>
};

export default InviteContactModal;