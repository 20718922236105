import React, { useCallback } from 'react';
import { Drawer } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { LabelWithIcon } from '../../components/partials/StyledText';
import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import '../../styles/zcvp_modal.scss';
import '../../styles/zcvp_vendor_details.scss';

export type CloseEvent = (e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => void;

type DataElement = {
    dataset: DataSet;
}

type DataSet = {
    [key: string]: number;
}

type DetailsDrawerProps = {
    pageTitle: string;
    titleIcon: React.ReactNode;
    footer: React.ReactNode;
    record?: TableRecord;
    otherProps?: TableRecord;
    onClose: CloseEvent;
    children: React.ReactNode;
}

const DetailsDrawer = ({ pageTitle, titleIcon, footer, record, onClose, otherProps = {}, children }: DetailsDrawerProps) => {
    /**
     * This adds a scroll attribute to the header element which is referenced by CSS so that the header bottom border only shows when body is scrolled
     */
    const addScrollEvent = useCallback(() => {
        const drawerBody = document.getElementsByClassName('ant-drawer-body')[0];
        const drawerHeader = document.getElementsByClassName('ant-drawer-header')[0];
        drawerBody?.addEventListener('scroll', () => {
            drawerHeader && ((drawerHeader as unknown as DataElement).dataset.scroll = drawerBody.scrollTop);
        });
    }, []);
    const afterOpenChange = useCallback((open: boolean) => open && addScrollEvent(), [addScrollEvent]);

    return <Drawer
        placement='right'
        rootClassName='zcvp-vendor-details'
        open={!!record}
        mask={false}
        title={<LabelWithIcon label={pageTitle} leftIcon={titleIcon} />}
        footer={footer}
        onClose={onClose}
        afterOpenChange={afterOpenChange}
        closable={false}
        destroyOnClose
        {...{
            ...otherProps,
            extra: <>{(otherProps?.extra || []).concat([<CloseOutlined key='close-icon' onClick={onClose}/>])}</>
        }}>
        {children}
    </Drawer>;
}

export default DetailsDrawer;