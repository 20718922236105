import { BaseFieldKey, FormFieldDefinition } from '../@types/zcvp_form';
import { IBaseDataGateway } from './IBaseDataGateway';
import { IBaseDataPresenter } from './IBaseDataPresenter';

export interface RecordData {
    [key: string]: string | number | boolean | object | undefined | null;
}

export abstract class BaseDataUseCase<T extends IBaseDataGateway, S extends IBaseDataPresenter> {
    protected gateway: T;
    protected presenter: S;

    constructor(gateway: T, presenter: S) {
        this.gateway = gateway;
        this.presenter = presenter;
    }

    public abstract execute(): void;

    /**
     * Returns field definitions as an array
     * @param fieldKeys
     * @param configuration
     * @protected
     */
    protected getFields(fieldKeys: BaseFieldKey[][]): FormFieldDefinition[][] {
        return this.presenter.convertFields(fieldKeys);
    }

    /**
     * Extracts values from data
     * 
     * @param fieldKeys Form field keys
     * @param data Data to get values from
     * @returns field with values
     */
    protected getValues<T2 extends RecordData>(fieldKeys: string[], data: T2): T2 {
        data = this.presenter.convertValues(data, this.presenter.fieldDefinition);

        return fieldKeys.reduce((values, fieldKey) => {
            const fieldDefinition: FormFieldDefinition = this.presenter.getField(fieldKey);
            if (fieldDefinition) {
                const path = fieldDefinition.path || fieldDefinition.name;
                // values[fieldDefinition.name as keyof T2] = data[fieldDefinition.name as keyof T2];
                values[fieldDefinition.name as keyof T2] = this.getNestedValue(data, path);
            }

            return values;
        }, {} as T2);
    }

    private getNestedValue(obj, path: string) {
        return path.split('.').reduce((acc, key) => (acc && acc[key] !== 'undefined' ? acc[key] : undefined), obj);
    }
}