import { UpdateVendorCompanyGateway } from './updateVendorCompanyGateway';
import { UpdateVendorCompanyPresenter } from './updateVendorCompanyPresenter';
import { UpdateVendorCompanyUseCase } from './updateVendorCompanyUseCase';
import { ValidateFormValuesUseCase } from '../validateForm/validateFormValues';
import { VendorCompanyActionPresenter } from './vendorCompanyActionPresenter';
import { VendorCompanyActionUseCase } from './vendorCompanyActionUseCase';
import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';

export class UpdateVendorCompanyDataController {
    private params;
    constructor(params) {
        this.params = params;
    }
    public executeSaveCompanyDetail(params) {
        const { updateType, isEdited, recordId, vendorId, fields, oldValues, newValues } = params;
        const updateVendorCompanyUseCase = this.getVendorCompanyUpdateUseCase(params);
        return updateVendorCompanyUseCase.updateCompanyDetails({
            isEdited, updateType, recordId, vendorId, fields, oldValues, newValues
        });
    }
    public executeSaveLegalInfo(params) {
        const { updateType, isEdited, recordId, vendorId, fields, oldValues, newValues } = params;
        const updateVendorCompanyUseCase = this.getVendorCompanyUpdateUseCase(params);
        return updateVendorCompanyUseCase.updateLegalInformation({
            isEdited, updateType, recordId, vendorId, fields, oldValues, newValues
        });
    }
    public executeSaveBankDetail(params) {
        const { updateType, isEdited, recordId, vendorId, subsidiaryId, primaryAccount, fields, oldValues, newValues } = params;
        const { createBankAccount, updateBankAccount, submitRequest, messageApi } = this.params;

        const updateVendorCompanyGateway = new UpdateVendorCompanyGateway({ createBankAccount, updateBankAccount, submitRequest });
        const updateVendorCompanyPresenter = new UpdateVendorCompanyPresenter(messageApi);
        const updateVendorCompanyUseCase = new UpdateVendorCompanyUseCase(updateVendorCompanyGateway, updateVendorCompanyPresenter, params.onRequestSubmit);
        return updateVendorCompanyUseCase.updateBankDetails({
            isEdited, updateType, recordId, vendorId, subsidiaryId, fields, oldValues, newValues, primaryAccount
        });
    }
    public executeSaveVendorCompany(params) {
        const presenter = new VendorCompanyActionPresenter({
            icon: params.icon,
            onOk: params.onOk,
            onCancel: params.onCancel,
            confirm: params.confirm
        });
        const useCase = new VendorCompanyActionUseCase(presenter);
        useCase.saveVendorCompany({
            isEdited: params.isEdited,
            recordId: params.recordId,
            values: params.values,
            saveFn: params.onOk
        });
    }
    public getNewValues(oldValues, newValues) {
        const validateFormValuesUseCase = new ValidateFormValuesUseCase();
        const { changedValues, addedValues } = validateFormValuesUseCase.getChanges(oldValues as TableRecord, newValues as TableRecord);
        const isEdited = !!Object.keys(changedValues).length || !!Object.keys(addedValues).length;
        const updatedValues = { ...changedValues, ...addedValues };
        return { isEdited, newValues: updatedValues };
    }
    private getVendorCompanyUpdateUseCase(params) {
        const { createAddress, updateAddress, updateVendor, submitRequest, messageApi } = this.params;
        const updateVendorCompanyGateway = new UpdateVendorCompanyGateway({ createAddress, updateAddress, updateVendor, submitRequest });
        const updateVendorCompanyPresenter = new UpdateVendorCompanyPresenter(messageApi);
        const updateVendorCompanyUseCase = new UpdateVendorCompanyUseCase(updateVendorCompanyGateway, updateVendorCompanyPresenter, params.onRequestSubmit);
        return updateVendorCompanyUseCase;
    }
}
