import { Form, Modal } from 'antd';
import { MessageInstance } from 'antd/lib/message/interface';
import React, { useCallback, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { FormFieldValues } from '../../@types/invite/zcvp_invite';
import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import { ActionType, FormFieldType } from '../../@types/zcvp_form_enum';
import { AppState } from '../../@types/zcvp_state';
import GenericForm from '../../components/form/GenericForm';
import { useGetAllSubsidiariesQuery, useGetCompanyDataQuery, useInviteVendorMutation } from '../../slice/APISlice';
import '../../styles/zcvp_modal.scss';
import '../../styles/zcvp_vendor_details.scss';
import { SendVendorInvitationController } from '../../use-case/inviteVendors/sendVendorInvitationController';

type InviteExistingVendorModalProps = {
    record: TableRecord;
    open: boolean;
    onClose: Function;
}
const InviteExistingVendorModal: React.FC<InviteExistingVendorModalProps> = ({ record, open, onClose }: InviteExistingVendorModalProps) => {
    const [inviteVendor] = useInviteVendorMutation({});
    const [form] = Form.useForm();
    const { messageApi } = useOutletContext<{ messageApi: MessageInstance }>();

    const user = useSelector((state: AppState) => state.user);
    const companyQuery = useGetCompanyDataQuery({ companyId: user.companyId });
    const companyFilter = { 'filterModel': { 'companyId': { 'values': [user.companyId], 'filterType': 'set' } } };
    const subsidiaries = useGetAllSubsidiariesQuery(companyFilter);

    const onSubmit = useCallback((values: FormFieldValues) => (new SendVendorInvitationController({ messageApi, inviteCallback: inviteVendor, subsidiaries: subsidiaries }).execute({
        ...values, vendorId: record?.id as string
    })), [messageApi, inviteVendor, record, subsidiaries]);

    const closeAndClear = useCallback(() => {
        form.resetFields();
        onClose();
    }, [form, onClose]);

    useEffect(() => form.setFieldsValue({ email: record?.email }), [form, record, companyQuery]);

    return <Modal
        key='invite-vendor-btn'
        open={open}
        onCancel={closeAndClear}
        title={'Invite existing Vendor'}
        footer={[]}
        destroyOnClose
    >
        <GenericForm
            formProps={{
                form,
                onFinish: (values: FormFieldValues) => {
                    onSubmit(values);
                    onClose(record.id);
                },
            }}
            fields={[
                [{
                    name: 'email',
                    label: 'Email Address',
                    required: true,
                    otherProps: { },
                    type: FormFieldType.EMAIL
                }]
            ]}
            actions={[{
                type: ActionType.CANCEL,
                definition: {
                    label: 'Cancel',
                    key: 'cancel-invite',
                    name: 'cancel-invite',
                    onClick: () => onClose()
                }
            }, {
                type: ActionType.SUBMIT,
                definition: {
                    label: 'Invite',
                    key: 'invite-vendor',
                    name: 'invite-vendor',
                }
            }]} />
    </Modal>
};

export default InviteExistingVendorModal;