import React from 'react';
import { RoutesUrls } from '../@types/zcvp_routes_enum';
import ErrorPage from '../pages/ErrorPage';
import InvitationForm from '../pages/invite-users/InvitationForm';
import InvitationValidation, { ValidationInvitationFormProps } from '../pages/invite-users/InvitationValidation';
import ForgotPasswordForm from '../pages/login/ForgotPasswordForm';
import LoginForm from '../pages/login/LoginForm';
import PasswordReset from '../pages/login/PasswordReset';
import OnboardingEmailSent from '../pages/onboarding/OnboardingEmailSent';
import OnboardingInitializeForm from '../pages/onboarding/OnboardingInitializeForm';
import Configuration from '../pages/user-configuration/Configuration';
import UserList from '../pages/user-management/UserList';
import UserProfile from '../pages/user-profile/UserProfile';
import VendorCompany from '../pages/vendor-company/VendorCompany';
import VendorList from '../pages/vendor-management/VendorList';
import { InvitationType } from '../@types/invite/zcvp_invite_enum';
import { Dashboard } from '../pages/portal/Dashboard';
import { getMainPath } from './Utils';
import Authentication from '../components/Authentication';
import { VendorCompanyTabKey } from '../@types/vendor-company/zcvp_vendor_company_enum';
import VendorRequestList from '../pages/vendor-requests/VendorRequestList';
//https://stackoverflow.com/questions/66265608/react-router-v6-get-path-pattern-for-current-route

const routesUnauthorized = [
    {
        path: RoutesUrls.FORGOT_PASSWORD,
        element: <ForgotPasswordForm />,
    },
    {
        path: RoutesUrls.LOGIN,
        element: <LoginForm />,
    },
    {
        path: RoutesUrls.RESET_PASSWORD + '/:token',
        element: <PasswordReset />,
    },
    {
        path: RoutesUrls.EMPLOYEE_INVITATION + '/:invitationId/:status',
        element: <InvitationValidation invitationType={InvitationType.EMPLOYEE} ValidInvitationForm={InvitationForm as React.FC<ValidationInvitationFormProps>}/>
    },
    {
        path: RoutesUrls.EMPLOYEE_INVITATION + '/:invitationId',
        element: <InvitationValidation invitationType={InvitationType.EMPLOYEE} ValidInvitationForm={InvitationForm as React.FC<ValidationInvitationFormProps>}/>
    },
    {
        path: RoutesUrls.VENDOR_INVITATION + '/:invitationId/:status',
        element: <InvitationValidation invitationType={InvitationType.VENDOR} ValidInvitationForm={OnboardingInitializeForm as React.FC<ValidationInvitationFormProps>} SuccessForm={OnboardingEmailSent}/>
    },
    {
        path: RoutesUrls.VENDOR_INVITATION + '/:invitationId',
        element: <InvitationValidation invitationType={InvitationType.VENDOR} ValidInvitationForm={OnboardingInitializeForm as React.FC<ValidationInvitationFormProps>} SuccessForm={OnboardingEmailSent}/>
    },
    {
        path: RoutesUrls.VENDOR_CONTACT_INVITATION + '/:invitationId/:status',
        element: <InvitationValidation invitationType={InvitationType.VENDOR_CONTACT} ValidInvitationForm={InvitationForm as React.FC<ValidationInvitationFormProps>}/>
    },
    {
        path: RoutesUrls.VENDOR_CONTACT_INVITATION + '/:invitationId',
        element: <InvitationValidation invitationType={InvitationType.VENDOR_CONTACT} ValidInvitationForm={InvitationForm as React.FC<ValidationInvitationFormProps>}/>
    }
];

const routesAuthorized = [
    {
        path: RoutesUrls.CONFIGURATION,
        element: <Configuration />,
    },
    {
        path: RoutesUrls.DASHBOARD,
        element: <Dashboard />,
    },
    {
        path: RoutesUrls.REQUESTS + '/*',
        element: <VendorRequestList />,
    },
    {
        path: RoutesUrls.REQUESTS + '/:requestId',
        element: <VendorRequestList />,
    },
    {
        path: RoutesUrls.INFO + '/*',
        element: <ErrorPage />,
    },
    {
        path: RoutesUrls.PROFILE + '/*',
        element: <UserProfile />,
    },
    {
        path: RoutesUrls.SETTINGS + '/*',
        element: <ErrorPage />,
    },
    {
        path: RoutesUrls.TOOLS + '/*',
        element: <ErrorPage />,
    },
    {
        path: RoutesUrls.USERS + '/*',
        element: <UserList />,
    },
    {
        path: RoutesUrls.VENDORS + '/:vendorId',
        element: <VendorList />,
    },
    {
        path: RoutesUrls.VENDORS + '/*',
        element: <VendorList />,
    },
    {
        path: RoutesUrls.COMPANY + '/*',
        element: <VendorCompany />,
    },
    {
        //this needs to come before company
        path: RoutesUrls.COMPANY_INVITE + '/*',
        element: <VendorCompany activeTab={VendorCompanyTabKey.VENDOR_CONTACT}/>,
    },
    {
        path: RoutesUrls.COMPANY + '/*',
        element: <VendorCompany />,
    }

];
export const routesConfig = [
    {
        path: RoutesUrls.MAIN,
        element: <Authentication/>,
        errorElement:
            <Authentication>
                <ErrorPage />
            </Authentication>,
        children: [
            //Unauthorized Layout
            {
                errorElement: <LoginForm />,
                children: routesUnauthorized
            },
            //Authorized Layout
            {
                errorElement: <ErrorPage />,
                children: routesAuthorized
            },
        ]
    },
];

export const routesListUnauthorized = routesUnauthorized.map((route) => getMainPath(route.path));
export const routesListAuthorized = routesAuthorized.map((route) => getMainPath(route.path));
export const invitationRoutesList: string[] = [
    RoutesUrls.EMPLOYEE_INVITATION, RoutesUrls.VENDOR_INVITATION, RoutesUrls.VENDOR_CONTACT_INVITATION
];