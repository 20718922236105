import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import { VendorRequestActionGateway } from '../../@types/vendor-requests/zcvp_vendor_requests';
import { UserState } from '../../@types/zcvp_state';
import { ApiRequestStatus } from "../../@types/zcvp_api_enum";

export class GetVendorRequestActionGateway implements VendorRequestActionGateway {
    private record: TableRecord;
    private user: UserState;
    constructor(record: TableRecord, user?: UserState) {
        this.record = record;
        this.user = user!;
    }
    public isVendor(): boolean {
        return !!this.user.isVendor;
    }
    public canUserCancelRequest(): boolean {
        const systemPermissions = this.getSystemPermissions();
        return !!systemPermissions?.canCancel;
    }
    public canUserSubmitRequest(): boolean {
        const systemPermissions = this.getSystemPermissions();
        return !!systemPermissions?.canSubmit;
    }
    public canUserApproveRequest(): boolean {
        const systemPermissions = this.getSystemPermissions();
        return !!systemPermissions?.canApproveReject;
    }
    public canUserDeleteAttachment(): boolean {
        const systemPermissions = this.getSystemPermissions();
        return !!systemPermissions?.canDelete;
    }
    public canSubmitRequest(): boolean {
        return this.record?.status === ApiRequestStatus.NEW;
    }
    public canApproveRequest(): boolean {
        return this.record?.status === ApiRequestStatus.REVIEW;
    }
    private getSystemPermissions(): TableRecord {
        return this.record?._system as unknown as TableRecord;
    }
}