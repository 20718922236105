import { BankDetailsFieldKey, IVendorCompanyBankDetailsDataPresenter } from '../../@types/vendor-company/zcvp_vendor_company';
import { VendorBankDetailsField } from '../../@types/vendor-company/zcvp_vendor_company_enum';
import { BaseFieldKey, FieldDefinitionType } from '../../@types/zcvp_form';
import { FormFieldType } from '../../@types/zcvp_form_enum';
import i18n from '../../i18n';
import { TranslationNS } from '../../lib/TranslationHelper';
import { BaseDataPresenter } from '../BaseDataPresenter';

export class VendorCompanyBankDetailsPresenter extends BaseDataPresenter<BankDetailsFieldKey> implements IVendorCompanyBankDetailsDataPresenter {

    public fieldDefinition: FieldDefinitionType = {
        [VendorBankDetailsField.ACCOUNT_NAME]: {
            name: 'accountName',
            type: FormFieldType.TEXT,
            label: i18n.t('bank_account_name_label', { ns: TranslationNS.VENDOR }),
            required: true,
            maxLength: 255,
        },
        [VendorBankDetailsField.BANK_NUMBER]: {
            name: 'bankNumber',
            type: FormFieldType.TEXT,
            label: i18n.t('bank_number_label', { ns: TranslationNS.VENDOR }),
            required: true,
        },
        [VendorBankDetailsField.BRANCH_NUMBER]: {
            name: 'branchNumber',
            type: FormFieldType.TEXT,
            label: i18n.t('branch_number_label', { ns: TranslationNS.VENDOR }),
            required: true,
        },
        [VendorBankDetailsField.ACCOUNT_NUMBER]: {
            name: 'accountNumber',
            type: FormFieldType.TEXT,
            label: i18n.t('bank_account_number_label', { ns: TranslationNS.VENDOR }),
            required: true,
        },
        /* [VendorBankDetailsField.PAYMENT_FILE_FORMAT]: {
            name: 'bankAccountType',
            type: FormFieldType.TEXT,
            label: 'Bank Account Type',
        },
        [VendorBankDetailsField.PAYMENT_DESCRIPTION]: {
            name: 'bankAccountType',
            type: FormFieldType.TEXT,
            label: 'Bank Account Type',
        }, */
    };

    public bankDetailsFieldsLayout: BaseFieldKey<BankDetailsFieldKey>[][] = [
        [VendorBankDetailsField.ACCOUNT_NAME],
        [VendorBankDetailsField.BANK_NUMBER, VendorBankDetailsField.BRANCH_NUMBER],
        [VendorBankDetailsField.ACCOUNT_NUMBER],
        /* [VendorBankDetailsField.PAYMENT_FILE_FORMAT],
        [VendorBankDetailsField.PAYMENT_DESCRIPTION], */
    ];
}