import React from 'react';
import { Form, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { FieldProps } from '../../../@types/zcvp_form';
import { getCommonT } from '../../../lib/TranslationHelper';

const { Dragger } = Upload;

const normFile = (event: any) => {
    if (Array.isArray(event)) {
      return event;
    }
    return event?.fileList;
};

const FileField = (props: FieldProps) => {
    return <Form.Item
        name={props.name}
        label={props.label}
        rules={[{
            required: props.required
        }]}
        valuePropName='fileList'
        getValueFromEvent={normFile}
    >
        <Dragger
            data-testid={`${props.name}-dragger`}
            beforeUpload={() => false}>
            <p className='ant-upload-drag-icon'> <InboxOutlined /></p>
            <p className='ant-upload-text'>{getCommonT('click_to_upload')}</p>
        </Dragger>
    </Form.Item>;
};

export default FileField