export enum VendorCompanyField {
    // Vendor details
    ID = 'id',
    COMPANY_ID = 'companyId',
    COMPANY_NAME = 'companyName',
    COMPANY_SIZE = 'size',
    CATEGORY = 'vendorCategoryId',
    ADDRESSES = 'addresses',
    BILLING_ADDRESS = 'billing_address',
    SHIPPING_ADDRESS = 'shipping_address',
    ADDRESSES_EQUAL = 'addressesEqual',
    COMPANY_EMAIL = 'email',
    COMPANY_URL = 'url',
    PHONE_NUMBER = 'phone',
    PHONE_NUMBER_ALT = 'altPhone',

    // Legal Information
    LEGAL_NAME = 'legalName',
    SUBSIDIARY_ID = 'subsidiaryId',
    TAX_ID = 'taxId',
    TAX_REG_NUMBER = 'taxRegNumber',
    PRIMARY_CURRENCY = 'currencyId',
    CREDIT_LIMIT = 'creditLimit',
    TERM_ID = 'termId',
    TERMS = 'terms', // to be removed

    // other
    COMPANY_AVATAR = 'companyAvatar',

    // Bank Details
    bankAccounts = 'bankAccounts',
}

export enum VendorBankDetailsField {
    ID = 'id',
    ACCOUNT_NAME = 'accountName',
    ACCOUNT_NUMBER = 'accountNumber',
    BANK_NUMBER = 'bankNumber',
    BRANCH_NUMBER = 'branchNumber',
    SUBSIDIARY_ID = 'subsidiaryId',
    TYPE = 'type',
    VENDOR_ID = 'vendorId',
    // PAYMENT_FILE_FORMAT = 'paymentFileFormat',
    // PAYMENT_DESCRIPTION = 'paymentDescription',
}

export enum VendorCompanyTabKey {
    GENERAL = 'general',
    BANK_DETAILS = 'bank-details',
    VENDOR_CONTACT = 'vendor-contact'
}

export enum VendorBankAccountType {
    PRIMARY = 'PRIMARY',
    SECONDARY = 'SECONDARY',
}
