import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { apiSlice } from './slice/APISlice';
import layoutThemeReducer from './slice/LayoutThemeSlice';
import loaderReducer from './slice/LoaderSlice';
import userReducer from './slice/UserSlice';
import tutorialReducer from './slice/TutorialSlice';

const store = configureStore({
    devTools: true,
    middleware: getDefaultMiddleware =>
        process.env.NODE_ENV !== 'production' ?
            getDefaultMiddleware().concat(apiSlice.middleware).concat(require('redux-immutable-state-invariant').default())
            :
            getDefaultMiddleware().concat(apiSlice.middleware),
    reducer: {
        loader: loaderReducer,
        layoutTheme: layoutThemeReducer,
        user: userReducer,
        tutorial: tutorialReducer,
        [apiSlice.reducerPath]: apiSlice.reducer
    }
});
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch; // Export a hook that can be reused to resolve types
export default store;