import { Col, Row, Select, Skeleton } from 'antd';
import { SelectValue } from 'antd/es/select';
import { MessageInstance } from 'antd/lib/message/interface';
import React, { useCallback, useMemo, useState } from 'react';
import { useOutletContext } from 'react-router-dom';

import GenericActionButton from '../../components/form/form-action/GenericActionButton';
import InlineField from '../../components/form/form-field/InlineField';
import CloseAccountModal from '../../components/modals/CloseAccountModal';
import CardComponent from '../../components/partials/CardComponent';
import InviteContactModal from './InviteContactModal';

import { useTranslation } from 'react-i18next';
import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import { ZCVPApiResponse } from '../../@types/zcvp_api';
import { ApiRecord } from '../../@types/zcvp_api_enum';
import { showErrorMessage, showSuccessMessage } from '../../lib/CustomHooks';
import { TranslationNS, getCommonT } from '../../lib/TranslationHelper';
import { useGetAllRolesQuery, useGetUserForVendorQuery, useUpdateUserRoleMutation } from '../../slice/APISlice';
import { canRemoveUser } from '../../use-case/activeUsers/getActiveUsersUseCase';
import { GetOptionsController } from '../../use-case/getOptions/getOptionsController';

type ContactInviteButtonProps = {
    record: TableRecord;
}
type VendorContactDetailsProps = {
    record?: TableRecord;
}
const ContactInviteButton = ({ record }: ContactInviteButtonProps) => {
    const { t } = useTranslation(TranslationNS.VENDOR);
    const [openInviteContact, setOpenInviteContact] = useState(false);
    const closeInviteContact = useCallback(() => setOpenInviteContact(!openInviteContact), [openInviteContact]);

    const disableInviteButton: boolean = !!record[ApiRecord.VendorContactField.REGISTERED_AT];
    const actionLabel: string = useMemo(() => record[ApiRecord.VendorContactField.INVITED_AT]
        ? t('resend_invitation_button')
        : getCommonT('invite')
        , [record, t]);
    const actionTooltip: string = useMemo((): string => disableInviteButton ? t('invitation_already_sent') : '', [disableInviteButton, t]);
    return <>
        <InviteContactModal open={openInviteContact} onClose={closeInviteContact} record={record} />
        <GenericActionButton
            key='invite-contact-btn'
            name='invite-contact-btn'
            type='primary'
            label={actionLabel}
            tooltip={actionTooltip}
            disabled={disableInviteButton}
            onClick={() => setOpenInviteContact(true)} />
    </>;
};

const VendorContactDetails: React.FC<VendorContactDetailsProps> = ({ record = {} }) => {
    const { t } = useTranslation(TranslationNS.VENDOR);
    const rawRoles = useGetAllRolesQuery({});
    const roles = useMemo(() => new GetOptionsController(rawRoles).execute(), [rawRoles]);

    const { messageApi } = useOutletContext<{ messageApi: MessageInstance }>();
    const [allowRoleChange, setAllowRoleChange] = useState(false);
    const [selectedRole, setSelectedRole] = useState(record.role as unknown as SelectValue);

    const [updateUserRole] = useUpdateUserRoleMutation();
    const { data: vendorUser, isLoading } = useGetUserForVendorQuery({ id: record.id as unknown as number });

    const onRoleChange = useCallback((value: SelectValue) => {
        setAllowRoleChange(true);
        setSelectedRole(value);
    }, []);

    const onRoleChangeSave = useCallback(() => {
        if (!vendorUser) {
            return;
        }

        const submitObj = {
            userId: vendorUser.id,
            companyId: vendorUser.companyId,
            roleIds: [selectedRole],
        };

        updateUserRole(submitObj).then((response: ZCVPApiResponse) => {
            if (typeof response.error !== 'undefined') {
                showErrorMessage(response.error.message, messageApi);
            } else {
                showSuccessMessage(t('role_change_success'), messageApi);
                setAllowRoleChange(false);
            }
        });
    }, [messageApi, selectedRole, updateUserRole, vendorUser, t]);

    return <Skeleton loading={isLoading}>
        <CardComponent
            title='Personal Details'
            titlePopover={{ content: getCommonT('go_to_netsuite_to_edit') }}
        >
            <InlineField label='Full Name' value={record.fullName as string} />
            <InlineField label='Email' value={record.email as string} />
        </CardComponent>
        <CardComponent title='User Role'>
            <Row gutter={10}>
                <Col>
                    <Select
                        className='w-48'
                        defaultValue={record.role as unknown as SelectValue}
                        options={roles}
                        onChange={onRoleChange}
                        disabled={!vendorUser || vendorUser._system.canEdit === false}
                    />
                </Col>
                <Col>
                    <GenericActionButton key='save-role-btn' name='save-role-btn' type='primary' label='Save changes' disabled={!allowRoleChange} onClick={onRoleChangeSave} />
                </Col>
            </Row>
        </CardComponent>
        <CardComponent title='Account Actions'>
            <div className='space-x-2'>
                <ContactInviteButton record={record} />
                <CloseAccountModal userId={vendorUser?.id} disabled={!canRemoveUser(vendorUser)} />
            </div>
        </CardComponent>
    </Skeleton>;
};
export default VendorContactDetails;