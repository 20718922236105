import { IGetIntegrationDataGateway, IntegrationField, IntegrationFieldKey, IntegrationQueryData } from '../../@types/zcvp_configuration';

export class GetIntegrationDataGateway implements IGetIntegrationDataGateway {
    public integrationData: IntegrationQueryData;
    public integrationFields: IntegrationFieldKey[] = [
        IntegrationField.CONNECTOR,
        IntegrationField.ACCOUNT_ID,
        IntegrationField.URL,
        IntegrationField.CONSUMER_KEY,
        IntegrationField.CONSUMER_SECRET,
        IntegrationField.TOKEN_KEY,
        IntegrationField.TOKEN_SECRET,
        IntegrationField.CONCURRENCY_LIMIT,
    ];

    constructor(integrationData: IntegrationQueryData) {
        this.integrationData = integrationData;
    }
}