import { ApartmentOutlined, BankOutlined, HistoryOutlined, LinkOutlined, MailOutlined, PhoneOutlined, ShopOutlined, ShoppingCartOutlined, TagsOutlined, TeamOutlined } from '@ant-design/icons';
import { Descriptions, Skeleton } from 'antd';
import React, { useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { AddressValues } from '../../@types/get-address/zcvp_get_address';
import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import { ListId } from '../../@types/list-details/zcvp_get_list_details_enum';
import {
    CollapsibleFieldValue,
    CollapsibleFunction,
    CollapsibleLabel,
    CopyableValue,
    LabelWithIcon,
    LinkValue,
    MailValue
} from '../../components/partials/StyledText';
import { TranslationNS, getCommonT } from '../../lib/TranslationHelper';
import { useGetAllVendorContactsQuery } from '../../slice/APISlice';
import '../../styles/zcvp_vendor_details.scss';
import { getAddress } from '../../use-case/getAddress/getAddressUseCase';
import { GetListDetailsController } from '../../use-case/getListDetails/getListDetailsController';
import { getByVendorId } from '../../use-case/getVendorContacts/getContacts';
import { getVendorSize } from '../../use-case/getVendors/getVendorsUseCase';
import VendorStatus from './VendorStatus';

type DynamicValue = {
    [key: string]: string;
}

const VendorDetailsHeader = ({ record }: { record?: TableRecord }) => {
    const { t } = useTranslation(TranslationNS.USER, { keyPrefix: 'vendor_management' });
    const vendorRecord = record || {};
    const vendorContactQueryData = useGetAllVendorContactsQuery({});
    const vendorContactData = useMemo(() => new GetListDetailsController({}, vendorContactQueryData).execute(ListId.VENDOR_CONTACT), [vendorContactQueryData]);
    const vendorContacts = getByVendorId(vendorContactData.dataSource, vendorRecord.id as string);

    const [isContactExpanded, setIsContactExpanded] = useState(false);
    const [isBankAccountExpanded, setIsBankAccountExpanded] = useState(false);

    const billingAddress = getAddress(vendorRecord.billingAddress as unknown as AddressValues);
    const shippingAddress = getAddress(vendorRecord.shippingAddress as unknown as AddressValues);

    const COLUMN_COUNT = 1;
    const MIN_COLLAPSIBLE = 2;
    const items = [
        {
            label: t('status'),
            labelIcon: <HistoryOutlined />,
            value: <VendorStatus status={record?.status as string} />,
            rawValue: record?.status
        },
        {
            label: t('category'),
            labelIcon: <TagsOutlined />,
            value: (record?.vendorCategory as unknown as DynamicValue)?.text,
            rawValue: record?.vendorCategory
        },
        {
            label: t('vendor_size'),
            labelIcon: <ApartmentOutlined />,
            value: getVendorSize(record?.size as string),
            rawValue: record?.size
        },
        {
            label: t('vendor_address'),
            labelIcon: <ShopOutlined />,
            value: <CopyableValue value={billingAddress as string} />,
            rawValue: billingAddress
        },
        {
            label: t('vendor_shipping_address'),
            labelIcon: <ShoppingCartOutlined />,
            value: <CopyableValue value={shippingAddress as string} />,
            rawValue: shippingAddress
        },
        {
            label: getCommonT('email'),
            labelIcon: <MailOutlined />,
            value: <CopyableValue value={vendorRecord.email as string} />,
            rawValue: vendorRecord.email
        },
        {
            label: t('vendor_phone'),
            labelIcon: <PhoneOutlined />,
            value: <CopyableValue value={vendorRecord.phone as string} />,
            rawValue: vendorRecord.phone
        },
        {
            label: t('vendor_url'),
            labelIcon: <LinkOutlined />,
            value: <LinkValue value={record?.url as string} />,
            rawValue: record?.url
        },
        {
            label: <CollapsibleLabel label={t('vendor_contacts')} isExpanded={isContactExpanded} setIsExpanded={setIsContactExpanded} isCollapsible={(vendorContacts as DynamicValue[])?.length >= MIN_COLLAPSIBLE} />,
            labelIcon: <TeamOutlined />,
            value: <CollapsibleFieldValue
                template={'{count} contacts'}
                valueCount={(vendorContacts as DynamicValue[])?.length}
                value={<>{(vendorContacts as DynamicValue[])?.map((fieldValue, index) => <LabelWithIcon
                    key={`collapsible-fldval-${index}`}
                    label={fieldValue?.name || fieldValue?.email}
                    rightIcon={<MailValue value={fieldValue?.email} />}
                />)}</>}
                collapseStatus={isContactExpanded}
                onClick={(() => setIsContactExpanded(!isContactExpanded)) as unknown as CollapsibleFunction} />,
            rawValue: vendorContacts
        },
        {
            label: <CollapsibleLabel label={t('vendor_bank_account')} isExpanded={isBankAccountExpanded} setIsExpanded={setIsBankAccountExpanded} isCollapsible={!!vendorRecord.bankAccounts && vendorRecord.bankAccounts?.length >= MIN_COLLAPSIBLE} />,
            labelIcon: <BankOutlined />,
            value: <CollapsibleFieldValue
                template={'{count} accounts'}
                value={<>{(vendorRecord.bankAccounts as unknown as DynamicValue[])?.map((fieldValue, index) => <CopyableValue
                    key={`collapsible-bnk-acct-fldval-${index}`}
                    value={fieldValue?.accountNumber}
                />)}</>}
                valueCount={vendorRecord.bankAccounts?.length}
                collapseStatus={isBankAccountExpanded}
                onClick={(() => setIsBankAccountExpanded(!isBankAccountExpanded)) as unknown as CollapsibleFunction} />,
            rawValue: record?.bankAccounts
        }
    ].filter(item => {
        if (Array.isArray(item.rawValue)) {
            return item.rawValue?.length;
        }
        return ![null, undefined, ''].includes(item.rawValue);
    })
        .map(({ label, labelIcon, value }, index) => ({
            key: index,
            label: <LabelWithIcon
                leftIcon={labelIcon}
                label={label} />,
            children: value
        }));

    return <Skeleton active loading={vendorRecord.isLoading as unknown as boolean}>
        <Descriptions items={items} column={COLUMN_COUNT} bordered />
    </Skeleton>;
}

export default VendorDetailsHeader;