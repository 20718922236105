import {
    FieldGrouping,
    FieldGroupingsIds, FieldGroupKeys,
    fieldsAndGroupsByRoleType,
    ThemeValues,
    UserField, userProfileEndpoint
} from '../../@types/user-profile/zcvp_user_profile_enum';
import { ApiRecord } from '../../@types/zcvp_api_enum';
import { FormActionProps, FormElementDefinition, FormFieldDefinition } from '../../@types/zcvp_form';
import { ActionType, FormElementType, FormFieldType } from '../../@types/zcvp_form_enum';
import { RoleType } from '../../@types/zcvp_user_enums';
import i18n from '../../i18n';
import { getCommonT, TranslationNS } from '../../lib/TranslationHelper';

export type FieldGroupingMap = { [key in FieldGroupKeys]: FieldGrouping };

export default class UserProfilePermissionsPresenter {
    public convert(roleType: RoleType): { fieldConfig: fieldsAndGroupsByRoleType, fieldGroupings: FieldGroupingMap } {
        return {
            fieldConfig: this.fieldConfig,
            fieldGroupings: this.getFieldGroupings(roleType)
        };
    }
    /**
     * Field definition lookup. Name matches the id that the API is expecting
     * @private
     */
    public fieldDefinition: { [key in UserField]: FormFieldDefinition | FormElementDefinition | FormActionProps } = {
        [UserField.FULL_NAME]: {
            name: ApiRecord.UserField.FULL_NAME,
            type: FormElementType.INLINE_TEXT,
            label: i18n.t('user_profile.full_name_label', { ns: TranslationNS.USER }),
        },
        [UserField.EMAIL]: {
            name: ApiRecord.UserField.EMAIL,
            type: FormElementType.INLINE_TEXT,
            label: getCommonT('email'),
        },
        [UserField.COMPANY_EMAIL]: {
            name: ApiRecord.VendorContactField.EMAIL,
            type: FormFieldType.EMAIL,
            label: getCommonT('email'),
        },
        [UserField.FIRST_NAME]: {
            name: ApiRecord.VendorContactField.FIRST_NAME,
            type: FormFieldType.TEXT,
            label: i18n.t('user_profile.first_name_label', { ns: TranslationNS.USER }),
        },
        [UserField.LAST_NAME]: {
            name: ApiRecord.VendorContactField.LAST_NAME,
            type: FormFieldType.TEXT,
            label: i18n.t('user_profile.last_name_label', { ns: TranslationNS.USER }),
        },
        [UserField.PHONE_NUMBER]: {
            name: ApiRecord.VendorContactField.PHONE,
            type: FormFieldType.PHONE,
            label: i18n.t('user_profile.phone_number_label', { ns: TranslationNS.USER })
        },
        [UserField.APP_NOTIFICATION]: {
            name: ApiRecord.UserField.APP_NOTIFICATION,
            type: FormFieldType.CHECKBOX,
            checkboxLabel: i18n.t('user_profile.in_app_notification_label', { ns: TranslationNS.USER })
        },
        [UserField.EMAIL_NOTIFICATION]: {
            name: ApiRecord.UserField.EMAIL_NOTIFICATION,
            type: FormFieldType.CHECKBOX,
            checkboxLabel: getCommonT('email'),
        },
        [UserField.THEME]: {
            name: ApiRecord.UserField.THEME,
            type: FormFieldType.RADIO,
            label: '',
            options: [
                { label: i18n.t('user_profile.theme_browser_settings_label', { ns: TranslationNS.USER }), value: ThemeValues.BROWSER, id: ThemeValues.BROWSER },
                { label: i18n.t('user_profile.theme_light_label', { ns: TranslationNS.USER }), value: ThemeValues.LIGHT, id: ThemeValues.LIGHT },
                { label: i18n.t('user_profile.theme_dark_label', { ns: TranslationNS.USER }), value: ThemeValues.DARK, id: ThemeValues.DARK },
            ],
        },
        [UserField.GOOGLE_LOGIN]: {
            type: ActionType.ELEMENT,
            definition: {
                label: i18n.t('user_profile.google_login_label', { ns: TranslationNS.USER }),
                type: 'default',
                name: ApiRecord.UserField.GOOGLE_LOGIN,
                key: ApiRecord.UserField.GOOGLE_LOGIN,
            },
        },
        [UserField.CHANGE_PASSWORD]: {
            type: ActionType.SUBMIT,
            definition: {
                label: i18n.t('user_profile.change_password_button', { ns: TranslationNS.USER }),
                type: 'primary',
                name: ApiRecord.UserField.CHANGE_PASSWORD,
                key: ApiRecord.UserField.CHANGE_PASSWORD,
            },
        },
    };

    //todo: this should be reexamined once user permissions are implemented
    public fieldConfig: fieldsAndGroupsByRoleType = {
        [RoleType.CUSTOMER as string]: {
            [FieldGroupingsIds.PERSONAL_DETAILS]: {
                fields: [
                    [this.fieldDefinition[UserField.FULL_NAME] as FormElementDefinition],
                    [this.fieldDefinition[UserField.EMAIL] as FormElementDefinition],
                ],
                endpoint: userProfileEndpoint.USER
            },
            [FieldGroupingsIds.NOTIFICATIONS]: {
                fields: [
                    [this.fieldDefinition[UserField.APP_NOTIFICATION] as FormFieldDefinition],
                    [this.fieldDefinition[UserField.EMAIL_NOTIFICATION] as FormFieldDefinition],
                ],
                endpoint: userProfileEndpoint.USER
            },
            [FieldGroupingsIds.THEME]: {
                fields: [
                    [this.fieldDefinition[UserField.THEME] as FormFieldDefinition],
                ],
                endpoint: userProfileEndpoint.USER
            },
            [FieldGroupingsIds.ADDITIONAL_LOGIN_OPTIONS]: {
                invalid: true,
            },
        },
        [RoleType.VENDOR]: {
            [FieldGroupingsIds.PERSONAL_DETAILS]: {
                fields: [
                    [this.fieldDefinition[UserField.COMPANY_EMAIL] as FormFieldDefinition],
                    [this.fieldDefinition[UserField.FIRST_NAME] as FormFieldDefinition,
                    this.fieldDefinition[UserField.LAST_NAME] as FormFieldDefinition],
                    [this.fieldDefinition[UserField.PHONE_NUMBER] as FormFieldDefinition],
                ],
                actions: [
                    {
                        type: ActionType.SUBMIT,
                        definition: {
                            label: getCommonT('save_changes'),
                            key: 'save_vendor_contact_details',
                            name: 'save_vendor_contact_details',
                        }
                    }
                ],
                endpoint: userProfileEndpoint.VENDOR_CONTACT
            },
            [FieldGroupingsIds.NOTIFICATIONS]: {
                fields: [
                    [this.fieldDefinition[UserField.APP_NOTIFICATION] as FormFieldDefinition],
                    [this.fieldDefinition[UserField.EMAIL_NOTIFICATION] as FormFieldDefinition],
                ],
                endpoint: userProfileEndpoint.USER
            },
            [FieldGroupingsIds.THEME]: {
                fields: [
                    [this.fieldDefinition[UserField.THEME] as FormFieldDefinition],
                ],
                endpoint: userProfileEndpoint.USER
            },
            [FieldGroupingsIds.ADDITIONAL_LOGIN_OPTIONS]: {
                invalid: true, //todo change this when we add this functionality
            },
        },
    };

    public getFieldGroupings(roleType: RoleType): FieldGroupingMap {
        const personalDetailTooltip = roleType === RoleType.CUSTOMER ? { content: i18n.t('common:go_to_netsuite_to_edit') } : undefined;
        return {
            [FieldGroupingsIds.PERSONAL_DETAILS]: {
                title: i18n.t('user_management.personal_details_card_title', { ns: TranslationNS.USER }),
                titlePopover: personalDetailTooltip,
                layout: 'vertical',
                saveButton: true,
            },
            [FieldGroupingsIds.NOTIFICATIONS]: {
                title: i18n.t('user_profile.notifications_card_title', { ns: TranslationNS.USER }),
                subtitle: i18n.t('user_profile.notifications_card_subtitle', { ns: TranslationNS.USER }),
            },
            [FieldGroupingsIds.THEME]: {
                title: i18n.t('user_profile.theme_card_title', { ns: TranslationNS.USER }),
            },
            [FieldGroupingsIds.ADDITIONAL_LOGIN_OPTIONS]: {
                title: i18n.t('user_profile.login_options_card_title', { ns: TranslationNS.USER }),
            },
        };
    }
}