import { createSlice } from '@reduxjs/toolkit';
import { TutorialState } from '../@types/zcvp_state';

export const TutorialSliceInitialState: TutorialState = {
    currentStep: 0,
    isTutorialOpen: false
};

const TutorialSlice = createSlice({
    name: 'tutorial',
    initialState: TutorialSliceInitialState,
    reducers: {
        setTutorial(state, { payload: isTutorialOpen }) {
            state.currentStep = 0;
            state.isTutorialOpen = isTutorialOpen;
        },
        openTutorial(state) {
            state.currentStep = 0;
            state.isTutorialOpen = true;
        },
        closeTutorial(state) {
            state.currentStep = 0;
            state.isTutorialOpen = false;
        },
        goNextStep(state) {
            ++state.currentStep;
        },
        goPreviousStep(state) {
            --state.currentStep;
        }
    }
});

export const { setTutorial, openTutorial, closeTutorial, goNextStep, goPreviousStep } = TutorialSlice.actions;
export default TutorialSlice.reducer;
