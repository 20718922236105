export enum RecordType {
    VENDOR = 'vendor',
    VENDOR_REQUEST = 'request',
    DOCUMENT = 'document'
}

export enum Feature {
    APPROVALS_CONFIG = 'approval',
    APPROVALS_ACTION = 'rejection',
    VENDOR_REQUEST_APPROVAL = 'request approval',
    VENDOR_REQUEST_REJECTION = 'request rejection',
    VENDOR_REQUEST_CANCELLATION = 'request cancellation',
    PASSWORD_CHANGE = 'password change'
}