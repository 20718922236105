import { showErrorMessage, showSuccessMessage } from '../../lib/CustomHooks';
import { TranslationNS } from '../../lib/TranslationHelper';
import i18n from '../../i18n';
import { ZCVPApiResponse } from '../../@types/zcvp_api';
import { VendorCompanyUpdatePresenter } from '../../@types/vendor-company/zcvp_vendor_company';

export class UpdateVendorCompanyPresenter implements VendorCompanyUpdatePresenter {
    private messageApi;
    constructor(messageApi) {
        this.messageApi = messageApi;
    }
    public convert({ responses }) {
        Promise.all(responses).then((responses) => this.processResponse(responses));
    }
    private processResponse(responses) {
        const messageApi = this.messageApi;
        const hasError = responses.some((response: ZCVPApiResponse) => typeof response.error !== 'undefined');
        if (hasError) {
            showErrorMessage(i18n.t('error_saving_data', { ns: TranslationNS.VENDOR }), messageApi);
        } else {
            showSuccessMessage(i18n.t(`${TranslationNS.VENDOR_REQUEST}.success_submit_request`, { ns: TranslationNS.VENDOR }), messageApi);
        }
    }
}