export enum RoutesUrls {
    MAIN = '/',
    DASHBOARD = '', // 'home' page for vendor users
    FORGOT_PASSWORD = 'forgot-password',
    LOGIN = 'login',
    RESET_PASSWORD = 'password-reset-confirm',
    SETTINGS = 'settings',
    TOOLS = 'tools',
    PROFILE = 'profile',
    CONFIGURATION = 'configuration',
    INFO = 'info',
    USERS = 'users',
    VENDORS = 'vendors',
    HELP = 'help',
    COMPANY = 'company',
    COMPANY_INVITE = 'company/invite',
    REQUESTS = 'requests',
    HOME = '',
    PRIVACY_NOTICE = 'privacy-notice',
    EMPLOYEE_INVITATION = 'employee-invite-confirm',
    VENDOR_INVITATION = 'vendor-invite-confirm',
    VENDOR_CONTACT_INVITATION = 'vendor-contact-invite-confirm',
    CONTACT_US = 'mailto:'
}

export enum StorageKey {
    TOKEN = 'zcvp-token',
    USER_ID = 'zcvp-userId',
    LOGIN_PAGE_MESSAGE = 'zcvp-loginPageMessage',
    DEEP_LINK = 'zcvp-deepLink'
}