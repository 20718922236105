import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import { UserState } from '../../@types/zcvp_state';
import { GetVendorRequestActionGateway } from './getVendorRequestActionGateway';
import { GetVendorRequestActionUseCase } from './getVendorRequestActionUseCase';

export class GetVendorRequestActionController {
    public execute(record: TableRecord, user: UserState) {
        const useCase = this.getUseCase(record, user);
        return useCase.execute();
    }
    public executeCanCancelRequest(record: TableRecord, user: UserState) {
        const useCase = this.getUseCase(record, user);
        return useCase.canCancelNewRequest();
    }
    public executeCanDeleteAttachment(record: TableRecord) {
        const useCase = this.getUseCase(record);
        return useCase.canDeleteAttachment();
    }
    private getUseCase(record: TableRecord, user?: UserState) {
        const gateway = new GetVendorRequestActionGateway(record, user);
        return new GetVendorRequestActionUseCase(gateway);
    }
}