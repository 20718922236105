import { Form, Modal } from 'antd';
import { MessageInstance } from 'antd/lib/message/interface';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import { ListId } from '../../@types/list-details/zcvp_get_list_details_enum';
import { RecordType } from '../../@types/submit-record/zcvp_submit_record_enum';
import { ApiRecord } from '../../@types/zcvp_api_enum';
import { Subsidiary } from '../../@types/zcvp_base_types';
import { FormFieldDefinition, disableSubmitTrigger } from '../../@types/zcvp_form';
import { ActionType, FormFieldType } from '../../@types/zcvp_form_enum';
import { AppState } from '../../@types/zcvp_state';
import GenericForm from '../../components/form/GenericForm';
import { COMPANY_SIZE_OPTIONS } from '../../lib/Constants';
import { useHasMultipleSubsidiaries } from '../../lib/CustomHooks';
import { TranslationNS, getCommonT } from '../../lib/TranslationHelper';
import { useCreateVendorMutation, useGetAllSubsidiariesQuery, useGetAllVendorCategoriesQuery, useGetCompanyDataQuery } from '../../slice/APISlice';
import '../../styles/zcvp_popover.scss';
import { GetListDetailsController } from '../../use-case/getListDetails/getListDetailsController';
import { GetOptionsController } from '../../use-case/getOptions/getOptionsController';
import { getVendorSubsidiary } from '../../use-case/getVendors/getVendorsUseCase';
import { SubmitRecordController } from '../../use-case/submitRecord/submitRecordController';

type ManualVendorAddModalProps = {
    open: boolean;
    onClose: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const ManualVendorAddModal: React.FC<ManualVendorAddModalProps> = ({ open, onClose }: ManualVendorAddModalProps) => {
    const { t } = useTranslation(TranslationNS.VENDOR);
    const { messageApi } = useOutletContext<{ messageApi: MessageInstance }>();
    const [form] = Form.useForm();
    const [createVendor] = useCreateVendorMutation({});
    const vendorCategoryQueryData = useGetAllVendorCategoriesQuery({});
    const vendorCategories = useMemo(() => new GetListDetailsController({}, vendorCategoryQueryData).execute(ListId.VENDOR_CATEGORY), [vendorCategoryQueryData]);
    const vendorCategoryOptions = useMemo(() => new GetOptionsController({ data: vendorCategories.dataSource }).execute(), [vendorCategories]);

    const user = useSelector((state: AppState) => state.user);
    const companyQuery = useGetCompanyDataQuery({ companyId: user.companyId });
    const companyFilter = { 'filterModel': { 'companyId': { 'values': [user.companyId], 'filterType': 'set' } } };
    const subsidiaries = useGetAllSubsidiariesQuery(companyFilter);
    const approveToOnboard = useMemo(() => companyQuery?.data && companyQuery.data[ApiRecord.CompanyField.APPROVALS_TO_ONBOARD], [companyQuery]);
    const saveLabel = useMemo(() => approveToOnboard ? t('submit_draft_for_approval_label') : t('save_new_vendor_label'), [approveToOnboard, t]);
    const hasMultipleSubsidiaries = useHasMultipleSubsidiaries();

    useEffect(() => {
        if (!hasMultipleSubsidiaries) {
            return;
        }

        form.setFieldsValue({ subsidiaryId: companyQuery.data?.defaultSubsidiaryId });
    }, [hasMultipleSubsidiaries, form, subsidiaries.data, companyQuery]);

    const closeAndClear = () => {
        form.resetFields();
        onClose();
    };

    const onFinish = useCallback((values: TableRecord) => {
        if (!hasMultipleSubsidiaries) {
            values.subsidiaryId = getVendorSubsidiary(subsidiaries?.data, companyQuery.data?.defaultSubsidiaryId);
        }

        return new SubmitRecordController({
            onSubmit: createVendor, onClose, messageApi
        }).execute(RecordType.VENDOR, values)
    }, [hasMultipleSubsidiaries, createVendor, onClose, messageApi, companyQuery.data, subsidiaries.data]);

    const fields: FormFieldDefinition[][] = useMemo(() => {
        const fields = [
            [{
                name: 'companyName',
                label: 'Company Name',
                type: FormFieldType.TEXT,
                required: true
            }, {
                name: 'size',
                label: 'Company Size',
                type: FormFieldType.SELECT,
                options: COMPANY_SIZE_OPTIONS
            }],
            [{
                name: 'category',
                label: 'Category',
                type: FormFieldType.SELECT,
                options: vendorCategoryOptions,
                required: true
            }],
            // not supported yet
            /* [{
                name: 'address',
                label: 'Address/Primary Location',
                type: FormFieldType.TEXT
            }], */
            [{
                name: 'email',
                label: 'Company Email',
                type: FormFieldType.EMAIL,
                required: true
            }, {
                name: 'url',
                label: 'Company URL',
                type: FormFieldType.WEB_ADDRESS
            }],
            [{
                name: 'phone',
                label: 'Phone Number',
                type: FormFieldType.PHONE
            }, {
                name: 'altPhone',
                label: 'Alternative Phone Number',
                type: FormFieldType.PHONE
            }],
        ];

        if (hasMultipleSubsidiaries) {
            fields.push(
                [{
                    name: 'subsidiaryId',
                    label: getCommonT('subsidiary'),
                    type: FormFieldType.SELECT,
                    options: subsidiaries.data?.map((subsidiary: Subsidiary) => ({
                        label: subsidiary.name,
                        value: subsidiary.id,
                        id: subsidiary.id,
                    })) || [],
                    required: true
                }]
            );
        }

        return fields;

    }, [hasMultipleSubsidiaries, subsidiaries.data, vendorCategoryOptions]);

    return <Modal
        open={open}
        title='New Vendor'
        onCancel={closeAndClear}
        footer={[]}
        destroyOnClose
    >
        <GenericForm
            validateTrigger='onSubmit'
            disableSubmitTrigger={disableSubmitTrigger.ON_ANY_FIELD_CHANGE}
            formProps={{ form, onFinish }}
            fields={fields}
            actions={[{
                type: ActionType.CANCEL,
                definition: {
                    label: 'Cancel',
                    key: 'cancel-invite',
                    name: 'cancel-invite',
                    onClick: closeAndClear
                }
            }, {
                type: ActionType.SUBMIT,
                definition: {
                    label: saveLabel,
                    key: 'submit-vendor',
                    name: 'submit-vendor'
                }
            }]} />
    </Modal>;
};

export default ManualVendorAddModal;