import i18next from '../../../src/i18n';
import { InviteUserDataGateway, RoleDetail } from "../../@types/invite-users/zcvp_invite_users";
import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import { FieldDefinitionType, FormFieldDefinition, FormFieldOption } from "../../@types/zcvp_form";
import { FormFieldType } from "../../@types/zcvp_form_enum";
import { getCommonT } from '../../lib/TranslationHelper';
import { Field } from "./getInviteUserDataUseCase";

enum RoleType {
    ADMIN = 'admin',
    USER = 'user',
    APPROVER = 'vendor-approver'
};

type RoleDefinitionType = {
    [type: string]: RoleDetail;
}

export class GetInviteUserDataGateway implements InviteUserDataGateway {
    private MAX_SELECTED_USERS = 3;
    private RoleDefinition: RoleDefinitionType = {
        [RoleType.ADMIN]: {
            name: i18next.t('onboarding:admin'),
            description: i18next.t('onboarding:admin_description'),
        },
        [RoleType.USER]: {
            name: i18next.t('onboarding:user'),
            description: i18next.t('onboarding:user_description'),
        },
        [RoleType.APPROVER]: {
            name: i18next.t('onboarding:vendor_approver'),
            description: i18next.t('onboarding:vendor_approver_description'),
        }
    };
    private FieldDefinition: FieldDefinitionType = {
        [Field.FIRST_NAME]: {
            name: 'firstname',
            type: FormFieldType.TEXT,
            label: 'First Name',
            disabled: true
        },
        [Field.LAST_NAME]: {
            name: 'lastname',
            type: FormFieldType.TEXT,
            label: 'Last Name',
            disabled: true
        },
        [Field.FULL_NAME]: {
            name: 'fullname',
            type: FormFieldType.MULTISELECT,
            label: getCommonT('name'),
            maxTagCount: this.MAX_SELECTED_USERS
        },
        [Field.ROLE]: {
            name: 'role',
            type: FormFieldType.SELECT,
            label: getCommonT('role'),
        }
    };
    private users: TableRecord[] | undefined;
    private roles: FormFieldOption[];
    constructor(roles: FormFieldOption[], users?: TableRecord[]) {
        this.roles = roles;
        this.users = users;
    }
    getUsers(): TableRecord[] | undefined {
        return this.users;
    }
    getField(fieldId: string): FormFieldDefinition {
        return this.FieldDefinition[fieldId];
    }
    getRoles(): FormFieldOption[] {
        return this.roles;
    }
    getRoleDetails(roleId: string = ""): RoleDetail {
        return this.RoleDefinition[roleId];
    }
    getAllRoleDetails(): RoleDetail[] {
        return Object.values(this.RoleDefinition);
    }
}