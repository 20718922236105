import { GetInviteUserDataGateway } from "./getInviteUserDataGateway";
import { GetInviteUserDataUseCase } from "./getInviteUserDataUseCase";

import { InviteUserData } from "../../@types/invite-users/zcvp_invite_users";
import { TableRecord } from "../../@types/list-details/zcvp_get_list_details";
import { FormFieldOption } from "../../@types/zcvp_form";

type GetInviteUserDataControllerParams = {
    user?: TableRecord;
    userIds?: string[];
    selectedRole?: string;
}

export class GetInviteUserDataController {
    private roles: FormFieldOption[];
    private users: TableRecord[]|undefined;
    constructor(roles: FormFieldOption[], users?: TableRecord[]) {
        this.roles = roles;
        this.users = users;
    }
    execute({ user, userIds, selectedRole }: GetInviteUserDataControllerParams): InviteUserData {
        const getInviteUserDataGateway = new GetInviteUserDataGateway(this.roles, this.users);
        const getInviteUserDataUseCase = new GetInviteUserDataUseCase(getInviteUserDataGateway);
        return getInviteUserDataUseCase.execute(user, userIds, selectedRole);
    }
}