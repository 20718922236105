import React, { useCallback, useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Upload, UploadFile } from 'antd';
import { DeleteOutlined, EllipsisOutlined, PaperClipOutlined, PlusOutlined } from '@ant-design/icons';
import { MessageInstance } from 'antd/lib/message/interface';

import { LabelWithIcon } from '../../components/partials/StyledText';
import { useRemoveDocumentMutation, useUploadDocumentMutation } from '../../slice/APISlice';
import { TranslationNS } from '../../lib/TranslationHelper';
import { SubmitRecordController } from '../../use-case/submitRecord/submitRecordController';
import { TableRecord, TableRowValue } from '../../@types/list-details/zcvp_get_list_details';
import { RecordType } from '../../@types/submit-record/zcvp_submit_record_enum';
import { ApiRecord } from '../../@types/zcvp_api_enum';
import '../../styles/zcvp_vendor_details.scss';
import { GetVendorRequestActionController } from '../../use-case/vendorRequests/getVendorRequestActionController';

type FileAttachment = {
    uid: string
}

const VendorRequestDetailAttachment = ({ record, attachments }: { record: TableRecord, attachments: FileAttachment[] }) => {
    const { messageApi } = useOutletContext<{ messageApi: MessageInstance }>();
    const { t } = useTranslation(TranslationNS.VENDOR, {
        keyPrefix: `${TranslationNS.VENDOR_REQUEST}`
    });
    const [uploadDocument] = useUploadDocumentMutation();
    const [removeDocument] = useRemoveDocumentMutation();
    const requestActionController = useMemo(() => new GetVendorRequestActionController(), []);

    const removeFile = useCallback((file: FileAttachment) => {
        const canRemoveFile = file?.uid && Number.isInteger(file?.uid);
        canRemoveFile && new SubmitRecordController({
            onSubmit: removeDocument,
            messageApi
        }).execute(RecordType.DOCUMENT, {
            id: file.uid
        });
    }, [messageApi, removeDocument]);

    const attachmentItems = useMemo(() => attachments?.map((attachment: TableRecord) => ({
        uid: attachment.id,
        name: attachment.name,
        url: attachment.url,
        status: 'done',
        canDelete: requestActionController.executeCanDeleteAttachment(attachment)
    })), [attachments]);

    return <Upload
        data-testid='attachment-uploader'
        beforeUpload={(file) => {
            new SubmitRecordController({
                onSubmit: uploadDocument,
                messageApi
            }).execute(RecordType.DOCUMENT, {
                [ApiRecord.DocumentField.FILE]: file as unknown as TableRowValue,
                [ApiRecord.DocumentField.TYPE]: ApiRecord.Type.VENDOR_REQUEST,
                [ApiRecord.DocumentField.VENDOR_ID]: record.vendorId,
                [ApiRecord.DocumentField.RECORD_ID]: record.id
            });
            return false;
        }}
        fileList={attachmentItems as UploadFile[]}
        itemRender={(originNode: React.ReactElement, file: UploadFile) => <div
            className={!(file as unknown as TableRecord).canDelete ? 'zcvp-hidden-file' : ''}
        >
            {originNode}
        </div>
        }
        showUploadList={{
            showDownloadIcon: true,
            showRemoveIcon: true,
            showPreviewIcon: false
        }}
        onRemove={removeFile}>
        <LabelWithIcon
            label={t('attachment')}
            leftIcon={<PaperClipOutlined />}
            rightIcon={<PlusOutlined />} />
    </Upload>;
}

export default VendorRequestDetailAttachment;