import { PortalTutorialStepData, TutorialButtonOnClick, TutorialStepPresenter, TutorialStepUseCaseOutput } from "../../@types/tutorial/zcvp_tutorial";
import { NextButtonLabel } from "./getTutorialStepsUseCase";

export class GetTutorialStepsPresenter implements TutorialStepPresenter {
    private goNext: TutorialButtonOnClick;
    private goPrevious: TutorialButtonOnClick;
    constructor(goNext: TutorialButtonOnClick, goPrevious: TutorialButtonOnClick) {
        this.goNext = goNext;
        this.goPrevious = goPrevious;
    }
    public convert(tutorialSteps: TutorialStepUseCaseOutput[], userName: string): PortalTutorialStepData[] {
        return tutorialSteps.map((tutorialStep, index) => ({
            ...tutorialStep,
            title: this.getTitle(tutorialStep.title, userName),
            nextButtonProps: {
                label: this.getNextButtonLabel(tutorialStep.nextButtonKey),
                onClick: this.goNext,
                page: this.getNextPage(tutorialSteps, index)
            },
            prevButtonProps: {
                label: 'Previous',
                onClick: this.goPrevious,
                page: this.getPreviousPage(tutorialSteps, index)
            }
        }));
    }
    private getTitle(title: string, userName: string): string {
        return title?.replace('{name}', userName || 'there');
    }
    private getNextButtonLabel(nextButtonKey: number): string {
        const NextButtonText: {[key: number]: string} = {
            [NextButtonLabel.START_TOUR]: 'Start Tour',
            [NextButtonLabel.TOUR_COMPLETE]: 'Complete',
            [NextButtonLabel.DEFAULT]: 'Next'
        };
        return NextButtonText[nextButtonKey];
    }
    private getNextPage(tutorialSteps: TutorialStepUseCaseOutput[], index: number): string {
        return tutorialSteps[index + 1]?.page;
    }
    private getPreviousPage(tutorialSteps: TutorialStepUseCaseOutput[], index: number): string {
        return tutorialSteps[index - 1]?.page;
    }
}