import { CheckRolePermissionGateway } from './checkRolePermissionGateway';
import { CheckRolePermissionUseCase } from './checkRolePermissionUseCase';
import { getMainPath } from '../../lib/Utils';
import { UserState } from '../../@types/zcvp_state';
import { PortalFeature } from '../../@types/role-permissions/zcvp_role_permissions_enum';

export class CheckRolePermissionController {
    private user: UserState;
    constructor(user: UserState) {
        this.user = user;
    }
    public execute(path: string): boolean {
        const mainPath = getMainPath(path);
        const gateway = new CheckRolePermissionGateway(this.user);
        const useCase = new CheckRolePermissionUseCase(gateway);
        return useCase.execute(mainPath);
    }
    public executeFeatureCheck(feature: PortalFeature): boolean {
        const gateway = new CheckRolePermissionGateway(this.user);
        const useCase = new CheckRolePermissionUseCase(gateway);
        return useCase.checkFeature(feature);
    }
}