import { SubsidiaryQueryData } from '../../@types/zcvp_base_types';
import { ConfigurationData, IGetIntegrationData, IntegrationQueryData, RecordQueryData } from '../../@types/zcvp_configuration';
import { GetConfigurationDataGateway } from './getConfigurationDataGateway';
import { GetConfigurationDataPresenter } from './getConfigurationDataPresenter';
import { GetConfigurationDataUseCase } from './getConfigurationDataUseCase';
import { GetIntegrationDataGateway } from './getIntegrationDataGateway';
import { GetIntegrationDataPresenter } from './getIntegrationDataPresenter';
import { GetIntegrationDataUseCase } from './getIntegrationDataUseCase';


export default class GetConfigurationDataController {
    public execute(configuration: RecordQueryData, subsidiaries: SubsidiaryQueryData): ConfigurationData {
        const configurationDataGateway = new GetConfigurationDataGateway(configuration);
        const configurationDataPresenter = new GetConfigurationDataPresenter(subsidiaries);
        const getConfigurationDataUseCase = new GetConfigurationDataUseCase(configurationDataGateway, configurationDataPresenter);

        return getConfigurationDataUseCase.execute(configuration);
    }

    public executeIntegration(integrationData: IntegrationQueryData): IGetIntegrationData {
        const integrationGateway = new GetIntegrationDataGateway(integrationData);
        const getIntegrationDataPresenter = new GetIntegrationDataPresenter();
        const getIntegrationDataUseCase = new GetIntegrationDataUseCase(integrationGateway, getIntegrationDataPresenter);

        return getIntegrationDataUseCase.execute(integrationData);
    }
}