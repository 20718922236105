import React, { useCallback, useEffect, useState } from 'react';
import { CheckCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { MessageInstance } from 'antd/es/message/interface';
import { ShowMessageTypes } from '../@types/zcvp_custom_hooks';
import { StorageKey } from '../@types/zcvp_routes_enum';
import { AppState } from '../@types/zcvp_state';
import { useSelector } from 'react-redux';
import { RoleType } from '../@types/zcvp_user_enums';
import { useMemo } from 'react';
import { useGetAllSubsidiariesQuery } from '../slice/APISlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { TableRecord } from '../@types/list-details/zcvp_get_list_details';

/**
 * Dispatches a custom event
 */
export const dispatchLocalStorageEvent = (event: string) => {
    window.dispatchEvent(new Event(event));
};
/**
 * Returns authorization header
 */
export const getAuthorizationHeader = (headers = {}) => ({
    Authorization: `Bearer ${localStorage.getItem(StorageKey.TOKEN)}`,
    'Content-Type': 'application/json',
    ...headers, //this will overwrite the above
});


/**
 * Displays an error message using Ant Design's message component and in the DOM
 * Displays a success message only in Ant Design's message component
 */
export const showMessage = (type: ShowMessageTypes, message: string, messageApi: MessageInstance, setDomMessage?: Function) => {
    let domMessage = '';
    if (type === ShowMessageTypes.ERROR) {
        const messageSuffix = message ? ` ${message}` : ' Something went wrong.';
        messageApi.open({
            type: 'error',
            content: `Error! ${messageSuffix}`,
            icon: <WarningOutlined />,
        });
        domMessage = `Error! ${message}`;
    } else {
        const messageSuffix = message ? ` ${message}` : ' Your changes have been saved.';
        messageApi.open({
            type: 'success',
            content: 'Success!' + messageSuffix,
            icon: <CheckCircleOutlined />,
        });
    }
    setDomMessage && domMessage && setDomMessage(domMessage);
};

export const showErrorMessage = (message: string, messageApi: MessageInstance, setDomMessage?: Function) => {
    showMessage(ShowMessageTypes.ERROR, message, messageApi, setDomMessage);
};

export const showSuccessMessage = (message: string, messageApi: MessageInstance, setDomMessage?: Function) => {
    showMessage(ShowMessageTypes.SUCCESS, message, messageApi, setDomMessage);
};


//this is purely to make testing easier
export const useSelectUserRole = () => useSelector((state : AppState) => state?.user?.roleName);

export const useUserRoleType = () => {
    const isVendor = useSelector((state : AppState) => state?.user?.isVendor);

    return isVendor ? RoleType.VENDOR : RoleType.CUSTOMER;
};

export const useHasMultipleSubsidiaries = () => {
    const SINGLE_INSTANCE = 1;
    const user = useSelector((state: AppState) => state.user);
    const companyFilter = { 'filterModel': { 'companyId': { 'values': [user.companyId], 'filterType': 'set' } } };
    const subsidiaries = useGetAllSubsidiariesQuery(companyFilter);
    const hasMultipleSubsidiaries = useMemo(() => subsidiaries?.data?.length > SINGLE_INSTANCE, [subsidiaries?.data]);

    return hasMultipleSubsidiaries;
};

export const useNavigateToRecord = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const onNavigate = useCallback((recordType: string, recordId?: number) => {
        const isListPage = location.pathname.startsWith(`/${recordType}`);
        const queryParams = recordId ? `/${recordId}` : '';
        isListPage && navigate(`/${recordType}${queryParams}`);
    }, [location.pathname, navigate]);

    return onNavigate;
};

export const useInfiniteScrolling = (results: TableRecord[]) => {
    const PAGE_SIZE = 10;
    const MARGIN_DIFF = 10;
    const MARGIN = 200;

    const [allResults, setAllResults] = useState<TableRecord[]>([]);
    const [currentRowId, setCurrentRowId] = useState(PAGE_SIZE);
    const isLastResult = useMemo(() => currentRowId >= allResults.length, [currentRowId, allResults]);

    useEffect(() => {
        setAllResults(results);
    }, [results]);

    const isEndOfScroll = useCallback((container: HTMLElement) => {
        const { scrollHeight, scrollTop, offsetHeight } = container;
        const scrollDiff = Math.abs(scrollHeight - scrollTop - offsetHeight);

        return scrollDiff <= MARGIN_DIFF || offsetHeight === 0;
    }, []);

    const getNext = useCallback((container: HTMLElement) => {
        setTimeout(() => {
            isEndOfScroll(container) && setCurrentRowId(
                Math.min(currentRowId + PAGE_SIZE, allResults.length)
            );
        }, 750);
    }, [isEndOfScroll, currentRowId, allResults]);

    const loadUntilVisibleScroll = useCallback((container: HTMLElement) => {
        const maxVisibleHeight = window.innerHeight;
        const containerVisibleHeight = container.clientHeight;
        const isScrollbarHidden = (maxVisibleHeight - containerVisibleHeight) > MARGIN;
        if (!isLastResult && isScrollbarHidden) {
            getNext(container);
        }
    }, [isLastResult, getNext]);

    return {
        results: allResults.slice(0, currentRowId),
        isLastResult,
        getNext,
        loadUntilVisibleScroll
    };
};