import { TableRecord, TableRowValue } from '../../@types/list-details/zcvp_get_list_details';
import { isEmpty } from '../../lib/Utils';

export class ValidateFormValuesUseCase {
    public getChanges(oldValues: TableRecord, newValues: TableRecord) {
        return Object.keys(newValues).reduce((updatedValues, key) => {
            const oldValue = oldValues[key];
            const newValue = newValues[key];
            if(this.isEdited(oldValue, newValue)) {
                const updatedValueKey = isEmpty(oldValue) && !isEmpty(newValue) ? 'addedValues' : 'changedValues';
                updatedValues[updatedValueKey][key] = newValue;
            }
            return updatedValues;
        }, {
            changedValues: {}, addedValues: {}
        });
    }
    public isEdited(oldValues: TableRecord|TableRowValue[]|TableRowValue, newValues: TableRecord|TableRowValue[]|TableRowValue): boolean {
        const isArray = Array.isArray(newValues);
        const isObject = typeof newValues === 'object' && newValues !== null;
        if(isObject && !isArray) {
            return this.compareObjects(oldValues, newValues);
        }
        if(isArray) {
            return this.compareArrays(oldValues, newValues);
        }
        return this.compareValues(oldValues, newValues);;
    }
    private compareValues(oldValue, newValue): boolean {
        const prevValue = oldValue || null;
        const nextValue = newValue || null
        return prevValue !== nextValue;
    }
    private compareObjects(oldValues, newValues): boolean {
        const keys = Object.keys(newValues);
        if(!keys.length) {
            return false;
        } else {
            return keys.some(key => this.isEdited(oldValues ? oldValues[key] : null, newValues[key]));
        }
    }
    private compareArrays(oldValues, newValues): boolean {
        return (newValues as unknown as TableRowValue[]).some((newValue, index) => this.isEdited(oldValues[index], newValue));
    }
}