import { SendVendorInvitationGateway } from '../../@types/invite-vendors/zcvp_invite_vendors';
import { SendInvitationPresenter } from '../invitation/sendInvitationPresenter';
import { ZCVPApiErrorResponse } from '../../@types/zcvp_api';

export type FieldValue = {
    [key: string]: string|string[];
}
export class SendVendorInvitationUseCase {
    private gateway: SendVendorInvitationGateway;
    private presenter: SendInvitationPresenter;
    constructor(gateway: SendVendorInvitationGateway, presenter: SendInvitationPresenter) {
        this.gateway = gateway;
        this.presenter = presenter;
    }
    public execute(values : {}) {
        this.gateway.inviteCallback(values).then(({ error }: {error: ZCVPApiErrorResponse}) => this.presenter.convert({ isError: typeof error !== 'undefined', error }));
    }
}