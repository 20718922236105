import { BellOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Popover, Badge, Empty } from 'antd';
import React, { useMemo } from 'react';

import NotificationPopoverContent from './NotificationPopoverContent';
import { TranslationNS } from '../../lib/TranslationHelper';
import { useGetNotificationsQuery } from '../../slice/APISlice';
import { NotificationType } from '../../use-case/getNotifications/getNotificationsUseCase';
import { GetNotificationsController } from '../../use-case/getNotifications/getNotificationsController';
import '../../styles/zcvp_card.scss'
import '../../styles/zcvp_notifications.scss';

const NotificationPopover = () => {
    const { t } = useTranslation(TranslationNS.USER, { keyPrefix: TranslationNS.NOTIFICATION });
    const notifications = useGetNotificationsQuery({});

    const notificationController = useMemo(() => new GetNotificationsController(), []);
    const sortedNotifications = useMemo(() => notificationController.execute(notifications?.data), [notificationController, notifications?.data]);
    const unreadNotificationCount = useMemo(() => notificationController.executeCountUnread(sortedNotifications[NotificationType.INBOX]), [notificationController, sortedNotifications]);

    return <Popover
        className='self-center'
        overlayClassName='zcvp-notification'
        arrow={false}
        placement="rightTop"
        title={t('notifications')}
        trigger='click'
        content={notifications?.data?.length
            ? <NotificationPopoverContent notifications={sortedNotifications}/>
            : <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                className='zcvp-empty-notif-list'
                description={t('empty_notifications')} />
        }>
            <Badge
                size='small'
                className='zcvp-notification-trigger'
                count={unreadNotificationCount}>
                <BellOutlined />
            </Badge>
    </Popover>
};

export default NotificationPopover;