import { CheckOutlined, ContactsOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Button, Flex, Space, Tooltip } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { PortalFeature } from '../../@types/role-permissions/zcvp_role_permissions_enum';
import { RoutesUrls } from '../../@types/zcvp_routes_enum';
import { UserState } from '../../@types/zcvp_state';
import BasicContentLayout from '../../components/layout/BasicContentLayout';
import CardComponent from '../../components/partials/CardComponent';
import { SupportEmailLink } from '../../components/partials/SupportEmailLink';
import { TranslationNS, getFixedT } from '../../lib/TranslationHelper';
import { useGetAllVendorContactsQuery, useGetVendorQuery } from '../../slice/APISlice';
import { CheckRolePermissionController } from '../../use-case/checkRolePermission/checkRolePermissionController';
import { DashboardController } from '../../use-case/portal/dashboardController';
import PendingRequestListCard from './PendingRequestListCard';

enum Task {
    UNFINISHED = 1,
    FINISHED = 0
}

const alertDescription = (companyName) => {


    return (
        <>
            {getFixedT('vendor:alert_description', { companyName: companyName })}
            <br />
            <br />
            <SupportEmailLink />
        </>
    )
};

export const DashboardVendor = ({ }) => {
    const { t } = useTranslation(TranslationNS.DASHBOARD);
    const user = useSelector((state: { user: UserState }) => state.user);
    const { data: vendorContactData } = useGetAllVendorContactsQuery({});
    const { data: vendorData } = useGetVendorQuery({ id: user.vendorId! }, { skip: user.vendorId === null });

    const checkPermissionController = useMemo(() => new CheckRolePermissionController(user), [user]);
    const dashboardController = useMemo(() => new DashboardController(), [user]);

    const contactsOnboarded = useMemo(() => dashboardController.executeHasInvitedContacts(vendorContactData), [vendorContactData]);
    const profileComplete = useMemo(() => dashboardController.executeHasCompletedProfile(vendorData), [vendorData]);
    const canInviteContacts = useMemo(() => checkPermissionController.executeFeatureCheck(PortalFeature.INVITE_CONTACT), []);
    const canEditProfile = useMemo(() => checkPermissionController.executeFeatureCheck(PortalFeature.EDIT_VENDOR), []);
    const inviteContactButtonTooltip = useMemo(() => dashboardController.executeValidateDashboardFeature(canInviteContacts, PortalFeature.INVITE_CONTACT), [canInviteContacts]);
    const editProfileButtonTooltip = useMemo(() => dashboardController.executeValidateDashboardFeature(canEditProfile, PortalFeature.EDIT_VENDOR), [canEditProfile]);

    const tasksRemaining = useMemo(() => (contactsOnboarded ? Task.FINISHED : Task.UNFINISHED) + (profileComplete ? Task.FINISHED : Task.UNFINISHED), [contactsOnboarded, profileComplete]);

    return (
        <>
            <BasicContentLayout
                title={t('dashboard_title')}
            >
                <Flex
                    vertical={true}
                    gap={44}
                    className={'zcvp-basic-content-centered'}
                >
                    <Alert
                        type={'info'}
                        description={alertDescription(user.companyName)}
                        showIcon={true}
                    />
                    <CardComponent
                        title={t('dashboard_card_title')}
                        subtitle={t('dashboard_card_subtitle')}
                        titleNotifications={[
                            t('remaining_tasks', { count: tasksRemaining })
                        ]}
                        className={'grow zcvp-dash-card-parent'}
                    >
                        <Flex
                            gap={16}
                            justify={'space-evenly'}
                            align={'center'}
                        >
                            <CardComponent title={(
                                <div className={'flex flex-row items-center'}>
                                    <CheckOutlined
                                        twoToneColor={'#FFBD7A'}
                                    />
                                    {t('vendor_created')}
                                </div>
                            )} className={'zcvp-dash-card'}>
                                {t('task_done')}
                            </CardComponent>
                            <CardComponent title={(
                                <div className={'flex flex-row items-center'} data-testid={'zcvp-dashboard-vendor-invite-contacts-header'}>
                                    {contactsOnboarded ? (
                                        <>
                                            <CheckOutlined
                                                twoToneColor={'#FFBD7A'}
                                            /> {t('contacts_exist')}
                                        </>) : (
                                        <>
                                            <ContactsOutlined
                                                twoToneColor={'#FFBD7A'}
                                            />
                                            {t('invite_contacts')}
                                        </>
                                    )}
                                </div>
                            )} className={'zcvp-dash-card'}>
                                {contactsOnboarded ? 'Additional contacts onboarded successfully.' : (
                                    <Space direction={'vertical'} size={'middle'}>
                                        {t('no_invites_sent')}
                                        <Tooltip title={inviteContactButtonTooltip}>
                                            <Button type={'primary'} disabled={!canInviteContacts}>
                                                <Link to={RoutesUrls.COMPANY_INVITE}>
                                                    {t('invite_contacts')}
                                                </Link>
                                            </Button>
                                        </Tooltip>
                                    </Space>
                                )}
                            </CardComponent>
                            <CardComponent title={(
                                <div className={'flex flex-row items-center'} data-testid={'zcvp-dashboard-vendor-complete-profile-header'}>
                                    {profileComplete ? (
                                        <>
                                            <CheckOutlined
                                                twoToneColor={'#FFBD7A'}
                                            /> {t('company_profile_complete')}
                                        </>
                                    ) : (
                                        <>
                                            <UserOutlined
                                                twoToneColor={'#FFBD7A'}
                                            />
                                            {t('update_company_profile')}
                                        </>
                                    )}
                                </div>
                            )} className={'zcvp-dash-card'}>
                                {profileComplete ? 'Thank You! Your company profile is complete.' : (
                                    <Space direction={'vertical'} size={'middle'}>
                                        {t('missing_profile_info')}
                                        <Tooltip title={editProfileButtonTooltip}>
                                            <Button type={'primary'} disabled={!canEditProfile}>
                                                <Link to={RoutesUrls.COMPANY}>
                                                    {t('update_profile_button')}
                                                </Link>
                                            </Button>
                                        </Tooltip>
                                    </Space>
                                )}
                            </CardComponent>

                        </Flex>
                    </CardComponent>
                    <PendingRequestListCard />
                </Flex>
            </BasicContentLayout>
        </>
    );
};
