import { Input } from 'antd';
import React from 'react';

import '../../styles/zcvp_search_bar.scss';

const { Search } = Input;

type SearchBarProps = {
    placeholder: string;
    value?: string;
    onSearch: (value: string) => void;
}
const SearchBar: React.FC<SearchBarProps> = ({ placeholder, value, onSearch }: SearchBarProps) => <Search
    className='zcvp-search-bar'
    data-cy='search_bar'
    placeholder={placeholder}
    value={value}
    onSearch={onSearch}
    onChange={(e) => onSearch(e.target.value)}
    allowClear />;

export default SearchBar;