import { Button, Form, Input } from 'antd';
import { ValidateStatus } from 'antd/es/form/FormItem';
import { MessageInstance } from 'antd/es/message/interface';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { ZCVPApiResponse } from '../../@types/zcvp_api';
import { ApiErrorCode } from '../../@types/zcvp_api_enum';
import { ShowMessageTypes } from '../../@types/zcvp_custom_hooks';
import SingleColumnContent from '../../components/page/SingleColumnContent';
import { showMessage } from '../../lib/CustomHooks';
import { TranslationNS } from '../../lib/TranslationHelper';
import { usePostResetPasswordMutation } from '../../slice/APISlice';

const ForgotPasswordForm: React.FC = () => {
    const { t } = useTranslation(TranslationNS.AUTHENTICATION);
    const navigate = useNavigate();
    const { messageApi } = useOutletContext<{ messageApi: MessageInstance }>();
    const [form] = Form.useForm();
    const [emailSent, setEmailSent] = useState(false);
    const [fieldValue, setFieldValue] = useState<{
        value: string,
        validateStatus?: ValidateStatus,
        errorMsg?: string | null
    }>({ value: '', validateStatus: 'success' });

    const handleBackToLogin = () => {
        navigate('/login');
    }

    const [resetPassword] = usePostResetPasswordMutation();

    const handleResetPassword = () => {
        const fieldValues = form.getFieldsValue();
        if (fieldValues.email) {
            resetPassword({ email: fieldValues.email })
                .then((response: ZCVPApiResponse) => {
                    if (typeof response.error !== 'undefined') {
                        const errorMessage = response.error.errorCode === ApiErrorCode.NOT_FOUND ? t('user_not_found') : response.error.message
                        setFieldValue({
                            validateStatus: 'error',
                            errorMsg: errorMessage,
                            value: fieldValues.email
                        });
                    } else {
                        setEmailSent(true);
                        showMessage(ShowMessageTypes.SUCCESS, t('password_reset_request_sent'), messageApi);
                    }
                });
        }
    }

    const validateEmail = (val: string): {
        validateStatus: ValidateStatus,
        errorMsg: string | null
    } => {
        const result: {
            validateStatus: ValidateStatus,
            errorMsg: string | null
        } = {
            validateStatus: 'success',
            errorMsg: null
        };
        if (!val) {
            result.validateStatus = 'error';
            result.errorMsg = t('please_enter_email');
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(val)) {
            result.validateStatus = 'error';
            result.errorMsg = t('email_not_valid');
        } else {
            result.validateStatus = 'success';
            result.errorMsg = null;
        }
        return result;
    }

    const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setFieldValue({
            ...validateEmail(value),
            value
        });
    }

    return (
        <SingleColumnContent
            h2={emailSent ? t('password_reset_request_sent') : t('forgot_password_heading')}
            tagline={emailSent ? t('check_your_email')
                : t('forgot_password_tagline')}
        >
            {!emailSent &&
                <Form
                    name={'zcvp_log_in_form'}
                    layout="vertical"
                    form={form}
                    requiredMark={false}
                    onFinish={handleResetPassword}
                >
                    <Form.Item label="Email" id={'zcvp_email'} name={'email'}
                        validateStatus={fieldValue.validateStatus}
                        help={fieldValue.errorMsg || t('email_field_help')}
                        rules={[{
                            required: true,
                            type: 'email'
                        }]}>
                        <Input placeholder="kate@company.com" onChange={handleValueChange} />
                    </Form.Item>
                    <Button size="large" type="primary" block htmlType={'submit'} className='mb-6 mt-6' disabled={fieldValue.validateStatus === 'error'}>{t('send_reset_instructions')}</Button>
                    <div className='text-center'>
                        {t('back_to_login_text')} <Button type="link" className='text-black px-0' onClick={handleBackToLogin}>{t('back_to_login_button')}</Button>
                    </div>
                </Form>
            }
            {emailSent &&
                <div className='text-center'>
                    {t('thank_you')} <Button type="link" className='text-black px-0' onClick={handleBackToLogin}>{t('back_to_login_button')}</Button>
                </div>
            }
        </SingleColumnContent>
    );
};

export default ForgotPasswordForm;