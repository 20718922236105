import { VendorRequestActionGateway } from '../../@types/vendor-requests/zcvp_vendor_requests';

export enum VendorRequestAction {
    SUBMIT = 'submit',
    REVIEW = 'review',
    CANCEL = 'cancel'
}
export type GetVendorRequestActionUseCaseParams = {
    canSubmitRequests: boolean;
    canApproveRequests: boolean;
}
export class GetVendorRequestActionUseCase {
    private gateway: VendorRequestActionGateway;
    constructor(gateway: VendorRequestActionGateway) {
        this.gateway = gateway;
    }
    public execute(): VendorRequestAction|undefined {
        if(this.gateway.isVendor() && this.gateway.canUserCancelRequest()) {
            return VendorRequestAction.CANCEL;
        }
        if(this.gateway.canSubmitRequest() && this.gateway.canUserSubmitRequest()) {
            return VendorRequestAction.SUBMIT;
        }
        if(this.gateway.canApproveRequest() && this.gateway.canUserApproveRequest()) {
            return VendorRequestAction.REVIEW;
        }
    }
    public canCancelNewRequest(): boolean {
        return !this.gateway.isVendor() && this.gateway.canUserCancelRequest();
    }
    public canDeleteAttachment(): boolean {
        return this.gateway.canUserDeleteAttachment();
    }
}