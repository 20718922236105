import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import { ApiRequestStatus, ApiVendorRequestType, ApiVendorStatus } from '../../@types/zcvp_api_enum';
import { StatusColor } from '../../@types/zcvp_base_types_enum';
import i18n from '../../i18n';
import { COMPANY_SIZE_OPTIONS, CompanyType } from '../../lib/Constants';
import { getCommonT, TranslationNS } from '../../lib/TranslationHelper';

export const getVendorFullName = (record: TableRecord): string => [record?.firstName, record?.lastName].filter(nameValue => !!nameValue).join(' ');

export const getVendorName = (record: TableRecord = {}) => {
    let displayName = '';
    if (record.size === CompanyType.INDIVIDUAL as unknown as string) {
        displayName = getVendorFullName(record);
    }
    return displayName || record.companyName || record.name;
};

export const getVendors = (vendorsList: TableRecord[]): TableRecord[] => vendorsList.map(record => ({
    ...record, displayName: getVendorName(record)
}));

type StatusMap = {
    [key: string]: string
};
export const getVendorStatus = (status: string): string => {
    const statusMap: StatusMap = {
        ...ApiVendorStatus,
        [ApiVendorStatus.PENDING_INVITATION]: 'READY_TO_INVITE',
        [ApiVendorStatus.REJECTED_TO_ONBOARD]: getCommonT('rejected'),
        [ApiVendorStatus.REJECTED_AFTER_ONBOARD]: getCommonT('rejected')
    };
    const customStatusMap: StatusMap = {
        [ApiVendorStatus.PENDING_APPROVAL_AFTER_ONBOARD]: i18n.t('vendor:onboarded_vendor_to_review'),
        [ApiVendorStatus.PENDING_APPROVAL_TO_ONBOARD]: i18n.t('vendor:draft_pending_approval'),
    };
    return customStatusMap[status] || statusMap[status]?.split('_')
        .map((statusToken: string) => getLabel(statusToken))
        .join(' ');
}

export const getVendorStatusFilterOptions = (statusOptions = Object.values(ApiVendorStatus)) => {
    const statusList = new Set<string>();
    return Array.from(statusOptions
        .map(vendorStatusCode => getVendorStatus(vendorStatusCode))
        .reduce((vendorStatusList: Set<string>, vendorStatusCode: string) => {
            vendorStatusList.add(vendorStatusCode);
            return vendorStatusList;
        }, statusList))
        .map((vendorStatus: string) => ({
            id: vendorStatus,
            name: vendorStatus
        }));
};

export const getOnboardingVendorStatusFilterOptions = () => {
    return getVendorStatusFilterOptions([
        ApiVendorStatus.PENDING_ONBOARDING,
        ApiVendorStatus.PENDING_APPROVAL_TO_ONBOARD,
        ApiVendorStatus.PENDING_APPROVAL_AFTER_ONBOARD
    ]);
};

export const getVendorStatusColor = (status: string): string => {
    const VendorStatusColor: { [key: string]: string } = {
        [ApiVendorStatus.ACTIVE]: StatusColor.GREEN,
        [ApiVendorStatus.INACTIVE]: StatusColor.RED,
        [ApiVendorStatus.PENDING_ONBOARDING]: StatusColor.BLUE,
        [ApiVendorStatus.PENDING_APPROVAL_AFTER_ONBOARD]: StatusColor.GOLD,
        [ApiVendorStatus.PENDING_APPROVAL_TO_ONBOARD]: StatusColor.GOLD,
        [ApiVendorStatus.REJECTED_TO_ONBOARD]: StatusColor.RED,
        [ApiVendorStatus.REJECTED_AFTER_ONBOARD]: StatusColor.RED
    };
    return VendorStatusColor[status];
};

export const getVendorSize = (sizeId?: string): string => {
    return COMPANY_SIZE_OPTIONS.find(sizeOption => sizeOption.id === sizeId?.toString())?.label || '';
};

export const getVendorSubsidiary = (subsidiaries: TableRecord[] = [], defaultSubsidiaryId: string): string => {
    if (defaultSubsidiaryId) return defaultSubsidiaryId;
    const [defaultSubsidiary] = subsidiaries;
    return defaultSubsidiary?.id as string;
};

export const getVendorRequestStatusFilterOptions = (isVendor: boolean) => {
    const reviewTextKey = isVendor ? 'pending_review' : 'to_review';
    const RequestStatusMap: { [key: string]: string } = {
        [ApiRequestStatus.NEW]: i18n.t(`${TranslationNS.VENDOR_REQUEST}.pending`, { ns: TranslationNS.VENDOR }),
        [ApiRequestStatus.REVIEW]: i18n.t(`${TranslationNS.VENDOR_REQUEST}.${reviewTextKey}`, { ns: TranslationNS.VENDOR })
    };
    return Object.values(ApiRequestStatus).map((vendorRequestStatus) => ({
        id: vendorRequestStatus,
        name: RequestStatusMap[vendorRequestStatus] || getLabel(vendorRequestStatus)
    }));
};

export const getPendingRequestStatusFilterOptions = (isVendor: boolean) => {
    return getVendorRequestStatusFilterOptions(isVendor).filter(filterOption => [
        ApiRequestStatus.NEW, ApiRequestStatus.REVIEW
    ].includes(filterOption.id));
};

export const getVendorRequestTypeFilterOptions = () => {
    return Object.values(ApiVendorRequestType).map((requestType) => ({
        id: requestType,
        name: getLabel(requestType.replace(/_/g, ' '))
    }));
};

export const getRequestVendorFilterOptions = (vendorList: TableRecord[], requests: TableRecord[]) => {
    return vendorList
        ?.filter(vendor => requests?.find(request => request.vendorId === vendor.id))
        .map((vendor) => ({
        id: vendor.id,
        name: getVendorName(vendor)
    }));
};

const getLabel = (value: string) => `${value.slice(0, 1).toUpperCase()}${value.slice(1).toLowerCase()}`;