import { ActionItem } from '../../components/layout/TabbedContentLayout';
import { GetTabActionsPresenter } from './getTabActionsPresenter';
import { GetTabActionsUseCase,  } from './getTabActionsUseCase';

export class GetTabActionsController {
    public execute(actions: ActionItem[], activeKey: string): React.ReactNode {
        const presenter = new GetTabActionsPresenter();
        const useCase = new GetTabActionsUseCase(presenter);
        return useCase.execute(actions, activeKey);
    }
}