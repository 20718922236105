import React, { createRef, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tour } from 'antd';
import { TourStepProps } from 'antd/lib';

import { useEditUserMutation, useGetUserQuery } from '../../slice/APISlice';
import { closeTutorial, goNextStep, goPreviousStep, setTutorial } from '../../slice/TutorialSlice';
import { canGoNextStep, hasTutorial, isCurrentStepDisabled } from '../../use-case/tutorial/portalTutorialUseCase';
import { PortalTutorialController } from '../../use-case/tutorial/portalTutorialController';
import { TutorialState, UserState } from '../../@types/zcvp_state';
import { ButtonProps, PortalTutorialStep, TutorialButtonProps, TutorialStep } from '../../@types/tutorial/zcvp_tutorial';
import { ApiRecord } from '../../@types/zcvp_api_enum';
import { RoutesUrls, StorageKey } from '../../@types/zcvp_routes_enum';
import { ConfigurationTabKey, UserTabKey } from '../../@types/zcvp_tabbed_page_enum';
import { TutorialStepKey, TutorialLinkType } from '../../@types/tutorial/zcvp_tutorial_enum';

const TutorialLink: {[key: string]: {text: string, link: string}} = {
    [TutorialLinkType.INTEGRATION]: {
        text: 'help page here', link: 'https://help.zoneandco.com/hc/en-us/articles/23088280053531-Vendor-Portal-Setup-and-Integration'
    },
    [TutorialLinkType.ROLE_INFO]: {
        text: 'Learn more about roles here', link: 'https://help.zoneandco.com/hc/en-us/articles/23383474625307-Roles-and-permissions'
    },
    [TutorialLinkType.KNOWLEDGE_BASE]: {
        text: 'knowledge base', link: 'https://help.zoneandco.com/hc/en-us/sections/23088272688283-Vendor-Portal'
    }
};

const getLink = (tutorialLink: string) => {
    const { text, link } = TutorialLink[tutorialLink];
    return <a href={link} target='_blank' rel="noreferrer">{text}</a>;
};

export const TUTORIAL_STEPS: TutorialStep[] = [
    {
        key: TutorialStepKey.MAIN,
        title: 'Hi {name}, let’s go through some basics together!',
        description: 'Join us as we guide you through a few fundamental steps to swiftly establish your company in just a minute.',
        type: 'primary',
        mask: false,
        page: RoutesUrls.HOME
    },
    {
        key: TutorialStepKey.COMPANY_CONFIG,
        title: `Let’s start with a configuration`,
        description: 'Access the configuration screen by clicking on the tool icon.',
        placement: 'rightTop',
        page: RoutesUrls.HOME
    },
    {
        key: TutorialStepKey.INTEGRATIONS,
        title: 'First is the NetSuite integration',
        description: 'Proceed by clicking on the Integration section above.',
        placement: 'bottom',
        page: `${RoutesUrls.CONFIGURATION}`
    },
    {
        key: TutorialStepKey.INTEGRATION_DETAILS,
        title: 'Integration details',
        description: <>Your integration has already been configured. However, should you ever need to update Netsuite credentials, you'll also need to modify parameters on this page. Access the integration {getLink(TutorialLinkType.INTEGRATION)}.</>,
        placement: 'top',
        page: `${RoutesUrls.CONFIGURATION}?tab=${ConfigurationTabKey.INTEGRATIONS}`,
        disabledInteraction: true
    },
    {
        key: TutorialStepKey.APPROVALS,
        title: 'Next is the Approval workflow',
        description: 'Proceed by clicking on the Approvals section above.',
        placement: 'bottom',
        page: `${RoutesUrls.CONFIGURATION}?tab=${ConfigurationTabKey.GENERAL}`
    },
    {
        key: TutorialStepKey.VENDOR_MANAGEMENT,
        title: 'Configure your Vendor workflow',
        description: `Take a few moments to explore our options above. When requesting an approval or review, it's essential to ensure that users have the appropriate roles assigned.`,
        placement: 'bottom',
        page: `${RoutesUrls.CONFIGURATION}?tab=${ConfigurationTabKey.APPROVALS}`,
    },
    {
        key: TutorialStepKey.USERS,
        title: 'Last to explain will be user invitations',
        description: 'Access the Users page by selecting the "Users" menu option.',
        placement: 'rightTop',
        page: `${RoutesUrls.CONFIGURATION}?tab=${ConfigurationTabKey.APPROVALS}`
    },
    {
        key: TutorialStepKey.NETSUITE_USERS,
        title: 'How to Invite a new User?',
        description: 'You can only invite users who already exist in Netsuite. Proceed by clicking on the Netsuite Users section above.',
        placement: 'bottom',
        page: `./${RoutesUrls.USERS}?tab=${UserTabKey.NETSUITE_USERS}`
    },
    {
        key: TutorialStepKey.NETSUITE_USERS_DETAILS,
        title: 'New users and roles',
        description: <>When you wish to invite more users, simply follow the instructions provided on this page. Ensure that you select the appropriate role when sending invitations. {getLink(TutorialLinkType.ROLE_INFO)}.</>,
        placement: 'bottom',
        page: `${RoutesUrls.USERS}?tab=${UserTabKey.NETSUITE_USERS}`
    },
    {
        key: TutorialStepKey.COMPLETION,
        title: `Congratulations, you're all set up!`,
        description: <>We hope this tutorial has helped you feel more confident, ensuring that nothing catches you off guard in the future. Remember, you can always refer to our {getLink(TutorialLinkType.KNOWLEDGE_BASE)} if you encounter any difficulties.</>,
        page: `${RoutesUrls.USERS}?tab=${UserTabKey.NETSUITE_USERS}`
    }
];

export const PORTAL_TUTORIAL_STEPS: PortalTutorialStep[] = TUTORIAL_STEPS.map((tutorialStep) => {
    const tutorialRef: React.RefObject<HTMLInputElement> = createRef();
    return {
        ...tutorialStep,
        elementRef: tutorialRef,
        target: () => tutorialRef.current
    };
});

export const getTutorialStepRef = (tutorialStepKey: string): React.RefObject<HTMLInputElement> | undefined => {
    return PORTAL_TUTORIAL_STEPS.find(tutorialStep => tutorialStep.key === tutorialStepKey)?.elementRef;
};

export const PortalTutorial = () => {
    const DELAY = 1;
    const START_TOUR = 0;
    const {userId, firstName: userFirstName} = useSelector((state: { user: UserState }) => state.user);
    const {currentStep, isTutorialOpen} = useSelector((state: { tutorial: TutorialState }) => state.tutorial);

    const dispatch = useDispatch();
    const [editUser] = useEditUserMutation();
    const goNext = useCallback(() => setTimeout(() => dispatch(goNextStep()), DELAY), [dispatch]);
    const goPrevious = useCallback(() => setTimeout(() => dispatch(goPreviousStep()), DELAY), [dispatch]);
    const onClose = useCallback(() => {
        editUser({
            [ApiRecord.UserField.ID]: userId,
            [ApiRecord.UserField.TUTORIAL_PROGRESS]: currentStep
        });
        dispatch(closeTutorial());
    }, [editUser, userId, currentStep, dispatch]);

    const className = useMemo(() => currentStep === START_TOUR ? 'first-step' : '', [currentStep]);
    const allSteps = useMemo(() => new PortalTutorialController(goNext, goPrevious).execute(PORTAL_TUTORIAL_STEPS, userFirstName!), [goNext, goPrevious, userFirstName]);
    const tutorialSteps = useMemo(() => allSteps.map((tutorialStep) => ({
        ...tutorialStep,
        nextButtonProps: getButtonProps(tutorialStep.nextButtonProps),
        prevButtonProps: getButtonProps(tutorialStep.prevButtonProps)
    })), [allSteps]);

    const disableInteraction = useMemo(() => isCurrentStepDisabled(PORTAL_TUTORIAL_STEPS, currentStep), [currentStep]);

    return <Tour open={isTutorialOpen} onClose={onClose} steps={tutorialSteps as TourStepProps[]} rootClassName={className} current={currentStep} disabledInteraction={disableInteraction}/>;
};

export const UserPortalTutorial = () => {
    const dispatch = useDispatch();
    const roleName = useSelector((state: { user: UserState }) => state.user.roleName);const {
        data: userData = {}
    } = useGetUserQuery({ userId: localStorage.getItem(StorageKey.USER_ID) }, { skip: !localStorage.getItem(StorageKey.USER_ID) });
    useEffect(() => {
        userData && dispatch(setTutorial(userData?.tutorialProgress === null));
    }, [dispatch, userData]);
    return hasTutorial(roleName!) ? <PortalTutorial /> : null;
}

export function useGoNextTutorialStep() {
    const dispatch = useDispatch();
    const currentTutorialStep = useSelector((state: { tutorial: TutorialState }) => state.tutorial.currentStep);
    const goNext = useCallback((key: string) => {
        canGoNextStep(TUTORIAL_STEPS, currentTutorialStep, key) && dispatch(goNextStep());
    }, [dispatch, currentTutorialStep]);
    return (key: string) => goNext(key);
}

const getButtonProps = ({ label, className, page, onClick }: ButtonProps): TutorialButtonProps => ({
    children: <Link to={page}>{label}</Link>,
    className,
    onClick
});