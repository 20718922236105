import React from 'react';
import { Radio, Form, Space } from 'antd';
import { RadioFieldProps } from '../../../@types/zcvp_form';

/**
 * Creates a radio field with name, label, default value, disabled, required, onChange properties
 *
 * @param {RadioFieldProps} props radio field properties
 * @returns {React.FC<RadioFieldProps>} a radio field inside a form item
 */
const RadioField: React.FC<RadioFieldProps> = (props: RadioFieldProps) => <Form.Item
    name={props.name}
    label={props.label}
    className={props.hideLabel ? 'zcvp-hidden-label' : '' }
    rules={[{
        required: props.required
    }]}
>
    <Radio.Group
        name={props.name}
        disabled={props.disabled}
        defaultValue={props.defaultValue}
        onChange={props.onChange}>
        <Space direction="vertical">
            {props.options.map((option) => (
                <Radio
                    value={option.value}
                    disabled={option.disabled}
                    key={`radio-${props.name}-${option.value}`}
                >
                    {option.label}
                </Radio>))}
        </Space>
    </Radio.Group>
</Form.Item>;

export default RadioField;