import React, { useCallback, useMemo, useState } from 'react';

import GenericActionButton from '../../components/form/form-action/GenericActionButton';
import InlineField from '../../components/form/form-field/InlineField';
import CardComponent from '../../components/partials/CardComponent';
import InviteUserModal from './InviteUsersModal';

import { useTranslation } from 'react-i18next';
import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import { ApiRecord } from '../../@types/zcvp_api_enum';
import { TranslationNS, getCommonT } from '../../lib/TranslationHelper';

type EmployeeInviteButtonProps = {
    record: TableRecord;
    onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

type NetSuiteUserDetailsProps = {
    record?: TableRecord;
}

const EmployeeInviteButton = ({ record, onClick }: EmployeeInviteButtonProps) => {
    const { t } = useTranslation(TranslationNS.USER, { keyPrefix: TranslationNS.USER_MANAGEMENT });
    const disableInviteButton: boolean = !!record[ApiRecord.EmployeeField.REGISTERED];
    const actionLabel: string = useMemo(() => record[ApiRecord.EmployeeField.INVITATION_PENDING]
        ? t('resend_invitation_button')
        : t('invite_user_button')
        , [record[ApiRecord.EmployeeField.INVITATION_PENDING]]);
    const actionTooltip: string = useMemo(() => disableInviteButton ? t('invitation_already_sent') : '', [disableInviteButton]);
    return <GenericActionButton
        key='invite-user'
        name='invite-user'
        type='primary'
        label={actionLabel}
        tooltip={actionTooltip}
        disabled={disableInviteButton}
        onClick={onClick} />
};

const NetSuiteUserDetails: React.FC<NetSuiteUserDetailsProps> = ({ record = {} }) => {
    const { t } = useTranslation(TranslationNS.USER, { keyPrefix: TranslationNS.USER_MANAGEMENT });
    const [openInviteUser, setOpenInviteUser] = useState(false);
    const closeInviteUser = useCallback(() => setOpenInviteUser(!openInviteUser), [openInviteUser]);

    return <>
        {openInviteUser ? <InviteUserModal open={openInviteUser} onClose={closeInviteUser} user={record} /> : null}
        <CardComponent
            title={t('personal_details_card_title')}
            titlePopover={{ content: getCommonT('go_to_netsuite_to_edit') }}
        >
            <InlineField label={getCommonT('name')} value={record.fullName as string} />
            <InlineField label={getCommonT('email')} value={record.email as string} />
        </CardComponent>
        {record.email && record.fullName ? <CardComponent title={t('account_actions_card_title')} subtitle={t('account_actions_card_subtitle')}>
            <EmployeeInviteButton record={record} onClick={() => setOpenInviteUser(true)} />
        </CardComponent> : null}
    </>;
};
export default NetSuiteUserDetails;