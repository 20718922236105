import React from 'react';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { RoutesUrls } from '../../@types/zcvp_routes_enum';
import SingleColumnContent from '../../components/page/SingleColumnContent';
import { TranslationNS } from '../../lib/TranslationHelper';

const InvitationSuccessPage: React.FC<{ passwordExists: boolean }> = ({ passwordExists }: { passwordExists: boolean }) => {
    const { t } = useTranslation(TranslationNS.ONBOARDING);

    return <SingleColumnContent
        h2={t('invitation_success_heading')}
        tagline={passwordExists ? t('invitation_success_tagline_no_user') : t('invitation_success_tagline')}
    >
        <div className='text-center'>
            <Link to={RoutesUrls.MAIN}>{t('back_to_login_button')}</Link>
        </div>
    </SingleColumnContent>;
}

export default InvitationSuccessPage;