import { CheckOutlined, CrownOutlined, DownOutlined, UserOutlined } from '@ant-design/icons';
import { Card, Dropdown, Space } from 'antd';
import { cloneElement, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { UserRole } from '../../@types/role-permissions/zcvp_role_permissions';
import { ZCVPApiResponse } from '../../@types/zcvp_api';
import { ApiRecord } from '../../@types/zcvp_api_enum';
import { RoutesUrls } from '../../@types/zcvp_routes_enum';
import { UserState } from '../../@types/zcvp_state';
import { RoleUserType } from '../../@types/zcvp_user_enums';
import { login } from '../../lib/AuthenticationHelper';
import { useGetAllRolesQuery, useSwitchProfileMutation } from '../../slice/APISlice';
import { LoaderState, updateLoaderInfo } from '../../slice/LoaderSlice';
import '../../styles/zcvp_auth.scss';
import { GetUserRolesController } from '../../use-case/getRoles/getUserRolesController';
import { LabelWithIcon } from '../partials/StyledText';
import LogoutButton from './LogoutButton';

const Offset = {
    X: 50,
    Y: -20
};

const RoleMenu = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state: { user: UserState }) => state.user);
    const isSwitchingProfile = useSelector((state: { loader: LoaderState }) => state.loader.isSwitchingProfile);

    const rawRoles = useGetAllRolesQuery({});
    const [switchProfile] = useSwitchProfileMutation();

    const { currentUserProfile, otherUserProfiles } = useMemo(() => new GetUserRolesController({
        user,
        roles: rawRoles as { data: ApiRecord.AppRole[] }
    })
    .execute(), [user, rawRoles]);

    const switchRole = useCallback((profileIds: ApiRecord.UserProfile) => switchProfile(profileIds)
        .then((response: ZCVPApiResponse) => {
            const userData = response?.data as ApiRecord.UserLoginData;
            login(userData?.token, userData?.userId.toString());
            !response.error && dispatch(updateLoaderInfo({
                isSwitchingProfile: true
            }))
        }), [dispatch, switchProfile]);

    useEffect(() => {
        isSwitchingProfile && navigate(RoutesUrls.MAIN);
    }, [isSwitchingProfile, navigate]);

    const roleItems = useMemo(() => otherUserProfiles.map((userRole: UserRole) => ({
        label: <span onClick={() => switchRole(userRole.profileIds)}>
            <LabelWithIcon label={userRole.label} leftIcon={<RoleIcon type={userRole.roleType} />} truncateText={true} />
        </span>
    })), [switchRole, otherUserProfiles]);

    const otherProfileList = useMemo(() => roleItems.length ? [{
        type: 'divider',
    },
    {
        key: 'otherprofiles',
        type: 'group',
        label: 'Other accounts',
        children: roleItems
    },
    {
        type: 'divider',
    }] : [], [roleItems]);

    const itemList = [
        ...otherProfileList,
        {
            key: 'signout',
            className: 'zcvp-signout',
            label: <LogoutButton />
        }
    ];

    return <Dropdown
        trigger={['click']}
        overlayClassName='zcvp-role-menu max-w-96'
        className={'flex items-center zcvp-nav-item-large zcvp-role-menu-trigger'}
        align={{ offset: Object.values(Offset) }}
        menu={{ items: itemList }}
        dropdownRender={(menu) => (<CustomRoleDropdown menu={menu} user={user} userRole={currentUserProfile}/>)}>
        <span onClick={(e) => e.preventDefault()} data-cy="role_menu">
            <LabelWithIcon label={user.companyName} rightIcon={<DownOutlined />} truncateText={true} />
        </span>
    </Dropdown>;
};

const RoleIcon = ({ type }: { type: RoleUserType }) => type === RoleUserType.ADMIN ? <CrownOutlined /> : <UserOutlined />;
const CustomRoleDropdown = ({ menu, user, userRole }) => <div className='bg-white'>
    <Space className='shadow-none w-full'>
        <Card.Meta className='zcvp-current-role'
            title={user.userEmail}
            description={<LabelWithIcon
                label={userRole.label}
                leftIcon={<RoleIcon type={userRole.roleType} />}
                rightIcon={<CheckOutlined/>}
                truncateText={true} />}
        />
    </Space>
    {cloneElement(menu)}
</div>;
export default RoleMenu;