import { LeftOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import RoleMenu from './RoleMenu';
import { getTourStep } from './VerticalNavStatic';
import { ZCVPMenuEntryConfig, ZCVPMenuEntryConfigLookup } from './NavigationConfig';
import { TranslationNS } from '../../lib/TranslationHelper';
import { useGoNextTutorialStep } from '../../pages/portal/PortalTutorial';
import { useGetCompanyDataQuery } from '../../slice/APISlice';
import { selectIsSubPortalPage } from '../../slice/LayoutThemeSlice';
import NotificationPopover from '../../pages/notifications/NotificationPopover';
import { AppState } from '../../@types/zcvp_state';

/**
 * Returns a menu that shows/hides as either a drawer or a popover, depending on the type of page
 * Note: they are both really drawers, just styled differently
 */
const VerticalNavToggleContent = () => {
    const { t } = useTranslation(TranslationNS.NAVIGATION);
    const location = useLocation();
    const isPortalPage = useSelector((state: AppState) => selectIsSubPortalPage(state, { pathname: location.pathname }));
    const goNextTutorialStep = useGoNextTutorialStep();

    const menu = useSelector((state: AppState) => isPortalPage ? state.layoutTheme.verticalSubPageMenu : state.layoutTheme.verticalIconMenu);
    const filteredMenu = menu && menu!.filter(item => item !== 'home' || isPortalPage);

    const {
        data: {
            logoUrl,
        } = {}
    } = useGetCompanyDataQuery({});

    return (
        <div
            className={'zcvp-nav-vertical-toggle'}>
            <div className={'zcvp-nav-vertical-toggle-content'}>
                <div className={'flex flex-col justify-between w-full'}>
                    <div>
                        <div>
                            {isPortalPage ? (
                                <div className='flex'>
                                    <RoleMenu />
                                    {filteredMenu && filteredMenu.length ? <NotificationPopover/> : null}
                                    {/* <div className={'zcvp-nav-quick-search'}>
                                        <div
                                            className={''}
                                        >
                                            <Input
                                                prefix={<SearchOutlined />}
                                                placeholder='Quick Search'
                                            />
                                        </div>
                                        <div className={''}>
                                            <BellOutlined className={'zcvp-nav-notification'} />
                                        </div>
                                    </div> */}
                                </div>
                            ) : (
                                <Link to={'/'} title={t('back_to_portal')} className={'zcvp-nav-item-large'}>
                                    <LeftOutlined /> {t('return_to_portal')}
                                </Link>
                            )}
                            <ul>
                                {filteredMenu && filteredMenu.map((id, index) => {
                                    const navData: ZCVPMenuEntryConfig = ZCVPMenuEntryConfigLookup[id] as ZCVPMenuEntryConfig;
                                    // translate the title again, as it is not translated in the config (why??)
                                    navData.title = t(navData.title);
                                    navData.subtitle = navData.subtitle ? t(navData.subtitle) : '';
                                    const { key, elementRef } = getTourStep(id);
                                    return (
                                        <li key={`toggle_nav_${index}_${id}`} onClick={() => goNextTutorialStep(key)}>
                                            <Link
                                                key={`toggle_nav_${index}_${id}_link`}
                                                to={navData.path}
                                                title={navData.title}
                                                target={navData.target}
                                                className={`flex items-center zcvp-nav-item ${location.pathname === navData.path ? 'zcvp-nav-item-mod-active' : ''}`}>
                                                <span ref={elementRef}>
                                                    {navData.icon}
                                                    {navData.title}
                                                    <span className='zcvp-nav-item-subtitle'>{navData.subtitle}</span>
                                                </span>
                                            </Link>
                                        </li>
                                    );
                                })}
                            </ul>

                        </div>
                    </div>
                    <img src={logoUrl} alt={'logo'} className={'mx-auto block zcvp-auth-logo'} />
                </div>
            </div>
        </div>
    );
};


export default VerticalNavToggleContent;