import { Form, Input } from 'antd';
import React from 'react';
import { PhoneNumberFieldProps } from '../../../@types/zcvp_form';
import '../../../styles/zcvp_phone_field.scss';
import { ZCVP_CONSTANTS } from '../../../lib/Constants';

/**
 * Creates a field for the area code select field
 *
 * @param {PhoneNumberFieldProps} props number field properties
 * @returns {React.FC} a number field inside a form item
 */
/*
const PhoneNumberCode = (props: { name: string }) => {
    const phoneCodeList = useMemo(() => customArray({
        label: '+{countryCallingCode}',
        value: '{countryCallingCode}',
        id: '{countryCode}',
        key: '{countryCode}'
    }), []);

    return <Form.Item name={`${props.name}code`}>
        <Select popupClassName='zcvp-phone-list' options={phoneCodeList} />
    </Form.Item>;
};
*/
/**
 * Creates a phone number field with name, label, default value, disabled, required, onChange properties
 *
 * @param {PhoneNumberFieldProps} props number field properties
 * @returns {React.FC<PhoneNumberFieldProps>} a number field inside a form item
 */
const PhoneNumberField: React.FC<PhoneNumberFieldProps> = (props: PhoneNumberFieldProps) => <Form.Item
    name={props.name}
    label={props.label}
    rules={[{
        required: props.required
    }, {
        pattern: new RegExp(ZCVP_CONSTANTS.VALIDATIONS.PHONE_NUMBER),
        message: 'Please enter a valid telephone number'
    }]}
    className={`zcvp-phone-fld ${props.className}`}>
    <Input
        placeholder={props.placeholder}
        /* addonBefore={<PhoneNumberCode name={props.name}/>} NS does not support phoneNumberCode */
        defaultValue={props.defaultValue}
        disabled={props.disabled}
        onChange={props.onChange} />
</Form.Item>;

export default PhoneNumberField;