import {
    useRedirectBasedOnAuthorization, useGetAuthentication,
} from '../lib/AuthenticationHelper';
import { PropsWithChildren } from 'react';
import TwoColumnsLayout from './layout/TwoColumnsLayout';
import UnauthenticatedLayout from './layout/UnauthenticatedLayout';

const Authentication = ({ children } : PropsWithChildren) => {
    const isRedirecting = useRedirectBasedOnAuthorization();
    const authentication = useGetAuthentication();
    //this is purely to stop a flash of content before the redirect happens.
    if (isRedirecting) {
        return null;
    }

    //return the correct layout for the respective error pages
    if (!!authentication) {
        if (children){
            return <TwoColumnsLayout>
                {children}
            </TwoColumnsLayout>;
        }
        
        return <TwoColumnsLayout/>;
    }

    if (children){
        return <UnauthenticatedLayout>
            {children}
        </UnauthenticatedLayout>;
    }

    return (<UnauthenticatedLayout />);

};

export default Authentication;