import React from 'react';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import { getCommonT } from '../lib/TranslationHelper';

/**
 * @param {unknown} error
 * return {string}
 */
function errorNormalizer(error: unknown) {
    if (isRouteErrorResponse(error)) {
        return `${error.status} ${error.statusText}`;
    } else if (error instanceof Error) {
        return error.message;
    } else if (typeof error === 'string') {
        return error;
    } else {
        //        console.error(error);
        return 'Unknown error';
    }
}

export default function ErrorPage() {
    const error = errorNormalizer(useRouteError());

    return (
        <div className={'text-center'}>
            <img src={'/loading_cap.png'} alt={'Error!'} className={'mx-auto block zcvp-main-img'} />
            <h1>{getCommonT('error')}: {error}</h1>
            <p>{getCommonT('unexpected_error')}</p>
        </div>
    );
}