import React, { useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { List, Avatar, Badge, Tooltip, Spin } from 'antd';
import { FileOutlined, LoadingOutlined, ReadOutlined, RestOutlined, ShopOutlined } from '@ant-design/icons';
import VirtualList from 'rc-virtual-list';

import DashboardCardTitle from '../portal/DashboardCardTitle';
import { TranslationNS } from '../../lib/TranslationHelper';
import { useInfiniteScrolling } from '../../lib/CustomHooks';
import { ApiRecord } from '../../@types/zcvp_api_enum';
import { RoutesUrls } from '../../@types/zcvp_routes_enum';
import '../../styles/zcvp_card.scss';
import '../../styles/zcvp_notifications.scss';
import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';

type NotificationListProps = {
    notifications: TableRecord[];
    onMarkAsRead: Function;
    onMarkAsArchived: Function;
    enableActions: boolean;
}

const getIcon = (recordType: string) => {
    return recordType === ApiRecord.Type.VENDOR ? <ShopOutlined /> : <FileOutlined />;
};

const NotificationList = ({ notifications, onMarkAsRead, onMarkAsArchived, enableActions = false }: NotificationListProps) => {
    const containerRef = useRef<HTMLElement>();
    const { t } = useTranslation(TranslationNS.USER, { keyPrefix: TranslationNS.NOTIFICATION });
    const { isLastResult, results, loadUntilVisibleScroll, getNext } = useInfiniteScrolling(notifications);

    const navigate = useNavigate();
    const onItemClick = useCallback((notification: TableRecord) => () => {
        const recordUrl = notification.recordType === ApiRecord.Type.VENDOR ? RoutesUrls.VENDORS : RoutesUrls.REQUESTS;
        onMarkAsRead([notification.id])().then(() => navigate(`${recordUrl}/${notification.recordId}`));
    }, [onMarkAsRead, navigate]);

    const onScroll = useCallback((event: { target: HTMLElement }) => {
        getNext(event.target);
    }, [getNext]);

    useEffect(() => {
        loadUntilVisibleScroll(containerRef.current!);
    }, [loadUntilVisibleScroll, containerRef]);

    return <List>
        <div ref={containerRef as React.RefObject<HTMLDivElement>}>
            <VirtualList
                height={'100%' as unknown as number}
                data={results}
                itemKey={'id'}
                onScroll={onScroll as unknown as React.UIEventHandler<HTMLElement>}
            >
                {(item, index) => (<>
                    <List.Item key={`notification-item-${item.id}`} onClick={onItemClick(item)}>
                        <List.Item.Meta
                            avatar={<Badge dot={!item.isRead}>
                                <Avatar
                                    size='small'
                                    icon={getIcon(item.recordType as string)}/>
                            </Badge>}
                            title={<DashboardCardTitle
                                title={<span className={item.isRead ? 'font-normal' : ''}>{item.title}</span>}
                                titleDetails={item.timeStamp} />}
                        />
                        {enableActions ? <div className='zcvp-notification-action flex self-start gap-2 opacity-0'>
                            {!item.isRead ? <Tooltip title={t('mark_read')}>
                                <ReadOutlined onClick={onMarkAsRead([item.id])} />
                            </Tooltip> : ''}
                            <Tooltip title={t('archive')}>
                                <RestOutlined onClick={onMarkAsArchived([item.id])} />
                            </Tooltip>
                        </div> : null}
                    </List.Item>

                    {!isLastResult && index === results.length - 1 ? <Spin 
                        className='pt-2 pb-2'
                        indicator={<LoadingOutlined
                        spin />} /> : null}
                    </>
                )}
            </VirtualList>
        </div>
    </List>;
};

export default NotificationList;