import { Tag } from 'antd';
import React, { useMemo } from 'react';

import { FieldQueryData, TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import { ListId } from '../../@types/list-details/zcvp_get_list_details_enum';
import { ApiRecord } from '../../@types/zcvp_api_enum';
import TableCard from '../../components/partials/TableCard';
import { useGetAllRolesQuery, useGetAllUsersQuery, useGetMetaDataQuery } from '../../slice/APISlice';
import { getActiveUsers } from '../../use-case/activeUsers/getActiveUsersUseCase';
import { GetListDetailsController } from '../../use-case/getListDetails/getListDetailsController';

type ActiveUsersTableProps = {
    filterValue: string;
    onRowClick: Function;
}

type RoleValue = {
    id: string;
    text: string;
}

const ActiveUsersTable: React.FC<ActiveUsersTableProps> = ({ filterValue, filters, setFilters, onRowClick }) => {
    const columnQueryData: FieldQueryData = useGetMetaDataQuery({});
    const recordQueryData = useGetAllUsersQuery({});
    const rawRoles = useGetAllRolesQuery({});

    const roles = useMemo(() => new GetListDetailsController({}, rawRoles).execute(ListId.ROLE, '', {}), [rawRoles]);
    const lists = useMemo(() => ({ roles: roles?.dataSource }), [roles]);
    const activeUsersData = useMemo(
        () => new GetListDetailsController(columnQueryData, recordQueryData, lists).execute(ListId.ACTIVE_USER, filterValue, filters)
        , [lists, columnQueryData, recordQueryData, filterValue, filters]);
    const activeUsersList = useMemo(() => getActiveUsers(activeUsersData.dataSource, roles.dataSource), [activeUsersData, roles]);

    const columns = activeUsersData.columns.map((column) => {
        if (column.dataIndex === ApiRecord.UserField.ROLES) {
            return {
                ...column,
                render: (roles: RoleValue[]) => {
                    return <>
                        {roles.map((role, index) => {
                            const className = role?.text === 'admin' ? 'zcvp-role-tag-primary' : 'zcvp-role-tag-user';
                            return <Tag className={className} key={`role-${index}`}>
                                {role.text.toUpperCase()}
                            </Tag>
                        })}
                    </>;

                }
            };
        }
        return column;
    });
    const actions = [{
        name: 'table-action1',
        key: 'table-action1',
        label: 'Action 1',
        show: (_: TableRecord) => false,
        onClick: () => alert('Action 1 is clicked')
    }, {
        name: 'table-action2',
        key: 'table-action2',
        label: 'Action 2',
        show: (_: TableRecord) => false,
        onClick: () => alert('Action 2 is clicked')
    }];

    return <TableCard
        dataSource={activeUsersList}
        columns={columns}
        actions={actions}
        onRow={(record: TableRecord) => ({
            onClick: () => onRowClick && onRowClick(record)
        })}
        onSelect={() => { }}
        setFilters={setFilters}/>;
};

export default ActiveUsersTable;