/**
 * imports required for the use case
 */
import { IVendorCompanyBankDetailsData, IVendorCompanyBankDetailsGateway, VendorBankAccountsData, VendorRecordQueryData } from '../../@types/vendor-company/zcvp_vendor_company';
import { VendorBankAccountType } from '../../@types/vendor-company/zcvp_vendor_company_enum';
import { BaseDataUseCase } from '../BaseDataUseCase';
import { VendorCompanyBankDetailsPresenter } from './vendorCompanyBankDetailsPresenter';

export class VendorCompanyBankDetailsUseCase extends BaseDataUseCase<IVendorCompanyBankDetailsGateway, VendorCompanyBankDetailsPresenter> {
    public execute(vendorCompany?: VendorRecordQueryData, requests): IVendorCompanyBankDetailsData {
        const response: IVendorCompanyBankDetailsData = {
            bankDetailsFields: [],
            primaryAccount: undefined,
            secondaryAccounts: [],
        };

        if (vendorCompany?.data) {
            const requestData = this.getRequestData(requests);
            response.bankDetailsFields = this.presenter.convertFields(this.presenter.bankDetailsFieldsLayout);
            const allBankAccounts: VendorBankAccountsData[] = [];
            if (vendorCompany.data.bankAccounts.length > 0) {
                this.gateway.bankAccounts.forEach((bankAccount: VendorBankAccountsData) => {
                    const requestBankAccount = requestData[bankAccount.id];
                    const updatedBankAccount = this.getBankAccount(bankAccount, requestBankAccount);
                    allBankAccounts.push(updatedBankAccount);
                });
            }
            response.primaryAccount = allBankAccounts.find((bankAccount: VendorBankAccountsData) => bankAccount.type === VendorBankAccountType.PRIMARY);
            response.secondaryAccounts = allBankAccounts.filter((bankAccount: VendorBankAccountsData) => bankAccount.type === VendorBankAccountType.SECONDARY);
        }

        return response;
    }

    public getFields() {
        return this.presenter.fieldDefinition;
    }

    protected getValues(fieldKeys: string[], data: VendorBankAccountsData): VendorBankAccountsData {
        const convertedData: VendorBankAccountsData = this.presenter.convertValues(data as VendorBankAccountsData, this.presenter.fieldDefinition) as unknown as VendorBankAccountsData;

        return fieldKeys.reduce((values, fieldKey) => {
            const fieldDefinition = this.presenter.getField(fieldKey);
            if (fieldDefinition) {
                values[fieldDefinition.name as keyof VendorBankAccountsData] = convertedData[fieldDefinition.name as keyof VendorBankAccountsData];
            } else {
                values[fieldKey as keyof VendorBankAccountsData] = convertedData[fieldKey as keyof VendorBankAccountsData];
            }

            return values;
        }, {} as VendorBankAccountsData);
    }
    private getRequestData(requests) {
        return requests?.filter(request => !!request.id).reduce((mergedRequestValues, request) => {
            const values = {}
            for(let key in request) {
                const requestValue = request[key];
                if(requestValue !== undefined) {
                    values[key] = requestValue;
                }
            }
            mergedRequestValues[request.id] = values;
            return mergedRequestValues;
        }, {}) || {};
    }
    private getBankAccount(bankAccount, requestBankAccount) {
        const bankDetails = this.getValues(this.gateway.bankDetailsFields, bankAccount);
        const requestBankDetails = this.getValues(this.gateway.bankDetailsFields, requestBankAccount);
        return this.mergeValues(bankDetails, requestBankDetails);
    }
    private mergeValues(oldValues, newValues) {
        if(!oldValues) {
            return null;
        }

        const mergedValues = { ...oldValues };
        for(let key in newValues) {
            mergedValues[key] = newValues[key] ?? oldValues[key];
        }
        return mergedValues;
    }

}