export namespace ApiPermission {
    export enum Target {
        COMPANY = 'Company',
        VENDOR = 'Vendor',
        VENDOR_CONTACT = 'VendorContact',
        USER = 'Usr',
        EMPLOYEE = 'Employee',
        BANK_ACCOUNT = 'BankAccount',
        INVITE_VENDOR = 'INVITE_VENDOR',
        INVITE_VENDOR_CONTACT = 'INVITE_VENDOR_CONTACT',
        INVITE_EMPLOYEE = 'INVITE_EMPLOYEE',
        APPROVE_TO_ONBOARD = 'APPROVE_TO_ONBOARD',
        APPROVE_AFTER_ONBOARD = 'APPROVE_AFTER_ONBOARD',
        REQUEST = 'Request',
        APPROVE_REQUEST = 'APPROVE_REQUEST',
        SUBMIT_REQUEST = 'REQUEST_SUBMIT'
    }

    export enum Action {
        READ = 'REC_READ',
        CREATE = 'REC_CREATE',
        EDIT = 'REC_EDIT',
        REMOVE = 'REC_REMOVE',
        WRITE = 'REC_WRITE',
        FULL = 'REC_FULL',
        GENERAL = 'GENERAL'
    }

    export type Permission = {
        action: Action;
        target: Target;
        selector: boolean;
    }
}

export namespace ApiRecord {
    export enum Type {
        EMPLOYEE = 'Employee',
        USER = 'Usr',
        VENDOR = 'Vendor',
        VENDOR_CONTACT = 'VendorContact',
        ROLE = 'AppRole',
        VENDOR_REQUEST = 'Request',
        ADDRESS = 'Address',
        BANK_ACCOUNT = 'BankAccount'
    }

    export enum SystemNoteField {
        TYPE = 'type',
        DATE = 'at',
        ORIGIN = 'origin',
        UPDATED_BY = 'updatedBy',
        UPDATED_BY_NAME = 'name'
    }

    export enum CommonField {
        STATUS = 'status'
    }

    export enum UserField {
        ID = 'id',
        FIRST_NAME = 'firstName',
        LAST_NAME = 'lastName',
        EMAIL = 'email',
        DATE_CREATED = 'createdAt',
        ROLES = 'roles',
        APP_NOTIFICATION = 'notificationInApp',
        EMAIL_NOTIFICATION = 'notificationByEmail',
        THEME = 'theme',
        FULL_NAME = 'fullName',
        CHANGE_PASSWORD = 'changePassword',
        GOOGLE_LOGIN = 'googleLogin',
        TUTORIAL_PROGRESS = 'tutorialProgress'
    }

    export enum PasswordChangeField {
        OLD_PASSWORD = 'oldPassword',
        NEW_PASSWORD = 'newPassword'
    }

    export type UserProfile = {
        companyId: number;
        companyName?: string;
        roleId?: number;
        roleName?: string;
        isVendor?: boolean;
        vendorId?: number;
        vendorName?: string;
        employeeId?: number;
        employeeName?: string;
        vendorContactId?: number;
        vendorContactName?: string;
    }

    export type UserLoginData = {
        token: string;
        userId: number;
    }

    export enum EmployeeField {
        FIRST_NAME = 'firstName',
        LAST_NAME = 'lastName',
        EMAIL = 'email',
        EXTERNAL_ID = 'externalId',
        REGISTERED = 'registered',
        INVITATION_PENDING = 'invitationPending'
    }

    export enum VendorField {
        ID = 'id',
        ACTIVE = 'active',
        /**
         * todo: confirm these address fields are correct.
         * we have test written for billing and shipping, but
         * I'm not seeing them in an API call to vendor/:id
         * Put them in anyways so the tests would work
         */
        ADDRESSES = 'addresses',
        BILLING_ADDRESS = 'billingAddress',
        SHIPPING_ADDRESS = 'shippingAddress',
        ALT_PHONE = 'altPhone',
        BANK_ACCOUNTS = 'bankAccounts',
        COMPANY_ID = 'companyId',
        COMPANY_NAME = 'companyName',
        CREATED_AT = 'createdAt',
        CREDIT_LIMIT = 'creditLimit',
        CURRENCY_ID = 'currencyId',
        EMAIL = 'email',
        EXTERNAL_ID = 'externalId',
        COMPANY_URL = 'url',
        PHONE = 'phone',
        STATUS = 'status',
        VENDOR_CATEGORY = 'vendorCategory',
        VENDOR_CATEGORY_ID = 'vendorCategoryId',
        SUBSIDIARY_ID = 'subsidiaryId',
        FIRST_NAME = 'firstName',
        LAST_NAME = 'lastName',
        LEGAL_NAME = 'legalName',
        NAME = 'name',
        PHONE_NUMBER = 'phone',
        SIZE = 'size',
        TAX_ID = 'taxId',
        TERM_ID = 'termId',
        UPDATED_AT = 'updatedAt',
        URL = 'url'
    }

    export type VendorFieldValue = `${VendorField}`
    export type VendorRecord = {[key in VendorFieldValue]: object|[]|boolean|string}

    export enum VendorContactField {
        ACTIVE = 'active',
        COMPANY_ID = 'companyId',
        CREATED_AT = 'createdAt',
        DATE_CREATED = 'createdAt',
        EMAIL = 'email',
        EXTERNAL_ID = 'externalId',
        FIRST_NAME = 'firstName',
        ID = 'id',
        INVITED_AT = 'invitedAt',
        IS_PRIMARY = 'isPrimary',
        LAST_NAME = 'lastName',
        NAME = 'name',
        PHONE = 'phone',
        REGISTERED_AT = 'registeredAt',
        ROLE = 'role',
        SALUTATION = 'salutation',
        SUBSIDIARY_ID = 'subsidiaryId',
        TITLE = 'title',
        UPDATED_AT = 'updatedAt',
        VENDOR = 'vendor',
        VENDOR_ID = 'vendorId',
    }

    export type VendorContactFieldValue = `${VendorContactField}`
    export type VendorContactRecord = {[key in VendorContactFieldValue]: object|[]|boolean|string}

    export enum CompanyField {
        ID = 'id',
        APPROVALS_TO_ONBOARD = 'approvalsToOnboard',
        APPROVALS_AFTER_ONBOARD = 'approvalsAfterOnboard',
        SENDER_EMAIL = 'senderEmail',
        SUPPORT_EMAIL = 'supportEmail'
    }

    export enum ApprovalField {
        IDS = 'ids',
        APPROVED = 'approved'
    }

    export enum AddressField {
        VENDOR_ID = 'vendorId',
        DEFAULT_BILLING = 'isDefaultBilling',
        DEFAULT_SHIPPING = 'isDefaultShipping',
        ADDRESS_LINE1 = 'address1',
        ADDRESS_LINE2 = 'address2',
        ADDRESS_LINE3 = 'address3',
        ADDRESSEE = 'addressee',
        ATTENTION = 'attention',
        CITY = 'city',
        STATE = 'state',
        ZIP_CODE = 'zipCode',
        COUNTRY = 'country'
    }

    export enum VendorRequestField {
        ID = 'id',
        TYPE = 'type',
        VENDOR_ID = 'vendorId',
        TITLE = 'title',
        DESCRIPTION = 'description',
        STATUS = 'status',
        RECORD_CHANGE_VALUES = 'recordChanges',
        RECORD_TYPE = 'model',
        RECORD_ACTION_TYPE = 'type',
        RECORD_VALUES = 'values'
    }

    export enum DocumentField {
        FILE = 'file',
        TYPE = 'type',
        VENDOR_ID = 'vendorId',
        RECORD_ID = 'recordId'
    }

    export enum NotificationField {
        ID = 'id',
        SUBJECT = 'subject',
        BODY = 'body',
        RECORD_ID = 'recordId',
        RECORD_TYPE = 'record',
        IS_READ = 'isRead',
        IS_ARCHIVED = 'isArchived',
        CREATED_AT = 'createdAt'
    }

    export type AppRolePermissionField = {
        id: number;
        text: string;
    }


    export type AppRole = {
        id: number;
        isVendor: boolean;
        perms: AppRolePermissionField[];
    }

}

export namespace ApiRequest {
    export enum FilterType {
        SET = 'set'
    }
    export type FilterValue = {
        values: string[],
        filterType: string;
    }

    export type FilterModel = {
        filterModel: {
            [key: string]: FilterValue;
        }
    }
}

export enum ApiVendorStatus {
    PENDING_INVITATION = 'PENDING_INVITATION',
    PENDING_ONBOARDING = 'PENDING_ONBOARDING',
    PENDING_APPROVAL_TO_ONBOARD = 'PENDING_APPROVAL_TO_ONBOARD',
    PENDING_APPROVAL_AFTER_ONBOARD = 'PENDING_APPROVAL_AFTER_ONBOARD',
    REJECTED_TO_ONBOARD = 'REJECTED_TO_ONBOARD',
    REJECTED_AFTER_ONBOARD = 'REJECTED_AFTER_ONBOARD',
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE'
}

export enum ApiFieldType {
    DATETIME = 'TIMESTAMP'
}

export enum ApiVendorRequestType {
    DOCUMENT = 'DOCUMENT',
    GENERAL = 'GENERAL',
    RECORD_CHANGE = 'RECORD_CHANGE'
}

export enum ResponseCode {
    UNAUTHORIZED = 401,
    FORBIDDEN = 403,
    NOT_FOUND = 404,
    BAD_REQUEST = 400,
    OK = 200,
}

export enum ApiRequestStatus {
    NEW = 'NEW',
    REVIEW = 'REVIEW',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    CANCELLED = 'CANCELLED'
}

export enum ApiRecordActionType {
    CREATE = 'c',
    UPDATE = 'u',
    DELETE = 'd'
}

export enum ApiSystemNoteOrigin {
    ZONE_PORTAL = 'Z',
    NETSUITE = 'N'
}

export enum ApiErrorCode {
    NOT_FOUND = 'NOT_FOUND'
}