import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import { NotificationMap, NotificationPresenter } from '../../@types/notifications/zcvp_notifications';

export enum NotificationType {
    INBOX = 'inbox',
    ARCHIVED = 'archived'
}

export class GetNotificationsUseCase {
    private presenter: NotificationPresenter|undefined;
    constructor(presenter?: NotificationPresenter) {
        this.presenter = presenter;
    }
    public execute(notifications: TableRecord[]): NotificationMap {
        const sortedNotifications = notifications.reduce((_sortedNotifications, currentNotification) => {
            const notifType = currentNotification.isArchived ? NotificationType.ARCHIVED : NotificationType.INBOX;
            _sortedNotifications[notifType].push(currentNotification);
            return _sortedNotifications;
        }, {
            [NotificationType.INBOX]: [] as TableRecord[],
            [NotificationType.ARCHIVED]: [] as TableRecord[]
        });
        return this.presenter!.convert(sortedNotifications);
    }
    public countUnread(notifications: TableRecord[]): number {
        return this.getUnreadNotifications(notifications).length;
    }
    public getUnreadNotificationIds(notifications: TableRecord[] ): number[] {
        return this.getNotificationIds(this.getUnreadNotifications(notifications));
    }
    public getNotificationIds(notifications: TableRecord[]): number[] {
        return notifications.map(notification => notification.id as unknown as number);
    }
    private getUnreadNotifications(notifications: TableRecord[] ): TableRecord[] {
        return notifications.filter(notification => !notification.isRead);
    }
}