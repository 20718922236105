import { Layout, Tabs } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import VerticalNavToggleTrigger from '../navigation/VerticalNavToggleTrigger';

import { TabItem } from '../../@types/zcvp_tabbed_page';
import '../../styles/zcvp_auth.scss';
import '../../styles/zcvp_tabbed_page.scss';
import { GetTabActionsController } from '../../use-case/getTabActions/getTabActionsController';

const { Header } = Layout;

export type ActionItem = {
    applyToTabs?: string[];
    element: React.ReactNode;
}

type TabbedPageLayoutProps = {
    title: string;
    items: TabItem[];
    actions: ActionItem[];
    activeKey?: string;
    onTabClick?: Function;
}
const TabbedContentLayout: React.FC<TabbedPageLayoutProps> = ({ title, items, actions, activeKey, onTabClick }: TabbedPageLayoutProps) => {

    // Get tab key from URL params
    const getUrlTabKey = useCallback(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const tabKey = urlSearchParams.get('tab');
        return tabKey || items[0].key;
    }, [items]);

    const [activeTabKey, setActiveTabKey] = useState<string | undefined>(getUrlTabKey());
    const tabActions = useMemo(() => new GetTabActionsController().execute(actions, activeTabKey!), [actions, activeTabKey]);
    const operationsSlot = {
        left: <VerticalNavToggleTrigger
            title={title}
        />,
        right: tabActions,
    };

    const tabClickHandler = useCallback((key: string) => {
        // add tab key to the URL params
        const urlSearchParams = new URLSearchParams(window.location.search);
        urlSearchParams.set('tab', key);

        // push state to the history
        window.history.pushState({}, '', `${window.location.pathname}?${urlSearchParams}`);

        // update state
        setActiveTabKey(key);
        onTabClick && onTabClick(key);
    }, [onTabClick]);

    // handle change of active tab from outside the component
    useEffect(() => {
        if (activeKey && activeKey !== activeTabKey) {
            tabClickHandler(activeKey!);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeKey]);

    useEffect(() => {
        // Function to handle URL change and update active tab
        const handleUrlChange = () => {
            const tabKey = getUrlTabKey();
            setActiveTabKey(tabKey || items[0].key);
        };

        // Add event listener for popstate and init the handler
        window.addEventListener('popstate', handleUrlChange);
        handleUrlChange();

        // Cleanup
        return () => {
            window.removeEventListener('popstate', handleUrlChange);
        };
    }, [items, getUrlTabKey]);

    return (
        <div className={'zcvp-tabbed-page'}>
            <Header>
                <Tabs
                    activeKey={activeTabKey}
                    tabBarExtraContent={operationsSlot}
                    items={items}
                    onTabClick={tabClickHandler} />
            </Header>
        </div>
    );
};

export default TabbedContentLayout;