import { Button, Form } from 'antd';
import React from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { FormFieldValues } from '../../@types/invite/zcvp_invite';
import PasswordField from '../../components/form/form-field/PasswordField';
import SingleColumnContent from '../../components/page/SingleColumnContent';
import { TranslationNS } from '../../lib/TranslationHelper';

export type ActivationFormProps = {
    passwordExists: boolean;
    submitForm: (values: FormFieldValues) => void;
}

const ActivationForm: React.FC<ActivationFormProps> = ({ passwordExists, submitForm }: ActivationFormProps) => {
    const { t } = useTranslation(TranslationNS.ONBOARDING)
    const [form] = Form.useForm();

    return <SingleColumnContent
        h2={t('welcome_heading')}
        tagline={passwordExists ? '' : t('welcome_tagline')}
    >
        <Form
            layout='vertical'
            form={form}
            requiredMark={false}
            onFinish={submitForm}
        >
            {!passwordExists ? <><PasswordField
                name={'password'}
                label={t('new_password')}
                required={true}
                hideHint={true}
            />
                <PasswordField
                    name={'confirm_password'}
                    label={t('confirm_new_password')}
                    required={true}
                    matchToField={{
                        name: 'password',
                        form: form
                    }}
                    hideHint={false}
                /></> : null}
            <Button size='large' type='primary' block htmlType={'submit'}>{t('complete_registration_button')}</Button>
            <p className={'text-center text-gray-400'}>
                    <Trans i18nKey="authentication:captcha_notice">
                        This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank" rel="noreferrer">Terms of Service</a> apply.
                    </Trans>
                </p>
            <p className={'text-center'}>
                <Trans i18nKey="login.privacy_notice">
                    By clicking "Complete registration" you agree with our <a href="https://www.zoneandco.com/legal/privacy" target="_blank" rel="noreferrer">Privacy Notice</a>
                </Trans>
            </p>
        </Form>
    </SingleColumnContent>;
};

export default ActivationForm;