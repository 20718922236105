import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Col, Row, Modal } from 'antd';
import { MessageInstance } from 'antd/es/message/interface';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import { VendorBankAccountsData, bankAccountSubmitFields, VendorRecordData } from '../../@types/vendor-company/zcvp_vendor_company';
import { ApiRecord, ApiRequestStatus, ApiVendorRequestType } from '../../@types/zcvp_api_enum';
import { disableSubmitTrigger } from '../../@types/zcvp_form';
import { ActionType } from '../../@types/zcvp_form_enum';
import GenericForm from '../../components/form/GenericForm';
import TabPageContent from '../../components/page/TabPageContent';
import CardComponent from '../../components/partials/CardComponent';
import { TranslationNS, getCommonT } from '../../lib/TranslationHelper';
import { useCancelRequestMutation, useCreateBankAccountMutation, useGetRequestsQuery, useGetVendorQuery, useSubmitRequestMutation, useUpdateBankAccountMutation } from '../../slice/APISlice';
import { VendorUpdateType } from '../../use-case/updateCompany/updateVendorCompanyUseCase';
import { GetVendorCompanyDataController } from '../../use-case/updateCompany/vendorCompanyController';
import { UpdateVendorCompanyDataController } from '../../use-case/updateCompany/updateVendorCompanyController';
const { confirm } = Modal;

export default function CompanyBankDetails() {
    const { t } = useTranslation(TranslationNS.VENDOR);
    const user = useSelector((state: any) => state.user);
    const companyQuery = useGetVendorQuery({ id: Number(user.vendorId) });
    const companyData = companyQuery?.data as VendorRecordData;
    const { messageApi } = useOutletContext<{ messageApi: MessageInstance }>();

    const requestFilter = {
        filterModel: {
            [ApiRecord.VendorRequestField.TYPE]: { values: [ApiVendorRequestType.RECORD_CHANGE], filterType: 'set' },
            [ApiRecord.VendorRequestField.STATUS]: { values: [ApiRequestStatus.NEW, ApiRequestStatus.REVIEW], filterType: 'set'}
        }
    };
    const requestsQuery = useGetRequestsQuery(requestFilter, { skip: !user?.vendorId});
    const [submitRequest] = useSubmitRequestMutation();
    const [cancelRequest] = useCancelRequestMutation();
    const [updateBankAccount] = useUpdateBankAccountMutation();
    const [createBankAccount] = useCreateBankAccountMutation();

    const vendorCompanyDataController = useMemo(() => new GetVendorCompanyDataController(), []);
    const updateVendorCompanyController = useMemo(() => new UpdateVendorCompanyDataController({
        createBankAccount, updateBankAccount, submitRequest, messageApi
    }), [createBankAccount, updateBankAccount, submitRequest, messageApi]);

    const allFields = useMemo(
        () => vendorCompanyDataController.executeGetBankFields()
    , [vendorCompanyDataController]);

    const {
        bankDetailsFields,
        primaryAccount,
        secondaryAccounts,
    } = useMemo(() => vendorCompanyDataController.executeBankDetails(companyQuery, requestsQuery), [vendorCompanyDataController, companyQuery, requestsQuery]);
    const [secondaryBankAccounts, setSecondaryBankAccounts] = useState<VendorBankAccountsData[]>(secondaryAccounts || []);

    const updateBankDetails = useCallback(({ isEdited, recordId, values }) => {
        const oldValues = primaryAccount?.id === recordId
            ? primaryAccount
            : secondaryAccounts?.find(secondaryAccount => secondaryAccount.id === recordId);

        updateVendorCompanyController.executeSaveBankDetail({
            updateType: VendorUpdateType.BANK_DETAILS,
            isEdited,
            recordId,
            vendorId: user?.vendorId,
            subsidiaryId: user?.subsidiaryId,
            oldValues,
            newValues: values,
            fields: allFields,
            primaryAccount
        });
    }, [
        allFields, primaryAccount, secondaryAccounts,
        updateVendorCompanyController, user?.vendorId, user?.subsidiaryId
    ]);

    const saveValues = useCallback((oldValues, values) => {
        const { newValues } = updateVendorCompanyController.getNewValues(oldValues || {}, values);
        updateVendorCompanyController.executeSaveVendorCompany({
            isEdited: true,
            recordId: oldValues?.id,
            values: newValues,
            icon: <ExclamationCircleOutlined />,
            onOk: updateBankDetails,
            onCancel: requestsQuery.refetch,
            confirm
        });
    }, [updateVendorCompanyController, updateBankDetails, requestsQuery.refetch]);

    const handleAddBankAccount = () => {
        const newBankAccount: VendorBankAccountsData = {
            accountName: '',
            accountNumber: '',
            bankNumber: '',
            branchNumber: '',
            subsidiaryId: null,
            type: 'SECONDARY',
            id: null,
            vendorId: companyData ? companyData.id.toString() : null,
        };
        setSecondaryBankAccounts([...secondaryBankAccounts, newBankAccount]);
    };

    const isDisabled = useCallback((bankAccountId: number): number => {
        const requestsData = vendorCompanyDataController
            .filterChangeRequests([ApiRecord.Type.BANK_ACCOUNT], requestsQuery)
            ?.map((request: TableRecord) => (request.recordChanges as unknown as TableRecord[])?.map(
                (recordChange: TableRecord) => recordChange.recordId) || []
            )
            .flat() || [];
    
        return requestsData.includes(bankAccountId);
    }, [vendorCompanyDataController, requestsQuery]);

    const getRequestToCancel = useCallback((bankAccountId: number): number => {
        return vendorCompanyDataController
            .filterChangeRequests([ApiRecord.Type.BANK_ACCOUNT], requestsQuery)
            ?.find((request: TableRecord) => (request.recordChanges as unknown as TableRecord[])?.some(
                (recordChange) => recordChange.recordId === bankAccountId
            ))?.requestId as unknown as number;
    }, [vendorCompanyDataController, requestsQuery]);
    
    const getCancelButton = useCallback((bankAccountId: number) => {
        const requestId = getRequestToCancel(bankAccountId);
        return vendorCompanyDataController.executeGetCancelButton({
            buttonKey: `cancel_bankdetails_${bankAccountId}`,
            icon: <DeleteOutlined />,
            canCancel: isDisabled(bankAccountId),
            recordId: requestId,
            cancelParams: {
                onSubmit: cancelRequest,
                messageApi
            }
        });
    }, [vendorCompanyDataController, getRequestToCancel, isDisabled, cancelRequest, messageApi]);

    useEffect(() => setSecondaryBankAccounts(secondaryAccounts), [secondaryAccounts]);

    return (
        <TabPageContent
            items={
                <>
                    <CardComponent title={t('primary_bank_account_label')}>
                        <Row>
                            <Col span={12}>
                                <GenericForm
                                    formProps={
                                        {
                                            disabled: isDisabled(primaryAccount?.id),
                                            onFinish: (values: bankAccountSubmitFields) => saveValues(primaryAccount, values),
                                        }
                                    }
                                    fieldValues={primaryAccount}
                                    fields={bankDetailsFields}
                                    id={'vendor_company_bank_details_form'}
                                    disableSubmitTrigger={disableSubmitTrigger.ON_ANY_FIELD_CHANGE}
                                    disableIfUnchanged={true}
                                    actions={[
                                        {
                                            type: ActionType.SUBMIT,
                                            definition: {
                                                label: getCommonT('save_changes'),
                                                key: 'save_vendor_company_bank_details',
                                                name: 'save_vendor_company_bank_details',
                                            }
                                        },
                                        ...getCancelButton(primaryAccount?.id as number)
                                    ]}
                                />
                            </Col>
                        </Row>
                    </CardComponent>
                    {secondaryBankAccounts && secondaryBankAccounts.map((value: VendorBankAccountsData, index) => {
                        return (
                            <CardComponent title={t('bank_account_with_order_label', { order: (index + 2) })} key={index}>
                                <Row>
                                    <Col span={12}>
                                        <GenericForm
                                            formProps={
                                                {
                                                    disabled: isDisabled(value.id),
                                                    onFinish: (values: bankAccountSubmitFields) => saveValues(value, values),
                                                }
                                            }
                                            fieldValues={value}
                                            fields={bankDetailsFields}
                                            id={'vendor_company_bank_details_form'}
                                            disableSubmitTrigger={disableSubmitTrigger.ON_ANY_FIELD_CHANGE}
                                            actions={[
                                                {
                                                    type: ActionType.SUBMIT,
                                                    definition: {
                                                        label: getCommonT('save_changes'),
                                                        key: 'save_vendor_company_bank_details',
                                                        name: 'save_vendor_company_bank_details',
                                                    }
                                                },
                                                ...getCancelButton(value?.id as number)
                                            ]}
                                        />
                                    </Col>
                                </Row>
                            </CardComponent>
                        );
                    })}
                    <CardComponent title={t('bank_account_with_order_label', { order: (secondaryBankAccounts ? secondaryBankAccounts.length + 2 : 2) })}>
                        <Button type='primary' onClick={handleAddBankAccount}>{t('add_bank_account_button')}</Button>
                    </CardComponent>
                </>
            }

        />
    );
}