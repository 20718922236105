import React from 'react';
import { Button, Layout, message, Skeleton } from 'antd';
import { PropsWithChildren, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, Outlet, useLocation } from 'react-router-dom';


import Loader from '../partials/Loader';
import VerticalNav from '../navigation/VerticalNav';
import { UserPortalTutorial } from '../../pages/portal/PortalTutorial';
import { LoaderState } from '../../slice/LoaderSlice';
import { CheckRolePermissionController } from '../../use-case/checkRolePermission/checkRolePermissionController';
import { RoutesUrls } from '../../@types/zcvp_routes_enum';
import { UserState } from '../../@types/zcvp_state';
import '../../styles/zcvp_auth.scss';
import '../../styles/zcvp_tabbed_page.scss';

const ErrorPage = () => <div className={'text-center'}>
    <img src={'/loading_cap.png'} alt={'Error!'} className={'mx-auto block zcvp-main-img'} />
    <h1>This was not supposed to happen</h1>
    <p>The page you are trying to access does not exist or you don’t have the necessary permissions.</p>
    <Button type='primary'><Link to={RoutesUrls.MAIN}>Go home</Link></Button>
</div>;

/**
 * Renders a two-column layout with a vertical navigation menu on the left and a main content area on the right
 * @constructor
 */
const TwoColumnsLayout = ({ children }: PropsWithChildren) => {
    const [messageApi, contextHolder] = message.useMessage();
    const path = useLocation();
    const user = useSelector((state: {user: UserState}) => state.user);
    const isLoading = useSelector((state: {loader: LoaderState }) => state.loader.isLoading);
    const isSwitchingProfile = useSelector((state: {loader: LoaderState }) => state.loader.isSwitchingProfile);
    const hasPermission = useMemo(() => (new CheckRolePermissionController(user)).execute(path.pathname), [user, path]);
    const SKELETON_PARAGRAPH_ROW_COUNT = 10;

    return (
        <div className={'flex flex-row items-stretch min-h-screen'}>
            <UserPortalTutorial />
            {contextHolder}
            <Loader />
            <VerticalNav />
            <Layout className={'flex flex-col justify-start '}>
                <Skeleton active avatar loading={isSwitchingProfile || (isLoading && !hasPermission)} paragraph={{ rows: SKELETON_PARAGRAPH_ROW_COUNT }} className={'zcvp-main-skeleton'}>
                    {hasPermission ? <div className={'zcvp-main-content basis-full '}>
                        <Outlet context={{ messageApi }} />
                        {children}
                    </div> : <ErrorPage />}
                </Skeleton>
            </Layout>
        </div>
    );
};

export default TwoColumnsLayout;