import { TabsProps } from 'antd';

export enum ZCVPDrawerContentTypes {
    ICONS= 'icons',
    TABS= 'tab',
}

export enum ZCVPThemeTypes {
    LIGHT= 'light',
    DARK= 'dark',
}

export type ZCVPAuthProps = {
    title: string,
    tabItems: TabsProps['items'],
    wrapperClass? : string
}

export type ZCVPCardTitlePopover = {
    title?: string;
    icon?: JSX.Element;
    trigger?: 'click'|'hover'
    content: string;
}

