import { CheckRolePermissionGateway } from '../checkRolePermission/checkRolePermissionGateway';
import { CheckRolePermissionUseCase } from '../checkRolePermission/checkRolePermissionUseCase';
import { GetNavigationItemsUseCase, NavigationItems } from './getNavigationItemsUseCase';

export class GetNavigationItemsController {
    execute(): NavigationItems {
        const rolePermissionGateway = new CheckRolePermissionGateway();
        const checkRolePermissionUseCase = new CheckRolePermissionUseCase(rolePermissionGateway);
        const getNavigationItemsUseCase = new GetNavigationItemsUseCase(rolePermissionGateway, checkRolePermissionUseCase);
        return getNavigationItemsUseCase.execute();
    }
}