import { App, ConfigProvider, ThemeConfig } from 'antd';
import type { MessageInstance } from 'antd/es/message/interface';
import type { ModalStaticFunctions } from 'antd/es/modal/confirm';
import type { NotificationInstance } from 'antd/es/notification/interface';
import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { ThemeValues } from '../@types/user-profile/zcvp_user_profile_enum';
import { StorageKey } from '../@types/zcvp_routes_enum';
import { useGetUserQuery } from '../slice/APISlice';
import '../styles/zcvp_app.css';
import '../styles/zcvp_css_variables_dark.scss';
import '../styles/zcvp_css_variables_light.scss';

let message: MessageInstance;
let notification: NotificationInstance;
let modal: Omit<ModalStaticFunctions, 'warn'>;

const Theme = ({ children }: PropsWithChildren) => {
    const staticFunction = App.useApp();
    message = staticFunction.message;
    modal = staticFunction.modal;
    notification = staticFunction.notification;

    const { data: {
        theme
    } = {
        theme: ThemeValues.LIGHT
    } } = useGetUserQuery({ userId: localStorage.getItem(StorageKey.USER_ID) }, { skip: !localStorage.getItem(StorageKey.USER_ID) });

    const [themeName, setThemeName] = useState('');
    const themeConfig = useMemo(() => {
        document.documentElement.classList.toggle('zcvp-theme-light', themeName === ThemeValues.LIGHT);
        document.documentElement.classList.toggle('zcvp-theme-dark', themeName === ThemeValues.DARK);

        const themeTokens = {
            token: {
                // Alias Token
                colorPrimary: '#ff6801',
                borderRadius: 0,
                colorBgBase: '#FFFFFF',
                colorTextBase: '#000000',
                fontFamily: 'Plus Jakarta Sans, \'Segoe UI\', \'Roboto\', \'Oxygen\', \'Ubuntu\', \'Cantarell\', \'Fira Sans\', \'Droid Sans\', \'Helvetica Neue\', sans-serif',
                // Map Tokens
                colorBorder: '#09214026',
                //Alert tokens
                colorInfo: '#1677ff',
                colorInfoBg: '#e6f4ff',
                colorInfoBorder: '#91caff',
            },
            components: {
                Divider: {},
                Layout: {
                    headerBg: '#fff4e6',
                    bodyBg: '#fff4e6',
                    footerBg: '#fff4e6',
                },
                Progress: {
                    colorBgBase: 'transparent',
                    colorInfo: '#ff6801',
                    colorSuccess: '#ff6801'
                },
                Select: {
                    multipleItemBg: '#ff6801',
                },
                Form: {
                    itemMarginBottom: 12,
                }
            }
        } as ThemeConfig;

        if (themeName === 'D') {
            themeTokens!.token!.colorBgBase = '#1F0C00';
            themeTokens!.token!.colorTextBase = '#FFFFFF';
            themeTokens!.token!.colorBorder = '#3a2412';
            themeTokens!.token!.colorBorderSecondary = '#3a2412';
            // set these or else it will be white on yellow in the dark theme
            themeTokens!.token!.colorWarning = '#ffffff';
            themeTokens!.token!.colorWarningBg = '#faad14';
            themeTokens!.token!.colorWarningBorder = '#faad14';
            themeTokens!.token!.colorInfo = '#e6f4ff';
            themeTokens!.token!.colorInfoBg = '#3569B2';

            themeTokens!.components!.Layout!.footerBg = '#1F0C00';
        }

        return themeTokens;

    }, [themeName]);
    //set theme on render
    useEffect(() => {
        const mediaQueryListener = (({ matches }: { matches: boolean }) => {
            const name = theme === 'browser' ? (matches ? ThemeValues.DARK : ThemeValues.LIGHT) : theme;
            setThemeName(name);
        });
        const mediaQueryDark = window.matchMedia('(prefers-color-scheme: dark)');
        mediaQueryDark.addEventListener('change', mediaQueryListener);

        return () => mediaQueryDark.addEventListener('change', mediaQueryListener);
    }, []);

    useEffect(() => {
        setThemeName(theme);
    }, [theme]);

    return (
        <ConfigProvider
            theme={themeConfig}
        >
            <App>
                {children}
            </App>
        </ConfigProvider>
    );
};

export default Theme;
export { message, modal, notification };
