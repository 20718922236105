import { PlusOutlined } from '@ant-design/icons';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, message, UploadFile } from 'antd';

import GenericForm from '../../components/form/GenericForm';
import GenericModal from '../../components/modals/GenericModal';
import { VENDOR_REQUEST_TYPE } from '../../lib/Constants';
import { useCreateRequestMutation, useGetAllVendorsQuery, useUploadDocumentMutation } from '../../slice/APISlice';
import { GetOptionsController } from '../../use-case/getOptions/getOptionsController';
import { SubmitRecordController } from '../../use-case/submitRecord/submitRecordController';
import { ActionType, FormFieldType } from '../../@types/zcvp_form_enum';
import { RecordType } from '../../@types/submit-record/zcvp_submit_record_enum';
import { ApiRecord, ApiRequest, ApiVendorRequestType, ApiVendorStatus } from '../../@types/zcvp_api_enum';
import { getCommonT, TranslationNS } from '../../lib/TranslationHelper';
import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import { ZCVPApiResponse } from '../../@types/zcvp_api';
import { useOutletContext } from 'react-router-dom';
import { MessageInstance } from 'antd/lib/message/interface';

const ACTIVE_VENDOR_FILTER = {
    [ApiRecord.VendorField.STATUS]: {
        values: [ApiVendorStatus.ACTIVE],
        filterType: ApiRequest.FilterType.SET
    }
};

const VendorRequestModal = ({ hideIcon = false, defaultValues = {} }: { hideIcon?: boolean, defaultValues?: TableRecord }) => {
    const [form] = Form.useForm();
    const { messageApi } = useOutletContext<{ messageApi: MessageInstance }>();
    const { t } = useTranslation(TranslationNS.VENDOR, {
        keyPrefix: `${TranslationNS.VENDOR_REQUEST}`
    });

    const [createRequest] = useCreateRequestMutation();
    const [uploadDocument] = useUploadDocumentMutation();
    const rawVendors = useGetAllVendorsQuery({
        filterModel: ACTIVE_VENDOR_FILTER
    });

    const [open, setIsOpen] = useState(false);
    const vendorOptions = useMemo(() => new GetOptionsController(rawVendors).execute(ApiRecord.VendorField.COMPANY_NAME), [rawVendors]);
    const onClose = useCallback(() => {
        form.resetFields();
        setIsOpen(false);
    }, [form]);

    const uploadFiles = useCallback((response: ZCVPApiResponse, values: TableRecord) => {
        const { data: { id: requestId } } = response;
        const { vendorId, attachments } = values;
        return (attachments as unknown as UploadFile[])?.map((file: UploadFile) => uploadDocument({
            [ApiRecord.DocumentField.FILE]: file.originFileObj,
            [ApiRecord.DocumentField.TYPE]: 'Request',
            [ApiRecord.DocumentField.VENDOR_ID]: vendorId,
            [ApiRecord.DocumentField.RECORD_ID]: requestId
        }));
    }, [uploadDocument]);

    const onSubmit = useCallback((values: TableRecord) => {
        const _values = { ...values };
        delete _values.attachments;
        new SubmitRecordController({
            onSubmit: createRequest,
            onClose,
            onSuccess: (response: ZCVPApiResponse) => uploadFiles(response, values),
            messageApi
        }).execute(RecordType.VENDOR_REQUEST, _values);
        onClose();
    }, [createRequest, onClose, messageApi, form]);

    return <GenericModal
        triggerButton={{
            content: t('new_vendor_request'),
            props: {
                type: 'default',
                icon: !hideIcon ? <PlusOutlined /> : null
            }
        }}
        title={t('new_vendor_request')}
        id={'new_vendor_request'}
        onOpenCallback={() => setIsOpen(true)}
        onCancelCallback={onClose}
        includeFooter={false}
        open={open}
    >
        <GenericForm
            formProps={{
                form,
                initialValues: {
                    [ApiRecord.VendorRequestField.TYPE]: ApiVendorRequestType.GENERAL,
                    ...defaultValues
                },
                onFinish: onSubmit,
                className: 'zcvp-request-modal'
            }}
            fields={[
                [{
                    name: ApiRecord.VendorRequestField.VENDOR_ID,
                    label: t('vendor'),
                    type: FormFieldType.SELECT,
                    options: vendorOptions,
                    required: true
                }, {
                    name: ApiRecord.VendorRequestField.TYPE,
                    label: t('type'),
                    type: FormFieldType.SELECT,
                    options: VENDOR_REQUEST_TYPE,
                    required: true
                }],
                [{
                    name: ApiRecord.VendorRequestField.TITLE,
                    label: t('title'),
                    type: FormFieldType.TEXT,
                    required: true
                }],
                [{
                    name: ApiRecord.VendorRequestField.DESCRIPTION,
                    label: t('description'),
                    type: FormFieldType.TEXTAREA
                }],
                [{
                    name: 'attachments',
                    label: t('attachment_label'),
                    type: FormFieldType.FILE
                }]
            ]}
            actions={[{
                type: ActionType.CANCEL,
                definition: {
                    label: getCommonT('cancel'),
                    key: 'cancel-btn',
                    name: 'cancel-btn',
                    onClick: () => {
                        setIsOpen(true);
                        onClose();
                    }
                }
            }, {
                type: ActionType.SUBMIT,
                definition: {
                    label: t('send_request'),
                    key: 'send-request-btn',
                    name: 'send-request-btn'
                }
            }]}
        />
    </GenericModal>;
}

export default VendorRequestModal;