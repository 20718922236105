import { ActionType } from "../../@types/zcvp_form_enum";
import { TranslationNS } from "../../lib/TranslationHelper";
import i18n from "../../i18n";
import { CancelActionProps, CompanyActionPresenterProps, GetCancelButtonProps } from "../../@types/vendor-company/zcvp_vendor_company";
import { TableRecord } from "../../@types/list-details/zcvp_get_list_details";

export class VendorCompanyActionPresenter {
    private icon;
    private onOk;
    private onCancel;
    private confirm;
    constructor({ icon, onOk, onCancel, confirm }: CompanyActionPresenterProps) {
        this.icon = icon;
        this.onOk = onOk;
        this.onCancel = onCancel;
        this.confirm = confirm;
    }
    public convertCancelButton({ canCancel, buttonKey, recordId, cancelParams, cancelCallback }: GetCancelButtonProps) {
        return canCancel ? [this.getCancelButtonProps({
            buttonKey, recordId, cancelParams, cancelCallback
        })] : [];
    }
    private getCancelButtonProps({ buttonKey, recordId, cancelParams, cancelCallback }) {
        return {
            type: ActionType.CANCEL,
            definition: {
                icon: this.icon,
                label: i18n.t(`${TranslationNS.VENDOR_REQUEST}.cancel_update_request`, { ns: TranslationNS.VENDOR }),
                key: buttonKey,
                name: buttonKey,
                disabled: false,
                danger: true,
                onClick: this.getCancelAction(recordId, cancelParams, cancelCallback)
            }
        }
    }
    public showConfirmation(isEdited: boolean, recordId: number, values: TableRecord) {
        const { icon, onCancel, confirm } = this;
        confirm({
            className: 'zcvp-vendor-save-confirmation confirm-modal',
            title: i18n.t('approval_required', { ns: TranslationNS.VENDOR }),
            content: i18n.t('approval_required_description', { ns: TranslationNS.VENDOR }),
            okText: i18n.t('submit_for_approval', { ns: TranslationNS.VENDOR }),
            cancelText: i18n.t('discard_changes', { ns: TranslationNS.VENDOR }),
            onOk: this.getOkAction(isEdited, recordId, values),
            icon,
            onCancel
        });
    }
    private getOkAction(isEdited: boolean, recordId: number, values: TableRecord) {
        return () => this.onOk({ isEdited, recordId, values });
    }
    private getCancelAction(recordId: number, cancelParams: CancelActionProps, cancelCallback: Function) {
        return () => cancelCallback(recordId, cancelParams);
    }
}