import { Typography } from 'antd';
import React, { PropsWithChildren } from 'react';

import '../../styles/zcvp_no_auth.scss';


type ZCVPSingleColumnContentProps = {
    h2: string,
    tagline?: string
    logo?: string;
}

const SingleColumnContent = ({ h2 = '', tagline = '', logo = '', children }: PropsWithChildren<ZCVPSingleColumnContentProps>) => {
    const brand = 'zone-capture'; //todo update with brand from backend
    const imgClassName = logo ? '' : 'zcvp-no-auth-logo';

    return (
        <>
            <img src={logo || `/logo-${brand}.png`} alt={'logo'} className={`mx-auto block ${imgClassName}`} />
            <div className={'zcvp-no-auth-header-text'}>
                <Typography.Title level={2} className={'text-primary'}>
                    {h2}
                </Typography.Title>
                {tagline && (
                    <p className={'text-primary mx-auto text-center'}>{tagline}</p>
                )}
            </div>
            <div className={'grow'}>
                {children}
            </div>
        </>
    );
};

export default SingleColumnContent;