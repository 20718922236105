import React from 'react';
import { Form, Input } from 'antd';
import { TextAreaFieldProps } from '../../../@types/zcvp_form';

/**
 * Creates a textarea field with name, label, default value, disabled, required, onChange properties
 *
 * @param {TextAreaFieldProps} props textarea field properties
 * @returns {React.FC<TextAreaFieldProps>} a textarea field inside a form item
 */
const TextAreaField: React.FC<TextAreaFieldProps> = (props: TextAreaFieldProps) => <Form.Item
    name={props.name}
    label={props.label}
    rules={[{
        required: props.required
    }]}
>
    <Input.TextArea
        placeholder={props.placeholder}
        autoSize={true}
        defaultValue={props.defaultValue}
        disabled={props.disabled}
        onChange={props.onChange}
        autoSize={{minRows: 3}} />
</Form.Item>;

export default TextAreaField;