import { CheckOutlined, CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { MessageInstance } from 'antd/lib/message/interface';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';

import { TableRecord, TableRowValue } from '../../@types/list-details/zcvp_get_list_details';
import { Feature, RecordType } from '../../@types/submit-record/zcvp_submit_record_enum';
import { UserState } from '../../@types/zcvp_state';
import { TranslationNS, getCommonT } from '../../lib/TranslationHelper';
import { useApproveRequestMutation, useCancelRequestMutation, useGetRequestQuery, useRejectRequestMutation, useSubmitRequestMutation } from '../../slice/APISlice';
import { SubmitRecordController } from '../../use-case/submitRecord/submitRecordController';
import { GetVendorRequestActionController } from '../../use-case/vendorRequests/getVendorRequestActionController';
import { VendorRequestAction } from '../../use-case/vendorRequests/getVendorRequestActionUseCase';
import '../../styles/zcvp_vendor_details.scss';

type CustomMouseEventParam = React.MouseEvent<HTMLElement, MouseEvent>;
type CustomMouseEvent = (event: CustomMouseEventParam) => void;

const VendorRequestDetailFooter = ({ record, onClick }: { record: TableRecord, onClick: CustomMouseEvent }) => {
    const { data: request } = useGetRequestQuery({ requestId: record?.id }, { skip: !record?.id });
    const { messageApi } = useOutletContext<{ messageApi: MessageInstance }>();
    const { t } = useTranslation(TranslationNS.VENDOR, { keyPrefix: TranslationNS.VENDOR_REQUEST });
    const user = useSelector((state: { user: UserState }) => state.user);
    const vendorRequestAction = useMemo(() => new GetVendorRequestActionController().execute(request!, user), [request]);

    const [submitRequest] = useSubmitRequestMutation();
    const [approveRequest] = useApproveRequestMutation();
    const [rejectRequest] = useRejectRequestMutation();
    const [cancelRequest] = useCancelRequestMutation();

    const getOnClick = useCallback((recordType: RecordType | Feature, submitAction: Function, onSuccess?: Function) => (event: CustomMouseEventParam) => {
        new SubmitRecordController({
            onSubmit: submitAction, onSuccess, messageApi
        }).execute(recordType, { id: record?.id as unknown as TableRowValue });
        onClick(event);
    }, [record, messageApi, submitRequest, onClick]);

    switch (vendorRequestAction) {
        case VendorRequestAction.CANCEL:
            return <Button type='default' onClick={getOnClick(Feature.VENDOR_REQUEST_CANCELLATION, cancelRequest)}  icon={<DeleteOutlined />} danger>{t('cancel_request')}</Button>;
        case VendorRequestAction.SUBMIT:
            return <Button type='primary' onClick={getOnClick(RecordType.VENDOR_REQUEST, submitRequest)}>{t('submit_request')}</Button>;
        case VendorRequestAction.REVIEW:
            return <>
                <Button className='mx-1' icon={<CloseOutlined />} onClick={getOnClick(Feature.VENDOR_REQUEST_REJECTION, rejectRequest)} danger>{getCommonT('reject')}</Button>
                <Button className='mx-1' icon={<CheckOutlined />} onClick={getOnClick(Feature.VENDOR_REQUEST_APPROVAL, approveRequest)} type='primary'>{getCommonT('approve')}</Button>
            </>
    }
};

export default VendorRequestDetailFooter