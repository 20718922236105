import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Tooltip, message } from 'antd';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ZCVPApiResponse } from '../../@types/zcvp_api';
import { UserState } from '../../@types/zcvp_state';
import { logout } from '../../lib/AuthenticationHelper';
import { showErrorMessage } from '../../lib/CustomHooks';
import { TranslationNS, getCommonT } from '../../lib/TranslationHelper';
import { useRemoveUserMutation } from '../../slice/APISlice';
const { confirm } = Modal;
        

const CloseAccountModal = ({ userId, disabled }: { userId: number, disabled: boolean }) => {
    const { t } = useTranslation(TranslationNS.USER);
    const [messageApi, contextHolder] = message.useMessage();
    const [removeUser] = useRemoveUserMutation();
    const loggedInUserId = useSelector((state: { user: UserState }) => state.user.userId);
    const isOwnAccount = useMemo(() => loggedInUserId === userId, [userId, loggedInUserId]);

    const closeAccount = useCallback(() => {
        removeUser({ id: userId }).then(({ error }: ZCVPApiResponse) => {
            if (error) {
                showErrorMessage(error.message, messageApi);
            } else if (isOwnAccount) {
                logout();
            }
        });
    }, [userId, isOwnAccount, messageApi, removeUser]);

    const showConfirm = useCallback(() => confirm({
        title: t('user_management.close_account_modal_title'),
        content: t('user_management.close_account_modal_content'),
        okText: isOwnAccount ? t('user_management.remove_and_logout_button') : getCommonT('remove'),
        icon: <ExclamationCircleOutlined />,
        className: 'confirm-modal',
        onOk: closeAccount
    }), [t, isOwnAccount, closeAccount]);

    const tooltip = useMemo(() => disabled ? 'This user cannot be removed' : '', [disabled]);

    return <Tooltip title={tooltip}>
        <Button name='remove-access-btn' onClick={showConfirm} icon={<DeleteOutlined />} disabled={disabled} danger>
            {t('user_management.remove_access_button')}
            {contextHolder}
        </Button>
    </Tooltip>;
};

export default CloseAccountModal;