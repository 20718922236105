import React from 'react';
import { InputNumber, Form } from 'antd';
import { NumberFieldProps } from '../../../@types/zcvp_form';

/**
 * Creates a number field with name, label, default value, disabled, required, onChange properties
 *
 * @param {NumberFieldProps} props number field properties
 * @returns {React.FC<NumberFieldProps>} a number field inside a form item
 */
const NumberField: React.FC<NumberFieldProps> = (props: NumberFieldProps) => <Form.Item
    name={props.name}
    label={props.label}
    rules={[{
        required: props.required
    }]}
>
    <InputNumber
        placeholder={props.placeholder}
        defaultValue={props.defaultValue}
        disabled={props.disabled}
        onChange={props.onChange}/>
</Form.Item>;

export default NumberField;