import { Form } from 'antd';
import React, { useEffect, useState } from 'react';
import GooglePlacesAutocomplete, { geocodeByAddress } from 'react-google-places-autocomplete';
import { useTranslation } from 'react-i18next';
import { AddressValues } from '../../../@types/get-address/zcvp_get_address';
import { AddressFieldProps } from '../../../@types/zcvp_form';
import { createAddressString } from '../../../lib/Utils';
import '../../../styles/zcvp_address_field.scss';

type AddressFieldOption = {
    label: string;
    value: google.maps.places.AutocompletePrediction;
    defaultValue?: AddressValues;
};

const findAddressComponentType = (
    type: string,
    r: google.maps.GeocoderAddressComponent[] | undefined,
    output: 'long_name' | 'short_name'
): string|undefined => {
    let out: string = null;
    if (r) {
        r.forEach((com: google.maps.GeocoderAddressComponent) => {
            com.types.forEach((t: string) => {
                if (t === type) {
                    out = com[output];
                }
            });
        });
    }

    return out;
};

/**
 * Converts a Google Maps PlaceResult to a AddressValues object.
 *
 * @param {google.maps.places.PlaceResult} googleAddress - The Google Maps PlaceResult to be converted.
 * @returns {AddressValues} - The formatted address object.
 */
const convertAddressForApi = (googleAddress: google.maps.places.PlaceResult): AddressValues => {
    let address1: string = '';
    let address2: string = '';
    const subpremise: string = findAddressComponentType('subpremise', googleAddress.address_components, 'long_name');
    const streetNumber: string = findAddressComponentType('street_number', googleAddress.address_components, 'long_name');
    const route: string = findAddressComponentType('route', googleAddress.address_components, 'long_name');
    const locality: string = findAddressComponentType('locality', googleAddress.address_components, 'long_name');
    const postal_town: string = findAddressComponentType('postal_town', googleAddress.address_components, 'long_name');
    const premise: string = findAddressComponentType('premise', googleAddress.address_components, 'long_name');

    if (subpremise) {
        address1 += subpremise;
        if (streetNumber) {
            address2 += streetNumber + ' ';
        }

        if (premise) {
            address2 += premise + ' ';
        }

        if (route) {
            address2 += route;
        }
    } else {
        if (streetNumber) {
            address1 += streetNumber + ' ';
        }

        if (premise) {
            address1 += premise + ' ';
        }

        if (route) {
            address1 += route;
        }
    }

    const address: AddressValues = {
        address1: address1 || null,
        address2: address2 !== '' ? address2 : null,
        city: postal_town || locality || null,
        zipCode: findAddressComponentType('postal_code', googleAddress.address_components, 'long_name'),
        state: findAddressComponentType('administrative_area_level_1', googleAddress.address_components, 'short_name'),
        country: findAddressComponentType('country', googleAddress.address_components, 'short_name'),
    };

    return address;
};

const AddressField: React.FC<AddressFieldProps> = (props: AddressFieldProps) => {
    const { t } = useTranslation();
    const [value, setValue] = useState<AddressFieldOption | null>(null);
    const [label, setLabel] = useState<string | undefined>();

    useEffect(() => {
        const valueLabel = value?.label;
        if (valueLabel) {
            setValue(null);
            setLabel(t('common:loading'));
            geocodeByAddress(value.label)
                .then(results => {
                    // this is the result that we need to send to the backend
                    const formattedAddress = convertAddressForApi(results[0]);
                    formattedAddress.id = props.defaultValue?.id;
                    setLabel(valueLabel);
                    props.form?.setFieldValue(props.name, formattedAddress);

                    // we have to manually trigger the onFieldsChange event
                    props.form?.validateFields([props.name]);
                })
                .catch(error => console.error(error));
        }
    }, [value?.label, props.form, props.defaultValue, props.name]);

    // set the initial value
    useEffect(() => {
        if (props.defaultValue) {
            setLabel(createAddressString(props.defaultValue));
        }
    }, [props.defaultValue]);

    return (
        <>

            <Form.Item
                name={props.name}
                label={props.label}
                className={'zcvp-address-field'}
                rules={[{
                    required: props.required
                }]}
            >
                <GooglePlacesAutocomplete
                    apiKey={process.env.REACT_APP_GOOGLE_API_KEY || ''}
                    selectProps={{
                        value: label ? { label, value: '' } : null,
                        isDisabled: props.disabled,
                        className: 'zcvp-select-field',
                        placeholder: 'Start typing the address...',
                        noOptionsMessage: () => 'Start typing to search for an address',
                        onChange: (event) => setValue(event),
                        styles: {
                            input: (provided) => ({
                                ...provided,
                                color: 'var(--colorTextBase)',
                            }),
                            singleValue: (provided) => ({
                                ...provided,
                                color: 'var(--colorTextBase)',
                            }),
                            menu: (provided) => ({
                                ...provided,
                                color: 'var(--colorPrimaryDark)',
                            }),
                        }
                    }}
                />
            </Form.Item >
        </>
    );
};

export default AddressField;