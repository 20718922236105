import { VendorOnboardingActionGateway } from '../../@types/onboarding/zcvp_onboarding';

export enum VendorOnboardingAction {
    INVITE = 'invite',
    APPROVALS_TO_ONBOARD = 'approvalsToOnboard',
    APPROVALS_AFTER_ONBOARD = 'approvalsAfterOnboard',
    CREATE_REQUEST = 'createRequest'
}

export type VendorOnboardingActionParams = {
    canApproveToOnboard: boolean;
    canApproveAfterOnboard: boolean;
    canInviteVendor: boolean;
    canCreateRequests: boolean;
}

export class GetVendorOnboardingActionUseCase {
    private gateway: VendorOnboardingActionGateway;
    constructor(gateway: VendorOnboardingActionGateway) {
        this.gateway = gateway;
    }
    public execute({ canApproveToOnboard, canApproveAfterOnboard, canInviteVendor, canCreateRequests }: VendorOnboardingActionParams): VendorOnboardingAction|null {
        if(this.gateway.isPendingApprovalToOnboard() && canApproveToOnboard) {
            return VendorOnboardingAction.APPROVALS_TO_ONBOARD;
        } else if(this.gateway.isPendingApprovalAfterOnboard() && canApproveAfterOnboard) {
            return VendorOnboardingAction.APPROVALS_AFTER_ONBOARD;
        } else if(this.gateway.isPendingInvitation() && canInviteVendor) {
            return VendorOnboardingAction.INVITE;
        } else if(this.gateway.isActive() && canCreateRequests) {
            return VendorOnboardingAction.CREATE_REQUEST;
        }
        return null;
    }
    public hasValidActionStatus() {
        return this.gateway.isPendingApprovalToOnboard() || this.gateway.isPendingApprovalAfterOnboard() || this.gateway.isPendingInvitation();
    }
}