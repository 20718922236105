import { MessageInstance } from 'antd/lib/message/interface';
import { FormFieldValues } from '../../@types/invite/zcvp_invite';
import { InvitationType } from '../../@types/invite/zcvp_invite_enum';
import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import { Field } from '../../@types/onboarding/zcvp_onboarding';
import { FieldMapValue } from '../../@types/submit-record/zcvp_submit_record';
import { ConfirmInvitationPresenter } from './confirmInvitationPresenter';
import { ConfirmInvitationUseCase } from './confirmInvitationUseCase';

export class ConfirmInvitationController {
    private navigate: Function;
    private messageApi: MessageInstance;
    private confirmInvitation: Function;
    constructor(navigate: Function, messageApi: MessageInstance, confirmInvitation: Function) {
        this.navigate = navigate;
        this.messageApi = messageApi;
        this.confirmInvitation = confirmInvitation;
    }
    public execute(invitationType: string, values: FormFieldValues): void {
        const dataValues = this.getValues(invitationType, values);
        const gateway = { confirmInvitation: this.confirmInvitation };
        const presenter = new ConfirmInvitationPresenter(this.navigate, this.messageApi);
        const useCase = new ConfirmInvitationUseCase(gateway, presenter);
        useCase.execute(invitationType, dataValues);
    }
    private getValues(invitationType: string, values: FormFieldValues): TableRecord {
        const fieldMap = this.getFieldMap(invitationType);

        const dataValues: TableRecord = {};
        for (const fieldId in fieldMap) {
            dataValues[fieldId] = values[fieldMap[fieldId]];
        }
        
        return dataValues;
    }
    private getFieldMap(invitationType: string): FieldMapValue {
        switch (invitationType) {
        case InvitationType.VENDOR:
            return {
                firstName: Field.FIRST_NAME,
                lastName: Field.LAST_NAME,
                randomKey: Field.RANDOM_KEY,
                companyName: Field.COMPANY_NAME,
                ownerContactName: Field.FULL_NAME,
                ownerContactEmail: Field.EMAIL,
                size: Field.COMPANY_SIZE,
                phone: Field.PHONE,
                url: Field.COMPANY_URL,
                captchaToken: Field.CAPTCHA_TOKEN,
            };
        default:
            return {
                randomKey: Field.RANDOM_KEY,
                password: Field.PASSWORD,
                captchaToken: Field.CAPTCHA_TOKEN,
            };
        }
    }
}