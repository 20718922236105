import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Layout } from 'antd';
import VerticalNavStatic from './VerticalNavStatic';
import VerticalNavToggleContent from './VerticalNavToggleContent';
import { fullNavWidth, staticNavWidth } from './NavigationConfig';
import { selectIsSubPortalPage, updateLayoutThemeState } from '../../slice/LayoutThemeSlice';
import { useSelectUserRole } from '../../lib/CustomHooks';
import { GetNavigationItemsController } from '../../use-case/navigationItems/getNavigationItemsController';
import { AppState, LayoutThemeState } from '../../@types/zcvp_state';

const { Sider } = Layout;

/**
 * Returns a menu that shows/hides as either a drawer or a popover, depending on the type of page
 * Note: they are both really drawers, just styled differently
 */
const VerticalNav = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const isPortalPage = useSelector((state: AppState) => selectIsSubPortalPage(state, { pathname: location.pathname }));
    const menuIsOpen = useSelector((state: AppState) => state.layoutTheme.menuIsOpen);
    const menuTrigger = useSelector((state: AppState) => state.layoutTheme.menuTrigger);
    const userRole = useSelectUserRole();
    useEffect(() => {
        if (!userRole) { return; }
        const {pages, subPortal} = (new GetNavigationItemsController()).execute();
        dispatch(updateLayoutThemeState({
            verticalIconMenu: pages,
            verticalSubPageMenu: subPortal,
        }));
    }, [dispatch, userRole]);

    useEffect(() => {
        if (isPortalPage) { return; }
        dispatch(updateLayoutThemeState({
            menuTrigger: 'click'
        }));
    }, [dispatch, isPortalPage, location.pathname]);

    const dispatchUpdateLayoutThemeState = (payload: LayoutThemeState) => {
        dispatch(updateLayoutThemeState(payload));
    };

    return (
        <Sider
            collapsed={!menuIsOpen}
            collapsedWidth={staticNavWidth}
            collapsible={true}
            /* If we're hovering, don't change the width of the menu */
            width={menuTrigger === 'hover' ? staticNavWidth : fullNavWidth}
            className={`zcvp-vertical-nav-container ${menuTrigger === 'hover' ? 'zcvp-nav-menu-mod-floating' : ''} ${!menuIsOpen ? 'zcvp-nav-menu-mod-collapsed' : ''}`}
            trigger={null}
            data-testid={'zcvp-nav-drawer'}
            onMouseOver={() => {
                if (menuTrigger === 'hover') {
                    dispatchUpdateLayoutThemeState({
                        menuIsOpen: true
                    });
                }
            }}
            onMouseLeave={() => {
                if (menuTrigger === 'hover') {
                    dispatchUpdateLayoutThemeState({
                        menuIsOpen: false
                    });
                }
            }}
        >
            <VerticalNavStatic />
            <VerticalNavToggleContent />
        </Sider>

    );
};


export default VerticalNav;