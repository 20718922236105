import { Form, Input } from 'antd';
import { Rule } from 'antd/es/form';
import React from 'react';
import { EmailFieldProps } from '../../../@types/zcvp_form';
import { validateEmail } from '../../../use-case/validateEmail/validateEmailUseCase';

export const multipleEmailRule: Rule = {
    validator: (_, allEmail) => validateEmail(allEmail),
    message: 'The input e-mail list is invalid'
};

export const singleEmailRule: Rule = {
    type: 'email',
    message: 'Please enter a valid Email Address'
};
/**
 * Creates an email field with name, label, default value, disabled, required, onChange properties
 * The component validates that the email is correct. An error message is displayed when the e-mail address is invalid
 *     (i.e. not ending in @<domain>)
 *
 * @param {EmailFieldProps} props email field properties
 * @returns {React.FC<EmailFieldProps>} a email field inside a form item
 */
const EmailField: React.FC<EmailFieldProps> = (props: EmailFieldProps) => {
    const emailValidator = props.isMultiple ? multipleEmailRule : singleEmailRule;
    const InputField = props.isMultiple ? Input.TextArea : Input;
    
    return <Form.Item
        name={props.name}
        label={props.label}
        extra={props.extra}
        className={props.className}
        normalize={(value) => value.trim()}
        rules={[{
            required: props.required,
            message: props.requiredMessage
        }, emailValidator]}>
        <InputField
            placeholder={props.placeholder}
            disabled={props.disabled}
            onChange={props.onChange}
        />
    </Form.Item>;
};

export default EmailField;