
import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import { VendorRequestsGateway, VendorRequestsPresenter } from '../../@types/vendor-requests/zcvp_vendor_requests';
import { ApiRecord, ApiRequestStatus } from '../../@types/zcvp_api_enum';
import { getVendorName } from '../getVendors/getVendorsUseCase';

export class GetVendorRequestsUseCase {
    private gateway: VendorRequestsGateway;
    private presenter: VendorRequestsPresenter;
    constructor(gateway: VendorRequestsGateway, presenter: VendorRequestsPresenter) {
        this.gateway = gateway;
        this.presenter = presenter;
    }
    public getRequests(requests: TableRecord[] = [], isVendor: boolean): TableRecord[] {
        return requests.map(request => this.getRequest(request, isVendor));
    }
    public getRequest(request: TableRecord, isVendor: boolean): TableRecord {
        const vendorsList = this.gateway.getVendorsList();
        const updatedRequest = this.addVendorName(request, vendorsList);
        return this.presenter.convertRequest(updatedRequest, isVendor);
    }
    public filterRequestsByStatus(requests: TableRecord[]): TableRecord[] {
        const validStatuses = [
            ApiRequestStatus.NEW,
            ApiRequestStatus.REVIEW,
            ApiRequestStatus.APPROVED
        ];
        return requests.filter(request => validStatuses.includes(request[ApiRecord.VendorRequestField.STATUS] as ApiRequestStatus));
    }
    private addVendorName(request: TableRecord, vendorsList: TableRecord[]) {
        const vendor = request ? vendorsList.find(vendor => vendor.id === request.vendorId) : null;
        const vendorName = vendor ? getVendorName(vendor) : undefined;
        return { ...request, vendorName };
    }
}