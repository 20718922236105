import React from 'react';
import { Descriptions, List, Popover } from 'antd';

import '../../styles/zcvp_vendor_details.scss';
import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';

const COLUMN_COUNT = 1;
const HistoryDetail = ({ record }: { record: TableRecord }) => <Descriptions
    items={record.details} column={COLUMN_COUNT} className='zcvp-history-details'
/>

const HistoryDetails = ({ records }: { records: TableRecord[] }) => <List
    dataSource={records}
    renderItem={(item: TableRecord) => 
    <Popover
        overlayClassName='zcvp-history-popover'
        placement='left'
        title='hello'
        content={<HistoryDetail record={item} />}>
        <List.Item
            className={'zcvp-history-item'}
            extra={[item.timeStamp]}
        >
            <List.Item.Meta
                title={item.title}
            />
        </List.Item>
    </Popover>
}/>;

export default HistoryDetails;