import { AdditionalFieldMap, DisplayedFieldMap, FieldQueryData, FilterableFieldMap, ListDetailField, ListDetailRecord, ListDetailsGateway, ListMap, MetaDataField, MetaDataMap, RecordQueryData, SearchableFieldMap } from '../../@types/list-details/zcvp_get_list_details';
import { ListId } from '../../@types/list-details/zcvp_get_list_details_enum';
import { AdditionalField } from '../../@types/role-permissions/zcvp_role_permissions_enum';
import { ApiRecord } from '../../@types/zcvp_api_enum';
import i18n from '../../i18n';
import { TranslationNS, getCommonT } from '../../lib/TranslationHelper';
const { UserField, EmployeeField, VendorField, VendorContactField, VendorRequestField } = ApiRecord;

/**
 * Converts backend data to format expected by the business logic
 */
export class GetListDetailsGateway implements ListDetailsGateway {
    private fieldQueryData: FieldQueryData;
    private recordQueryData: RecordQueryData;
    private lists;
    private DisplayedFields: DisplayedFieldMap = {
        [ListId.ACTIVE_USER]: [UserField.FIRST_NAME, UserField.LAST_NAME, UserField.EMAIL, UserField.DATE_CREATED, UserField.ROLES],
        [ListId.NETSUITE_USER]: [EmployeeField.FIRST_NAME, EmployeeField.LAST_NAME, EmployeeField.EMAIL, EmployeeField.EXTERNAL_ID, AdditionalField.STATUS],
        [ListId.VENDOR]: [AdditionalField.DISPLAY_NAME, VendorField.EMAIL, VendorField.VENDOR_CATEGORY, VendorField.STATUS],
        [ListId.VENDOR_CONTACT]: [VendorContactField.FIRST_NAME, VendorContactField.LAST_NAME, VendorContactField.EMAIL, VendorContactField.DATE_CREATED, VendorContactField.ROLE],
        [ListId.ROLE]: [],
        [ListId.VENDOR_CATEGORY]: [],
        [ListId.VENDOR_REQUEST]: [VendorRequestField.TITLE, VendorRequestField.TYPE, AdditionalField.REQUESTED_ON, VendorRequestField.STATUS],
        [ListId.REQUEST]: [VendorRequestField.TITLE, VendorRequestField.VENDOR_ID, AdditionalField.REQUESTED_ON, VendorRequestField.STATUS]
    };
    private MetaDataEndpoint: MetaDataMap = {
        [ListId.NETSUITE_USER]: ApiRecord.Type.EMPLOYEE,
        [ListId.ACTIVE_USER]: ApiRecord.Type.USER,
        [ListId.VENDOR]: ApiRecord.Type.VENDOR,
        [ListId.VENDOR_CONTACT]: ApiRecord.Type.VENDOR_CONTACT,
        [ListId.ROLE]: ApiRecord.Type.ROLE,
        [ListId.VENDOR_REQUEST]: ApiRecord.Type.VENDOR_REQUEST,
        [ListId.REQUEST]: ApiRecord.Type.VENDOR_REQUEST
    };
    private SearchableFields: SearchableFieldMap = {
        [ListId.ACTIVE_USER]: [UserField.FIRST_NAME, UserField.LAST_NAME, UserField.EMAIL],
        [ListId.NETSUITE_USER]: [EmployeeField.FIRST_NAME, EmployeeField.LAST_NAME, EmployeeField.EMAIL],
        [ListId.VENDOR]: [VendorField.NAME, VendorField.FIRST_NAME, VendorField.LAST_NAME, VendorField.EMAIL, VendorField.COMPANY_NAME],
        [ListId.VENDOR_CONTACT]: [VendorContactField.NAME, VendorContactField.EMAIL],
        [ListId.VENDOR_REQUEST]: [VendorRequestField.TITLE],
        [ListId.REQUEST]: [VendorRequestField.TITLE, AdditionalField.VENDOR_NAME]
    };
    private FilterableFields: FilterableFieldMap = {
        [ListId.ACTIVE_USER]: [UserField.ROLES],
        [ListId.NETSUITE_USER]: [],
        [ListId.VENDOR]: [VendorField.VENDOR_CATEGORY, VendorField.STATUS],
        [ListId.VENDOR_CONTACT]: [],
        [ListId.VENDOR_REQUEST]: [VendorRequestField.TYPE, VendorRequestField.STATUS],
        [ListId.REQUEST]: [VendorRequestField.VENDOR_ID, VendorRequestField.STATUS]
    };
    private SearchableFilterableFields: FilterableFieldMap = {
        [ListId.ACTIVE_USER]: [],
        [ListId.NETSUITE_USER]: [],
        [ListId.VENDOR]: [],
        [ListId.VENDOR_CONTACT]: [],
        [ListId.VENDOR_REQUEST]: [],
        [ListId.REQUEST]: [VendorRequestField.VENDOR_ID]
    };
    private AdditionalFields: AdditionalFieldMap = {
        [ListId.ACTIVE_USER]: [{
            fieldId: UserField.ROLES,
            label: getCommonT('role'),
        }],
        [ListId.NETSUITE_USER]: [{
            fieldId: AdditionalField.STATUS,
            label: i18n.t('integration.status_label', { ns: TranslationNS.USER })
        }],
        [ListId.VENDOR]: [{
            fieldId: AdditionalField.DISPLAY_NAME,
            label: getCommonT('name')
        }],
        [ListId.VENDOR_CONTACT]: [{
            fieldId: AdditionalField.ROLE,
            label: getCommonT('role'),
        }],
        [ListId.ROLE]: [],
        [ListId.VENDOR_CATEGORY]: [],
        [ListId.VENDOR_REQUEST]: [{
            fieldId: AdditionalField.REQUESTED_ON,
            label: i18n.t('vendor_request.requested_on', { ns: TranslationNS.VENDOR })
        }],
        [ListId.REQUEST]: [{
            fieldId: AdditionalField.REQUESTED_ON,
            label: i18n.t('vendor_request.requested_on', { ns: TranslationNS.VENDOR })
        }]
    };
    constructor(fieldQueryData: FieldQueryData, recordQueryData: RecordQueryData, lists: ListMap) {
        this.fieldQueryData = fieldQueryData;
        this.recordQueryData = recordQueryData;
        this.lists = lists;
    }
    public getAdditionalFields(listId: string): ListDetailField[] {
        return this.AdditionalFields[listId] || [];
    }
    public getDisplayedFields(listId: string): string[] {
        return this.DisplayedFields[listId] || [];
    }
    public getFields(listId: string): ListDetailField[] {
        const endpoint = this.MetaDataEndpoint[listId];
        const fieldData = this.fieldQueryData?.data || {};
        const fields: FieldQueryData = fieldData[endpoint]?.fields || {};
        const fieldList: MetaDataField[] = Object.values(fields) as MetaDataField[];

        return fieldList.map(field => ({
            fieldId: field.nid,
            label: field.uiName,
            type: field.type?.toLowerCase()
        }));
    }
    public getRecords(): ListDetailRecord[] {
        const { data: records } = this.recordQueryData || { data: [] };

        return records;
    }
    public getSearchableFields(listId: string): string[] {
        return this.SearchableFields[listId];
    }
    public getList(fieldId: string): ListDetailRecord[] {
        return this.lists[fieldId];
    }
    public isFilterable(listId: string, fieldId: string): boolean {
        return this.FilterableFields[listId]?.includes(fieldId);
    }
    public isFilterSearchable(listId: string, fieldId: string): boolean {
        return this.SearchableFilterableFields[listId]?.includes(fieldId);
    }
}