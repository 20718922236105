import { IGetIntegrationDataPresenter, IntegrationField, IntegrationFieldKey } from '../../@types/zcvp_configuration';
import { BaseFieldKey, FieldDefinitionType } from '../../@types/zcvp_form';
import { FormFieldType } from '../../@types/zcvp_form_enum';
import i18n from '../../i18n';
import { TranslationNS } from '../../lib/TranslationHelper';
import { BaseDataPresenter } from '../BaseDataPresenter';

export class GetIntegrationDataPresenter extends BaseDataPresenter<IntegrationFieldKey> implements IGetIntegrationDataPresenter {

    public fieldDefinition: FieldDefinitionType = {
        [IntegrationField.ACCOUNT_ID]: {
            name: 'accountId',
            path: 'details.realm',
            type: FormFieldType.TEXT,
            label: 'Account Id',
            required: true,
        },
        [IntegrationField.URL]: {
            name: 'url',
            path: 'details.url',
            type: FormFieldType.TEXT,
            label: i18n.t('configuration.url_label', { ns: TranslationNS.USER }),
            required: true,
        },
        [IntegrationField.CONCURRENCY_LIMIT]: {
            name: 'maxConnectionCount',
            type: FormFieldType.NUMBER,
            label: i18n.t('configuration.concurrency_limit_label', { ns: TranslationNS.USER }),
            required: true,
        },
    };

    public integrationLayout: BaseFieldKey<IntegrationFieldKey>[][] = [
        [IntegrationField.URL],
        [IntegrationField.CONCURRENCY_LIMIT],
    ];
}