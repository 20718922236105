import { SendInvitationGateway, SendInvitationPresenter } from '../../@types/invite/zcvp_invite';
import { ZCVPApiErrorResponse } from '../../@types/zcvp_api';

export type FieldValue = {
    [key: string]: string|string[];
}
export class SendUserInvitationUseCase {
    private gateway: SendInvitationGateway;
    private presenter: SendInvitationPresenter;
    constructor(gateway: SendInvitationGateway, presenter: SendInvitationPresenter) {
        this.gateway = gateway;
        this.presenter = presenter;
    }
    public execute({ userId, selectedUsers, roleId }: FieldValue) {
        const users: string[] = [];
        if (userId) {
            users.push(userId as string);
        }
        if (selectedUsers) {
            users.push(...selectedUsers as string[]);
        }
        users.forEach(user => this.sendInvite(user, roleId as string));
    }
    private sendInvite(employeeId: string, roleId: string) {
        this.gateway.inviteCallback({ employeeId, roleId }).then(({ isError, error }: {isError: boolean, error? : ZCVPApiErrorResponse}) => this.presenter.convert({ isError, error }));
    }
}