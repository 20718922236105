import { RadioChangeEvent } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { FormInstance } from 'antd/lib';
import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import { UpdateResult } from '../../@types/user-profile/zcvp_user_profile';
import { InitializeProfilePresenter } from './initializeProfilePresenter';
import { InitializeProfileUseCase } from './initializeProfileUseCase';
import { UpdateProfileGateway } from './updateProfileGateway';
import { UpdateProfileUseCase } from './updateProfileUseCase';

export const executeFieldSubmit = (event: CheckboxChangeEvent|RadioChangeEvent, editUser: Function) => {
    const fieldId = getFieldId(event);
    if (!fieldId) {return;}
    const fieldValue = getValue(event);
    const gateway = new UpdateProfileGateway(editUser);
    const useCase = new UpdateProfileUseCase(gateway);
    useCase.execute({ [fieldId]: fieldValue });
};

export const executeFieldsSubmit = (updateValues: {}, editUser: Function): UpdateResult => {
    const gateway = new UpdateProfileGateway(editUser);
    const useCase = new UpdateProfileUseCase(gateway);
    return useCase.execute(updateValues);
};


export const initializeForm = ({ form, formData, isLoading, isSuccess }: { form: FormInstance, formData: TableRecord, isLoading: boolean, isSuccess: boolean }) => {
    const presenter = new InitializeProfilePresenter(form);
    const useCase = new InitializeProfileUseCase(presenter);
    useCase.execute({
        formData,
        isLoading,
        isSuccess
    });
};

const getFieldId = (event: CheckboxChangeEvent|RadioChangeEvent) => event.target.id || event.target.name;

const getValue = (event: CheckboxChangeEvent|RadioChangeEvent) => {
    if (event.target.hasOwnProperty('value')) {
        return event.target.value;
    }

    return event.target.checked;
};