import { AddressValues } from "../../@types/get-address/zcvp_get_address";
import { GetVendorGateway } from "../../@types/get-vendors/zcvp_get_vendors";
import { TableRecord, TableRowValue } from "../../@types/list-details/zcvp_get_list_details";

export class GetVendorUseCase {
    private gateway: GetVendorGateway;
    constructor(gateway: GetVendorGateway) {
        this.gateway = gateway;
    }
    public execute(currentRecord: TableRecord) {
        const IS_USE_CACHE = true;
        this.gateway.setRecord({
            ...currentRecord,
            isLoading: true as unknown as TableRowValue
        });
        this.gateway.getVendor({
            id: currentRecord.id as unknown as number
        }, IS_USE_CACHE).then(({data: newRecord}: {data: TableRecord}) => this.updateRecord(currentRecord, newRecord));
    }
    private updateRecord(currentRecord: TableRecord, newRecord: TableRecord): void {
        if(newRecord.id === currentRecord.id) {
            this.gateway.setRecord({
                ...currentRecord,
                ...newRecord,
                isLoading: false as unknown as TableRowValue,
                billingAddress: this.getBillingAddress(newRecord.addresses as unknown as AddressValues[]) as unknown as TableRowValue,
                shippingAddress: this.getShippingAddress(newRecord.addresses as unknown as AddressValues[]) as unknown as TableRowValue,
                bankAccounts: newRecord.bankAccounts
            });
        }
    }
    private getBillingAddress(addressList: AddressValues[]): AddressValues|undefined {
        return addressList?.find((address) => address.isDefaultBilling)
    }
    private getShippingAddress(addressList: AddressValues[]): AddressValues|undefined {
        return addressList?.find(address => address.isDefaultShipping)
    }
}