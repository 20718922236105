import React from 'react';
import { useTranslation } from 'react-i18next';
import SingleColumnContent from '../../components/page/SingleColumnContent';
import { TranslationNS } from '../../lib/TranslationHelper';

const OnboardingEmailSent: React.FC = () => {
    const { t } = useTranslation(TranslationNS.ONBOARDING);

    return <SingleColumnContent
        h2={t('onboarding_email_sent_heading')}
        tagline={t('onboarding_email_sent_tagline')}
    />
};

export default OnboardingEmailSent;