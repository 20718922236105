import React from 'react';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { RoutesUrls } from '../../@types/zcvp_routes_enum';
import SingleColumnContent from '../../components/page/SingleColumnContent';
import { TranslationNS } from '../../lib/TranslationHelper';

const InvalidLinkPage: React.FC = () => {
    const { t } = useTranslation(TranslationNS.ONBOARDING);

    return <span className='zcvp-error-page'>
        <SingleColumnContent
            h2={t('invalid_link_heading')}
            tagline={t('invalid_link_tagline')}
            logo='/loading_cap.png'
        >
            <div className='text-center'>
                <Link to={RoutesUrls.MAIN}>{t('back_to_login_button')}</Link>
            </div>
        </SingleColumnContent>
    </span>;
}

export default InvalidLinkPage;