export enum FormFieldType {
    CHECKBOX = 'checkbox',
    RADIO = 'radio',
    PASSWORD = 'password',
    NUMBER = 'number',
    NUMERIC = 'numeric',
    PHONE = 'phone',
    DATE = 'date',
    DATETIME = 'datetime',
    EMAIL = 'email',
    MULTIEMAIL = 'multiemail',
    SELECT = 'select',
    MULTISELECT = 'multiselect',
    TEXT = 'text',
    TEXTAREA = 'textarea',
    HIDDEN = 'hidden',
    ELEMENT = 'element',
    WEB_ADDRESS = 'url',
    ADDRESS = 'address',
    FILE = 'file'
}

export enum FormElementType {
    DIVIDER = 'divider',
    INLINE_TEXT = 'inline',
}

export enum ActionType {
    SUBMIT = 'submit',
    CANCEL = 'cancel',
    RESET = 'reset',
    ELEMENT = 'element'
}