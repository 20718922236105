import { TabActionsPresenter } from '../../@types/get-tab-actions/zcv_get_tab_actions';
import { ActionItem } from '../../components/layout/TabbedContentLayout';

export class GetTabActionsUseCase {
    private presenter: TabActionsPresenter;
    constructor(presenter: TabActionsPresenter) {
        this.presenter = presenter;
    }
    public execute(actions: ActionItem[], activeKey: string) {
        const filteredActions = activeKey ? actions.filter(action => !action.applyToTabs || action.applyToTabs?.includes(activeKey)) : actions;
        return this.presenter.convert(filteredActions);
    }
}