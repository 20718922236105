import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import { SubmitRecordGatewayInterface, SubmitRecordPresenterInterface } from '../../@types/submit-record/zcvp_submit_record';
import { Feature, RecordType } from '../../@types/submit-record/zcvp_submit_record_enum';

export class SubmitRecordUseCase {
    private gateway: SubmitRecordGatewayInterface;
    private presenter: SubmitRecordPresenterInterface;
    constructor(gateway: SubmitRecordGatewayInterface, presenter: SubmitRecordPresenterInterface) {
        this.gateway = gateway;
        this.presenter = presenter;
    }
    public execute(recordType: RecordType|Feature, values: TableRecord) {
        this.gateway.submitRecord(recordType, values)
            .then((response) => this.presenter.convert({
                ...response,
                recordType
            }));
    }
}