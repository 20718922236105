import { Field } from '../../@types/invite-vendors/zcvp_invite_vendors';
import { InvitationControllerProps } from '../../@types/invite/zcvp_invite';
import { FormFieldDefinition } from '../../@types/zcvp_form';
import { FormFieldType } from '../../@types/zcvp_form_enum';
import { getCommonT } from '../../lib/TranslationHelper';
import { SendInvitationController } from '../invitation/sendInvitationController';
import { SendVendorInvitationUseCase } from './sendVendorInvitationUseCase';

export class SendVendorInvitationController extends SendInvitationController {
    /**
     * Field definition lookup. Name matches the id that the API is expecting
     * @private
     */
    public fields: FormFieldDefinition[][] = [
        [{
            name: Field.COMPANY_NAME,
            type: FormFieldType.TEXT,
            label: 'Company Name',
            required: true,
        }],
        [{
            name: Field.INVITE_EMAILS,
            label: 'Company Email',
            type: FormFieldType.MULTIEMAIL,
            required: true,
        }],
        [{
            name: Field.SUBSIDIARY_ID,
            type: FormFieldType.SELECT,
            label: getCommonT('subsidiary'),
            options: [],
            required: true,
        }],
    ];

    constructor({ inviteCallback, onSuccessCallback, messageApi, subsidiaries }: InvitationControllerProps) {
        super({
            sendUseCase: SendVendorInvitationUseCase,
            inviteCallback,
            onSuccessCallback,
            messageApi
        });

        if (subsidiaries?.data) {
            if (subsidiaries.data.length <= 1) {
                delete this.fields.find((field) => field.find((field) => field.name === Field.SUBSIDIARY_ID))![0];
            } else {
                const subsidiaryField = this.fields.find((field) => field.find((field) => field.name === Field.SUBSIDIARY_ID))![0];
                subsidiaryField.options = subsidiaries.data!.map((subsidiary) => ({
                    label: subsidiary.name,
                    value: subsidiary.id,
                    id: subsidiary.id,
                }));
            }
        }
    }

}