import { LogoutOutlined } from '@ant-design/icons';
import { useCallback } from 'react';

import { LabelWithIcon } from '../partials/StyledText';

import { logout } from '../../lib/AuthenticationHelper';

const LogoutButton = () => {
    const logoutUser = useCallback(() => {
        logout();
    }, []);

    return <span onClick={logoutUser} data-cy='logout_btn'>
        <LabelWithIcon label='Sign out' leftIcon={<LogoutOutlined/>}/>
    </span>;
};

export default LogoutButton;