import { RecordQueryData } from '../../@types/list-details/zcvp_get_list_details';
import { GetOptionsGateway } from "./getOptionsGateway";
import { GetOptionsUseCase } from "./getOptionsUseCase";

export class GetOptionsController {
    private records;
    constructor(records: RecordQueryData) {
        this.records = records;
    }
    execute(nameProp: string) {
        const getOptionsGateway = new GetOptionsGateway(this.records);
        const getOptionsUseCase = new GetOptionsUseCase(getOptionsGateway);
        return getOptionsUseCase.execute(nameProp);
    }
}