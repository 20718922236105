import { UpdateProfileGateway, UpdateResult } from '../../@types/user-profile/zcvp_user_profile';

export class UpdateProfileUseCase {
    private gateway: UpdateProfileGateway;
    constructor(gateway: UpdateProfileGateway) {
        this.gateway = gateway;
    }
    public execute(updateValues : {}): UpdateResult {
        return this.gateway.updateProfile(updateValues);
    }
}