import { Dispatch } from '@reduxjs/toolkit';
import axios, { AxiosProgressEvent, InternalAxiosRequestConfig } from 'axios';
import { updateLoaderInfo } from '../slice/LoaderSlice';

enum LoadProgress {
    STARTED = 0,
    LOADING = 30,
    COMPLETED = 100
};

export class RequestInterceptor {
    constructor(dispatch: Dispatch) {
        this.dispatch = dispatch;
    }
    private dispatch;
    public setup() {
        this.setupRequestInterceptor();
        this.setupResponseInterceptor();
    }
    private setupRequestInterceptor() {
        axios.interceptors.request.use((config: InternalAxiosRequestConfig) => {
            this.dispatch(updateLoaderInfo({
                isLoading: true,
                percentCompleted: LoadProgress.STARTED
            }));
            const onProgress = (progressEvent: AxiosProgressEvent) => this.setProgress(progressEvent);

            config.onDownloadProgress = onProgress;
            config.onUploadProgress = onProgress;

            return config;
        }, (error) => Promise.reject(error));
    }
    private setupResponseInterceptor() {
        axios.interceptors.response.use((config) => {
            this.completeRequest();

            return config;
        }, (error) => {
            this.completeRequest();

            return Promise.reject(error);
        });
    }
    private setProgress(progressEvent: AxiosProgressEvent) {
        const { loaded, total = 0 } = progressEvent;
        const percentCompleted = Math.floor((loaded * 100) / total);
        const progress = isFinite(percentCompleted) ? percentCompleted : LoadProgress.LOADING;
        this.dispatch(updateLoaderInfo({
            percentCompleted: progress
        }));
    }
    private completeRequest() {
        this.dispatch(updateLoaderInfo({
            percentCompleted: LoadProgress.COMPLETED
        }));
        setTimeout(() => {
            this.dispatch(updateLoaderInfo({
                isLoading: false
            }));
        }, 500);
    }
}