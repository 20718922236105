import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { InvitationType } from '../../@types/invite/zcvp_invite_enum';
import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import { useLazyValidateInvitationQuery } from '../../slice/APISlice';
import InvalidLinkPage from './InvalidLinkPage';
import InvitationSuccessPage from './InvitationSuccessPage';

enum Status {
    SUCCESS = 'success'
}
export type ValidationInvitationFormProps = {
    invitationType?: InvitationType;
    data?: TableRecord;
}
export type InvitationValidationFormProps = {
    invitationType: InvitationType;
    ValidInvitationForm: React.FC<ValidationInvitationFormProps>;
    SuccessForm?: React.FC<{ passwordExists: boolean }>
}

const InvitationValidation: React.FC<InvitationValidationFormProps> = ({ invitationType, ValidInvitationForm, SuccessForm = InvitationSuccessPage }: InvitationValidationFormProps) => {
    const { invitationId, status } = useParams();
    const [isValid, setIsValid] = useState(true);
    const [validationData, setValidationData] = useState<TableRecord>();
    const isSuccess = useMemo(() => status === Status.SUCCESS, [status]);
    const [validateInvitation] = useLazyValidateInvitationQuery({});

    useEffect(() => {
        if (invitationId) {
            validateInvitation({ invitationId }).then(({ data }) => {
                setValidationData(data);
                setIsValid(data?.valid);
            });
        }
    }, [invitationId, validateInvitation]);

    return isValid && isSuccess ? <SuccessForm passwordExists={!!validationData?.passwordExists} /> :
        isValid && invitationId ? <ValidInvitationForm invitationType={invitationType} data={validationData!} /> : <InvalidLinkPage />;
};

export default InvitationValidation;