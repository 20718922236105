import { Button, Form } from 'antd';
import { MessageInstance } from 'antd/es/message/interface';
import React from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { ZCVPApiResponse } from '../../@types/zcvp_api';
import { ShowMessageTypes } from '../../@types/zcvp_custom_hooks';
import PasswordField from '../../components/form/form-field/PasswordField';
import SingleColumnContent from '../../components/page/SingleColumnContent';
import { showErrorMessage, showMessage } from '../../lib/CustomHooks';
import { TranslationNS, getCommonT } from '../../lib/TranslationHelper';
import { usePostResetConfirmPasswordMutation } from '../../slice/APISlice';

const PasswordReset: React.FC = () => {
    const { t } = useTranslation(TranslationNS.AUTHENTICATION);
    const { executeRecaptcha } = useGoogleReCaptcha();
    const navigate = useNavigate();
    const { messageApi } = useOutletContext<{ messageApi: MessageInstance }>();
    const [form] = Form.useForm();
    const params = useParams<{ token: string }>();
    const [resetConfirmPassword] = usePostResetConfirmPasswordMutation();

    const handlePasswordReset = async () => {
        if (!executeRecaptcha) {
            showErrorMessage(getCommonT('invalid_data'), messageApi);
            return;
        }

        const captchaToken = await executeRecaptcha('passwordResetFormSubmit');
        if (captchaToken) {
            resetConfirmPassword({
                    randomKey: params.token,
                    password: form.getFieldsValue().password,
                    captchaToken: captchaToken
                })
                .then((response: ZCVPApiResponse) => {
                    if (typeof response.error !== 'undefined') {
                        showErrorMessage(response.error.message, messageApi)
                    } else {
                        showMessage(ShowMessageTypes.SUCCESS, t('password_reset_success'), messageApi);
                        navigate('/');
                    }
                })
        }
    }

    return (
        <SingleColumnContent
            h2={t('reset_password_heading')}
        >
            <Form
                name={'zcvp_password_reset_form'}
                layout="vertical"
                form={form}
                requiredMark={false}
                validateTrigger={'onSubmit'}
                onFinish={handlePasswordReset}
            >
                <PasswordField
                    name={'password'}
                    label={t('password')}
                    required={true}
                    hideHint={true}
                />
                <PasswordField
                    name={'confirm_password'}
                    label={t('confirm_password')}
                    required={true}
                    matchToField={{
                        name: 'password',
                        form: form
                    }}
                />
                <p className={'text-center text-gray-400'}>
                    <Trans i18nKey="authentication:captcha_notice">
                        This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank" rel="noreferrer">Terms of Service</a> apply.
                    </Trans>
                </p>
                <Button size="large" type="primary" block htmlType={'submit'}>{t('change_password_button')}</Button>
            </Form>
        </SingleColumnContent>
    );
};

export default PasswordReset;