import { MailOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useCallback, useState } from 'react';

import GenericActionButton from '../../components/form/form-action/GenericActionButton';
import TabbedContentLayout, { ActionItem } from '../../components/layout/TabbedContentLayout';
import ListPageContent from '../../components/page/ListPageContent';

import { useTranslation } from 'react-i18next';
import { TranslationNS } from '../../lib/TranslationHelper';
import '../../styles/zcvp_tabbed_page.scss';
import InviteVendorModal from './InviteVendorModal';
import ManualVendorAddModal from './ManualVendorAddModal';
import VendorTable from './VendorTable';

const VendorList: React.FC = () => {
    const { t } = useTranslation(TranslationNS.VENDOR);
    const [isInviteNew, setIsInviteNew] = useState(false);
    const [isManualAddVendor, setIsManualAddVendor] = useState(false);
    const vendorsTab = <ListPageContent
        title={t('vendors_tab_title')}
        pageId={'vendors'}
        key={'vendors'}
        DataTable={VendorTable}
        searchPlaceHolder='Find vendor' />;

    const items = [{
        label: '',
        key: 'vendors',
        children: [vendorsTab]
    }];
    const actions: ActionItem[] = [{
        element: <GenericActionButton
            key='invite-vendor-btn'
            name={'zvcp-invite-vendor'}
            label={t('invite_new_label')}
            tooltip={''}
            icon={<MailOutlined />}
            onClick={() => setIsInviteNew(true)} />
    }, {
        element: <GenericActionButton
            key='add-manually-btn'
            name={'zvcp-add-manually'}
            label={t('add_manually_label')}
            tooltip={''}
            icon={<PlusOutlined />}
            onClick={() => setIsManualAddVendor(true)} />
    }];
    const onClose = useCallback(() => setIsInviteNew(false), []);

    return <>
        {isInviteNew ? <InviteVendorModal open={isInviteNew} onClose={onClose} /> : ''}
        {isManualAddVendor ? <ManualVendorAddModal open={isManualAddVendor} onClose={() => setIsManualAddVendor(false)} /> : ''}
        <TabbedContentLayout
            title={t('vendors_tab_title')}
            items={items}
            actions={actions}
            activeKey={'vendors'} // remove
        />
    </>;
};

export default VendorList;