import React from 'react';
import { ConfigProvider, Layout, Space } from 'antd';

const { Compact } = Space;

type TabPageContentProps = {
    subHeader?: React.ReactNode;
    items: React.ReactNode;
}

const themeTokens = {
    components: {
        Form: {
            itemMarginBottom: 12,
        }
    } 
};
const TabPageContent: React.FC<TabPageContentProps> = ({ subHeader, items, elementRef }: TabPageContentProps) => (
    <Layout ref={elementRef}>
        <ConfigProvider
            theme={themeTokens}
        >
            {subHeader && (
                <Compact className={'zcvp-sub-header'}>
                    {subHeader}
                </Compact>
            )}
            <Layout className={'zcvp-tab-content-pane'}>
                {items}
            </Layout>
        </ConfigProvider>
    </Layout>
);

export default TabPageContent;