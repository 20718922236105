import React from 'react';
import { Button, Tooltip } from 'antd';
import { ActionButtonDefinition } from '../../../@types/zcvp_form';

/**
 * Creates a generic action button
 *
 * @param {ActionButtonDefinition} props form action properties
 * @returns {React.FC} action button
 */
const GenericActionButton: React.FC<ActionButtonDefinition> = ({ name, label, disabled, type, icon, tooltip, danger, onClick, block = false, size = 'middle' }: ActionButtonDefinition) => (
    <Tooltip title={tooltip}>
        <Button
            name={name}
            type={type}
            disabled={disabled}
            htmlType='button'
            className='my-0'
            icon={icon}
            onClick={onClick}
            size={size}
            block={block}
            danger={danger}
        >
            {label}
        </Button>
    </Tooltip>
);

export default GenericActionButton;