import { Form } from 'antd';
import { MessageInstance } from 'antd/lib/message/interface';
import React, { useCallback, useEffect } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Trans, useTranslation } from 'react-i18next';
import { FormFieldValues } from '../../@types/invite/zcvp_invite';
import { InvitationType } from '../../@types/invite/zcvp_invite_enum';
import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import { Field } from '../../@types/onboarding/zcvp_onboarding';
import { disableSubmitTrigger } from '../../@types/zcvp_form';
import { ActionType } from '../../@types/zcvp_form_enum';
import GenericForm from '../../components/form/GenericForm';
import SingleColumnContent from '../../components/page/SingleColumnContent';
import { CompanyType } from '../../lib/Constants';
import { showErrorMessage } from '../../lib/CustomHooks';
import { TranslationNS, getCommonT } from '../../lib/TranslationHelper';
import { useLazyConfirmInvitationQuery } from '../../slice/APISlice';
import { hasMissingField } from '../../use-case/formErrorHandling/validateFormErrorUseCase';
import { getVendorFullName } from '../../use-case/getVendors/getVendorsUseCase';
import { ConfirmInvitationController } from '../../use-case/invitation/confirmInvitationController';
import { OnboardingGateway } from '../../use-case/onboarding/onboardingGateway';
import { SubmitRecordGateway } from '../../use-case/submitRecord/submitRecordGateway';

export type OnboardingInitializeFormProps = {
    data: FormFieldValues;
}
const OnboardingInitializeForm: React.FC<OnboardingInitializeFormProps> = ({ data }: OnboardingInitializeFormProps) => {
    const { t } = useTranslation(TranslationNS.ONBOARDING);
    const { executeRecaptcha } = useGoogleReCaptcha();
    const navigate = useNavigate();
    const { invitationId: randomKey } = useParams();
    const [form] = Form.useForm();
    const { messageApi } = useOutletContext<{ messageApi: MessageInstance }>();

    const [confirmInvitation] = useLazyConfirmInvitationQuery({});
    const gateway = new OnboardingGateway();

    const submitForm = useCallback(async (values: TableRecord) => {
        if (!executeRecaptcha) {
            showErrorMessage(getCommonT('invalid_data'), messageApi);
            return;
        }

        const captchaToken = await executeRecaptcha('onboardingInitializeFormSubmit');
        if (captchaToken) {
            return new ConfirmInvitationController(navigate, messageApi, confirmInvitation)
                .execute(InvitationType.VENDOR, {
                    ...values,
                    captchaToken: captchaToken,
                    [Field.RANDOM_KEY]: randomKey!,
                    [Field.FULL_NAME]: getVendorFullName(values),
                    [Field.COMPANY_URL]: new SubmitRecordGateway().getUrlValue(values[Field.COMPANY_URL] as string) as string
                })
        }
    }
    , [randomKey, messageApi, navigate, confirmInvitation, executeRecaptcha]);

    const submitFormFailed = useCallback((errorInfo) => {
        const hasMissingValue = hasMissingField(errorInfo.errorFields);
        hasMissingValue && showErrorMessage(getCommonT('field_is_mandatory'), messageApi);
    }, [messageApi]);

    useEffect(() => {
        const vendor = data?.vendor as unknown as TableRecord;
        vendor && form.setFieldsValue({
            [Field.COMPANY_NAME]: vendor.companyName,
            [Field.COMPANY_SIZE]: vendor.size || CompanyType.COMPANY.toString(),
            [Field.COMPANY_URL]: vendor.url,
            [Field.PHONE]: vendor.phone
        });
    }, [data, form]);

    return (
        <SingleColumnContent
            h2={t('onboarding_initialize_heading')}
            tagline={t('onboarding_initialize_tagline')}
        >
            <GenericForm
                formProps={{
                    form,
                    layout: 'vertical',
                    requiredMark: true,
                    initialValues: { phonecode: '48', randomKey },
                    onFinish: (values: TableRecord) => submitForm(values),
                    onFinishFailed: (errorInfo) => submitFormFailed(errorInfo)
                }}
                disableSubmitTrigger={disableSubmitTrigger.ON_ANY_FIELD_CHANGE}
                fields={gateway.getInitializingFields()} actions={[
                    {
                        type: ActionType.SUBMIT,
                        definition: {
                            label: t('initialize_form_submit_button'),
                            key: 'submit_onboarding_form',
                            name: 'submit_onboarding_form',
                            size: 'large',
                            block: true,
                        }
                    }
                ]} />
            <p className={'text-center text-gray-400'}>
                <Trans i18nKey="authentication:captcha_notice">
                    This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank" rel="noreferrer">Terms of Service</a> apply.
                </Trans>
            </p>
            <p className={'text-center'}>
                <Trans i18nKey="onboarding.initialize_privacy_notice">
                    By clicking "Let's Work Together" you agree with our <a href="https://www.zoneandco.com/legal/privacy" target="_blank" rel="noreferrer">Privacy Notice</a>
                </Trans>
            </p>
        </SingleColumnContent>
    );
};

export default OnboardingInitializeForm;