import { DownOutlined, LinkOutlined, MailOutlined, RightOutlined } from '@ant-design/icons';
import { Collapse, Tooltip, Typography } from 'antd';
import React, { useCallback, useMemo } from 'react';

import { getCollapsibleLabel } from '../../use-case/collapsibleDetails/getCollapsibleDetails';

const { Paragraph } = Typography;
export type CollapsibleFunction = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
enum CollapseStatus {
    OPEN = '1',
    CLOSED = ''
}

type LabelWithIconProps = {
    label: string | React.ReactNode;
    leftIcon?: React.ReactNode;
    rightIcon?: React.ReactNode;
    truncateText?: boolean;
}

type CollapsibleLabelProps = {
    label: string;
    isExpanded: boolean;
    isCollapsible: boolean;
    setIsExpanded: (isExpanded: boolean) => void;
}

type CollapsibleFieldValueProps = {
    value: React.ReactNode;
    valueCount?: number;
    template?: string;
    collapseStatus?: boolean;
    Icon?: React.ReactNode;
    onClick: CollapsibleFunction;
}
const TAB_SPACE = <>&ensp;</>;

export const CopyableValue = ({ value }: { value?: string }) => !!value ? <Paragraph copyable>{value}</Paragraph> : null;

export const LinkValue = ({ value }: { value?: string }) => !!value ? <>
    {value}<a href={value} target='_blank' rel='noreferrer'><LinkOutlined /></a>
</> : null;

export const MailValue = ({ value }: { value?: string }) => !!value ? <>
    <Tooltip title="Send email">
        <a href={`mailto:${value}`}><MailOutlined /></a>
    </Tooltip>
</> : null;

export const LabelWithIcon = ({ label, leftIcon, rightIcon, truncateText = false }: LabelWithIconProps) => {
    let labelClass = 'label-with-icon truncate';
    if (!truncateText) {
        labelClass += ' whitespace-normal';
    }

    const labelWithIcon = [leftIcon, <span className={labelClass}>{label}</span>, rightIcon]
        .filter(icon => !!icon)
        .map((element, index) => <React.Fragment key={index}>{element}{TAB_SPACE}</React.Fragment>);

    return label ? <div className='flex truncate'>{labelWithIcon}</div> : null
};

export const CollapsibleLabel = ({ label, isExpanded, setIsExpanded, isCollapsible }: CollapsibleLabelProps) => {
    const onExpand = useCallback(() => setIsExpanded(!isExpanded), [isExpanded, setIsExpanded]);

    const Icon = isExpanded ? DownOutlined : RightOutlined;
    const icon = isCollapsible ? <Icon onClick={onExpand} /> : null;
    return <>{label}{TAB_SPACE}{icon}</>
};

export const CollapsibleFieldValue = ({ template, value, valueCount, collapseStatus, onClick }: CollapsibleFieldValueProps) => {
    const collapsibleLabel = useMemo(() => getCollapsibleLabel(template, valueCount), [template, valueCount]);
    const activeKey = useMemo(() => collapseStatus ? CollapseStatus.OPEN : CollapseStatus.CLOSED, [collapseStatus]);

    return collapsibleLabel ? <Collapse destroyInactivePanel activeKey={[activeKey]} ghost items={[{
        key: CollapseStatus.OPEN,
        label: <span onClick={onClick}>{collapsibleLabel}</span>,
        children: value,
    }]} /> : <>{value}</>;
};