import { RolePermissionGateway } from '../../@types/role-permissions/zcvp_role_permissions';
import { FeaturePermissionDefinition, PageFeatureMap } from '../../@types/role-permissions/zcvp_role_permissions';
import { PortalFeature } from '../../@types/role-permissions/zcvp_role_permissions_enum';
import { ApiPermission } from '../../@types/zcvp_api_enum';
import { ZCVPMenuEntryKeys } from '../../@types/zcvp_nav.d';

export class CheckRolePermissionUseCase {
    private gateway: RolePermissionGateway;
    private portalPageFeatureMap: PageFeatureMap = {
        [ZCVPMenuEntryKeys.MAIN]: [],
        [ZCVPMenuEntryKeys.VENDORS]: [PortalFeature.VENDOR_LIST, PortalFeature.CREATE_VENDOR, PortalFeature.VIEW_VENDOR_DETAIL, PortalFeature.INVITE_VENDOR],
        [ZCVPMenuEntryKeys.DASHBOARD]: [],
        [ZCVPMenuEntryKeys.REQUESTS]: [PortalFeature.REQUEST_LIST],
        [ZCVPMenuEntryKeys.HOME]: [],
        [ZCVPMenuEntryKeys.PROFILE]: [PortalFeature.EDIT_PROFILE],
        [ZCVPMenuEntryKeys.COMPANY]: [PortalFeature.EDIT_VENDOR, PortalFeature.EDIT_BANK_DETAILS, PortalFeature.CONTACT_LIST, PortalFeature.EDIT_CONTACT, PortalFeature.INVITE_CONTACT],
        [ZCVPMenuEntryKeys.CONFIGURATION]: [PortalFeature.EDIT_COMPANY],
        [ZCVPMenuEntryKeys.USERS]: [PortalFeature.ACTIVE_USERS_LIST, PortalFeature.EDIT_USER, PortalFeature.EMPLOYEE_USERS_LIST, PortalFeature.INVITE_EMPLOYEE],
        [ZCVPMenuEntryKeys.HELP]: [],
    };
    constructor(gateway: RolePermissionGateway) {
        this.gateway = gateway;
    }
    public execute(pageId: string): boolean {
        const features = this.portalPageFeatureMap[pageId];
        
        return features?.every(feature => this.checkFeature(feature));
    }
    public checkFeature(feature: PortalFeature): boolean {
        const permissions = this.gateway.getPermissions();
        const featurePermissions = this.gateway.getPortalFeaturePermissions(feature);
        
        return featurePermissions.every(featurePermission => this.checkPermission(permissions, featurePermission));
    }
    private checkPermission(permissions: ApiPermission.Permission[], featurePermission: FeaturePermissionDefinition): boolean {
        return permissions.some(permission => {
            const featurePermissionLevels = this.gateway.getPortalPermissionLevels(featurePermission.level);
            
            return permission.target === featurePermission.permission && featurePermissionLevels.includes(permission.action);
        });
    }
}