import React, { MouseEventHandler, useCallback, useMemo, useState } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Alert, Breadcrumb } from 'antd';

import TabPageContent from './TabPageContent';
import TablePageHeader from '../partials/TablePageHeader';
import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import { InfoDetails } from '../../@types/task-info/zcvp_task_info';
import '../../styles/zcvp_tabbed_page.scss';

type DataTableProps = {
    filterValue: string;
    filters: {[key: string]: string[]};
    onRowClick: Function;
    setFilters: Function;
}

type SubPageProps = {
    record?: TableRecord;
}

type ListPageContentProps = {
    pageId: string;
    searchPlaceHolder: string;
    title: string;
    infoDetails?: InfoDetails;
    DataTable: React.FC<DataTableProps>;
    SubPage?: React.FC<SubPageProps>;
    alertElementRef?: React.RefObject<HTMLInputElement>;
}

type TablePageProps = {
    pageId: string;
    searchPlaceHolder: string;
    infoDetails?: InfoDetails;
    DataTable: React.FC<DataTableProps>;
    onRowClick: Function;
    alertElementRef?: React.RefObject<HTMLInputElement>;
}

type TableRowDetailsProps = {
    pageId: string;
    record?: TableRecord;
    title: string;
    onBack: MouseEventHandler<HTMLSpanElement>;
    Page: React.FC<SubPageProps>;
}

const TablePage = ({ pageId, searchPlaceHolder, infoDetails, DataTable, onRowClick, alertElementRef }: TablePageProps) => {
    const [filterValue, setFilterValue] = useState('');
    const [availableFilters, setAvailableFilters] = useState([]);
    const [filters, setFilters] = useState({});
    const onSearch = useCallback((value: string) => setFilterValue(value), []);

    const dataTable = useMemo(() => <DataTable
        key={`usrlist-datatable-${pageId}`} 
        onRowClick={onRowClick}
        filterValue={filterValue}
        filters={filters}
        setFilters={setAvailableFilters}/>
    , [pageId, onRowClick, DataTable, filterValue, filters]);

    const banner = useMemo(() => infoDetails?.message ? <span ref={alertElementRef}>
        <Alert {...infoDetails} showIcon key='table-banner'/>
    </span> : null, [infoDetails, alertElementRef]);

    const items = useMemo(() => banner ? [banner, dataTable] : [dataTable], [banner, dataTable]);
    return <TabPageContent
        items={items}
        subHeader={<TablePageHeader
            searchPlaceHolder={searchPlaceHolder}
            filters={availableFilters}
            appliedFilters={filters}
            onSearch={onSearch}
            onFilter={setFilters}
            />
        }/>;
}

const TableRowDetails = ({ pageId, record, title, onBack, Page }: TableRowDetailsProps) => {
    return <TabPageContent
        subHeader={<Breadcrumb items={[
            {
                title: <span onClick={onBack} className='cursor-pointer flex gap-2'>
                    <ArrowLeftOutlined />{title}
                </span>
            }, {
                title: <span className='zcvp-list-nav'>{record?.fullName as string}</span>
            }
        ]} />
        }
        items={[<Page key={`usrlist-subpage-${pageId}`} record={record} />]} />;
}
const ListPageContent = ({ pageId, searchPlaceHolder, title, infoDetails, DataTable, SubPage, alertElementRef }: ListPageContentProps) => {
    const [subPageData, setSubPageData] = useState<TableRecord | undefined>();
    const onRowClick = useCallback((record: TableRecord) => setSubPageData(record), []);
    const onBack = useCallback(() => setSubPageData(undefined), []);

    return subPageData && SubPage
        ? <TableRowDetails
            pageId={pageId}
            title={title}
            record={subPageData}
            Page={SubPage}
            onBack={onBack} />
        : <TablePage
            alertElementRef={alertElementRef}
            pageId={pageId}
            searchPlaceHolder={searchPlaceHolder}
            infoDetails={infoDetails}
            DataTable={DataTable}
            onRowClick={onRowClick} />
};

export default ListPageContent;