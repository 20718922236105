import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'antd';
import { MessageInstance } from 'antd/lib/message/interface';

import GenericForm from '../../components/form/GenericForm';
import TabPageContent from '../../components/page/TabPageContent';
import CardComponent from '../../components/partials/CardComponent';
import { getTutorialStepRef } from '../portal/PortalTutorial';
import { TranslationNS } from '../../lib/TranslationHelper';
import { useGetCompanyDataQuery, useUpdateCompanyDataMutation } from '../../slice/APISlice';
import { SubmitRecordController } from '../../use-case/submitRecord/submitRecordController';

import { FieldValueMap, OnChange } from '../../@types/zcvp_form';
import { TableRowValue } from '../../@types/list-details/zcvp_get_list_details';
import { ApiRecord } from '../../@types/zcvp_api_enum';
import { FormFieldType } from '../../@types/zcvp_form_enum';
import { TutorialStepKey } from '../../@types/tutorial/zcvp_tutorial_enum';
import { Feature, } from '../../@types/submit-record/zcvp_submit_record_enum';

const ApprovalConfiguration = () => {
    const { t } = useTranslation(TranslationNS.USER, { keyPrefix: TranslationNS.APPROVALS });
    const { messageApi } = useOutletContext<{ messageApi: MessageInstance }>();
    const [form] = Form.useForm();

    const [updateCompany] = useUpdateCompanyDataMutation();
    const companyQuery = useGetCompanyDataQuery({});
    const companyData = useMemo(() => companyQuery?.data || {}, [companyQuery?.data]);

    const updateConfig = useCallback((fieldId: string, value: TableRowValue) => new SubmitRecordController({
        onSubmit: updateCompany, messageApi
    }).execute(Feature.APPROVALS_CONFIG, {
        [ApiRecord.CompanyField.ID]: companyData[ApiRecord.CompanyField.ID],
        [fieldId]: value
    }), [companyData, updateCompany, messageApi]);

    const getOnChange = (fieldId: string, setterFn: Function) => ((value: TableRowValue) => {
        setterFn(value);
        updateConfig(fieldId, value);
    }) as OnChange;

    const [approvalToOnboard, setApprovalToOnboard] = useState();
    const [approvalAfterOnboard, setApprovalAfterOnboard] = useState();
    useEffect(() => {
        setApprovalToOnboard(companyData[ApiRecord.CompanyField.APPROVALS_TO_ONBOARD]);
        setApprovalAfterOnboard(companyData[ApiRecord.CompanyField.APPROVALS_AFTER_ONBOARD]);
    }, [companyData]);

    const NEW_VENDOR_CONFIG_OPTIONS = [
        { id: '1', value: true, label: t('config_options_ask_for_approval') },
        { id: '2', value: false, label: t('config_options_dont_ask_for_approval') }
    ];

    const AFTER_ONBOARDING_CONFIG_OPTIONS = [
        { id: '1', value: true, label: t('config_options_enable_review') },
        { id: '2', value: false, label: t('config_options_disable_review') }
    ];

    return (
        <TabPageContent
            key={'configuration_approvals_tab_content_wrapper'}
            items={
                [<div key={'configuration_approvals_tab_content'}>
                    <CardComponent
                        title={t('vendor_management')}
                        elementRef={getTutorialStepRef(TutorialStepKey.VENDOR_MANAGEMENT)}
                    >
                        <Row>
                            <Col xl={12} lg={16} md={18} sm={20} xs={24}>
                                <GenericForm
                                    fields={[[{
                                        name: ApiRecord.CompanyField.APPROVALS_TO_ONBOARD,
                                        type: FormFieldType.SELECT,
                                        label: t('new_vendor_created'),
                                        options: NEW_VENDOR_CONFIG_OPTIONS,
                                        subText: approvalToOnboard ? t('new_vendor_created_description') : t('new_vendor_created_off_description'),
                                        onChange: getOnChange(ApiRecord.CompanyField.APPROVALS_TO_ONBOARD, setApprovalToOnboard)
                                    }], [{
                                        name: ApiRecord.CompanyField.APPROVALS_AFTER_ONBOARD,
                                        type: FormFieldType.SELECT,
                                        options: AFTER_ONBOARDING_CONFIG_OPTIONS,
                                        label: t('review_after_onboarding'),
                                        subText: approvalAfterOnboard ? t('review_after_onboarding_description') : t('review_after_onboarding_off_description'),
                                        onChange: getOnChange(ApiRecord.CompanyField.APPROVALS_AFTER_ONBOARD, setApprovalAfterOnboard)
                                    }]]}
                                    actions={[]}
                                    fieldValues={companyData as unknown as FieldValueMap}
                                    formProps={{
                                        form: form
                                    }}
                                />
                            </Col>
                        </Row>
                    </CardComponent>
                </div>]
            }
        />
    );
}

export default ApprovalConfiguration;