import { Form, Input } from 'antd';
import { Rule, RuleObject } from 'antd/lib/form';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PasswordFieldProps } from '../../../@types/zcvp_form';
import { TranslationNS } from '../../../lib/TranslationHelper';

/**
 * Minimum length a password must be
 * Exported for use in tests
 */
export const minPasswordLength = 10;

/**
 * Validates password contain at least three of these four character types — uppercase letters, lowercase letters, numbers, non-alphanumeric ASCII characters.
 *
 * Exported for use in tests
 * @param {string} value
 * @return {boolean}
 */
export const PasswordCharacterValidation = (value: string): boolean => {
    var criteria = 0;
    if (value.toUpperCase() !== value) {
        // has lower case letters 
        criteria++;
    }
    if (value.toLowerCase() !== value) {
        // has upper case letters
        criteria++;
    }
    if (!/^[\w\d]*$/.test(value)) {
        // has special characters
        criteria++;
    }
    if (/\d/.test(value)) {
        // has numbers
        criteria++;
    }

    return criteria >= 3;
};

/**
 * Returns a password field
 * There are two versions: one that runs standard validation, and one that only validates if the password field matches another field.
 * @param {string} name
 * @param {string} label
 * @param {placeholder} placeholder
 * @param {boolean} disabled
 * @param {string} className
 * @param {boolean} hideHint - hides the validation hint (is false if skipValidationRule is false)
 * @param {Object} matchToField
 * @property {string} matchToField.name - name of the field to match to
 * @property matchToField.form - FormInstance to look up the field we're matching
 * @param {boolean} skipValidationRule - Doesn't perform a check for uppercase letters, lowercase letters, numbers, non-alphanumeric ASCII characters OR length
 * @param {Function} onChange
 * @param {React.ReactNode} extra - extra content to display below the field
 * @constructor
 */
const PasswordField: React.FC<PasswordFieldProps> = ({ name, label, placeholder, disabled, className, hideHint, matchToField, skipValidationRule, onChange, extra }: PasswordFieldProps) => {
    const { t } = useTranslation(TranslationNS.AUTHENTICATION)
    const standardRules: Rule[] = [
        { required: true },
        ...(skipValidationRule ? [] :
            [
                { min: minPasswordLength },
            ]
        ),
        ...(skipValidationRule ? [] :
            [{
                validator(rule: RuleObject, value: string | null) {
                    if (value === null) {
                        return Promise.resolve();
                    }

                    if (PasswordCharacterValidation(value)) {
                        return Promise.resolve();
                    }

                    return Promise.reject(new Error());
                },
                message: t('password_not_meet_requirements')
            }]
        ),
    ];

    const matchingRules: Rule[] = [
        { required: true },
        {
            message: t('passwords_do_not_match'),
            validator(rule, value) {
                const form = matchToField?.form;
                if (value === null) {
                    return Promise.resolve();
                }
                const passwordValue = form?.getFieldValue(matchToField?.name);
                if (value !== passwordValue) {
                    return Promise.reject(new Error());
                }

                return Promise.resolve();
            },
        }];

    return (
        <>
            <Form.Item id={`zcvp_${name}`} name={name}
                label={label}
                className={className}
                rules={matchToField?.name ? matchingRules : standardRules}
                extra={extra}
            >
                <Input.Password
                    placeholder={placeholder}
                    disabled={disabled}
                    onChange={onChange}
                />
            </Form.Item>
            {hideHint || skipValidationRule ? null : (
                <p className={'zcvp-no-auth-subtext max-w-screen-sm'}>
                    {t('password_requirements')}
                </p>
            )}
        </>
    );
};


export default PasswordField;
