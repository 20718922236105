import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import { GetNotificationsPresenter } from './getNotificationsPresenter';
import { GetNotificationsUseCase } from './getNotificationsUseCase';

export class GetNotificationsController {
    public execute(notifications: TableRecord[] = []) {
        const presenter = new GetNotificationsPresenter();
        const useCase = new GetNotificationsUseCase(presenter);
        return useCase.execute(notifications);
    }
    public executeCountUnread(notifications: TableRecord[] = []) {
        const useCase = new GetNotificationsUseCase();
        return useCase.countUnread(notifications);
    }
    public executeGetUnreadNotificationIds(notifications: TableRecord[] = []) {
        const useCase = new GetNotificationsUseCase();
        return useCase.getUnreadNotificationIds(notifications);
    }
    public executeGetNotificationIds(notifications: TableRecord[] = []) {
        const useCase = new GetNotificationsUseCase();
        return useCase.getNotificationIds(notifications);
    }
}