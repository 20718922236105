import {
    DashboardOutlined,
    FieldTimeOutlined,
    FormOutlined,
    HomeFilled,
    InfoCircleOutlined,
    SettingOutlined,
    ShopOutlined,
    TeamOutlined,
    ToolOutlined
} from '@ant-design/icons';
import { ZCVPMenuEntryKeys } from '../../@types/zcvp_nav.d';
import { RoutesUrls } from '../../@types/zcvp_routes_enum';
import { KNOWLEDGE_BASE_URL } from '../../lib/Constants';

export type ZCVPMenuEntryConfig = {
    path: string;
    title: string;
    icon: JSX.Element;
    subtitle?: string;
    target?: string;
    hideIconMenu?: boolean;
}
export type ZCVPMenuEntryConfigKeys = `${ZCVPMenuEntryKeys}`

export const ZCVPMenuEntryConfigLookup: {
    [key in ZCVPMenuEntryKeys]: ZCVPMenuEntryConfig
} = {
    [ZCVPMenuEntryKeys.DASHBOARD]: {
        path: `/${RoutesUrls.DASHBOARD}`,
        title: 'navigation:dashboard',
        icon: <DashboardOutlined />
    },
    [ZCVPMenuEntryKeys.REQUESTS]: {
        path: `/${RoutesUrls.REQUESTS}`,
        title: 'navigation:requests',
        icon: <FormOutlined />
    },
    [ZCVPMenuEntryKeys.VENDORS]: {
        path: `/${RoutesUrls.VENDORS}`,
        title: 'navigation:vendors',
        icon: <ShopOutlined />
    },
    [ZCVPMenuEntryKeys.PROFILE]: {
        path: `/${RoutesUrls.PROFILE}`,
        title: 'navigation:settings',
        icon: <SettingOutlined />
    },
    [ZCVPMenuEntryKeys.CONFIGURATION]: {
        path: `/${RoutesUrls.CONFIGURATION}`,
        title: 'navigation:configuration',
        icon: <ToolOutlined />
    },
    [ZCVPMenuEntryKeys.USERS]: {
        path: `/${RoutesUrls.USERS}`,
        title: 'navigation:users',
        icon: <TeamOutlined />
    },
    [ZCVPMenuEntryKeys.HELP]: {
        path: KNOWLEDGE_BASE_URL,
        title: 'navigation:help',
        subtitle: 'navigation:opens_in_new_tab',
        icon: <InfoCircleOutlined />,
        hideIconMenu: true,
        target: '_blank'
    },
    [ZCVPMenuEntryKeys.TASKS]: {
        path: `/${RoutesUrls.TASKS}`,
        title: 'navigation:tasks',
        icon: <FieldTimeOutlined />
    },
    [ZCVPMenuEntryKeys.COMPANY]: {
        path: `/${RoutesUrls.COMPANY}`,
        title: 'navigation:company',
        icon: <ShopOutlined />
    },
    [ZCVPMenuEntryKeys.HOME]: {
        path: '/',
        title: 'navigation:home',
        icon: <HomeFilled />
    }
};

const toggleNavWidthBase = 240;
const staticNavWidthBase = 48;

export const toggleNavWidth = `${toggleNavWidthBase}px`; // this must match the CSS variable $toggleNavWidth in zcvp_auth.scss
export const staticNavWidth = `${staticNavWidthBase}px`; // this must match the CSS variable $toggleNavWidth in zcvp_auth.scss
export const fullNavWidth = `${toggleNavWidthBase + staticNavWidthBase}px`; // this must match the CSS variable $toggleNavWidth in zcvp_auth.scss