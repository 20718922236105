import { TableProps, Tag } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import VendorStatus from './VendorStatus';
import VendorDetails from './VendorDetails';
import InviteExistingVendorModal from './InviteExistingVendorModal';
import TableCard from '../../components/partials/TableCard';
import { getCommonT } from '../../lib/TranslationHelper';
import { useGetAllVendorCategoriesQuery, useGetAllVendorsQuery, useGetMetaDataQuery, useLazyGetVendorQuery } from '../../slice/APISlice';
import { GetVendorUseCase } from '../../use-case/getVendors/getVendorUseCase';
import { getVendorStatus, getVendorStatusFilterOptions, getVendors } from '../../use-case/getVendors/getVendorsUseCase';
import { GetListDetailsController } from '../../use-case/getListDetails/getListDetailsController';
import { FieldQueryData, ListMap, TableColumnType, TableRecord, TableRowValue } from '../../@types/list-details/zcvp_get_list_details';
import { ListId } from '../../@types/list-details/zcvp_get_list_details_enum';
import { ApiRecord, ApiRequest, ApiVendorStatus } from '../../@types/zcvp_api_enum';
import { useParams } from 'react-router-dom';
import { useNavigateToRecord } from '../../lib/CustomHooks';
import { RoutesUrls } from '../../@types/zcvp_routes_enum';

type VendorTableProps = {
    filterValue?: string;
    onRowClick?: Function;
    tableProps?: TableProps;
    filter?: ApiRequest.FilterModel;
    lookup?: ListMap;
}

const TagValue: Function = (value?: { text: string }) => value ? <Tag>{value?.text}</Tag> : null;
const VendorStatusValue: Function = (value?: string) => <VendorStatus status={value as string} />

const getRenderFunction = (dataIndex: string): Function | undefined => {
    switch (dataIndex) {
        case ApiRecord.VendorField.VENDOR_CATEGORY:
            return TagValue;
        case ApiRecord.VendorField.STATUS:
            return VendorStatusValue;
    }
};

const getFilterFunction = (dataIndex: string, onFilter: Function): Function => {
    if (dataIndex === ApiRecord.VendorField.STATUS) {
        return (value: string, record: TableRecord) => {
            const recordValues = record[dataIndex];
            return getVendorStatus(recordValues!) === value;
        };
    }
    return onFilter;
};

const VendorTable: React.FC<VendorTableProps> = ({ filterValue, filters, setFilters, onRowClick, tableProps, filter = {} as ApiRequest.FilterModel, lookup = {} }: VendorTableProps) => {
    const { vendorId } = useParams();
    const navigate = useNavigateToRecord();
    const columnQueryData: FieldQueryData = useGetMetaDataQuery({});
    const recordQueryData = useGetAllVendorsQuery(filter);
    const vendorCategoryQueryData = useGetAllVendorCategoriesQuery({});
    const [getVendor] = useLazyGetVendorQuery();
    const [viewedRecord, setViewedRecord] = useState<TableRecord | undefined>();
    const [isOpenInvite, setIsOpenInvite] = useState(false);
    const [modalData, setModalData] = useState<TableRecord>();

    const vendorCategories = useMemo(() => new GetListDetailsController({}, vendorCategoryQueryData).execute(ListId.VENDOR_CATEGORY, '', {}), [vendorCategoryQueryData]);
    const lists = useMemo(() => ({
        [ApiRecord.VendorField.VENDOR_CATEGORY]: vendorCategories.dataSource,
        [ApiRecord.VendorField.STATUS]: getVendorStatusFilterOptions(),
        ...(lookup || {})
    }), [vendorCategories, lookup]);

    const vendorData = useMemo(() => new GetListDetailsController(columnQueryData, recordQueryData, lists)
        .execute(ListId.VENDOR, filterValue, filters)
        , [lists, columnQueryData, recordQueryData, filterValue, filters]);

    const columns = useMemo<TableColumnType[]>(() => vendorData.columns.map(column => ({
        ...column,
        render: getRenderFunction(column.dataIndex),
        onFilter: getFilterFunction(column.dataIndex, column.onFilter)
    })), [vendorData.columns]);
    const dataSource = useMemo(() => getVendors(vendorData.dataSource), [vendorData.dataSource]);

    const onClick = useCallback((record: TableRecord) => {
        const recordId = record?.id;
        recordId && (new GetVendorUseCase({
            setRecord: setViewedRecord,
            getVendor
        })).execute({ id: Number(recordId) as unknown as TableRowValue });
        navigate(RoutesUrls.VENDORS, recordId as unknown as number);
    }, [getVendor, navigate]);

    const onClose = useCallback(() => {
        setViewedRecord(undefined);
        navigate(RoutesUrls.VENDORS);
    }, [navigate]);

    const actions = [{
        name: 'invite-vendor',
        key: 'invite-vendor',
        label: 'Invite',
        show: (data: TableRecord) => data && data.status === 'PENDING_INVITATION',
        onClick: (_: string, data: TableRecord) => {
            setIsOpenInvite(true);
            setModalData(data);
        }
    }, {
        name: 'review-btn',
        key: 'review-btn',
        label: getCommonT('review'),
        show: (record: TableRecord) => [
            ApiVendorStatus.PENDING_APPROVAL_AFTER_ONBOARD,
            ApiVendorStatus.PENDING_APPROVAL_TO_ONBOARD
        ].includes(record[ApiRecord.VendorField.STATUS] as ApiVendorStatus),
        onClick: (_event: MouseEvent, record: TableRecord) => onClick(record)
    }];

    useEffect(() => {
        vendorId && onClick({ id: vendorId })
    }, [vendorId, onClick]);

    const handleOnClose = (recordId: string) => {
        // update record status
        if (recordId) {
            const record = dataSource.find(record => record.id === recordId);
            if (record) {
                record.status = ApiVendorStatus.PENDING_ONBOARDING;
            }
        }
        setIsOpenInvite(false);
    }

    return <>
        <InviteExistingVendorModal open={isOpenInvite} onClose={handleOnClose} record={modalData!} />
        <VendorDetails record={viewedRecord} onClose={onClose} />
        <TableCard
            dataSource={dataSource}
            columns={columns}
            actions={actions}
            onRow={(record: TableRecord) => ({
                onClick: () => {
                    onClick(record);
                    onRowClick && onRowClick(record);
                }
            })}
            setFilters={setFilters}
            tableProps={tableProps}/>
    </>;
};

export default VendorTable;