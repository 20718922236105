import { MessageInstance } from 'antd/lib/message/interface';
import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import { Feature, RecordType } from '../../@types/submit-record/zcvp_submit_record_enum';
import { SubmitRecordGateway } from './submitRecordGateway';
import { SubmitRecordPresenter } from './submitRecordPresenter';
import { SubmitRecordUseCase } from './submitRecordUseCase';

export class SubmitRecordController {
    private onSubmit: Function;
    private onClose: Function|undefined;
    private onSuccess: Function|undefined;
    private messageApi: MessageInstance;
    constructor({ onSubmit, onClose, onSuccess, messageApi }: { onSubmit: Function, onClose?: Function, onSuccess?: Function, messageApi: MessageInstance }) {
        this.onSubmit = onSubmit;
        this.onClose = onClose;
        this.onSuccess = onSuccess;
        this.messageApi = messageApi;
    }
    public execute(recordType: RecordType|Feature, values: TableRecord): void {
        const gateway = new SubmitRecordGateway(this.onSubmit);
        const presenter = new SubmitRecordPresenter({
            messageApi: this.messageApi,
            onClose: this.onClose,
            onSuccess: this.onSuccess
        });
        const useCase = new SubmitRecordUseCase(gateway, presenter);
        useCase.execute(recordType, values);
    }
}