import React, { PropsWithChildren } from 'react';

import { Layout } from 'antd';
import VerticalNavToggleTrigger from '../navigation/VerticalNavToggleTrigger';
import '../../styles/zcvp_auth.scss';
import '../../styles/zcvp_tabbed_page.scss';

const { Content, Header } = Layout;

type BasicLayoutProps = {
    title: string;
}
const BasicContentLayout = ({ title, children }: PropsWithChildren<BasicLayoutProps>) => (
    <div className={'zcvp-basic-page'}>
        <Header>
            <div className={'ant-tabs ant-tabs-top'}>
                <div className={'ant-tabs-nav'}>
                    <VerticalNavToggleTrigger
                        title={title}
                    />
                </div>
            </div>
        </Header>
        <Content>
            {children}
        </Content>
    </div>
);

export default BasicContentLayout;