import { AddressValues } from '../../@types/get-address/zcvp_get_address';
import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import {
    IVendorCompanyData,
    IVendorCompanyDataGateway,
    VendorRecordData,
    VendorRecordQueryData
} from '../../@types/vendor-company/zcvp_vendor_company';
import { VendorCompanyField } from '../../@types/vendor-company/zcvp_vendor_company_enum';
import { ApiRecord } from '../../@types/zcvp_api_enum';
import { CompanyType } from '../../lib/Constants';
import { isEmpty } from '../../lib/Utils';
import { BaseDataUseCase, RecordData } from '../BaseDataUseCase';
import { VendorCompanyPresenter } from './vendorCompanyPresenter';

type VendorCompanyDataUseCaseParams = {
    vendorCompany?: VendorRecordQueryData;
    isShippingSameAsBilling?: boolean;
    isCompanyDetailDisabled?: boolean;
}

export class VendorCompanyDataUseCase extends BaseDataUseCase<IVendorCompanyDataGateway, VendorCompanyPresenter> {
    public execute({ vendorCompany, isShippingSameAsBilling, isCompanyDetailDisabled }: VendorCompanyDataUseCaseParams): IVendorCompanyData {
        const vendorCompanyData = vendorCompany?.data;
        const companySize = (vendorCompanyData ? vendorCompanyData[ApiRecord.VendorField.SIZE] : null) as unknown as number;
        return this.presenter.convert({
            isShippingSameAsBilling,
            isCompanyDetailDisabled,
            isIndividual: vendorCompanyData && companySize === CompanyType.INDIVIDUAL,
            values: vendorCompanyData || null
        });
    }

    public getEditableValues ({ vendorCompany, requests }) {
        const requestData = this.getRequestData(requests);
        const { companyDetailRequestIds, legalInfoRequestIds } = this.getRequestIds(requests);
        const { companyDetailValues,  companyDetailRequestValues, legalInfoValues, legalInfoRequestValues } = this.getFieldValues(vendorCompany, requestData);
        return {
            companyDetailValues: this.mergeValues(companyDetailValues, companyDetailRequestValues),
            legalInfoValues: this.mergeValues(legalInfoValues, legalInfoRequestValues),
            companyDetailRequestValues,
            legalInfoRequestValues,
            companyDetailRequestIds,
            legalInfoRequestIds
        };
    }

    public getFields() {
        const fieldDefinition = this.presenter.fieldDefinition;
        fieldDefinition[VendorCompanyField.SHIPPING_ADDRESS].label = fieldDefinition[VendorCompanyField.ADDRESSES_EQUAL].label
        return fieldDefinition;
    }

    public getValues(fieldKeys: string[], data: VendorRecordData): RecordData {
        // get all standard values
        const values = super.getValues(fieldKeys, data as RecordData);

        // handle the address values
        const billingAddress = data[VendorCompanyField.BILLING_ADDRESS] || (data.addresses as AddressValues[])?.find((address: AddressValues) => address.isDefaultBilling);
        const shippingAddress = data[VendorCompanyField.SHIPPING_ADDRESS] || (data.addresses as AddressValues[])?.find((address: AddressValues) => address.isDefaultShipping);
        values[VendorCompanyField.BILLING_ADDRESS] = billingAddress;
        values[VendorCompanyField.SHIPPING_ADDRESS] = shippingAddress;
        if(shippingAddress && shippingAddress.isDefaultBilling) {
            values[VendorCompanyField.SHIPPING_ADDRESS] = {
                ...shippingAddress,
                id: undefined
            };
        }
        if(!isEmpty(values?.size)) {
            values.size = values?.size?.toString();
        }
        values.addressesEqual = this.checkEqualAddresses(values[VendorCompanyField.BILLING_ADDRESS] as AddressValues, values[VendorCompanyField.SHIPPING_ADDRESS] as AddressValues);

        return values;
    }
    private getRequestData(requests) {
        return requests?.reduce((mergedRequestValues, request) => {
            for(let key in request) {
                const requestValue = request[key];
                if(requestValue !== undefined) {
                    mergedRequestValues[key] = requestValue;
                }
            }
            return mergedRequestValues;
        }, {}) || {};
    }
    private checkEqualAddresses(billingAddress: AddressValues, shippingAddress: AddressValues): boolean {
        const { AddressField } = ApiRecord;
        const addressFields = [
            AddressField.ADDRESS_LINE1, AddressField.ADDRESS_LINE2, AddressField.ADDRESS_LINE3,
            AddressField.ADDRESSEE, AddressField.ATTENTION,
            AddressField.CITY, AddressField.STATE, AddressField.ZIP_CODE, AddressField.COUNTRY
        ];
        return billingAddress && shippingAddress && addressFields.every(addressField => {
            return billingAddress[addressField] === shippingAddress[addressField];
        });
    }
    private getFieldValues (vendorCompanyData: RecordData, requestData: TableRecord[]) {
        const companyDetailValues = vendorCompanyData ? this.getValues(this.gateway.companyDetailsFields, vendorCompanyData) : null;
        const companyDetailRequestValues = requestData ? this.getValues(this.gateway.companyDetailsFields, requestData) : null;

        const legalInfoValues = vendorCompanyData ? super.getValues([
            ...this.gateway.legalInformationFields,
            ...this.gateway.avatarFields
        ], vendorCompanyData) : null;
        const legalInfoRequestValues = requestData ? super.getValues([
            ...this.gateway.legalInformationFields,
            ...this.gateway.avatarFields
        ], requestData as unknown as RecordData) : null;
        return {
            companyDetailValues,  companyDetailRequestValues,
            legalInfoValues, legalInfoRequestValues
        };
    }
    private mergeValues(oldValues: TableRecord, newValues: TableRecord) {
        if(!oldValues) {
            return null;
        }

        const mergedValues = { ...oldValues };
        for(let key in newValues) {
            mergedValues[key] = newValues[key] ?? oldValues[key];
        }
        return mergedValues;
    }

    private getRequestIds (requests: TableRecord[]) {
        const companyDetailRequestIds = this.filterRequestByFields(this.gateway.companyDetailsFields, requests);
        const legalInfoRequestIds = this.filterRequestByFields([
            ...this.gateway.legalInformationFields,
            ...this.gateway.avatarFields
        ], requests);
        return { companyDetailRequestIds, legalInfoRequestIds };
    }

    private filterRequestByFields(fieldKeys, requests) {
        return requests?.filter(
            request => fieldKeys.some(fieldKey => fieldKey in request)
        ).map(request => request.requestId) || [];
    }
}
