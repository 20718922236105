import { AddressValues } from '../@types/get-address/zcvp_get_address';

/**
     * Creates an address string from the Address object of the EmployeeRecord
     * 
     * @param address The Address object from the Addresses of the Employee
     * @returns the address string in readable form
     */
export const createAddressString = (address: AddressValues | undefined): string | undefined => {
    if (!address) {
        return undefined;
    }
    let addressStr: string = '';
    if (!isEmpty(address.address1)) {
        addressStr += address.address1;
    }

    if (!isEmpty(address.address2)) {
        if (!isEmpty(addressStr)) {
            addressStr += '/';
        }
        addressStr += address.address2;
    }

    if (!isEmpty(address.city)) {
        if (!isEmpty(addressStr)) {
            addressStr += ', ';
        }

        addressStr += address.city;
    }

    if (!isEmpty(address.state)) {
        if (!isEmpty(addressStr)) {
            addressStr += ', ';
        }

        addressStr += address.state;
    }

    if (!isEmpty(address.zipCode)) {
        if (!isEmpty(addressStr)) {
            addressStr += ' ';
        }

        addressStr += address.zipCode;
    }

    if (!isEmpty(address.country)) {
        if (!isEmpty(addressStr)) {
            addressStr += ', ';
        }

        addressStr += address.country;
    }

    return addressStr;
};

export const isEmpty = (value: unknown): boolean => {
    if (value === null || value === undefined) {
        return true;
    }

    if (typeof value === 'string' || Array.isArray(value)) {
        return value.length === 0;
    }

    if (typeof value === 'object') {
        if (value instanceof Map || value instanceof Set) {
            return value.size === 0;
        }

        // Ensure the object has only string-keyed properties
        return Object.keys(value).length === 0;
    }

    // For other types (number, boolean, etc.), consider them non-empty
    return false;
};

export const isEmptyObject = (obj: unknown): boolean => !obj || Object.values(obj).filter(value => value !== undefined).length == 0;

export const getMainPath = (path: string): string => path?.split('/').filter((pathValue: string) => !!pathValue).shift() || '';