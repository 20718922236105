import { UserProfileDetails, UserRole, UserRoleDetails, UserRolesPresenter } from '../../@types/role-permissions/zcvp_role_permissions';
import { RoleUserType } from '../../@types/zcvp_user_enums';

export class GetUserRolesPresenter implements UserRolesPresenter {
    public convert(currentUserProfile: UserRoleDetails, otherUserProfiles: UserRoleDetails[]): UserProfileDetails {
        return {
            currentUserProfile: this.getRoleDetails(currentUserProfile),
            otherUserProfiles: otherUserProfiles.map(otherUserProfile => this.getRoleDetails(otherUserProfile))
        };
    }
    private getRoleDetails({ roleLabel, userRoleName, hasAdminPermission, profileIds }: UserRoleDetails): UserRole {
        const label = this.getLabel(roleLabel, userRoleName);
        const roleType = this.getRoleType(hasAdminPermission);
        return { label, roleType, profileIds };
    }
    private getLabel(roleLabel: string, userRoleName: string): string {
        return [roleLabel, userRoleName].filter(Boolean).join(' - ');
    }
    private getRoleType(hasAdminPermission: boolean): RoleUserType {
        return hasAdminPermission ? RoleUserType.ADMIN : RoleUserType.USER;
    }
}