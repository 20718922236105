import i18n from '../../i18n';
import { TranslationNS, getCommonT } from '../../lib/TranslationHelper';
import { HistoryDetail, HistoryItem, RecordHistoryPresenter } from '../../@types/history/zcvp_history';
import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import { ApiRecord, ApiSystemNoteOrigin, ApiRecordActionType } from '../../@types/zcvp_api_enum';
import { formatDateTime, formatFromNow } from '../util/dateUtil';

export class GetRecordHistoryPresenter implements RecordHistoryPresenter {
    private DEFAULT_AUTHOR = 'system';
    private translationParams = {
        ns: TranslationNS.VENDOR
    };
    public convert(history: TableRecord[]): HistoryItem[] {
        return history.map(historyItem => ({
            title: this.getTitle(historyItem),
            timeStamp: formatFromNow(historyItem[ApiRecord.SystemNoteField.DATE] as string),
            details: this.getDetails(historyItem)
        }));
    }
    private getTitle(historyItem: TableRecord): string {
        const { type, modified } = historyItem;
        const updatedByName = this.getUpdatedBy(historyItem);
        const titleParams = this.getTitleParams(updatedByName);

        const isStatusUpdated = type === ApiRecordActionType.UPDATE && ApiRecord.CommonField.STATUS in (modified as unknown as TableRecord);
        if(isStatusUpdated) {
            return i18n.t(`${TranslationNS.HISTORY}.status_updated_by_emp`, {
                ...titleParams,
                [ApiRecord.CommonField.STATUS]: (modified as unknown as TableRecord)![ApiRecord.CommonField.STATUS]
            });
        }
        return this.getTitleAction(type as string, updatedByName);
    }
    private getDetails(historyItem: TableRecord): HistoryDetail[] {
        const actionBy = this.getActionBy(historyItem);
        const actionDate = this.getActionDate(historyItem);
        const origin = this.getOrigin(historyItem);
        return [actionBy, actionDate, origin].map(({ label, children }, index) => ({
            key: index, label, children,
        }));
    }
    private getTitleAction(actionType: string, updatedByName: string): string {
        const titleParams = this.getTitleParams(updatedByName);
        const actionKey = this.getAction(actionType);
        return i18n.t(`${TranslationNS.HISTORY}.${actionKey}_by_emp`, titleParams);
    }
    private getTitleParams(updatedByName: string): TableRecord {
        return{ ...this.translationParams, employee: updatedByName || this.DEFAULT_AUTHOR };
    }
    private getActionBy(historyItem: TableRecord): HistoryDetail {
        const detailActionKey = this.getAction(historyItem.type as string);
        const actionBy = i18n.t(`${TranslationNS.HISTORY}.${detailActionKey}_by`, this.translationParams);
        return { label: actionBy, children: this.getUpdatedBy(historyItem) };
    }
    private getActionDate(historyItem: TableRecord): HistoryDetail  {
        const actionDateValue = formatDateTime(historyItem[ApiRecord.SystemNoteField.DATE] as string);
        const detailActionKey = this.getAction(historyItem[ApiRecord.SystemNoteField.TYPE] as string);
        const actionDate = i18n.t(`${TranslationNS.HISTORY}.${detailActionKey}_at`, this.translationParams);
        return { label: actionDate, children: actionDateValue }
    }
    private getOrigin(historyItem: TableRecord): HistoryDetail {
        const originValue = historyItem[ApiRecord.SystemNoteField.ORIGIN];
        const originKey = originValue === ApiSystemNoteOrigin.ZONE_PORTAL ? 'zone_portal' : 'netsuite';
        const originLabel = i18n.t(`${TranslationNS.HISTORY}.origin`, this.translationParams);
        return { label: originLabel, children: getCommonT(originKey)}
    }
    private getAction(actionTypeKey: string): string {
        const actionMap: {[key: string]: string} = {
            [ApiRecordActionType.CREATE]: 'created',
            [ApiRecordActionType.UPDATE]: 'updated',
            [ApiRecordActionType.DELETE]: 'deleted',
        };
        return actionMap[actionTypeKey as string];
    }
    private getUpdatedBy(historyItem: TableRecord): string {
        return (historyItem[ApiRecord.SystemNoteField.UPDATED_BY] as unknown as TableRecord).name as string;
    }
}