import { formatFromNow } from '../util/dateUtil';
import { ApiRecord } from '../../@types/zcvp_api_enum';
import { NotificationType } from './getNotificationsUseCase';
import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import { NotificationMap, NotificationPresenter } from '../../@types/notifications/zcvp_notifications';
const { NotificationField } = ApiRecord;

export class GetNotificationsPresenter implements NotificationPresenter {
    public convert(notifications: NotificationMap): NotificationMap {
        return {
            [NotificationType.INBOX]: this.getNotifications(notifications[NotificationType.INBOX]),
            [NotificationType.ARCHIVED]: this.getNotifications(notifications[NotificationType.ARCHIVED])
        };
    }
    private getNotifications(notifications: TableRecord[]): TableRecord[] {
        return notifications.map(notification => ({
            id: notification[NotificationField.ID],
            title: notification[NotificationField.SUBJECT],
            description: notification[NotificationField.BODY],
            recordId: notification[NotificationField.RECORD_ID],
            recordType: notification[NotificationField.RECORD_TYPE],
            isRead: notification[NotificationField.IS_READ],
            isArchived: notification[NotificationField.IS_ARCHIVED],
            timeStamp: formatFromNow(notification[NotificationField.CREATED_AT] as string)
        }));
    }
}