import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import { InitializeProfilePresenter } from '../../@types/user-profile/zcvp_user_profile';

export class InitializeProfileUseCase {
    private presenter: InitializeProfilePresenter;
    constructor(presenter: InitializeProfilePresenter) {
        this.presenter = presenter;
    }
    public execute({ formData, isLoading, isSuccess }: { formData: TableRecord, isLoading: boolean, isSuccess: boolean}) {
        if (formData && !isLoading && !isSuccess) {
            this.presenter.convert(formData);
        }
    }
}