import { ExclamationCircleFilled } from '@ant-design/icons';
import { Col, Form, Modal, Row } from 'antd';
import { RadioChangeEvent } from 'antd/lib';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useCallback, useEffect, useMemo } from 'react';

import GenericForm from '../../components/form/GenericForm';
import CardComponent from '../../components/partials/CardComponent';
import { logout } from '../../lib/AuthenticationHelper';
import { executeFieldsSubmit, executeFieldSubmit, initializeForm } from '../../use-case/updateUserProfile/userProfileController';

import { useTranslation } from 'react-i18next';
import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import {
    FieldGrouping,
    FieldGroupKeys,
    fieldsByGroupsType, UserField
} from '../../@types/user-profile/zcvp_user_profile_enum';
import { disableSubmitTrigger, FormFieldDefinition } from '../../@types/zcvp_form';
import { FormElementType } from '../../@types/zcvp_form_enum';
import { TranslationNS } from '../../lib/TranslationHelper';
import '../../styles/user_profile.scss';
const { confirm } = Modal;


export const UserProfileForm = ({ formField, formGroup, formData, updateFunction, isLoading, isSuccess }: {
    formField: fieldsByGroupsType[FieldGroupKeys],
    formGroup: FieldGrouping,
    formData: TableRecord,
    updateFunction: Function,
    isLoading: boolean,
    isSuccess: boolean,
}) => {
    const { t } = useTranslation(TranslationNS.USER, { keyPrefix: TranslationNS.USER_PROFILE });
    const [form] = Form.useForm();

    const onFieldUpdate = useCallback((event: CheckboxChangeEvent | RadioChangeEvent) => executeFieldSubmit(event, updateFunction), [updateFunction]);
    const updateMultipleFields = useCallback((updateValues: {}) => executeFieldsSubmit(updateValues, updateFunction), [updateFunction]);
    const showConfirm = useCallback((values: TableRecord) => confirm({
        content: t('confirm_email_on_save', { email: values[UserField.EMAIL] }),
        okText: t('save_and_logout_label'),
        cancelText: t('discard_new_email_label'),
        icon: <ExclamationCircleFilled />,
        className: 'confirm-modal',
        onOk: () => new Promise((resolve) => {
            const values = form.getFieldsValue();
            updateMultipleFields({ ...values, ...{ id: formData.vendorContactId } }).then((result) => {
                !result.error && logout();
                resolve("success");
            });
        }),
        onCancel: () => form.setFieldValue(UserField.EMAIL, formData[UserField.EMAIL])
    }), [form, formData, updateMultipleFields]);

    const onFinish = useCallback((values: TableRecord) => {
        if (form.getFieldValue(UserField.EMAIL) !== formData[UserField.EMAIL]) {
            showConfirm(values);
        } else {
            updateMultipleFields({ ...values, ...{ id: formData.vendorContactId } });
        }
    }, [form, formData, showConfirm, updateMultipleFields]);

    useEffect(() => initializeForm({
        form,
        formData,
        isLoading,
        isSuccess
    }), [form, formData, isLoading, isSuccess]);


    const fields = useMemo(() => {
        const formFieldTmp = [...(formField.fields || [])];
        formFieldTmp?.forEach((fieldArr) => {
            fieldArr.forEach((field) => {
                //assign a value to the field if it is an inline text field
                if (field.name && field.type === FormElementType.INLINE_TEXT) {
                    field.value = formData[field.name] || '';
                    //assign update functionality to fields that do not have actions
                } else if (!formField.actions || formField.actions.length === 0) {
                    (field as FormFieldDefinition).onChange = onFieldUpdate;
                }
            });
        });

        return formFieldTmp;
    }, [formData, formField.actions, formField.fields, onFieldUpdate]);

    if (!fields && !formField.actions) { return null; }


    return (
        <CardComponent
            title={formGroup.title}
            subtitle={formGroup.subtitle || ''}
            {...formGroup.titlePopover ? { titlePopover: formGroup.titlePopover } : {}}
        >
            <Row>
                <Col xl={12} lg={16} md={18} sm={20}>
                    <GenericForm
                        fields={formField.fields || []}
                        actions={formField.actions || []}
                        disableSubmitTrigger={formField.actions && formField.actions.length > 0 ? disableSubmitTrigger.ON_ANY_FIELD_CHANGE : undefined}
                        formProps={{ form, onFinish }}
                    />
                </Col>
            </Row>
        </CardComponent>
    );
};