export enum PortalFeature {
    VENDOR_LIST = 'vendorlist',
    CONTACT_LIST = 'contactlist',
    ACTIVE_USERS_LIST = 'activeuserslist',
    EMPLOYEE_USERS_LIST = 'employeeuserslist',
    REQUEST_LIST = 'viewrequests',
    VIEW_VENDOR_DETAIL = 'viewvendordetail',
    CREATE_VENDOR = 'createvendor',
    EDIT_PROFILE = 'editprofile',
    EDIT_VENDOR = 'editvendor',
    EDIT_BANK_DETAILS = 'editbankdetails',
    EDIT_CONTACT = 'editcontact',
    EDIT_COMPANY = 'editcompany',
    EDIT_USER = 'edituser',
    FULL_COMPANY = 'fullcompany',
    INVITE_VENDOR = 'invitevendor',
    INVITE_CONTACT = 'invitecontact',
    INVITE_EMPLOYEE = 'inviteemployee',
    APPROVE_TO_ONBOARD = 'approvetoonboard',
    APPROVE_AFTER_ONBOARD = 'approveafteronboard',
    APPROVE_REQUEST = 'approverequest',
    SUBMIT_REQUEST = 'submitrequest',
    CREATE_REQUEST = 'createrequest'
}

export enum PortalPermissionLevel {
    READ = 'read',
    CREATE = 'create',
    EDIT = 'edit',
    GENERAL = 'general',
    FULL = 'full'
}

export enum AdditionalField {
    NAME = 'name',
    DISPLAY_NAME = 'displayName',
    STATUS = 'status',
    ROLE = 'role',
    REQUESTED_ON = 'requestedOn',
    VENDOR_NAME = 'vendorName'
}