import { MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import { MenuItemType } from 'antd/lib/menu/hooks/useItems';
import React, { useCallback, useMemo } from 'react';

import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import '../../styles/zcvp_action_grp_btn.scss';
import GenericActionButton from '../form/form-action/GenericActionButton';

export type ActionDefinition = {
    key: string;
    name: string;
    label: string | Function;
    show?: (data: TableRecord) => boolean;
    onClick: Function;
    icon?: React.ReactNode;
}
type ActionItem = {
    key: string;
    name: string;
    data: TableRecord;
    label: string | React.ReactElement;
    icon: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}
type ActionGroupProps = {
    data?: TableRecord;
    actions: ActionDefinition[];
}
type ActionComponentProps = {
    items: ActionItem[];
}
const ActionDropdown: React.FC<ActionComponentProps> = ({ items }: ActionComponentProps) => <Dropdown menu={{ items: items as MenuItemType[] }} placement="bottomLeft" arrow={{ pointAtCenter: true }}>
    <Button type='link' icon={<MoreOutlined className='zcvp-action-grp-btn' />} onClick={(event) => event.stopPropagation()} />
</Dropdown>;

const ActionList: React.FC<ActionComponentProps> = ({ items }: ActionComponentProps) => <div className='flex gap-x-1'>
    {items.map(item => {
        return <GenericActionButton type='primary' {...item} />
    })}
</div>;

const ActionGroup: React.FC<ActionGroupProps> = ({ actions, data }: ActionGroupProps) => {
    const MAX_ACTION_COUNT = 2;

    const getClickHandler = useCallback((action) => (event) => {
        event.stopPropagation();
        action.onClick(event, data);
    }, [data]);
    const items: ActionItem[] = useMemo(() => actions.filter(action => action.show ? action.show(data!) : true).map(action => ({
        key: action.key,
        name: action.key,
        data: data!,
        onClick: getClickHandler(action),
        label: <a className='zcvp-action-grp-btn' onClick={getClickHandler(action)}>
            {typeof (action.label) === 'function' ? action.label(data) : action.label}
        </a>,
        icon: action.icon
    })), [actions, data, getClickHandler]);

    return items.length > MAX_ACTION_COUNT
        ? <ActionDropdown items={items} />
        : <ActionList items={items} />;
};

export default ActionGroup;