import { QuestionCircleOutlined } from '@ant-design/icons';
import { Form, Modal, Popover } from 'antd';
import { MessageInstance } from 'antd/lib/message/interface';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { RoleDetail } from '../../@types/invite-users/zcvp_invite_users';
import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import { FormFieldOption, OnChange } from '../../@types/zcvp_form';
import GenericForm from '../../components/form/GenericForm';
import GenericActionButton from '../../components/form/form-action/GenericActionButton';
import SubmitButton from '../../components/form/form-action/SubmitButton';
import i18next from '../../i18n';
import { TranslationNS, getCommonT } from '../../lib/TranslationHelper';
import { useGetAllRolesQuery, useLazyInviteEmployeeUserQuery } from '../../slice/APISlice';
import '../../styles/zcvp_popover.scss';
import { GetOptionsController } from '../../use-case/getOptions/getOptionsController';
import { GetInviteUserDataController } from '../../use-case/inviteUsers/getInviteUserDataController';
import { SendUserInvitationController } from '../../use-case/inviteUsers/sendUserInvitationController';
import { Field } from '../../use-case/inviteUsers/getInviteUserDataUseCase';

const getRoleDescription = (roleDetails: RoleDetail[]) => <div>
    {roleDetails.map((roleDetail, index) => <p key={`role-dtl-${index}`}><b>{roleDetail?.name}</b> - {roleDetail?.description}</p>)}
</div>;

const getRolePopover = (roleDetails: RoleDetail[]) => {
    const content = getRoleDescription(roleDetails);

    return <Popover placement='rightTop' overlayClassName='zcvp-role-popover' trigger='hover' content={content}>
        {i18next.t('user_management.invite_users_modal_role_label', { ns: TranslationNS.USER })}<QuestionCircleOutlined className='zcvp-role-popover-icon' />
    </Popover>;
};

type CloseEvent = React.MouseEvent<HTMLElement, MouseEvent>;
type InviteUserModalProps = {
    users?: TableRecord[];
    user?: TableRecord;
    userIds?: string[];
    open: boolean;
    onClose: (e: CloseEvent) => void;
}
const InviteUserModal: React.FC<InviteUserModalProps> = ({ user, users, userIds, open, onClose }: InviteUserModalProps) => {
    const { t } = useTranslation(TranslationNS.USER, { keyPrefix: TranslationNS.USER_MANAGEMENT });
    const [form] = Form.useForm();
    const [selectedRole, setSelectedRole] = useState<string | undefined>('');
    const { messageApi } = useOutletContext<{ messageApi: MessageInstance }>();
    const [inviteEmployeeUser] = useLazyInviteEmployeeUserQuery({});

    const rawRoles = useGetAllRolesQuery({});
    const roles = useMemo(() => new GetOptionsController(rawRoles).execute(), [rawRoles]);
    const { nameFields, roleField, roleDetails, initialValues } = useMemo(() => new GetInviteUserDataController(roles, users).execute({
        user, userIds, selectedRole
    }), [roles, users, user, userIds, selectedRole]);
    const rolePopover = useMemo(() => getRolePopover(roleDetails), [roleDetails]);

    useEffect(() => {
        initialValues[Field.ROLE] && !form.getFieldValue(Field.ROLE) && form.setFieldValue(Field.ROLE, initialValues[Field.ROLE]);
    }, [initialValues[Field.ROLE]]);

    const onRoleChange = useCallback((_value: string, option: FormFieldOption) => setSelectedRole(option.key), []) as OnChange;
    const onSubmit = useCallback((event: CloseEvent) => {
        (new SendUserInvitationController({
            messageApi, inviteCallback: inviteEmployeeUser
        })).execute(form.getFieldsValue(), user);
        onClose(event);
    }, [form, messageApi, inviteEmployeeUser, user, onClose]);

    const roleFieldWithHandler = useMemo(() => ({
        ...roleField,
        label: rolePopover,
        onChange: onRoleChange
    }), [roleField, rolePopover, onRoleChange]);

    return <Modal
        open={open}
        title={t('invite_users_modal_title')}
        onCancel={onClose}
        footer={[
            <GenericActionButton key='back' label={getCommonT('cancel')} name='cancel-btn' onClick={onClose} />,
            <SubmitButton key='submit' label={getCommonT('invite')} name='invite-btn' onClick={onSubmit} />,
        ]}
    >
        <GenericForm
            formProps={{
                form,
                initialValues
            }}
            fields={[
                nameFields,
                [roleFieldWithHandler]
            ]}
            actions={[]} />
    </Modal>;
};

export default InviteUserModal;