import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { UserState, UserStateKeyTypes } from '../@types/zcvp_state';

export const UserSliceInitialState = {
    companyId: null,
    companyName: null,
    firstName: null,
    roleId: null,
    roleName: null,
    userId: null,
    userName: null,
    userEmail: null,
    vendorId: null,
    vendorName: null,
    vendorContactId: null,
    subsidiaryId: null,
    isVendor: false,
    tutorialProgress: null,
    perms: [],
    otherProfiles: []
} as UserState;

const UserSlice = createSlice({
    name: 'user',
    initialState: UserSliceInitialState,
    reducers: {
        updateUserState(state, action: PayloadAction<UserState>) {
            if (action.payload) {
                const payloadKeys = Object.keys(action.payload) as UserStateKeyTypes[];
                payloadKeys?.forEach((key: UserStateKeyTypes) => {
                    const value = action.payload[key];
                    if (state[key] === value) {
                        return;
                    }
                    (state as Record<typeof key, typeof value>)[key] = value;
                });
                state.isVendor = !!state.vendorId;
            }
        }
    }
});

export const { updateUserState } = UserSlice.actions;
export default UserSlice.reducer;