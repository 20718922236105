import { Modal } from 'antd';
import { MessageInstance } from 'antd/es/message/interface';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import { ZCVPApiResponse } from '../../@types/zcvp_api';
import { ActionType, FormFieldType } from '../../@types/zcvp_form_enum';
import GenericForm from '../../components/form/GenericForm';
import { showErrorMessage, showSuccessMessage } from '../../lib/CustomHooks';
import { TranslationNS, getCommonT } from '../../lib/TranslationHelper';
import { useUpdateIntegrationDataMutation } from '../../slice/APISlice';

type ChangeKeyModalProps = {
    keySpec: ModalKeySpec;
    open: boolean;
    onClose: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export type ModalKeySpec = { id: number, keyName: string, label: string };

const ChangeKeyModal: React.FC<ChangeKeyModalProps> = ({ keySpec, open, onClose }) => {
    const { t } = useTranslation(TranslationNS.USER, { keyPrefix: TranslationNS.INTEGRATION });
    const [modal, contextHolder] = Modal.useModal();
    const { messageApi } = useOutletContext<{ messageApi: MessageInstance }>();
    const [updateIntegration] = useUpdateIntegrationDataMutation();

    const onSubmit = useCallback((values: TableRecord) => {
        modal.confirm({
            title: t('change_key_confirm_title'),
            okText: getCommonT('yes'),
            cancelText: getCommonT('no'),
            onOk: () => {
                const submitObj = {
                    id: keySpec.id,
                    details: {
                        [keySpec.keyName]: values.keyValue
                    }
                };
                updateIntegration(submitObj).then((response: ZCVPApiResponse) => {
                    if (response?.error) {
                        showErrorMessage(response.error.message, messageApi);
                        onClose();
                        return;
                    }
                    showSuccessMessage('Key changed successfully', messageApi);
                    onClose();
                })
            },
            onCancel: () => {
                onClose();
            },
        });
    }, [messageApi, updateIntegration, keySpec, modal, onClose]);

    return <>
        {contextHolder}
        <Modal
            key={`change_key_${keySpec}`}
            open={open}
            title={t('change_key_title', { key: keySpec.label })}
            footer={[]}
            onCancel={onClose}
            destroyOnClose
        >
            <GenericForm
                formProps={{
                    onFinish: (values) => {
                        onSubmit(values);
                    }
                }}
                fields={[
                    [{
                        name: 'keyValue',
                        label: t('change_key_new_key_label', { key: keySpec.label }),
                        type: FormFieldType.TEXT,
                        required: false,
                    }]
                ]}
                actions={[{
                    type: ActionType.CANCEL,
                    definition: {
                        label: getCommonT('cancel'),
                        key: 'cancel-change',
                        name: 'cancel-change',
                        onClick: () => onClose(),
                    },
                }, {
                    type: ActionType.SUBMIT,
                    definition: {
                        label: getCommonT('change'),
                        key: 'change-key',
                        name: 'change-key',
                    },
                }]}
            />
        </Modal>
    </>;
}

export default ChangeKeyModal;
