import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { RoutesUrls } from '../../@types/zcvp_routes_enum';
import { TranslationNS } from '../../lib/TranslationHelper';
import { useGetCompanyDataQuery } from '../../slice/APISlice';

export const SupportEmailLink = () => {
    const { t } = useTranslation(TranslationNS.VENDOR);
    const {
        data: {
            supportEmail
        } = {} } = useGetCompanyDataQuery({});
    if (!supportEmail) { return null; }

    return (
        <Link to={`${RoutesUrls.CONTACT_US}${supportEmail}`}>{t('contact_us_link')}</Link>
    );
};