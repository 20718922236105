import { IVendorCompanyDataGateway, VendorCompanyFieldKey, VendorRecordQueryData } from '../../@types/vendor-company/zcvp_vendor_company';
import { VendorCompanyField } from '../../@types/vendor-company/zcvp_vendor_company_enum';

export class VendorCompanyGateway implements IVendorCompanyDataGateway {

    public vendorCompany: VendorRecordQueryData;

    public companyDetailsFields: VendorCompanyFieldKey[] = [
        VendorCompanyField.COMPANY_NAME,
        VendorCompanyField.COMPANY_SIZE,
        VendorCompanyField.CATEGORY,
        VendorCompanyField.BILLING_ADDRESS,
        VendorCompanyField.SHIPPING_ADDRESS,
        VendorCompanyField.COMPANY_EMAIL,
        VendorCompanyField.COMPANY_URL,
        VendorCompanyField.PHONE_NUMBER,
        VendorCompanyField.PHONE_NUMBER_ALT,
    ];

    public legalInformationFields: VendorCompanyFieldKey[] = [
        VendorCompanyField.LEGAL_NAME,
        VendorCompanyField.TAX_ID,
        VendorCompanyField.TAX_REG_NUMBER,
        VendorCompanyField.PRIMARY_CURRENCY,
    ];

    public avatarFields: VendorCompanyFieldKey[] = [
        VendorCompanyField.COMPANY_AVATAR,
    ];

    constructor(vendorCompany: VendorRecordQueryData) {
        this.vendorCompany = vendorCompany;
    }
}