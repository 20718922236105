import { MessageInstance } from 'antd/lib/message/interface';
import React, { useCallback } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { FormFieldValues } from '../../@types/invite/zcvp_invite';
import { InvitationType } from '../../@types/invite/zcvp_invite_enum';
import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import { showErrorMessage } from '../../lib/CustomHooks';
import { getCommonT } from '../../lib/TranslationHelper';
import { useLazyConfirmInvitationQuery } from '../../slice/APISlice';
import { ConfirmInvitationController } from '../../use-case/invitation/confirmInvitationController';
import ActivationForm from './ActivationForm';

type InvitationFormProps = {
    data: TableRecord;
    invitationType: InvitationType;
}
const InvitationForm: React.FC<InvitationFormProps> = ({ data, invitationType }: InvitationFormProps) => {
    const navigate = useNavigate();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const {invitationId: randomKey} = useParams();
    const {messageApi} = useOutletContext<{ messageApi: MessageInstance }>();
    const [confirmInvitation] = useLazyConfirmInvitationQuery({});

    const confirmDetails = useCallback(async (values: FormFieldValues) => {
        if (!executeRecaptcha) {
            showErrorMessage(getCommonT('invalid_data'), messageApi);
            return;
        }
        
        const captchaToken = await executeRecaptcha('invitationFormSubmit');
        if (captchaToken) {
            return new ConfirmInvitationController(navigate, messageApi, confirmInvitation)
            .execute(invitationType, { ...values, captchaToken: captchaToken, randomKey: randomKey! })
        }
    }
    , [invitationType, randomKey, messageApi, navigate, confirmInvitation, executeRecaptcha]);

    return data ? <ActivationForm submitForm={confirmDetails} passwordExists={!!data?.passwordExists}/> : null;
}

export default InvitationForm;