import { BankDetailsFieldKey, IVendorCompanyBankDetailsGateway, VendorBankAccountsData, VendorRecordQueryData } from '../../@types/vendor-company/zcvp_vendor_company';
import { VendorBankDetailsField } from '../../@types/vendor-company/zcvp_vendor_company_enum';

export class VendorCompanyBankDetailsGateway implements IVendorCompanyBankDetailsGateway {

    public vendorRecord: VendorRecordQueryData;
    public bankAccounts: VendorBankAccountsData[];

    public bankDetailsFields: BankDetailsFieldKey[] = [
        VendorBankDetailsField.ACCOUNT_NAME,
        VendorBankDetailsField.ACCOUNT_NUMBER,
        VendorBankDetailsField.BANK_NUMBER,
        VendorBankDetailsField.BRANCH_NUMBER,
        VendorBankDetailsField.TYPE,
        VendorBankDetailsField.ID,
        // VendorBankDetailsField.PAYMENT_FILE_FORMAT,
        // VendorBankDetailsField.PAYMENT_DESCRIPTION,
    ];

    constructor(vendorRecord: VendorRecordQueryData) {
        this.vendorRecord = vendorRecord;
        this.bankAccounts = vendorRecord.data ? vendorRecord.data!.bankAccounts as VendorBankAccountsData[] : [];
    }
}