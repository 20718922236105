import React from 'react';
import CheckboxField from './CheckboxField';
import DateField from './DateField';
import EmailField from './EmailField';
import NumberField from './NumberField';
import PasswordField from './PasswordField';
import RadioField from './RadioField';
import SelectField from './SelectField';
import TextAreaField from './TextAreaField';
import TextField from './TextField';
import UrlField from './UrlField';

import { Input } from 'antd';
import { AddressValues } from '../../../@types/get-address/zcvp_get_address';
import { AddressOnChange, CheckboxOnChange, DateOnChange, FieldOnChange, FormFieldDefinition, InputOnChange, NumberOnChange, RadioOnChange, SelectValue, TextAreaOnChange } from '../../../@types/zcvp_form';
import { FormFieldType } from '../../../@types/zcvp_form_enum';
import i18next from '../../../i18n';
import AddressField from './AddressField';
import NumericField from './NumericField';
import PhoneNumberField from './PhoneNumberField';
import FileField from './FileField';

/**
 * Creates a form field given a type
 *
 * @param {FormFieldDefinition} props form field definition
 * @returns {React.FC} form field
 */
const FormField = (props: FormFieldDefinition) => {
    const { options, subText } = props;
    const disabled = getBooleanValue(props.disabled);
    const required = getBooleanValue(props.required);
    const requiredMessage = i18next.t('common:missing_required_field', { fieldName: props.label });
    const propsProps = {
        name: props.name,
        label: props.label,
        placeholder: props.placeholder,
        disabled,
        required,
        requiredMessage,
        extra: props.extra,
        maxLength: props.maxLength,
        ...(props.hideHint ? { hideHint: props.hideHint } : {}),
        ...(props.className ? { className: props.className } : {}),
        ...(props.extra ? { extra: props.extra } : {}),
        ...(props.skipValidationRule ? { skipValidationRule: props.skipValidationRule } : {}),
        ...(props.otherProps || {})
    };
    const getFieldElement = () => {
        switch (props.type) {
            case FormFieldType.CHECKBOX:
                return <CheckboxField {...propsProps} checkboxLabel={props.checkboxLabel} defaultValue={!!props.value} onChange={props.onChange as CheckboxOnChange} />;
            case FormFieldType.RADIO:
                return <RadioField {...propsProps} defaultValue={props.value?.toString()} options={options || []} onChange={props.onChange as RadioOnChange} />;
            case FormFieldType.NUMBER:
                return <NumberField {...propsProps} defaultValue={props.value?.toString()} onChange={props.onChange as NumberOnChange} />;
            case FormFieldType.NUMERIC:
                return <NumericField {...propsProps} defaultValue={props.value?.toString()} onChange={props.onChange as InputOnChange} />;
            case FormFieldType.PHONE:
                return <PhoneNumberField {...propsProps} defaultValue={props.value?.toString()} onChange={props.onChange as InputOnChange} />;
            case FormFieldType.DATE:
                return <DateField {...propsProps} defaultValue={props.value?.toString()} onChange={props.onChange as DateOnChange} />;
            case FormFieldType.DATETIME:
                return <DateField {...propsProps} defaultValue={props.value?.toString()} onChange={props.onChange as DateOnChange} showTime={true} />;
            case FormFieldType.EMAIL:
                return <EmailField {...propsProps} onChange={props.onChange as InputOnChange} />;
            case FormFieldType.MULTIEMAIL:
                return <EmailField {...propsProps} defaultValue={props.value?.toString()} onChange={props.onChange as InputOnChange} isMultiple />;
            case FormFieldType.PASSWORD:
                return <PasswordField {...propsProps} onChange={props.onChange as InputOnChange} />;
            case FormFieldType.SELECT:
                return <SelectField {...propsProps} defaultValue={props.value as SelectValue} options={options || []} onChange={props.onChange as FieldOnChange} />;
            case FormFieldType.MULTISELECT:
                return <SelectField {...propsProps} defaultValue={props.value as SelectValue} options={options || []} onChange={props.onChange as FieldOnChange} mode={'multiple'} maxTagCount={props.maxTagCount} />;
            case FormFieldType.TEXT:
                return <TextField {...propsProps} onChange={props.onChange as InputOnChange} />;
            case FormFieldType.TEXTAREA:
                return <TextAreaField {...propsProps} onChange={props.onChange as TextAreaOnChange} />;
            case FormFieldType.ADDRESS:
                return <AddressField {...propsProps} defaultValue={props.value as AddressValues} form={props.form} onChange={props.onChange as AddressOnChange} />;
            case FormFieldType.HIDDEN:
                return <Input {...propsProps} type={'hidden'} />;
            case FormFieldType.WEB_ADDRESS:
                return <UrlField {...propsProps} />;
            case FormFieldType.FILE:
                return <FileField {...propsProps} />;
            default:
                return <>{props.label}</>;
        }
    };

    const getSubText = () => subText ? <p className={'zcvp-form-field zcvp-no-auth-subtext'}>{subText}</p> : null;

    return <>
        {getFieldElement()}
        {getSubText()}
    </>;
};

const getBooleanValue = (booleanFn?: boolean | Function) => typeof booleanFn === 'function' ? booleanFn() : booleanFn;

export default FormField;