import { StorageKey } from '../@types/zcvp_routes_enum';
import { useEffect, useState } from 'react';
import { dispatchLocalStorageEvent } from './CustomHooks';
import { useSelector } from 'react-redux';
import { AppState } from '../@types/zcvp_state';
import { selectAPIReturnedAuthenticationError } from '../slice/APISlice';
import { useLocation } from 'react-router-dom';
import { ManageAuthenticationController } from '../use-case/authentication/manageAuthenticationController';

enum localStorageEvent {
    LOGIN = 'zcvp-local-storage-login',
    LOGOUT = 'zcvp-local-storage-logout',
}

export const useRedirectBasedOnAuthorization = () => {
    const path = useLocation();
    const hasApiError = useSelector((state: AppState) => selectAPIReturnedAuthenticationError(state));
    const isAuthorized = useGetAuthentication();

    const [redirecting, setRedirecting] = useState(false);

    useEffect(() => new ManageAuthenticationController(logout, setRedirecting).execute({
        isAuthorized, hasApiError, pathName: path.pathname
    }), [hasApiError, isAuthorized, path.pathname]);

    return redirecting;
};
/**
 * Returns true if a user in authenticated, false otherwise. Updates when a login event is dispatched
 */
export const useGetAuthentication = () => {
    const [authentication, setAuthentication] = useState(localStorage.getItem(StorageKey.TOKEN));
    const setAuthenticationToLocalStorage = () => {
        setAuthentication(localStorage.getItem(StorageKey.TOKEN));
    };
    useEffect(() => {
        window.addEventListener(localStorageEvent.LOGOUT, () => setAuthenticationToLocalStorage());
        window.addEventListener(localStorageEvent.LOGIN, () => setAuthenticationToLocalStorage());

        return () => {
            window.removeEventListener(localStorageEvent.LOGOUT, () => setAuthenticationToLocalStorage());
            window.removeEventListener(localStorageEvent.LOGIN, () => setAuthenticationToLocalStorage());
        };
    }, []);

    return authentication;
};
/**
 * logs user out of frontend. Unsets local storage and dispatches event
 */
export const logout = () => {
    localStorage.setItem(StorageKey.TOKEN, '');
    localStorage.setItem(StorageKey.USER_ID, '');
    dispatchLocalStorageEvent(localStorageEvent.LOGOUT);
};
/**
 * logs user in to frontend. Sets local storage and dispatches event
 */
export const login = (token: string, userId: string) => {
    localStorage.setItem(StorageKey.TOKEN, token);
    localStorage.setItem(StorageKey.USER_ID, userId);
    dispatchLocalStorageEvent(localStorageEvent.LOGIN);
};

export const resetDeepLink = () => {
    const deepLink = localStorage.getItem(StorageKey.DEEP_LINK);
    localStorage.setItem(StorageKey.DEEP_LINK, '');
    deepLink && (window.location.href = deepLink);
};