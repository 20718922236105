import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Avatar, Dropdown, Skeleton } from 'antd';
import { DeleteOutlined, EllipsisOutlined, FileOutlined } from '@ant-design/icons';

import VendorRequestTabs from './VendorRequestTabs';
import VendorRequestHeader from './VendorRequestHeader';
import VendorRequestDetailFooter from './VendorRequestDetailFooter';
import DetailsDrawer, { CloseEvent } from '../../components/drawer/DetailsDrawer';
import { GetVendorRequestActionController } from '../../use-case/vendorRequests/getVendorRequestActionController';
import { UserState } from '../../@types/zcvp_state';
import { TableRecord, TableRowValue } from '../../@types/list-details/zcvp_get_list_details';
import '../../styles/zcvp_vendor_details.scss';
import { useTranslation } from 'react-i18next';
import { TranslationNS } from '../../lib/TranslationHelper';
import { SubmitRecordController } from '../../use-case/submitRecord/submitRecordController';
import { useCancelRequestMutation, useGetRequestQuery } from '../../slice/APISlice';
import { useOutletContext } from 'react-router-dom';
import { MessageInstance } from 'antd/lib/message/interface';
import { Feature } from '../../@types/submit-record/zcvp_submit_record_enum';

export const VendorRequestInfo = ({ record, onVendorDetailClick }: { record: TableRecord, onVendorDetailClick: Function }) => {
    return <>
        <VendorRequestHeader record={record} onVendorDetailClick={onVendorDetailClick}/><br />
        <VendorRequestTabs record={record}/>
    </>;
};

const CancelRequestDropdown = ({ record, onClose }: { record: TableRecord, onClose: CloseEvent }) => {
    const { messageApi } = useOutletContext<{ messageApi: MessageInstance }>();
    const { t } = useTranslation(TranslationNS.VENDOR, { keyPrefix: TranslationNS.VENDOR_REQUEST });
    const [cancelRequest] = useCancelRequestMutation();

    const onClick = useCallback((event) => {
        new SubmitRecordController({
            messageApi,
            onSubmit: cancelRequest
        }).execute(Feature.VENDOR_REQUEST_CANCELLATION, {
            id: record?.id as unknown as TableRowValue
        });
        onClose(event);
    }, [cancelRequest, messageApi, record?.id, onClose]);

    const items = [{
        key: 'cancel-request-btn',
        label: t('cancel_request'),
        danger: true,
        icon: <DeleteOutlined />,
    }];
    return <Dropdown menu={{ items, onClick }} trigger={['click']}>
        <EllipsisOutlined />
    </Dropdown>
}

const VendorRequestDetails = ({ record, onClose, onVendorDetailClick }: { record: TableRecord, onClose: CloseEvent, onVendorDetailClick: Function }) => {
    const { data: request } = useGetRequestQuery({ requestId: record?.id }, { skip: !record?.id });
    const [requestRecord, setRequestRecord] = useState<TableRecord|undefined>();

    const user = useSelector((state: { user: UserState }) => state.user);
    const requestActionController = useMemo(() => new GetVendorRequestActionController(), []);
    const canCancelNewRequest = useMemo(() => requestActionController.executeCanCancelRequest(request, user), [requestActionController, request, user]);

    const onSubmitClick = useCallback(() => setRequestRecord(undefined), []);
    const onDrawerClose = useCallback((event) => {
        onSubmitClick();
        onClose(event);
    }, [onSubmitClick, onClose]);

    useEffect(() => request && setRequestRecord(request), [request]);

    return <DetailsDrawer
        record={requestRecord || record}
        pageTitle={requestRecord?.title as string}
        titleIcon={<Avatar size='small' className='zcvp-venddetails-img-icon' icon={<FileOutlined />} />}
        footer={<VendorRequestDetailFooter record={requestRecord} onClick={onSubmitClick}/>}
        onClose={onDrawerClose}
        otherProps={{
            extra: canCancelNewRequest ? [
                <CancelRequestDropdown
                    key='cancel-request-dropdown'
                    record={requestRecord}
                    onClose={onDrawerClose}/>
            ] : []
        }}
    >
        <Skeleton loading={!requestRecord}>
            <VendorRequestInfo record={requestRecord} onVendorDetailClick={onVendorDetailClick} />
        </Skeleton>
    </DetailsDrawer>;
}

export default VendorRequestDetails;