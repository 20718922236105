import { FormElementType } from '../../@types/zcvp_form_enum';
import { Divider } from 'antd';
import React from 'react';
import { FormElementDefinition } from '../../@types/zcvp_form';
import InlineField from './form-field/InlineField';

const FormElement = (props: FormElementDefinition): JSX.Element|null => {
    switch (props.type) {
    case FormElementType.DIVIDER:
        return <Divider />;
    case FormElementType.INLINE_TEXT:
        return <InlineField {...props} />;
    default:
        return null;
    }
};

export default FormElement;