import { FormInstance } from 'antd';
import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';

export class InitializeProfilePresenter {
    private view: FormInstance;
    constructor(view: FormInstance) {
        this.view = view;
    }
    convert(userData: TableRecord) {
        this.view.setFieldsValue(userData);
    }
}