import { isEmpty } from '../../lib/Utils';
import { AddressValues } from '../../@types/get-address/zcvp_get_address';
import { TableRecord, TableRowValue } from '../../@types/list-details/zcvp_get_list_details';
import { VendorBankDetailProps, VendorCompanyUpdateGateway, VendorCompanyUpdatePresenter, VendorCompanyUpdateProps } from '../../@types/vendor-company/zcvp_vendor_company';
import { VendorCompanyField } from '../../@types/vendor-company/zcvp_vendor_company_enum';
import { ZCVPApiResponse } from '../../@types/zcvp_api';
import { ApiRecord } from '../../@types/zcvp_api_enum';

export enum VendorUpdateType {
    COMPANY_DETAILS = 'Company_Details',
    LEGAL_INFORMATION = 'Legal_Info',
    BANK_DETAILS = 'Bank_Details'
}

export class UpdateVendorCompanyUseCase {
    private gateway: VendorCompanyUpdateGateway;
    private presenter: VendorCompanyUpdatePresenter|undefined;
    private onRequestSubmit: Function|undefined;
    constructor(gateway: VendorCompanyUpdateGateway, presenter?: VendorCompanyUpdatePresenter, onRequestSubmit?: Function) {
        this.gateway = gateway;
        this.presenter = presenter;
        this.onRequestSubmit = onRequestSubmit;
    }
    public updateCompanyDetails({ isEdited, updateType, recordId, vendorId, fields, oldValues, newValues }: VendorCompanyUpdateProps): void {
        const companyValues = this.getValuesToSubmit(vendorId, oldValues, newValues);
        isEdited
            ? this.saveRequest({
                updateType, recordId, fields, oldValues, values: companyValues
            })
            : this.saveVendor({
                updateType, vendorId, values: companyValues
            });
    }
    public updateLegalInformation ({ isEdited, updateType, recordId, vendorId, fields, oldValues, newValues }: VendorCompanyUpdateProps): void {
        isEdited
            ? this.saveRequest({
                updateType, recordId, fields, oldValues, values: {
                    [VendorCompanyField.ID]: recordId,
                    ...newValues
                }
            })
            : this.saveVendor({
                updateType, vendorId, values: newValues
            });
    }
    public updateBankDetails ({ isEdited, updateType, recordId, subsidiaryId, vendorId, fields, oldValues, newValues, primaryAccount }: VendorCompanyUpdateProps): void {
        const bankDetails = this.getBankDetailsToSubmit({ recordId, subsidiaryId, vendorId, newValues, primaryAccount })
        isEdited
            ? this.saveRequest({
                updateType, recordId, fields, oldValues, values: bankDetails
            })
            : this.saveBankDetails({
                updateType, bankDetails
            });
    }
    private saveRequest = ({ updateType, fields, oldValues, values }: { updateType: string, recordId: number, oldValues, values: TableRecord }): void => {
        const descriptionDetails = this.getDescriptionDetails(fields, oldValues, values);
        this.gateway.submitVendorRequest({ updateType, values, descriptionDetails }).then(response => {
            this.onRequestSubmit && this.onRequestSubmit(response);
            this.presenter!.convert({
                updateType: `request_${updateType}`, responses: [response]
            });
        });
    }
    private saveVendor({ updateType, vendorId, values }: { updateType: string, vendorId: number, values: TableRecord }): void {
        const undefinedValues = ({
            [VendorCompanyField.ADDRESSES_EQUAL]: undefined,
            [VendorCompanyField.BILLING_ADDRESS]: undefined,
            [VendorCompanyField.SHIPPING_ADDRESS]: undefined
        } as unknown as TableRecord);
        const responses = this
            .saveVendorAddresses(values)
            .concat([
                this.gateway.updateVendorData({
                    ...values,
                    id: vendorId as unknown as TableRowValue,
                    ...undefinedValues
                })
            ]);

        this.presenter!.convert({
            updateType: `vendor_${updateType}`, responses
        });
    }
    private saveBankDetails({ updateType, bankDetails }: { updateType: string, bankDetails: TableRecord }): void {
        const responses = isEmpty(bankDetails.id)
            ? this.gateway.createBankDetails(bankDetails)
            : this.gateway.updateBankDetails(bankDetails);

        this.presenter!.convert({
            updateType: `vendor_${updateType}`, responses: [responses]
        });
    }
    private saveVendorAddresses(values: TableRecord): Promise<ZCVPApiResponse>[] {
        const addresses: AddressValues[] = [values[VendorCompanyField.BILLING_ADDRESS], values[VendorCompanyField.SHIPPING_ADDRESS]] as unknown as AddressValues[];
        const responses = addresses
            .filter(value => !!value)
            .map((address) => this.saveAddress(address as unknown as AddressValues));
        return responses;
    }
    private saveAddress(address: AddressValues): Promise<ZCVPApiResponse> {
        const _address = { ...address, createdAt: undefined, updatedAt: undefined };

        return isEmpty(address.id)
            ? this.gateway.createVendorAddress({ ..._address })
            : this.gateway.updateVendorAddress({
                ..._address,
                [ApiRecord.AddressField.VENDOR_ID]: undefined
            });
    }
    private getValuesToSubmit (vendorId: number, oldValues: TableRecord, newValues: TableRecord): TableRecord {
        const values = {...newValues};
        const billingAddress = values[VendorCompanyField.BILLING_ADDRESS] as unknown as AddressValues;
        const shippingAddress = values[VendorCompanyField.SHIPPING_ADDRESS] as unknown as AddressValues;
        if (billingAddress) {
            const oldBillingAddressId = (oldValues[VendorCompanyField.BILLING_ADDRESS] as unknown as AddressValues)?.id;
            billingAddress.id = oldBillingAddressId;
            billingAddress.vendorId = vendorId;
            billingAddress.isDefaultBilling = true;
            billingAddress.isDefaultShipping = false;
        }
        if (values.shipping_address) {
            const shippingAddressId = (oldValues[VendorCompanyField.SHIPPING_ADDRESS] as unknown as AddressValues)?.id;
            shippingAddress.id = shippingAddressId;
            shippingAddress.vendorId = vendorId;
            shippingAddress.isDefaultShipping = true;
            shippingAddress.isDefaultBilling = false;
        }

        const vendorCategoryId = values[VendorCompanyField.CATEGORY];
        const companySize = values[VendorCompanyField.COMPANY_SIZE];
        return {
            ...values,
            [VendorCompanyField.ID]: vendorId as unknown as TableRowValue,
            [VendorCompanyField.COMPANY_SIZE]: (companySize ? Number(values.size) : companySize) as unknown as TableRowValue,
            [VendorCompanyField.CATEGORY]: (vendorCategoryId ? Number(vendorCategoryId) : vendorCategoryId) as unknown as TableRowValue,
            [VendorCompanyField.ADDRESSES_EQUAL]: (undefined) as unknown as TableRowValue
        };
    }
    private getBankDetailsToSubmit({ recordId, subsidiaryId, vendorId, newValues, primaryAccount }: VendorBankDetailProps): TableRecord {
        const bankDetails = { ...newValues, id: recordId } as unknown as TableRecord;
        if (!recordId) {
            delete bankDetails.id;
            bankDetails.vendorId = vendorId as unknown as TableRowValue;
            bankDetails.subsidiaryId = subsidiaryId as unknown as TableRowValue;

            if (!primaryAccount) {
                bankDetails.type = 'PRIMARY';
            } else {
                bankDetails.type = 'SECONDARY';
            }
        }
        return bankDetails;
    }
    private getDescriptionDetails(fields, oldValues = {}, newValues) {
        const updatedValues = this.getRecordValues(newValues);
        return Object.keys(updatedValues)
            .filter(fieldId => fields[fieldId] && newValues[fieldId] !== undefined)
            .map(fieldId => ({
                fieldId,
                field: fields[fieldId],
                oldValue: oldValues[fieldId],
                newValue: newValues[fieldId]
            }))
    }
    private getRecordValues(values: TableRecord): TableRecord {
        const recordValues = {};
        for(let fieldId in values) {
            const value = values[fieldId];
            if(value !== undefined) {
                (recordValues as TableRecord)[fieldId] = value;
            }
        }
        return recordValues;
    }
}