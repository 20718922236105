import { MessageInstance } from "antd/lib/message/interface";
import { InvitationConfirmationPresenter } from "../../@types/invite/zcvp_invite";
import { ZCVPApiErrorResponse } from "../../@types/zcvp_api";
import { ShowMessageTypes } from "../../@types/zcvp_custom_hooks";
import { showMessage } from "../../lib/CustomHooks";

export class ConfirmInvitationPresenter implements InvitationConfirmationPresenter{
    private navigate: Function;
    private messageApi: MessageInstance;
    constructor(navigate: Function, messageApi: MessageInstance) {
        this.navigate = navigate;
        this.messageApi = messageApi;
    }
    convert({ isSuccess, isError, error }: { isSuccess: boolean, isError: boolean, error?: ZCVPApiErrorResponse }) {
        if(isSuccess) {
            this.navigate('success');
        }
        if(isError) {
            showMessage(ShowMessageTypes.ERROR, error?.message as string, this.messageApi);
        }
    }
}