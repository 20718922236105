import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Progress } from 'antd';

import { LoaderState } from '../../slice/LoaderSlice';
import { RequestInterceptor } from '../../service/RequestInterceptor';

import '../../styles/zcvp_loader.scss';

const Loader: React.FC = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        new RequestInterceptor(dispatch).setup();
    }, []);
    const {
        isLoading,
        percentCompleted
    } = useSelector((state: {loader: LoaderState}) => state.loader);
    
    return isLoading ? <Progress percent={percentCompleted} showInfo={false} className={'zcvp-loader'}/> : null;
};

export default Loader;