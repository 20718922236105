import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
        fallbackLng: "en",
        debug: false,
        ns: ['common', 'navigation', 'vendor', 'user', 'authentication', 'onboarding', 'dashboard'],
        defaultNS: 'common',
        initImmediate: false,
        detection: {
            order: ['queryString', 'cookie', 'localStorage', 'htmlTag', 'sessionStorage'],
            lookupQuerystring: 'lng',
            lookupCookie: 'lng',
            lookupLocalStorage: 'lng',
            lookupSessionStorage: 'lng',
            caches: ['cookie', 'localStorage'],
        },
        backend: {
            loadPath: '/locales/{{ns}}/{{lng}}.json',
        }

    });

export default i18n;