import { CompanyActionPresenter, GetCancelButtonProps, SaveVendorCompanyProps } from "../../@types/vendor-company/zcvp_vendor_company";
import { FormActionProps } from "../../@types/zcvp_form";

export class VendorCompanyActionUseCase {
    private presenter: CompanyActionPresenter;
    constructor(presenter: CompanyActionPresenter) {
        this.presenter = presenter;
    }
    public getCancelButton(params: GetCancelButtonProps): FormActionProps[] {
        return this.presenter.convertCancelButton(params);
    }
    public saveVendorCompany({ isEdited, recordId, values, saveFn }: SaveVendorCompanyProps): void {
        isEdited ? this.presenter.showConfirmation(isEdited, recordId, values) : saveFn({ isEdited, recordId, values });
    }
}