import {
    Field,
} from '../../@types/onboarding/zcvp_onboarding';
import { FieldDefinitionType, FormFieldDefinition } from '../../@types/zcvp_form';
import { FormFieldType } from '../../@types/zcvp_form_enum';
import { COMPANY_SIZE_OPTIONS } from '../../lib/Constants';

export class OnboardingGateway {
    /**
     * Field definition lookup. Name matches the id that the API is expecting
     * @private
     */
    private FieldDefinition: FieldDefinitionType = {
        [Field.FIRST_NAME]: {
            name: Field.FIRST_NAME,
            type: FormFieldType.TEXT,
            label: 'First Name',
            placeholder: 'First Name',
            className: 'zcvp-hidden-label',
            required: true
        },
        [Field.LAST_NAME]: {
            name: Field.LAST_NAME,
            type: FormFieldType.TEXT,
            label: 'Last Name',
            placeholder: 'Last Name',
            className: 'zcvp-hidden-label',
            required: true
        },
        [Field.EMAIL]: {
            name: Field.EMAIL,
            type: FormFieldType.EMAIL,
            label: 'Email',
            placeholder: 'Your account email',
            className: 'zcvp-hidden-label',
            required: true,
            extra: (
                <span className={'hidden sm:inline ant-form-item-extra'}>
                    Your email will be used as a primary contact. You can change this later in the portal.
                </span>
            )
        },
        [Field.COMPANY_NAME]: {
            name: Field.COMPANY_NAME,
            type: FormFieldType.TEXT,
            label: 'Company Name',
            placeholder: 'Company Name',
            className: 'zcvp-hidden-label',
            colSpan: 14,
            required: true,
        },
        [Field.COMPANY_SIZE]: {
            name: Field.COMPANY_SIZE,
            type: FormFieldType.SELECT,
            label: 'Company Size',
            placeholder: 'Company Size',
            colSpan: 10,
            options: COMPANY_SIZE_OPTIONS,
            className: 'zcvp-hidden-label',
            required: true
        },
        [Field.COMPANY_URL]: {
            name: Field.COMPANY_URL,
            type: FormFieldType.WEB_ADDRESS,
            label: 'Web Address',
            placeholder: 'Web Address (optional)',
            className: 'zcvp-hidden-label'
        },
        [Field.PHONE]: {
            name: Field.PHONE,
            type: FormFieldType.PHONE,
            label: 'Company Phone',
            placeholder: 'Company Phone (optional)',
            className: 'zcvp-hidden-label',
        },
        [Field.PASSWORD]: {
            name: Field.PASSWORD,
            label: 'New Password',
            type: FormFieldType.PASSWORD,
            hideHint: true,
        },
        [Field.CONFIRM_PASSWORD]: {
            name: Field.CONFIRM_PASSWORD,
            label: 'Confirm New Password',
            type: FormFieldType.PASSWORD,
        }
    };

    /**
     * Get a field definition
     * @param {string} fieldId
     */
    public getField(fieldId: string): FormFieldDefinition {
        return this.FieldDefinition[fieldId];
    }

    public getInitializingFields() {
        return [
            [
                this.FieldDefinition[Field.FIRST_NAME],
                this.FieldDefinition[Field.LAST_NAME]
            ],
            [this.FieldDefinition[Field.EMAIL]],
            [
                this.FieldDefinition[Field.COMPANY_NAME],
                this.FieldDefinition[Field.COMPANY_SIZE]
            ],
            [this.FieldDefinition[Field.COMPANY_URL]],
            [this.FieldDefinition[Field.PHONE]],
        ];
    }

    public getPasswordFields() {
        return [
            [this.FieldDefinition[Field.PASSWORD]],
            [this.FieldDefinition[Field.CONFIRM_PASSWORD]],

        ];
    }
}