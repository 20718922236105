import { RolePermissionGateway } from '../../@types/role-permissions/zcvp_role_permissions';
import { ZCVPMenuEntryKeys } from '../../@types/zcvp_nav.d';
import { RoleType } from '../../@types/zcvp_user_enums';
import { CheckRolePermissionUseCase } from '../checkRolePermission/checkRolePermissionUseCase';

export type NavigationItems = {
    pages: ZCVPMenuEntryKeys[];
    subPortal: ZCVPMenuEntryKeys[];
}

export class GetNavigationItemsUseCase {
    private gateway: RolePermissionGateway;
    private checkPermissionUseCase: CheckRolePermissionUseCase;
    private navigationConfigMap = {
        [RoleType.CUSTOMER]: {
            subPortal: [
                ZCVPMenuEntryKeys.DASHBOARD,
                ZCVPMenuEntryKeys.VENDORS,
                ZCVPMenuEntryKeys.REQUESTS
            ],
            pages: [
                ZCVPMenuEntryKeys.HOME,
                ZCVPMenuEntryKeys.PROFILE,
                ZCVPMenuEntryKeys.CONFIGURATION,
                ZCVPMenuEntryKeys.USERS,
                ZCVPMenuEntryKeys.HELP
            ]
        },
        [RoleType.VENDOR]: {
            subPortal: [
                ZCVPMenuEntryKeys.DASHBOARD,
                ZCVPMenuEntryKeys.REQUESTS,
            ],
            pages: [
                ZCVPMenuEntryKeys.HOME,
                ZCVPMenuEntryKeys.PROFILE,
                ZCVPMenuEntryKeys.COMPANY,
                ZCVPMenuEntryKeys.HELP,
            ]
        }
    };
    constructor(gateway: RolePermissionGateway, checkPermissionUseCase: CheckRolePermissionUseCase) {
        this.gateway = gateway;
        this.checkPermissionUseCase = checkPermissionUseCase;
    }
    execute(): NavigationItems {
        const roleType = this.gateway.getRoleType();
        const { pages, subPortal } = this.navigationConfigMap[roleType];
        
        return {
            pages: this.getPages(pages),
            subPortal: this.getPages(subPortal)
        };
    }
    getPages(pages: ZCVPMenuEntryKeys[]): ZCVPMenuEntryKeys[] {
        return pages.filter(page => this.checkPermissionUseCase.execute(page));
    }
}