import React, { useMemo } from 'react';
import { Tabs } from 'antd';

import '../../styles/zcvp_vendor_details.scss';
import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import { useGetRequestCommentsQuery, useGetRequestHistoryQuery } from '../../slice/APISlice';
import { useTranslation } from 'react-i18next';
import { TranslationNS } from '../../lib/TranslationHelper';
import { GetRecordHistoryUseCase } from '../../use-case/history/getRecordHistoryUseCase';
import { GetRecordHistoryPresenter } from '../../use-case/history/getRecordHistoryPresenter';
import HistoryDetails from '../history/HistoryDetails';
import VendorRequestComments from './VendorRequestComments';

const VendorRequestTabs = ({ record }: { record: TableRecord }) => {
    const { t } = useTranslation(TranslationNS.VENDOR);
    const { data: history } = useGetRequestHistoryQuery({ requestId: record?.id }, { skip: !record?.id });
    const { data: comments } = useGetRequestCommentsQuery({ requestId: record?.id }, { skip: !record?.id });
    const requestHistory = useMemo(() => new GetRecordHistoryUseCase(new GetRecordHistoryPresenter()).execute(history), [history]);

    return <Tabs
        type='card'
        items={[
            {
                key: 'vendorreq-comments',
                label: t('comments', { keyPrefix: TranslationNS.VENDOR_REQUEST }),
                children: <VendorRequestComments request={record} comments={comments}/>
            },
            {
                key: 'vendorreq-history',
                label: t('history', { keyPrefix: TranslationNS.HISTORY }),
                children: <HistoryDetails records={requestHistory}/>
            }
        ]}
    />;
};

export default VendorRequestTabs;