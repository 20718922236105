import { IGetIntegrationData, IGetIntegrationDataGateway, IntegrationQueryData } from '../../@types/zcvp_configuration';
import { BaseDataUseCase } from '../BaseDataUseCase';
import { GetIntegrationDataPresenter } from './getIntegrationDataPresenter';

export class GetIntegrationDataUseCase extends BaseDataUseCase<IGetIntegrationDataGateway, GetIntegrationDataPresenter> {

    public execute(integrationData?: IntegrationQueryData): IGetIntegrationData {
        const response: IGetIntegrationData = {
            integrationFields: [],
        };

        const configIntegrationData = integrationData?.data || [];
        response.integrationFields = this.presenter.convertFields(this.presenter.integrationLayout);
        response.editableValues = this.getValues(this.gateway.integrationFields, configIntegrationData[0] || {});

        return response;
    }
}
