import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Avatar } from 'antd';import { FileOutlined, LeftOutlined } from '@ant-design/icons';

import VendorDetailsTabs from './VendorDetailsTabs';
import VendorDetailsFooter from './VendorDetailsFooter';
import VendorDetailsHeader from './VendorDetailsHeader';
import DetailsDrawer from '../../components/drawer/DetailsDrawer';
import VendorRequestDetailFooter from '../vendor-requests/VendorRequestDetailFooter';
import { useGetCompanyDataQuery } from '../../slice/APISlice';
import { GetVendorOnboardingActionController } from '../../use-case/onboarding/getVendorOnboardingActionController';
import { UserState } from '../../@types/zcvp_state';
import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import '../../styles/zcvp_modal.scss';
import '../../styles/zcvp_vendor_details.scss';
import { VendorRequestInfo } from '../vendor-requests/VendorRequestDetail';
import { getVendorName } from '../../use-case/getVendors/getVendorsUseCase';

type CloseEvent = React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>;
type VendorDetailsProps = {
    record?: TableRecord;
    onClose: (e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => void;
}

const VendorInfo = ({ record, onRequestClick }: { record?: TableRecord, onRequestClick: (requestRecord: TableRecord) => void }) => {
    return <>
        <VendorDetailsHeader record={record} /><br />
        <VendorDetailsTabs record={record} onRequestClick={onRequestClick}/>
    </>;
};

const VendorDetails = ({ record, onClose }: VendorDetailsProps) => {
    const [selectedRequest, setSelectedRequest] = useState<TableRecord | undefined>();

    const user = useSelector((state: {user: UserState}) => state.user);

    const footerType = useMemo(() => (new GetVendorOnboardingActionController(record!, user)).execute(), [user, record]);
    const footer = useMemo(() => footerType ? <VendorDetailsFooter type={footerType} record={record!} onSubmit={onClose} /> : null , [footerType, record, onClose]);

    const onRequestClick = useCallback((requestRecord: TableRecord) => setSelectedRequest(requestRecord), []);
    const onBack = useCallback(() => setSelectedRequest(undefined), []);
    const onDetailClose = useCallback((event: CloseEvent) => {
        setSelectedRequest(undefined);
        onClose(event);
    }, [onClose]);

    const backButtonIcon = useMemo(() => <>
        <LeftOutlined className='zcvp-venddetails-title-icon' onClick={onBack} />
        <Avatar size='small' icon={<FileOutlined />} />
    </>, [onBack]);

    return selectedRequest
        ? <DetailsDrawer
            record={selectedRequest}
            pageTitle={selectedRequest?.title as string}
            titleIcon={backButtonIcon}
            footer={<VendorRequestDetailFooter record={selectedRequest} onClick={onDetailClose}/>}
            onClose={onDetailClose}
        >
            <VendorRequestInfo record={selectedRequest} onVendorDetailClick={onBack} />
        </DetailsDrawer>
        : <DetailsDrawer
            record={record}
            pageTitle={getVendorName(record) as string}
            titleIcon={null}
            footer={footer}
            onClose={onDetailClose}
        >
            <VendorInfo record={record} onRequestClick={onRequestClick} />
        </DetailsDrawer>;
}

export default VendorDetails;