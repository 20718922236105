import { TutorialStep } from "../../@types/tutorial/zcvp_tutorial";
import { UserRoles } from "../../@types/zcvp_user_enums";

export const hasTutorial = (roleName?: string) => {
    return roleName === UserRoles.ADMIN;
};

export const canGoNextStep = (tutorialSteps: TutorialStep[], currentTutorialStep: number, key: string) => {
    const elementStepIndex = tutorialSteps.findIndex(tutorialStep => tutorialStep.key === key);
    return currentTutorialStep === elementStepIndex;
};

export const isCurrentStepDisabled = (tutorialSteps: TutorialStep[], currentTutorialStep: number) => {
    return tutorialSteps[currentTutorialStep]?.disabledInteraction;
}