import React from 'react';
import GenericActionButton from './GenericActionButton';
import SubmitButton from './SubmitButton';
import { ActionType } from '../../../@types/zcvp_form_enum';
import { FormActionProps } from '../../../@types/zcvp_form';

/**
 * Creates a form action (submit, cancel, reset)
 *
 * @param {FormActionProps} props form action properties
 * @returns {React.FC} form action
 */
const FormAction: React.FC<FormActionProps> = (props: FormActionProps) => {
    switch (props.type) {
    case ActionType.SUBMIT:
        props.definition.disableSubmitButton = props.disableSubmitButton;
        return <SubmitButton {...props.definition}/>;
    default:
        return <GenericActionButton {...props.definition}/>;
    }
};

export default FormAction;