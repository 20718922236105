import React from 'react';
import dayjs from 'dayjs';
import { DatePicker, Form } from 'antd';
import { DateFieldProps } from '../../../@types/zcvp_form';

/**
 * Creates a date field with name, label, default value, disabled, required, showTime, onChange properties
 * When showTime is true, a datepicker with time component is created
 *
 * @param {DateFieldProps} props date field properties
 * @returns {React.FC<DateFieldProps>} a date field inside a form item
 */
const DateField: React.FC<DateFieldProps> = (props: DateFieldProps) => {
    const defaultValue = props.defaultValue ? dayjs(props.defaultValue) : undefined;
    
    return <Form.Item
        name={props.name}
        label={props.label}
        rules={[{
            required: props.required
        }]}>
        <DatePicker
            showTime={props.showTime}
            disabled={props.disabled}
            defaultValue={defaultValue}
            onChange={props.onChange}/>
    </Form.Item>;
};

export default DateField;