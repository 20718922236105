import i18n from '../../i18n';
import { TranslationNS, getCommonT } from '../../lib/TranslationHelper';
import { isEmpty } from '../../lib/Utils';
import { formatDate } from '../util/dateUtil';
import { ListDetailField, ListDetailRecord, TableColumnType, TableData, TableRecord, TableRowObjectValue, TableRowValue } from '../../@types/list-details/zcvp_get_list_details';
import { ListId } from '../../@types/list-details/zcvp_get_list_details_enum';
import { ApiFieldType } from '../../@types/zcvp_api_enum';

type RawValue = string | number | undefined;
type ListFieldMap = {
    [key: string]: ListDetailField;
}
/**
 * Converts business logic output to format expected by the UI (antd table)
 */
export class GetListDetailsPresenter {
    public convert(listId: string, fields: ListDetailField[], records: ListDetailRecord[]): TableData {
        const hasNameFields = this.hasNameFields(fields);
        return {
            columns: this.convertFieldsToColumns(fields, hasNameFields),
            dataSource: this.convertRecordsToRows(listId, fields, records, hasNameFields)
        };
    }
    private NAME_FIELDS = ['firstName', 'lastName'];
    private convertFieldsToColumns(fields: ListDetailField[], hasNameFields: boolean): TableColumnType[] {
        const fieldsWithName = this.addNameField(fields, hasNameFields);
        const allFields = fieldsWithName.map(field => {
            const filters = this.getFilters(field.filters);
            const onFilter = this.getFilterHandler(field.fieldId);
            const sorter = this.getSorterHandler(field.fieldId);
            return {
                title: field.label,
                dataIndex: field.fieldId,
                key: field.fieldId,
                type: field.type,
                filterSearch: field.isFilterSearchable,
                filteredValue: field.filterValue || null,
                filters,
                sorter,
                onFilter
            };
        });

        return allFields;
    }
    private convertRecordsToRows(listId: string, fields: ListDetailField[], records: ListDetailRecord[], hasNameFields: boolean): ListDetailRecord[] {
        const updatedRecords = this.addsValues(listId, records, hasNameFields);
        return this.formatRecords(fields, updatedRecords);
    }
    private addsValues(listId: string, records: ListDetailRecord[], hasNameFields: boolean): ListDetailRecord[] {
        return records?.map(record => {
            const fullName = hasNameFields ? this.getFullName(record.firstName, record.lastName) : record.fullName;
            return {
                ...record,
                key: record.id,
                fullName: fullName || record.name,
                status: this.getStatus(listId, record),
            }
        }) || [];
    }
    private formatRecords(fields: ListDetailField[], records: ListDetailRecord[]): ListDetailRecord[] {
        const fieldMap = this.getFieldMap(fields);
        return records.map((record: TableRecord) => {
            const _record = { ...record };
            for(let fieldId in record) {
                const fieldType = fieldMap[fieldId]?.type;
                if(fieldType === ApiFieldType.DATETIME.toLowerCase()) {
                    _record[fieldId] = formatDate(record[fieldId] as string);
                }
            }
            return _record;
        }) as ListDetailRecord[];
    }
    private getStatus(listId: string, record: ListDetailRecord): string {
        if (listId === ListId.NETSUITE_USER) {
            return record.invitationPending ? i18n.t('status_invitation_sent', { ns: TranslationNS.VENDOR }) : record.registered ? i18n.t('status_active_user', { ns: TranslationNS.VENDOR }) : record.status;
        }
        return record.status;
    }
    private addNameField(fields: ListDetailField[], hasNameFields: boolean): ListDetailField[] {
        let fieldsWithName = fields;
        if (hasNameFields) {
            const fullNameField = {
                label: getCommonT('name'),
                fieldId: 'fullName',
                type: 'string'
            };
            fieldsWithName = [
                fullNameField,
                ...fieldsWithName
            ];
            fieldsWithName = fieldsWithName.filter(field => !this.NAME_FIELDS.includes(field.fieldId));
        }

        return fieldsWithName;
    }
    private getFilters(filters?: ListDetailRecord[]): ListDetailRecord[] | undefined {
        return filters?.map(filter => ({
            value: filter.id,
            text: filter.name
        }));
    }
    private getFilterHandler(fieldId: string) {
        return (value: string, record: TableRecord) => {
            const recordValues = record[fieldId];
            if (Array.isArray(recordValues)) {
                return (recordValues as TableRowValue[]).find((recordValue: TableRowValue) => this.filterValue(value, recordValue));
            }
            return this.filterValue(value, recordValues);
        }
    }
    private getSorterHandler(fieldId: string): Function {
        return (leftValue: TableRecord, rightValue: TableRecord) => {
            const leftFieldValue = this.getFieldValue(leftValue, fieldId);
            const rightFieldValue = this.getFieldValue(rightValue, fieldId);
            return this.sortValue(leftFieldValue, rightFieldValue);
        };
    }
    private filterValue(value: string, recordValue?: TableRowValue): boolean {
        const fieldValue = this.getRawValue(recordValue);
        return fieldValue?.toString() === value?.toString();
    }
    private sortValue(leftValue: RawValue, rightValue: RawValue): number {
        if (isEmpty(leftValue) || leftValue! < rightValue!) {
            return -1;
        }
        if (isEmpty(rightValue) || leftValue! > rightValue!) {
            return 1;
        }
        return 0;
    }
    private getFieldValue(value: TableRecord, fieldId: string): RawValue {
        const recordValues = value[fieldId];
        if (Array.isArray(recordValues)) {
            return recordValues.map(recordValue => this.getRawValue(recordValue)).sort().pop();
        }
        return this.getRawValue(recordValues);
    }
    private getRawValue(recordValue?: TableRowValue): RawValue {
        if (recordValue) {
            const dateValue = Date.parse(recordValue as string);
            if (!isNaN(dateValue) && typeof (recordValue) === 'string') {
                return dateValue;
            }
            if (typeof recordValue === 'object') {
                return (recordValue as TableRowObjectValue).id;
            }
            return recordValue;
        }
        return recordValue as RawValue;
    }
    private hasNameFields(fields: ListDetailField[]): boolean {
        return fields.some(field => this.NAME_FIELDS.includes(field.fieldId));
    }
    private getFullName(firstName: string, lastName: string): string {
        return [firstName, lastName].filter(nameValue => !!nameValue).join(' ');
    }
    private getFieldMap(fields: ListDetailField[]): ListFieldMap {
        return fields.reduce((fieldMap, field) => {
            fieldMap[field.fieldId] = field;
            return fieldMap;
        }, {} as ListFieldMap);
    }
}