import React, { useMemo, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import { Form, Modal } from 'antd';

import { MessageInstance } from 'antd/lib/message/interface';
import GenericForm from '../../components/form/GenericForm';
import { useCreateVendorMutation, useGetAllSubsidiariesQuery, useGetCompanyDataQuery, useInviteVendorMutation } from '../../slice/APISlice';
import { useHasMultipleSubsidiaries } from '../../lib/CustomHooks';
import { SendVendorInvitationController } from '../../use-case/inviteVendors/sendVendorInvitationController';
import { Field, submitFields } from '../../@types/invite-vendors/zcvp_invite_vendors';
import { ZCVPApiResponse, ZCVPCreateVendorData } from '../../@types/zcvp_api';
import { disableSubmitTrigger } from '../../@types/zcvp_form';
import { ActionType } from '../../@types/zcvp_form_enum';
import { AppState } from '../../@types/zcvp_state';
import '../../styles/zcvp_popover.scss';
import { getVendorSubsidiary } from '../../use-case/getVendors/getVendorsUseCase';

type InviteVendorModalProps = {
    open: boolean;
    onClose: () => void;
}

const SINGLE_INSTANCE = 1;

const InviteVendorModal: React.FC<InviteVendorModalProps> = ({ open, onClose }: InviteVendorModalProps) => {
    const [form] = Form.useForm();
    const { messageApi } = useOutletContext<{ messageApi: MessageInstance }>();
    const [inviteVendor] = useInviteVendorMutation();
    const [createVendor] = useCreateVendorMutation();

    const user = useSelector((state: AppState) => state.user);
    const companyQuery = useGetCompanyDataQuery({ companyId: user.companyId });
    const companyFilter = { 'filterModel': { 'companyId': { 'values': [user.companyId], 'filterType': 'set' } } };
    const subsidiaries = useGetAllSubsidiariesQuery(companyFilter);
    const hasMultipleSubsidiaries = useHasMultipleSubsidiaries();

    useEffect(() => {
        if (subsidiaries.data?.length <= SINGLE_INSTANCE) {
            return;
        }

        form.setFieldsValue({ subsidiary: companyQuery.data?.defaultSubsidiaryId })
    }, [form, subsidiaries, companyQuery]);

    const closeAndClear = useCallback(() => {
        form.resetFields();
        onClose();
    }, [form, onClose]);

    const createAndInviteVendor = useCallback((params: { [Field.COMPANY_NAME]: string, [Field.SUBSIDIARY_ID]: string, [Field.INVITE_EMAILS]: string }) => createVendor({
        //create vendor uses different names for the fields
        email: params[Field.INVITE_EMAILS],
        companyName: params[Field.COMPANY_NAME],
        subsidiaryId: params[Field.SUBSIDIARY_ID],
    }).then((result: ZCVPApiResponse) => {
        if (result?.data) {
            const data = result.data as ZCVPCreateVendorData;

            return inviteVendor({ email: params[Field.INVITE_EMAILS], vendorId: data.id });
        }
        const updatedResult = {
            ...result,
            ...(result.error ? {
                error: {
                    call: 'createVendor',
                    ...result.error
                }
            } : {})
        };

        return Promise.reject(updatedResult);
    }), [createVendor, inviteVendor]);

    const controller = useMemo(() => new SendVendorInvitationController({
        inviteCallback: createAndInviteVendor,
        onSuccessCallback: closeAndClear!,
        messageApi,
        subsidiaries: subsidiaries
    }), [createAndInviteVendor, closeAndClear, messageApi, subsidiaries]);

    const onSubmit = useCallback((values: submitFields) => {
        if (!hasMultipleSubsidiaries) {
            values.subsidiary = getVendorSubsidiary(subsidiaries?.data, companyQuery.data?.defaultSubsidiaryId);
        }
        controller.execute(values);
    }, [hasMultipleSubsidiaries, controller, subsidiaries?.data, companyQuery?.data]);

    return (
        <Modal
            open={open}
            title='Invite new Vendor'
            onCancel={onClose}
            footer={[]}
            destroyOnClose
        >
            <GenericForm
                validateTrigger={'onSubmit'}
                disableSubmitTrigger={disableSubmitTrigger.ON_ANY_FIELD_CHANGE}
                formProps={{
                    form,
                    name: 'invite_vendor_form',
                    onFinish: (values: submitFields) => onSubmit(values),
                    requiredMark: true
                }}
                id={'invite_vendor_form'}
                fields={controller.fields}
                actions={[{
                    type: ActionType.CANCEL,
                    definition: {
                        label: 'Cancel',
                        key: 'cancel-invite',
                        name: 'cancel-invite',
                        onClick: closeAndClear
                    }
                }, {
                    type: ActionType.SUBMIT,
                    definition: {
                        label: 'Invite',
                        key: 'invite-vendor',
                        name: 'invite-vendor'
                    }
                }]} />
        </Modal>
    );
};

export default InviteVendorModal;