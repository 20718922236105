import React, { useMemo, useState } from 'react';
import { Form, Select, Tag } from 'antd';
import { FormFieldOption, SelectFieldProps, SelectValue } from '../../../@types/zcvp_form';

import '../../../styles/zcvp_select_field.scss';

type SelectValueProps = {
    label: string;
    closable: boolean;
    onClose: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}
const SelectValueTag = (props: SelectValueProps) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };
    
    return <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
    >
        {label}
    </Tag>;
};

/**
 * Creates a select field with name, label, default value, disabled, required, mode, onChange properties
 * When mode is multiple, a multiple select field is created where multiple values are allowed
 *
 * @param {SelectFieldProps} props select field properties
 * @returns {React.FC<SelectFieldProps>} a select field inside a form item
 */
const SelectField: React.FC<SelectFieldProps> = (props: SelectFieldProps) => {
    const { defaultValue } = props;
    const [maxTagCount, setMaxTagCount] = useState<number|undefined>(props.maxTagCount);
    const [selectedTagCount, setSelectedTagCount] = useState(Array.isArray(defaultValue) ? defaultValue.length : 0);

    const maxTagPlaceholder = useMemo(() => selectedTagCount && maxTagCount
        ? <Tag onClick={() => setMaxTagCount(undefined)}>
            {`Show ${selectedTagCount - maxTagCount} more`}
        </Tag>
        : '', [selectedTagCount, maxTagCount]);

    const onChange = (value: SelectValue[], option: FormFieldOption) => {
        setSelectedTagCount(value.length);
        props.onChange && props.onChange(value, option);
    };
    
    return <Form.Item
        name={props.name}
        label={props.label}
        className={props.className}
        rules={[{
            required: props.required,
            message: props.requiredMessage
        }]}
    >
        <Select 
            className={'zcvp-select-field'}
            optionFilterProp='label'
            disabled={props.disabled}
            options={props.options}
            mode={props.mode}
            maxTagCount={maxTagCount}
            maxTagPlaceholder={maxTagPlaceholder}
            tagRender={SelectValueTag}
            onChange={onChange}
            defaultValue={defaultValue}
        />
    </Form.Item>;
};

export default SelectField;