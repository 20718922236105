import React, { useMemo } from 'react';
import { Button } from 'antd';
import { FilterFilled } from '@ant-design/icons';

import TableFilterDropdown, { Filter, FilterMap } from './TableFilterDropdown';
import SearchBar from './SearchBar';
import { LabelWithIcon } from './StyledText';
import { getCommonT } from '../../lib/TranslationHelper';
import '../../styles/zcvp_tabbed_page.scss';

type TableFilterPanelProps = {
    filters: Filter[];
    appliedFilters: FilterMap;
    onFilter: (appliedFilters: FilterMap) => void;
}
type TablePageHeaderProps = {
    searchPlaceHolder: string;
    filters: Filter[];
    appliedFilters: FilterMap;
    onSearch: (value: string) => void;
    onFilter: (appliedFilters: FilterMap) => void;
}

const TableFilterPanel = ({ filters, appliedFilters, onFilter }: TableFilterPanelProps) => {
    const hasAppliedFilters = useMemo(() => Object.values(appliedFilters).some(appliedFilter => appliedFilter?.length), [appliedFilters]);

    return filters.length ? <div className='flex zcvp-filter-panel'>
        <LabelWithIcon label={getCommonT('filtered_by')} leftIcon={<FilterFilled/>} />
        {filters.map((filter, index) => <TableFilterDropdown
            key={`filter-${filter.value}-${index}`}
            filter={filter}
            appliedFilters={appliedFilters}
            onFilter={onFilter}/>
        )}
    {hasAppliedFilters ? <Button type='text' onClick={() => onFilter({})}>{getCommonT('clear_filters')}</Button> : null}
    </div> : null;
};

const TablePageHeader = ({ searchPlaceHolder, filters, appliedFilters, onSearch, onFilter }: TablePageHeaderProps) => {
    const searchBar = useMemo(() => <SearchBar placeholder={searchPlaceHolder} onSearch={onSearch}/>, [searchPlaceHolder, onSearch]);
    return <>
        <TableFilterPanel
            filters={filters}
            appliedFilters={appliedFilters}
            onFilter={onFilter}/>
        {searchBar}
    </>
};

export default TablePageHeader;