import { FieldValue, SendUserInvitationUseCase } from './sendUserInvitationUseCase';
import { SendInvitationController } from '../invitation/sendInvitationController';
import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import { InvitationControllerProps } from '../../@types/invite/zcvp_invite';

export class SendUserInvitationController extends SendInvitationController {

    constructor({ inviteCallback, messageApi }: InvitationControllerProps) {
        super({
            sendUseCase: SendUserInvitationUseCase,
            inviteCallback,
            messageApi
        });
    }

    public processValues(values : FieldValue, user: TableRecord): FieldValue {
        return {
            userId: user?.id as string,
            selectedUsers: values.fullname,
            roleId: values.role
        };
    }
}