import { IBaseDataGateway } from '../use-case/IBaseDataGateway';
import { IBaseDataPresenter } from '../use-case/IBaseDataPresenter';
import { BaseFieldKey, FormFieldDefinition } from './zcvp_form';

export enum Field {
    ID = 'id',
    COMPANY_NAME = 'companyName',
    ADDRESS = 'addressMain',
    SENDER_EMAIL = 'senderEmail',
    SUPPORT_EMAIL = 'supportEmail',
    SENDER_SUPPORT_EMAIL = 'senderSupportEmail',
    LOGO_URL = 'logo',
    NAME = 'name',
    SUBSIDIARY = 'subsidiary',
}

export enum IntegrationField {
    ID = 'id',
    URL = 'url',
    ACCOUNT_ID = 'accountId',
    CONSUMER_KEY = 'consumerKey',
    CONSUMER_SECRET = 'consumerSecret',
    TOKEN_KEY = 'tokenKey',
    TOKEN_SECRET = 'tokenSecret',
    CONCURRENCY_LIMIT = 'maxConnectionCount',
}

export type FieldKey = `${Field}`
export type IntegrationFieldKey = `${IntegrationField}`

export enum apiFieldKeys {
    ID = 'id',
    COMPANY_NAME = 'companyLegalName',
    ADDRESS = 'addressMain',
    SENDER_EMAIL = 'senderEmail',
    SUPPORT_EMAIL = 'supportEmail',
    LOGO_URL = 'logoUrl',
    NAME = 'name',
    SUBSIDIARY = 'defaultSubsidiaryId',
}
export type submitKeys = `${Field}`

export type submitFields = Record<submitKeys, string>;

export type RecordData = Record<FieldKey, string>
export type RecordQueryData = {
    data?: RecordData
}

export type IntegrationData = Record<IntegrationFieldKey, string>

export type IntegrationQueryData = {
    data?: IntegrationData[]
}

export interface IConfigurationDataGateway extends IBaseDataGateway {
    editableFields: FieldKey[];
    viewFields: FieldKey[];
    logoFields: FieldKey[];
}

export interface ConfigurationData {
    editableValues?: RecordData;
    viewValues?: RecordData;
    logoValues?: RecordData;
    editableFields: FormFieldDefinition[][];
    viewFields: FormFieldDefinition[][];
    logoFields: FormFieldDefinition[][];
    id: string;
}

/** INTEGRATION DATA **/
export interface IGetIntegrationData {
    editableValues?: IntegrationData;
    integrationFields: FormFieldDefinition[][];
}
export interface IGetIntegrationDataGateway extends IBaseDataGateway {
    integrationFields: IntegrationFieldKey[];
}

export interface IGetIntegrationDataPresenter extends IBaseDataPresenter {
    integrationLayout: BaseFieldKey<IntegrationFieldKey>[][];
}

export interface IGetConfigurationDataPresenter extends IBaseDataPresenter {
    companyAccountFieldsLayout: BaseFieldKey<FieldKey>[][];
    viewFieldsLayout: BaseFieldKey<FieldKey>[][];
    logoFieldsLayout: BaseFieldKey<FieldKey>[][];
}