import { FieldQueryData, FilterMap, TableData, TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import { RecordQueryData } from '../../@types/zcvp_base_types';
import { GetListDetailsController } from '../getListDetails/getListDetailsController';
import { GetVendorRequestsGateway } from './getVendorRequestsGateway';
import { GetVendorRequestsPresenter } from './getVendorRequestsPresenter';
import { GetVendorRequestsUseCase } from './getVendorRequestsUseCase';

type GetRequestListProps = {
    columnQueryData: FieldQueryData;
    recordQueryData: RecordQueryData;
    lists: TableRecord;
    listId: string;
    filterValue: string;
    filters: FilterMap;
    isVendor: boolean;
}

export class GetVendorRequestsController {
    private vendorsList: TableRecord[]|undefined;
    constructor(vendorsList?: TableRecord[]) {
        this.vendorsList = vendorsList;
    }
    public executeGetRequests(requests: TableRecord[], isVendor: boolean): TableRecord[] {
        return this.getUseCase().getRequests(requests, isVendor);
    }
    public executeGetRequest(request: TableRecord, isVendor: boolean): TableRecord {
        return this.getUseCase().getRequest(request, isVendor);
    }
    public executeGetRequestList({
        columnQueryData, recordQueryData, lists, listId, filterValue, filters, isVendor
    }: GetRequestListProps): TableData {
        const postProcessor = (requests) => this.getUseCase().getRequests(requests, isVendor);
        return new GetListDetailsController(columnQueryData, recordQueryData, lists, postProcessor)
            .execute(listId, filterValue, filters)
    }
    public executeFilterRequestsByStatus(requests: TableRecord[]): TableRecord[] {
        return this.getUseCase().filterRequestsByStatus(requests);
    }
    private getUseCase() {
        const gateway = new GetVendorRequestsGateway(this.vendorsList);
        const presenter = new GetVendorRequestsPresenter();
        return new GetVendorRequestsUseCase(gateway, presenter);
    }
    
}