import { AddressValues } from '../../@types/get-address/zcvp_get_address';
import { ApiPermission, ApiRecord, ApiRecordActionType, ApiVendorRequestType } from '../../@types/zcvp_api_enum';
import { VendorUpdateType } from './updateVendorCompanyUseCase';
import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import { ZCVPApiResponse } from '../../@types/zcvp_api';
import { VendorCompanyUpdateGateway } from '../../@types/vendor-company/zcvp_vendor_company';
import i18n from '../../i18n';
import { TranslationNS } from '../../lib/TranslationHelper';
import { SubmitRecordGateway } from '../submitRecord/submitRecordGateway';
import { RecordType } from '../../@types/submit-record/zcvp_submit_record_enum';
import { VendorCompanyField } from '../../@types/vendor-company/zcvp_vendor_company_enum';
import { FormFieldType } from '../../@types/zcvp_form_enum';

export class UpdateVendorCompanyGateway implements VendorCompanyUpdateGateway {
    private createAddress;
    private updateAddress;
    private createBankAccount;
    private updateBankAccount;
    private updateVendor;
    private submitRequest;
    private submitRecordGateway;
    constructor({ createAddress, updateAddress, createBankAccount, updateBankAccount, updateVendor, submitRequest }) {
        this.createAddress = createAddress;
        this.updateAddress = updateAddress;
        this.createBankAccount = createBankAccount;
        this.updateBankAccount = updateBankAccount;
        this.updateVendor = updateVendor;
        this.submitRequest = submitRequest;
        this.submitRecordGateway = new SubmitRecordGateway();
    }
    public createVendorAddress(address: AddressValues): Promise<ZCVPApiResponse> {
        return this.createAddress(address);
    }
    public updateVendorAddress(address: AddressValues): Promise<ZCVPApiResponse> {
        return this.updateAddress(address);
    }
    public createBankDetails(bankDetails: TableRecord): Promise<ZCVPApiResponse> {
        return this.createBankAccount(bankDetails);
    }
    public updateBankDetails(bankDetails: TableRecord): Promise<ZCVPApiResponse>{
        return this.updateBankAccount(bankDetails);
    }
    public updateVendorData(values: TableRecord): Promise<ZCVPApiResponse> {
        const DEFAULT_TO_NULL = true;
        const updatedValues = this.submitRecordGateway.getFieldValues(RecordType.VENDOR, values, !DEFAULT_TO_NULL);
        return this.updateVendor({ ...values, ...updatedValues });
    }
    public submitVendorRequest({ updateType, values, descriptionDetails }): Promise<ZCVPApiResponse> {
        const { title, recordType } = this.getRequestDetails(updateType);
        const valuesToSubmit = this.getValuesToSubmit(recordType, values);
        const valueChangeDescription = this.getDescription(descriptionDetails);

        return this.submitRequest({
            [ApiRecord.VendorRequestField.TITLE]: title,
            [ApiRecord.VendorRequestField.DESCRIPTION]: valueChangeDescription,
            [ApiRecord.VendorRequestField.TYPE]: ApiVendorRequestType.RECORD_CHANGE,
            [ApiRecord.VendorRequestField.RECORD_CHANGE_VALUES]: valuesToSubmit
        });
    }
    private getValuesToSubmit(recordType: string, values: TableRecord): TableRecord[] {
        const valuesToSubmit = this.getChangedValues(recordType, values);
        const  allValues = recordType === ApiRecord.Type.VENDOR
            ? this.getVendorValuesToSubmit(valuesToSubmit)
            : this.getBankAccountValuesToSubmit(valuesToSubmit);

        return allValues.map(value => {
            const { id: recordId, model, ...otherValues } = value;
            const recordValues = this.getRecordValues(otherValues);
            return {
                recordId,
                [ApiRecord.VendorRequestField.RECORD_TYPE]: model,
                [ApiRecord.VendorRequestField.RECORD_ACTION_TYPE]: recordId ? ApiRecordActionType.UPDATE : ApiRecordActionType.CREATE,
                [ApiRecord.VendorRequestField.RECORD_VALUES]: recordValues
            } as unknown as TableRecord;
        }).filter(value => !!value.values && Object.keys(value.values).length);
    }
    private getRecordValues(values: TableRecord): TableRecord {
        const recordValues = {};
        for(let fieldId in values) {
            const value = values[fieldId];
            if(value !== undefined) {
                (recordValues as TableRecord)[fieldId] = value;
            }
        }
        return recordValues;
    }
    private getChangedValues(recordType: string, values: TableRecord): TableRecord {
        const DEFAULT_TO_NULL = true;
        return {
            ...values,
            ...this.submitRecordGateway.getFieldValues(recordType.toLowerCase(), values, !DEFAULT_TO_NULL),
        };
    }
    private getVendorValuesToSubmit(values: TableRecord): TableRecord[] {
        const {
            billing_address: billingAddress,
            shipping_address: shippingAddress,
            ...companyDetails
        } = values;

        const vendorDetails = { ...companyDetails, model: ApiRecord.Type.VENDOR } as TableRecord;
        const billingAddressDetails = billingAddress ? { ...billingAddress, model: ApiRecord.Type.ADDRESS } : null;
        const shippingAddressDetails = shippingAddress ? { ...shippingAddress, model: ApiRecord.Type.ADDRESS } : null;

        return [vendorDetails, billingAddressDetails, shippingAddressDetails].filter(values => !!values) as TableRecord[];
    }
    private getBankAccountValuesToSubmit(values: TableRecord): TableRecord[] {
        return [{
            ...values, model: ApiRecord.Type.BANK_ACCOUNT
        }];
    }
    private getRequestDetails(updateType: string): { title: string, recordType: string } {
        const title = i18n.t(`${TranslationNS.VENDOR_REQUEST}.${updateType.toLowerCase()}_update`, { ns: TranslationNS.VENDOR });
        const recordType = updateType === VendorUpdateType.BANK_DETAILS ? ApiPermission.Target.BANK_ACCOUNT : ApiPermission.Target.VENDOR;

        return { title, recordType };
    }
    private getDescription(descriptionDetails) {
        const valueChange = descriptionDetails.map(fieldDescription => {
            const { fieldId, field, oldValue, newValue } = fieldDescription;
            return [
                `<b>${field.label}</b>`,
                `<span><s>${this.getValue(fieldId, field, oldValue)}</s></span>`,
                `<span>-> ${this.getValue(fieldId, field, newValue)}</span>`
            ].join('<br/>')
        }).join('<br/><br/>');
        const valueChangeDescription = `<br/><br/>${valueChange}<br/><br/>`;
        return i18n
            .t(`${TranslationNS.VENDOR}:${TranslationNS.VENDOR_REQUEST}.company_update_description`)
            .replace('{{changes}}', valueChangeDescription);
    }
    private getValue(fieldId, field, value) {
        const DEFAULT_LABEL = '(empty)';
        if(field.type === FormFieldType.SELECT) {
            return field.options.find(option => option.id.toString() === value?.toString())?.label || DEFAULT_LABEL;
        } else if([
            VendorCompanyField.SHIPPING_ADDRESS,
            VendorCompanyField.BILLING_ADDRESS
        ].includes(fieldId)) {
            const { address1, city, state, country } = value || {};
            return [address1, city, state, country]
                .filter(addressValue => !!addressValue)
                .join(', ') || DEFAULT_LABEL;
        }
        return value || DEFAULT_LABEL;
    }
}