import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { AppState, LayoutThemeState, LayoutThemeStateTypes } from '../@types/zcvp_state';
import { ZCVPThemeTypes } from '../@types/zcvp_layout.d';

export const LayoutThemeSliceInitialState = {
    theme: ZCVPThemeTypes.LIGHT,
    //note: keeping these on state to move rendering the menus out of the layout component
    //to try and keep rerenders to a minimum
    menuIsOpen: true,
    menuTrigger: 'click',
    verticalIconMenu: [],
    verticalSubPageMenu: [],
} as LayoutThemeState;

const LayoutThemeSlice = createSlice({
    name: 'layoutTheme',
    initialState: LayoutThemeSliceInitialState,
    reducers: {
        updateLayoutThemeState(state, action: PayloadAction<LayoutThemeState>) {
            const payloadKeys = Object.keys(action.payload) as LayoutThemeStateTypes[];
            payloadKeys?.forEach((key : LayoutThemeStateTypes) => {
                const value = action.payload[key];
                if (state[key] === value) {
                    return;
                }
                (state as Record<typeof key, typeof value>)[key] = value;
            });
        },
    },
});

export const { updateLayoutThemeState } = LayoutThemeSlice.actions;
export default LayoutThemeSlice.reducer;

/**
 * Caches and returns whether we are on a subportal page or note
 */
export const selectIsSubPortalPage = createSelector(
    (state : AppState) => state.layoutTheme.verticalSubPageMenu as string[],
    (state : AppState, props : {pathname: string}) => props.pathname,
    (subPortalPages, pathname) => {
        const [mainPath] = pathname.split('/').filter(value => !!value);

        return !mainPath || subPortalPages?.includes(mainPath);
    });
