import { ConfigProvider, Layout, Space, message } from 'antd';
import { PropsWithChildren, useEffect, useMemo } from 'react';
import { Outlet } from 'react-router-dom';

import '../../styles/zcvp_no_auth.scss';
import GlobalFooter from '../partials/GlobalFooter';
import { StorageKey } from '../../@types/zcvp_routes_enum';

const { Footer } = Layout;
const validateMessages = {
    required: '${label} is required',
};

const themeTokens = {
    token: {
        controlHeight: 32,
        paddingSM: 12,
    },
    //use slightly bigger margins if user isn't logged in
    components: {
        Divider: {
            margin: 26,
        },
        Layout: {
            headerBg: '#ffffff',
            bodyBg: '#ffffff',
            footerBg: '#ffffff',

        },
        Form: {
            itemMarginBottom: 26,
        }
    }
};
const UnauthenticatedLayout = ({ children }: PropsWithChildren) => {
    const [messageApi, contextHolder] = message.useMessage();
    const messageText = useMemo(() => localStorage.getItem(StorageKey.LOGIN_PAGE_MESSAGE), [localStorage.getItem(StorageKey.LOGIN_PAGE_MESSAGE)]);

    useEffect(()=>{
        if (messageText){
            messageApi.open({
                type: 'info',
                content: messageText,
                key: messageText
            });

            localStorage.setItem(StorageKey.LOGIN_PAGE_MESSAGE, '');
        }
    }, []);

    return (
        <ConfigProvider
            theme={themeTokens}
        >
            {contextHolder}
            <div className={'flex flex-row items-stretch min-h-screen zcvp-unauthenticated-layout'}>
                <div className="basis-full lg:basis-3/5 flex flex-col bg-white">
                    <Layout className={'mx-auto basis-full md:basis-3/5 lg:basis-1/2'}>
                        <Space direction={'vertical'}>
                            <ConfigProvider form={{ validateMessages }}>
                                < Outlet context={{ messageApi }} />
                                {children}
                            </ConfigProvider>
                        </Space>
                    </Layout>
                    <Footer className={'relative justify-between'}>
                        <GlobalFooter />
                    </Footer>
                </div>
                <div className="bg-pattern-light bg-repeat-y bg-right-top md:basis-1/5 hidden md:flex" />
                <div className="bg-pattern-dark md:basis-1/5 hidden md:flex" />
            </div>
        </ConfigProvider>
    );
};

export default UnauthenticatedLayout;