
import { GetUserRolesParams, PermissionChecker, UserProfileDetails, UserRoleDetails, UserRolesGateway, UserRolesPresenter } from '../../@types/role-permissions/zcvp_role_permissions';
import { ApiRecord } from '../../@types/zcvp_api_enum';
import { PortalFeature } from '../../@types/role-permissions/zcvp_role_permissions_enum';

export class GetUserRolesUseCase {
    private gateway: UserRolesGateway;
    private presenter: UserRolesPresenter;
    private getPermissionChecker: (role: ApiRecord.AppRole) => PermissionChecker;
    constructor({ gateway, presenter, getPermissionChecker }: GetUserRolesParams) {
        this.gateway = gateway;
        this.presenter = presenter;
        this.getPermissionChecker = getPermissionChecker;
    }
    public execute(): UserProfileDetails {
        const currentUserProfile = this.getCurrentUserProfile();
        const otherUserProfiles = this.getOtherUserProfiles();
        return this.presenter.convert(currentUserProfile, otherUserProfiles);
    }
    private getCurrentUserProfile(): UserRoleDetails {
        const currentProfile = this.gateway.getCurrentUserProfile();
        return this.getRoleDetails(currentProfile);
    }
    private getOtherUserProfiles(): UserRoleDetails[] {
        const otherProfiles = this.gateway.getOtherProfiles();
        return otherProfiles.map(otherProfile => this.getRoleDetails(otherProfile));
    }
    private getRoleDetails(userRole: ApiRecord.UserProfile): UserRoleDetails {
        const { roleId, roleName, vendorName, companyName } = userRole;
        const roleDefinition = this.gateway.getRoleById(roleId!);
        const hasAdminPermission = this.checkAdminPermission(roleDefinition);
        const userRoleName = this.getUserRoleName(vendorName!, companyName!);
        const profileIds = this.getProfileIds(userRole);
        return {
            roleLabel: roleName as string,
            userRoleName,
            hasAdminPermission,
            profileIds
        };
    }
    private checkAdminPermission(role: ApiRecord.AppRole): boolean {
        const permissionChecker = this.getPermissionChecker(role);
        return role.isVendor
            ? permissionChecker.executeFeatureCheck(PortalFeature.EDIT_VENDOR)
            : permissionChecker.executeFeatureCheck(PortalFeature.FULL_COMPANY);
    }
    private getUserRoleName(vendorName: string, companyName: string): string {
        return vendorName || companyName;
    }
    private getProfileIds(userRole: ApiRecord.UserProfile): ApiRecord.UserProfile {
        const { roleId, companyId, employeeId, vendorId, vendorContactId } = userRole;
        return { companyId, roleId, employeeId, vendorId, vendorContactId };
    }
}
