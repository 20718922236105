import React from 'react';
import { Input, Form } from 'antd';
import { GenericFieldProps } from '../../../@types/zcvp_form';

/**
 * Creates a URL field with name, label, default value, disabled, required, onChange properties
 * The component validates that the URL is valid. An error message is displayed when the URL is invalid
 *
 * @param props email field properties
 * @returns a email field inside a form item
 */
const UrlField: React.FC<GenericFieldProps> = (props: GenericFieldProps) => <Form.Item
    name={props.name}
    label={props.label}
    extra={props.extra}
    className={props.className}
    normalize={(value) => value.trim()}
    rules={[{
        required: props.required
    }, {
        type: 'url',
        message: 'This field must be a valid url.'
    }]}>
    <Input
        placeholder={props.placeholder}
        disabled={props.disabled}
        onChange={props.onChange}
    />
</Form.Item>;

export default UrlField;