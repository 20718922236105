import { SendInvitationPresenter } from './sendInvitationPresenter';
import { MessageInstance } from 'antd/lib/message/interface';
import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import { FormFieldDefinition } from '../../@types/zcvp_form';
import { sendUseCase } from '../../@types/invite/zcvp_invite';
import { FieldValue } from '../inviteUsers/sendUserInvitationUseCase';

type SendInvitationControllerProps = {
    inviteCallback: Function;
    messageApi: MessageInstance;
    onSuccessCallback?: Function;
    sendUseCase: sendUseCase
}
export class SendInvitationController {
    public fields: FormFieldDefinition[][] = [];

    public readonly inviteCallback: Function;
    public readonly onSuccessCallback: Function;
    //note: sendUseCase is required, but it can be set on the constructor or by making a custom class (see sendUserInvitationController)
    public readonly sendUseCase: sendUseCase;
    public readonly messageApi: MessageInstance;
    constructor({ sendUseCase, inviteCallback, messageApi, onSuccessCallback }: SendInvitationControllerProps) {
        this.inviteCallback = inviteCallback;
        this.onSuccessCallback = onSuccessCallback as Function;
        this.messageApi = messageApi;
        this.sendUseCase = sendUseCase;
    }

    public execute(values : FieldValue, record?: TableRecord) {
        values = this.processValues(values, record);
        const gateway = { inviteCallback: this.inviteCallback };
        const presenter = new SendInvitationPresenter(this.messageApi, this.onSuccessCallback);
        const useCase = new this.sendUseCase(gateway, presenter);
        useCase.execute(values);
    }

    /**
     * Overwrite this when extending classes to have custom field processing
     * @param values : FieldValue
     * @param record : TableRecord
     * @private
     */
    public processValues(values : FieldValue, record?: TableRecord) {
        return { ...values, ...(record?.id ? { id: record?.id } : {}) };
    }
}