import { PropsWithChildren, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TutorialStepKey } from '../../@types/tutorial/zcvp_tutorial_enum';
import {
    ZCVPMenuEntryKeys,
    ZCVPVerticalNavData,
    ZCVPVerticalNavStationaryLiProps
} from '../../@types/zcvp_nav.d';
import { AppState } from '../../@types/zcvp_state';
import { TranslationNS } from '../../lib/TranslationHelper';
import { getTutorialStepRef, useGoNextTutorialStep } from '../../pages/portal/PortalTutorial';
import { selectIsSubPortalPage } from '../../slice/LayoutThemeSlice';
import { ZCVPMenuEntryConfigLookup } from './NavigationConfig';
import VerticalNavToggleTrigger from './VerticalNavToggleTrigger';


/**
 * Generates an LI element for use in the vertical stationary nav menu
 * @param {string} path
 * @param {string} title
 * @param {string} key
 * @param {JSXElement} children
 * @constructor
 */
const ZCVPVerticalNavLi = ({ path, title, target, children }: PropsWithChildren<ZCVPVerticalNavStationaryLiProps>) => (
    <li className={'flex'}>
        <Tooltip title={title} placement={'right'}>
            <Link to={path} title={title} target={target}
                className={'flex justify-items-center zcvp-nav-item'}>
                {children}
            </Link>
        </Tooltip>
    </li>
);

export const getTourStep = (id: string) => {
    const tourMap: { [key: string]: string } = {
        [ZCVPMenuEntryKeys.CONFIGURATION]: TutorialStepKey.COMPANY_CONFIG,
        [ZCVPMenuEntryKeys.USERS]: TutorialStepKey.USERS
    };
    const key = tourMap[id];
    const elementRef = getTutorialStepRef(key);
    return { key, elementRef };
};

/**
 * Generates a vertical menu of icons that does not show/hide
 * @constructor
 */
const VerticalNavStatic = () => {
    const { t } = useTranslation(TranslationNS.NAVIGATION);
    const location = useLocation();
    const isPortalPage = useSelector((state: AppState) => selectIsSubPortalPage(state, { pathname: location.pathname }));
    const verticalIconMenu = useSelector((state: AppState) => state.layoutTheme.verticalIconMenu);
    const menu = useMemo(() => isPortalPage ? verticalIconMenu : [], [isPortalPage, verticalIconMenu]);
    const goNextTutorialStep = useGoNextTutorialStep();

    if (!menu) { return null; }

    return (
        <div className={'zcvp-nav-vertical flex flex-col justify-between'}
        >
            <ul>
                <ZCVPVerticalNavLi
                    path={'/'}
                    title={t('return_home') /*this is named like this to avoid confusion with the 'Home' icon during testing */}
                >
                    <img src={'/zone-capture-icon.png'}
                        alt={'Zone Capture Logo'} />
                </ZCVPVerticalNavLi>
                {menu
                .filter(menuItem => !ZCVPMenuEntryConfigLookup[menuItem].hideIconMenu)
                .map((id, index) => {
                    const navData: ZCVPVerticalNavData = {
                        ...ZCVPMenuEntryConfigLookup[id],
                        id: id
                    };
                    // translate the title again, as it is not translated in the config (why??)
                    navData.title = t(navData.title);

                    const { key, elementRef } = getTourStep(id);
                    return (
                        <ZCVPVerticalNavLi
                            path={navData.path}
                            title={navData.title}
                            target={navData.target}
                            key={`zcvp_vertical_nav_${index}`}

                        >
                            <span ref={elementRef} onClick={() => goNextTutorialStep(key)}>{navData.icon}</span>
                        </ZCVPVerticalNavLi>
                    );
                })}
            </ul>
            <VerticalNavToggleTrigger
                isCaret={true}
            />
        </div>
    );
};

export default VerticalNavStatic;