import { DashboardGatewayInterface, DashboardPresenterInterface } from '../../@types/portal/zcvp_portal';
import { isEmpty } from '../../lib/Utils';
import { ApiRecord } from '../../@types/zcvp_api_enum';
import { UserRoles } from '../../@types/zcvp_user_enums';
import { PortalFeature } from '../../@types/role-permissions/zcvp_role_permissions_enum';

export class DashboardUseCase {
    private gateway: DashboardGatewayInterface;
    private presenter: DashboardPresenterInterface;

    constructor(gateway: DashboardGatewayInterface, presenter: DashboardPresenterInterface) {
        this.gateway = gateway;
        this.presenter = presenter
    }

    public hasInvitedContacts(vendors?: ApiRecord.VendorContactRecord[]): boolean {
        if (!vendors) {
            return false;
        }
        const hasOwner = vendors.find((contact) => contact[ApiRecord.VendorContactField.ROLE] === UserRoles.OWNER)?.[ApiRecord.VendorContactField.ID];
        const hasAdditionalUser = vendors.some((contact) => contact[ApiRecord.VendorContactField.ID] !== hasOwner && contact[ApiRecord.VendorContactField.INVITED_AT]);

        return !!hasOwner && hasAdditionalUser;
    }

    public hasCompletedProfile(vendorData?: ApiRecord.VendorRecord): boolean {
        return !!vendorData && !this.gateway.mandatoryFields.some((id) => isEmpty(vendorData[id]));
    }

    public validateDashboardFeature(hasPermission: boolean, feature: PortalFeature): string {
        return this.presenter.convert(hasPermission, feature);
    }

}