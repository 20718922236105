import { MessageInstance } from 'antd/lib/message/interface';
import { ShowMessageTypes } from '../../@types/zcvp_custom_hooks';
import { showMessage } from '../../lib/CustomHooks';
import { ZCVPApiErrorResponse } from '../../@types/zcvp_api';

export class SendInvitationPresenter {
    private messageApi: MessageInstance;
    private onSuccessCallback: Function;

    constructor(messageApi: MessageInstance, onSuccessCallback: Function) {
        this.messageApi = messageApi;
        this.onSuccessCallback = onSuccessCallback;
    }
    public convert({ isError, error }: { isError: boolean, error?: ZCVPApiErrorResponse }) {
        if (isError) {
            showMessage(ShowMessageTypes.ERROR, error?.message || '', this.messageApi);
        } else {
            showMessage(ShowMessageTypes.SUCCESS, 'Invitation sent.', this.messageApi);
            this.onSuccessCallback && this.onSuccessCallback();
        }
    }
}