import { Col, Form, Row, Modal } from 'antd';
import { MessageInstance } from 'antd/es/message/interface';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';

import GenericForm from '../../components/form/GenericForm';
import TabPageContent from '../../components/page/TabPageContent';
import CardComponent from '../../components/partials/CardComponent';
import { TranslationNS, getCommonT } from '../../lib/TranslationHelper';
import {
    useCancelRequestMutation,
    useCreateAddressMutation, useGetAllCurrenciesQuery, useGetAllVendorCategoriesQuery,
    useGetVendorQuery,
    useLazyGetRequestsQuery,
    useSubmitRequestMutation,
    useUpdateAddressMutation, useUpdateVendorMutation
} from '../../slice/APISlice';
import { isEmptyObject } from '../../lib/Utils';
import { VendorUpdateType } from '../../use-case/updateCompany/updateVendorCompanyUseCase';
import { UpdateVendorCompanyDataController } from '../../use-case/updateCompany/updateVendorCompanyController';
import { GetVendorCompanyDataController } from '../../use-case/updateCompany/vendorCompanyController';
import { AppState } from '../../@types/zcvp_state';
import { disableSubmitTrigger } from '../../@types/zcvp_form';
import { TableRecord, TableRowValue } from '../../@types/list-details/zcvp_get_list_details';
import { VendorRecordData, submitFields } from '../../@types/vendor-company/zcvp_vendor_company';
import { ActionType } from '../../@types/zcvp_form_enum';
import { VendorCompanyField } from '../../@types/vendor-company/zcvp_vendor_company_enum';
import { ApiRecord, ApiRequestStatus, ApiVendorRequestType } from '../../@types/zcvp_api_enum';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { ZCVPApiResponse } from '../../@types/zcvp_api';
const { confirm } = Modal;

export default function CompanyGeneral() {
    const { t } = useTranslation(TranslationNS.VENDOR);
    const [companyDetailsForm] = Form.useForm();
    const [legalInformationForm] = Form.useForm();
    const formShippingAddress = Form.useWatch(VendorCompanyField.SHIPPING_ADDRESS, companyDetailsForm);
    const formBillingAddress = Form.useWatch(VendorCompanyField.BILLING_ADDRESS, companyDetailsForm);
    const isShippingSameAsBilling = Form.useWatch(VendorCompanyField.ADDRESSES_EQUAL, companyDetailsForm);

    const user = useSelector((state: AppState) => state.user);
    const [billingAddress, setBillingAddress] = useState<TableRecord|undefined>();
    const [shippingAddress, setShippingAddress] = useState<TableRecord|undefined>();
    const [isCompanyDetailDisabled, setIsCompanyDetailDisabled] = useState(false);
    const [isLegalInfoDisabled, setIsLegalInfoDisabled] = useState(false);
    const [requestsQuery, setRequestsQuery] = useState<ZCVPApiResponse>();

    const companyQuery = useGetVendorQuery({
        id: Number(user?.vendorId)
    }, { skip: !user?.vendorId });
    const companyData = companyQuery?.data as VendorRecordData;
    const { messageApi } = useOutletContext<{ messageApi: MessageInstance }>();

    const companyFilter = {
        filterModel: {
            [ApiRecord.VendorField.COMPANY_ID]: { values: [companyData?.companyId], 'filterType': 'set' }
        }
    };
    const requestFilter = {
        filterModel: {
            [ApiRecord.VendorRequestField.VENDOR_ID]: { values: [user?.vendorId], filterType: 'set' },
            [ApiRecord.VendorRequestField.TYPE]: { values: [ApiVendorRequestType.RECORD_CHANGE], filterType: 'set' },
            [ApiRecord.VendorRequestField.STATUS]: { values: [ApiRequestStatus.NEW, ApiRequestStatus.REVIEW], filterType: 'set'}
        }
    };
    const vendorCategories = useGetAllVendorCategoriesQuery({});
    const currencies = useGetAllCurrenciesQuery(companyFilter);

    const [createAddress] = useCreateAddressMutation();
    const [updateAddress] = useUpdateAddressMutation();
    const [updateVendor] = useUpdateVendorMutation();
    const [submitRequest] = useSubmitRequestMutation();
    const [cancelRequest] = useCancelRequestMutation();
    const [getRequests] = useLazyGetRequestsQuery({});

    const vendorCompanyDataController = useMemo(() => new GetVendorCompanyDataController(), []);
    const updateVendorCompanyController = useMemo(() => new UpdateVendorCompanyDataController({
        createAddress, updateAddress, updateVendor, submitRequest, messageApi
    }), [createAddress, updateAddress, updateVendor, submitRequest, messageApi]);

    const allFields = useMemo(
        () => vendorCompanyDataController.executeGetFields({
            vendorCategories,
            currencies
        })
    , [vendorCompanyDataController, vendorCategories, currencies]);

    const {
        companyDetailsFields,
        legalInformationFields
    } = useMemo(() => vendorCompanyDataController.execute({
        vendorRecord: companyQuery!, vendorCategories, currencies, isShippingSameAsBilling, isCompanyDetailDisabled
    }), [vendorCompanyDataController, companyQuery, vendorCategories, currencies, isShippingSameAsBilling, isCompanyDetailDisabled]);

    const { 
        companyDetailRequestIds, companyDetailValues, companyDetailRequestValues,
        legalInfoRequestIds, legalInfoValues, legalInfoRequestValues
    } = useMemo(() => vendorCompanyDataController.executeGetCompanyDetails({
        vendorRecord: companyQuery!, requestsQuery
    }), [companyQuery, requestsQuery]);

    const addressValues = useMemo(() => ({
        [VendorCompanyField.BILLING_ADDRESS]: billingAddress,
        [VendorCompanyField.SHIPPING_ADDRESS]: shippingAddress
    }), [billingAddress, shippingAddress]);

    const resetCompanyDetails = useCallback(() => {
        if(companyDetailValues) {
            const billingAddress = companyDetailValues[VendorCompanyField.BILLING_ADDRESS];
            const shippingAddress = companyDetailValues[VendorCompanyField.SHIPPING_ADDRESS];
            setBillingAddress(billingAddress as unknown as TableRecord);
            setShippingAddress(shippingAddress as unknown as TableRecord);
            companyDetailsForm.setFieldsValue(companyDetailValues);
        }
    }, [companyDetailValues, companyDetailsForm]);

    const resetLegalInfo = useCallback(() => {
        if(legalInfoValues) {
            legalInformationForm.setFieldsValue(legalInfoValues);
        }
    }, [isLegalInfoDisabled, legalInfoValues, legalInformationForm]);

    useEffect(() => {
        getRequests(requestFilter, false).then(response => setRequestsQuery(response));
    }, [getRequests]);

    useEffect(() => {
        setBillingAddress(formBillingAddress);
    }, [formBillingAddress]);

    useEffect(() => {
        setShippingAddress(formShippingAddress);
    }, [formShippingAddress]);

    useEffect(() => {
        setIsCompanyDetailDisabled(!isEmptyObject(companyDetailRequestValues));
    }, [companyDetailRequestValues]);

    useEffect(() => {
        setIsLegalInfoDisabled(!isEmptyObject(legalInfoRequestValues));
    }, [legalInfoRequestValues]);

    useEffect(() => {
        isShippingSameAsBilling && setShippingAddress({
            ...billingAddress,
            id: addressValues[VendorCompanyField.SHIPPING_ADDRESS]?.id!
        });
    }, [isShippingSameAsBilling, formBillingAddress, billingAddress]);

    useEffect(resetCompanyDetails, [resetCompanyDetails]);
    useEffect(resetLegalInfo, [resetLegalInfo]);

    const addRequest = useCallback((response: ZCVPApiResponse) => {
        if(response && !response.error && response.data) {
            const requestData = requestsQuery!.data as TableRecord[];
            const newRequestQuery = {
                ...requestsQuery,
                data: [
                    response.data,
                    ...(requestData || [])
                ]
            } as ZCVPApiResponse;
            setRequestsQuery(newRequestQuery);
        }
    }, [requestsQuery]);

    const removeRequest = useCallback((requestId: number) => {
        const newRequestQuery = {
            ...requestsQuery,
            data: requestsQuery?.data!.filter((request: TableRecord) => request.id !== requestId as unknown as TableRowValue)
        };
        setRequestsQuery(newRequestQuery);
    }, [requestsQuery]);

    const saveValues = useCallback((oldValues, values, onOk, onCancel) => {
        const { isEdited, newValues } = updateVendorCompanyController.getNewValues(oldValues, values);
        updateVendorCompanyController.executeSaveVendorCompany({
            isEdited,
            values: newValues,
            icon: <ExclamationCircleOutlined />,
            onOk,
            onCancel,
            confirm
        });
    }, [updateVendorCompanyController]);

    const updateCompanyDetails = useCallback(({ isEdited, values }) => updateVendorCompanyController.executeSaveCompanyDetail({
        updateType: VendorUpdateType.COMPANY_DETAILS,
        recordId: Number(companyData?.id),
        vendorId: Number(companyData?.id),
        isEdited,
        fields: allFields,
        oldValues: companyDetailValues,
        newValues: values,
        onRequestSubmit: (response: ZCVPApiResponse) => {
            addRequest(response);
            setIsCompanyDetailDisabled(true);
        }
    }), [updateVendorCompanyController, companyData?.id, allFields, companyDetailValues, addRequest]);

    const updateLegalInformation = useCallback(({ isEdited, values }) => updateVendorCompanyController.executeSaveLegalInfo({
        updateType: VendorUpdateType.LEGAL_INFORMATION,
        recordId: Number(companyData?.id),
        vendorId: Number(companyData?.id),
        isEdited,
        fields: allFields,
        oldValues: legalInfoValues,
        newValues: values,
        onRequestSubmit: (response: ZCVPApiResponse) => {
            addRequest(response);
            setIsLegalInfoDisabled(true)
        }
    }), [updateVendorCompanyController, companyData?.id, allFields, legalInfoValues, addRequest]);
    
    const getCancelButton = useCallback((disabledFlag: boolean, buttonKey: string, requestIds: string[]) => {
        const [requestId] = requestIds;
        return vendorCompanyDataController.executeGetCancelButton({
            buttonKey,
            icon: <DeleteOutlined />,
            canCancel: disabledFlag,
            recordId: requestId,
            cancelParams: {
                onSubmit: cancelRequest,
                onSuccess: () => removeRequest(requestId),
                messageApi
            }
        });
    }, [vendorCompanyDataController, cancelRequest, removeRequest, messageApi]);


    return (
        <TabPageContent
            items={
                <>
                    <CardComponent title={t('company_details_card_title')}>
                        <Row>
                            <Col xl={12} lg={16} md={18} sm={20}>
                                <GenericForm
                                    formProps={
                                        {
                                            disabled: isCompanyDetailDisabled,
                                            form: companyDetailsForm,
                                            onFinish: (values: submitFields) => saveValues(companyDetailValues, values, updateCompanyDetails, resetCompanyDetails),
                                        }
                                    }
                                    fields={companyDetailsFields}
                                    fieldValues={addressValues}
                                    id={'vendor_company_details_form'}
                                    disableSubmitTrigger={disableSubmitTrigger.ON_ANY_FIELD_CHANGE}
                                    disableIfUnchanged={true}
                                    actions={[
                                        {
                                            type: ActionType.SUBMIT,
                                            definition: {
                                                label: getCommonT('save_changes'),
                                                key: 'save_vendor_company',
                                                name: 'save_vendor_company',
                                            }
                                        },
                                        ...getCancelButton(isCompanyDetailDisabled, 'cancel_companydetail_request', companyDetailRequestIds)
                                    ]}
                                />
                            </Col>
                        </Row>
                    </CardComponent>
                    <CardComponent title={t('legal_information_card_title')}>
                        <Row>
                            <Col xl={12} lg={16} md={18} sm={20}>
                                <GenericForm
                                    formProps={
                                        {
                                            disabled: isLegalInfoDisabled,
                                            form: legalInformationForm,
                                            onFinish: (values: submitFields) => saveValues(legalInfoValues, values, updateLegalInformation, resetLegalInfo),
                                        }
                                    }
                                    fields={legalInformationFields}
                                    id={'vendor_company_legal_information_form'}
                                    disableSubmitTrigger={disableSubmitTrigger.ON_ANY_FIELD_CHANGE}
                                    disableIfUnchanged={true}
                                    actions={[
                                        {
                                            type: ActionType.SUBMIT,
                                            definition: {
                                                label: getCommonT('save_changes'),
                                                key: 'save_vendor_company',
                                                name: 'save_vendor_company',
                                            }
                                        },
                                        ...getCancelButton(isLegalInfoDisabled, 'cancel_legalinfo_request', legalInfoRequestIds)
                                    ]}
                                />
                            </Col>
                        </Row>
                    </CardComponent>
                    {/* Currently not supported by backend */}
                    {/* <CardComponent title={'Company Avatar'}>
                        <Row>
                            <Col span={8}>
                                {avatarFields.map((avatarField, index) => {
                                    const label: VendorCompanyFieldKey = avatarField[0].name as VendorCompanyFieldKey;
                                    const value = editableValues ? editableValues[label] : '';

                                    return (
                                        <Row key={`avatar_upload_${index}`} align='middle'>
                                            {value &&
                                                <img src={value as string} alt={'avatar'} className={'max-w-xs max-h-xs'} />
                                            }
                                            <Upload
                                                name={'avatar'}
                                                maxCount={1}
                                                customRequest={({ file }) => {
                                                    file = file as Blob;
                                                    uploadAvatar({
                                                        data: file,
                                                        headers: {
                                                            'Content-Type': file.type
                                                        }
                                                    });
                                                }}
                                            >
                                                TODO: Add logo support to backend
                                                <Button type={'default'} className={'ml-3'} data-testid={'edit_avatar_btn'}><EditOutlined /> Edit</Button>
                                            </Upload>
                                        </Row>
                                    );
                                }
                                )}

                            </Col>
                        </Row>
                    </CardComponent> */}
                </>
            }

        />
    );
}
