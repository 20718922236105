import { CheckRolePermissionGateway } from './checkRolePermissionGateway';
import { CheckRolePermissionUseCase } from './checkRolePermissionUseCase';
import { UserSliceInitialState } from '../../slice/UserSlice';

import { UserState } from '../../@types/zcvp_state';
import { ApiPermission, ApiRecord } from '../../@types/zcvp_api_enum';
import { PortalFeature } from '../../@types/role-permissions/zcvp_role_permissions_enum';

export class CheckAppRolePermissionController {
    private user: UserState;
    constructor(role: ApiRecord.AppRole) {
        this.user = this.getPermissionCheckParams(role);
    }
    public executeFeatureCheck(feature: PortalFeature): boolean {
        const gateway = new CheckRolePermissionGateway(this.user);
        const useCase = new CheckRolePermissionUseCase(gateway);
        return useCase.checkFeature(feature);
    }
    private getPermissionCheckParams(role: ApiRecord.AppRole): UserState {
        return {
            ...UserSliceInitialState,
            isVendor: role.isVendor,
            perms: this.getPermissionsFromRoleList(role.perms)
        };
    }
    private getPermissionsFromRoleList(permissions: ApiRecord.AppRolePermissionField[]): ApiPermission.Permission[] {
        const ROLE_PERMISSION_DELIMETER = ' : ';
        return permissions?.map(permission => {
            const [action, target] = permission.text.split(ROLE_PERMISSION_DELIMETER);
            return {action: action as ApiPermission.Action, target: target as ApiPermission.Target, selector: false};
        }) || [];
    }
}