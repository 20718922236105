import { SubsidiaryQueryData } from '../../@types/zcvp_base_types';
import { Field, FieldKey, IGetConfigurationDataPresenter, apiFieldKeys } from '../../@types/zcvp_configuration';
import { BaseFieldKey, FieldDefinitionType } from '../../@types/zcvp_form';
import { FormFieldType } from '../../@types/zcvp_form_enum';
import i18n from '../../i18n';
import { TranslationNS } from '../../lib/TranslationHelper';
import { BaseDataPresenter } from '../BaseDataPresenter';

export class GetConfigurationDataPresenter extends BaseDataPresenter<FieldKey> implements IGetConfigurationDataPresenter {
    public subsidiaries: SubsidiaryQueryData;

    constructor(subsidiaries: SubsidiaryQueryData) {
        super();
        this.subsidiaries = subsidiaries;
        if (this.subsidiaries?.data) {
            // remove the Subsidiary field if there is only one subsidiary available
            if (this.subsidiaries.data.length <= 1) {
                delete this.fieldDefinition[Field.SUBSIDIARY];
                this.companyAccountFieldsLayout = this.companyAccountFieldsLayout.filter((row) => row[0] !== Field.SUBSIDIARY);
            } else {
                this.fieldDefinition[Field.SUBSIDIARY].options = this.subsidiaries.data!.map((subsidiary) => ({
                    label: subsidiary.name,
                    value: subsidiary.id,
                    id: subsidiary.id,
                }));
            }
        }
    }

    public fieldDefinition: FieldDefinitionType = {
        [Field.COMPANY_NAME]: {
            name: apiFieldKeys.COMPANY_NAME,
            type: FormFieldType.TEXT,
            label: i18n.t('configuration.legal_name_label', { ns: TranslationNS.USER }),
        },
        [Field.ADDRESS]: {
            name: apiFieldKeys.ADDRESS,
            type: FormFieldType.TEXTAREA,
            label: i18n.t('configuration.address_label', { ns: TranslationNS.USER }),
        },
        [Field.SUBSIDIARY]: {
            name: apiFieldKeys.SUBSIDIARY,
            type: FormFieldType.SELECT,
            label: i18n.t('configuration.primary_subsidiary_label', { ns: TranslationNS.USER }),
        },
        [Field.SENDER_SUPPORT_EMAIL]: {
            name: Field.SENDER_SUPPORT_EMAIL,
            type: FormFieldType.TEXT,
            label: i18n.t('configuration.support_email_label', { ns: TranslationNS.USER }),
        },
        [Field.ID]: {
            name: apiFieldKeys.ID,
            type: FormFieldType.TEXT,
            label: 'ID',
        },
        [Field.NAME]: {
            name: apiFieldKeys.NAME,
            type: FormFieldType.TEXT,
            label: i18n.t('configuration.company_name_label', { ns: TranslationNS.USER }),
        },
        [Field.LOGO_URL]: {
            name: apiFieldKeys.LOGO_URL,
            type: FormFieldType.TEXT,
            label: i18n.t('configuration.logo_label', { ns: TranslationNS.USER }),
        }
    };

    /**
     * Fields the user can view
     */
    public viewFieldsLayout: BaseFieldKey<FieldKey>[][] = [
        [Field.NAME],
        [Field.COMPANY_NAME],
        [Field.ADDRESS],
        [Field.SENDER_SUPPORT_EMAIL]
    ];

    /**
     * Fields the user can edit
     */
    public companyAccountFieldsLayout: BaseFieldKey<FieldKey>[][] = [
        [Field.SUBSIDIARY],
    ];

    /**
     * Logo fields
     */
    public logoFieldsLayout: BaseFieldKey<FieldKey>[][] = [
        [Field.LOGO_URL],
    ];

}
