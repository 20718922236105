import { BaseFieldKey, FieldDefinitionType, FormFieldDefinition } from '../@types/zcvp_form';
import { FormFieldType } from '../@types/zcvp_form_enum';
import { RecordData } from './BaseDataUseCase';
import { IBaseDataPresenter } from './IBaseDataPresenter';

export abstract class BaseDataPresenter<T extends BaseFieldKey> implements IBaseDataPresenter {
    public abstract fieldDefinition: FieldDefinitionType;

    public convertFields(fieldsLayout: BaseFieldKey<T>[][]): FormFieldDefinition[][] {
        return fieldsLayout.map((fieldKey: BaseFieldKey<T>[]) => fieldKey.map((fieldKey: BaseFieldKey<T>) => this.getField(fieldKey.toString())));
    }

    /**
     * Convert values to string for select fields, because the values are numbers in the API response,
     * but the select field expects strings.
     * 
     * @param values values to convert
     * @param fieldDefinition Field definition to use for conversion
     * @returns Converted values
     */
    public convertValues<T2 extends RecordData>(values: T2, fieldDefinition: FieldDefinitionType): T2 {
        const convertedValues: Partial<T2> = { ...values };

        Object.keys(values || {}).forEach((key: string) => {
            const fieldKey = this.getFieldKey(key);
            if (fieldKey) {
                const field = fieldDefinition[fieldKey];
                if (field) {
                    if (field.type === FormFieldType.SELECT) {
                        if (values[key] !== undefined && values[key] !== null) {
                            convertedValues[fieldKey] = values[key]!.toString() as T2[T];
                        }
                    } else if (typeof values[fieldKey] === 'object' && values[fieldKey] !== null) {
                        convertedValues[fieldKey] = this.convertValues(values[fieldKey] as unknown as T2, fieldDefinition);
                    }
                }
            }
        });

        return convertedValues as T2;
    }

    public getFieldKey(fieldKey: string): T {
        return fieldKey as T;
    }

    public getField(fieldKey: string): FormFieldDefinition {
        return this.fieldDefinition[this.getFieldKey(fieldKey)];
    }
}