import { ApiRecord } from '../../@types/zcvp_api_enum';

export class DashboardGateway {
    public mandatoryFields = [
        ApiRecord.VendorField.ACTIVE,
        ApiRecord.VendorField.ADDRESSES,
        ApiRecord.VendorField.BANK_ACCOUNTS,
        ApiRecord.VendorField.COMPANY_ID,
        ApiRecord.VendorField.COMPANY_NAME,
        ApiRecord.VendorField.CREATED_AT,
        ApiRecord.VendorField.EMAIL,
        ApiRecord.VendorField.ID,
        ApiRecord.VendorField.LEGAL_NAME,
        ApiRecord.VendorField.NAME,
        ApiRecord.VendorField.PHONE_NUMBER,
        ApiRecord.VendorField.SIZE,
        ApiRecord.VendorField.STATUS,
        ApiRecord.VendorField.SUBSIDIARY_ID,
        ApiRecord.VendorField.TAX_ID,
        ApiRecord.VendorField.UPDATED_AT,
        ApiRecord.VendorField.VENDOR_CATEGORY,
        ApiRecord.VendorField.VENDOR_CATEGORY_ID,
    ];
}