
import { AddressValues } from '../../@types/get-address/zcvp_get_address';
import { RecordQueryData, TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import { CancelActionProps, IVendorCompanyBankDetailsData, IVendorCompanyData, VendorRecordQueryData } from '../../@types/vendor-company/zcvp_vendor_company';
import { ApiRecord, ApiVendorRequestType } from '../../@types/zcvp_api_enum';
import { isEmptyObject } from '../../lib/Utils';
import { Feature } from '../../@types/submit-record/zcvp_submit_record_enum';
import { SubmitRecordController } from '../submitRecord/submitRecordController';
import { VendorCompanyActionPresenter } from './vendorCompanyActionPresenter';
import { VendorCompanyActionUseCase } from './vendorCompanyActionUseCase';
import { VendorCompanyBankDetailsGateway } from './vendorCompanyBankDetailsGateway';
import { VendorCompanyBankDetailsPresenter } from './vendorCompanyBankDetailsPresenter';
import { VendorCompanyBankDetailsUseCase } from './vendorCompanyBankDetailsUseCase';
import { VendorCompanyDataUseCase } from './vendorCompanyDataUseCase';
import { VendorCompanyGateway } from './vendorCompanyGateway';
import { VendorCompanyPresenter } from './vendorCompanyPresenter';
const { VendorRequestField } = ApiRecord;

type VendorCompanyDataControllerParams = {
    vendorRecord: VendorRecordQueryData;
    vendorCategories: RecordQueryData;
    currencies: RecordQueryData;
    isShippingSameAsBilling?: boolean;
    isCompanyDetailDisabled?: boolean;
    billingAddress?: AddressValues;
    shippingAddress?: AddressValues;
}
export class GetVendorCompanyDataController {
    public execute({ vendorRecord: vendorCompany, vendorCategories, currencies, isShippingSameAsBilling, isCompanyDetailDisabled }: VendorCompanyDataControllerParams): IVendorCompanyData {
        const vendorCompanyDataGateway = new VendorCompanyGateway(vendorCompany);
        const vendorCompanyDataPresenter = new VendorCompanyPresenter(vendorCategories, currencies);
        const vendorCompanyDataUseCase = new VendorCompanyDataUseCase(vendorCompanyDataGateway, vendorCompanyDataPresenter);

        return vendorCompanyDataUseCase.execute({ vendorCompany, isShippingSameAsBilling, isCompanyDetailDisabled });
    }
    public executeGetFields(params) {
        const { vendorCategories, currencies } = params || {};
        const vendorCompanyDataPresenter = new VendorCompanyPresenter(vendorCategories || {}, currencies || {});
        const vendorCompanyDataUseCase = new VendorCompanyDataUseCase(null, vendorCompanyDataPresenter);
        return vendorCompanyDataUseCase.getFields();
    }
    public executeGetBankFields() {
        const vendorCompanyDataPresenter = new VendorCompanyBankDetailsPresenter();
        const vendorCompanyDataUseCase = new VendorCompanyBankDetailsUseCase(null, vendorCompanyDataPresenter);
        return vendorCompanyDataUseCase.getFields();
    }
    public executeGetCompanyDetails({ vendorRecord: vendorCompany, requestsQuery }) {
        const requestData = this.getVendorRequestData(requestsQuery);
        const vendorCompanyDataGateway = new VendorCompanyGateway(vendorCompany);
        const vendorCompanyDataPresenter = new VendorCompanyPresenter({}, {});
        const vendorCompanyDataUseCase = new VendorCompanyDataUseCase(vendorCompanyDataGateway, vendorCompanyDataPresenter);
        return vendorCompanyDataUseCase.getEditableValues({ vendorCompany: vendorCompany?.data, requests: requestData });
    }
    public executeBankDetails(vendorRecord: VendorRecordQueryData, requestsQuery): IVendorCompanyBankDetailsData {
        const requestData = this.getBankRequestData(requestsQuery);
        const vendorCompanyDataGateway = new VendorCompanyBankDetailsGateway(vendorRecord);
        const vendorCompanyDataPresenter = new VendorCompanyBankDetailsPresenter();
        const vendorCompanyDataUseCase = new VendorCompanyBankDetailsUseCase(vendorCompanyDataGateway, vendorCompanyDataPresenter);

        return vendorCompanyDataUseCase.execute(vendorRecord, requestData);
    }
    public filterChangeRequests(recordTypes: string[], requestsQuery: VendorRecordQueryData): TableRecord[] {
        return requestsQuery?.data?.filter((request: TableRecord) => request[VendorRequestField.TYPE] === ApiVendorRequestType.RECORD_CHANGE)
        .map((request: TableRecord) => ({
            requestId: request.id,
            recordChanges: (request.recordChanges as unknown as TableRecord[])?.filter((recordChange: TableRecord) => 
                recordTypes.includes(recordChange[VendorRequestField.RECORD_TYPE] as string) && 
                !isEmptyObject(recordChange[VendorRequestField.RECORD_VALUES])
            )
        }))
        .filter((request: TableRecord) => request.recordChanges?.length);
    }
    private getVendorRequestData(requestsQuery: VendorRecordQueryData) {
        const requests = this.filterChangeRequests([
            ApiRecord.Type.VENDOR, ApiRecord.Type.ADDRESS
        ], requestsQuery);

        return requests?.reduce((allRequests, { requestId, recordChanges }) => {
            const { vendorRecordChanges, billingAddress, shippingAddress } = this.getVendorData(recordChanges)
            const vendorData = { requestId, ...vendorRecordChanges };
            billingAddress && (vendorData.billing_address = billingAddress.values);
            shippingAddress && (vendorData.shipping_address = shippingAddress.values);
            allRequests.push(vendorData);
    
            return allRequests;
        }, []);
    }
    private getBankRequestData(requestsQuery: VendorRecordQueryData): TableRecord[] {
        return this.filterChangeRequests([ApiRecord.Type.BANK_ACCOUNT], requestsQuery)
        ?.map(({ requestId, recordChanges }) => ({
            requestId,
            ...((recordChanges as TableRecord[])?.reduce((allChanges, changes) => ({
                ...allChanges, ...(changes?.values || {}), id: changes?.recordId
            }), {}) as unknown as TableRecord)
        }));
    }
    private getVendorData(recordChanges: TableRecord[]): { vendorRecordChanges: TableRecord, billingAddress: TableRecord, shippingAddress: TableRecord } {
        const vendorRecordChanges = recordChanges?.find((recordChange) => recordChange[VendorRequestField.RECORD_TYPE] === ApiRecord.Type.VENDOR)?.values as unknown as TableRecord;
        const billingAddress = recordChanges?.find((recordChange) => 
            recordChange[VendorRequestField.RECORD_TYPE] === ApiRecord.Type.ADDRESS && 
            (recordChange[VendorRequestField.RECORD_VALUES]! as unknown as TableRecord).isDefaultBilling
        ) as unknown as TableRecord;
        const shippingAddress = recordChanges?.find((recordChange) => 
            recordChange[VendorRequestField.RECORD_TYPE] === ApiRecord.Type.ADDRESS && 
            (recordChange[VendorRequestField.RECORD_VALUES]! as unknown as TableRecord).isDefaultShipping
        ) as unknown as TableRecord;
        return { vendorRecordChanges, billingAddress, shippingAddress };
    }
    public executeGetCancelButton({ canCancel, buttonKey, recordId, icon, cancelParams }) {
        const presenter = new VendorCompanyActionPresenter({ icon });
        const useCase = new VendorCompanyActionUseCase(presenter);
        return useCase.getCancelButton({
            canCancel, buttonKey, recordId, cancelParams, cancelCallback: this.cancelCallback
        });
    }
    private cancelCallback(requestId: number, cancelParams: CancelActionProps) {
        new SubmitRecordController(cancelParams)
        .execute(Feature.VENDOR_REQUEST_CANCELLATION, {
            id: requestId
        });
    }
}
