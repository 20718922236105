import { PortalTutorialStep, PortalTutorialStepData, TutorialStepPresenter, TutorialStepUseCaseOutput } from "../../@types/tutorial/zcvp_tutorial";

export const NextButtonLabel = {
    START_TOUR: 0,
    TOUR_COMPLETE: 1,
    DEFAULT: 2
}
export class GetTutorialStepsUseCase {
    private presenter: TutorialStepPresenter;
    constructor(presenter: TutorialStepPresenter) {
        this.presenter = presenter;
    }
    public execute(tutorialSteps: PortalTutorialStep[], userName: string): PortalTutorialStepData[] {
        const allSteps: TutorialStepUseCaseOutput[] = tutorialSteps.map((tutorialStep, index) => ({
            ...tutorialStep,
            nextButtonKey: this.getNextButtonKey(tutorialSteps, index)
        }));
        return this.presenter.convert(allSteps, userName);
    }
    private getNextButtonKey(tutorialSteps: PortalTutorialStep[], index: number): number {
        const START_TOUR = 0;
        const TOUR_COMPLETE = tutorialSteps.length - 1;
        switch(index) {
            case START_TOUR:
                return NextButtonLabel.START_TOUR;
            case TOUR_COMPLETE:
                return NextButtonLabel.TOUR_COMPLETE;
            default:
                return NextButtonLabel.DEFAULT
        }
    }
}