import { TableRecord, TableRowValue } from '../../@types/list-details/zcvp_get_list_details';
import { FieldListMap, FieldMap, SubmitRecordGatewayInterface } from '../../@types/submit-record/zcvp_submit_record';
import { Feature, RecordType } from '../../@types/submit-record/zcvp_submit_record_enum';
import { ZCVPApiResponse } from '../../@types/zcvp_api';
import { ApiRecord } from '../../@types/zcvp_api_enum';
import { WebProtocol } from '../../lib/Constants';
const { VendorField, CompanyField, ApprovalField } = ApiRecord;

export class SubmitRecordGateway implements SubmitRecordGatewayInterface{
    private onSubmit: Function|undefined;
    private FieldMapping: FieldMap = {
        [RecordType.VENDOR]: {
            companyName: VendorField.COMPANY_NAME,
            size: VendorField.SIZE,
            category: VendorField.VENDOR_CATEGORY_ID,
            phone: VendorField.PHONE,
            altPhone: VendorField.ALT_PHONE,
            email: VendorField.EMAIL,
            subsidiaryId: VendorField.SUBSIDIARY_ID,
            url: VendorField.COMPANY_URL
        },
        [Feature.APPROVALS_CONFIG]: {
            [CompanyField.ID]: CompanyField.ID,
            [CompanyField.APPROVALS_TO_ONBOARD]: CompanyField.APPROVALS_TO_ONBOARD,
            [CompanyField.APPROVALS_AFTER_ONBOARD]: CompanyField.APPROVALS_AFTER_ONBOARD,
        },
        [Feature.APPROVALS_ACTION]: {
            [ApprovalField.IDS]: ApprovalField.IDS,
            [ApprovalField.APPROVED]: ApprovalField.APPROVED,
        },
    };
    private UrlFieldMapping: FieldListMap = {
        [RecordType.VENDOR]: [VendorField.COMPANY_URL]
    };
    constructor(onSubmit?: Function) {
        this.onSubmit = onSubmit;
    }
    public submitRecord(recordType: RecordType|Feature, values: TableRecord): Promise<ZCVPApiResponse> {
        const fieldValues = this.getFieldValues(recordType, values);
        return this.onSubmit && this.onSubmit(fieldValues);
    }
    public getFieldValues(recordType: RecordType|Feature, values: TableRecord, defaultToNull = true): TableRecord {
        const fieldMap = this.getFieldMapping(recordType, values);
        const fieldValues: TableRecord = {};
        for(let formFieldId in values) {
            const fieldId: string = fieldMap[formFieldId];
            const fieldValue = this.getFieldValue(recordType, formFieldId, values);
            const updatedFieldValue = defaultToNull ? fieldValue ?? null : fieldValue;
            fieldId && (fieldValues[fieldId] = updatedFieldValue);
        }
        return fieldValues;
    }
    private getFieldMapping(recordType: RecordType|Feature, values: TableRecord) {
        return this.FieldMapping[recordType] || Object.keys(values).reduce((fieldMapping, currentKey) => {
            fieldMapping[currentKey] = currentKey;
            return fieldMapping;
        }, {} as TableRecord);
    }
    public getFieldValue(recordType: RecordType|Feature, formFieldId: string, values: TableRecord): TableRowValue|null {
        let fieldValue = values[formFieldId];

        const urlFields = this.UrlFieldMapping[recordType] || [];
        if(urlFields.includes(formFieldId)) {
            fieldValue = this.getUrlValue(fieldValue as string);
        }

        return fieldValue;
    }
    public getUrlValue (urlValue: string): string|null {
        let updatedUrlValue = urlValue;
        const hasProtocol = [WebProtocol.HTTP, WebProtocol.HTTPS].some(protocol => updatedUrlValue?.startsWith(protocol));
        return hasProtocol ? updatedUrlValue : updatedUrlValue ? `${WebProtocol.HTTPS}${updatedUrlValue}` : null;
    }
}