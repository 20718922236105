import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { HistoryOutlined, SearchOutlined, ShopOutlined, TagsOutlined, UserOutlined } from '@ant-design/icons';
import { Badge, Button, Checkbox, Divider, Dropdown, Input, Tag } from 'antd';

import { ApiRecord } from '../../@types/zcvp_api_enum';
import '../../styles/zcvp_tabbed_page.scss';
import { getCommonT } from '../../lib/TranslationHelper';

export type FilterItem = {
    value: string;
    text: string;
}
export type FilterMap = {
    [key: string]: string[];
}
export type Filter = {
    key: string;
    value: string;
    title: string[];
    filters: FilterItem[];
}
type FilterOption = {
    key: string;
    value: string;
    label: string;
}
type TableFilterDropdownContentProps = {
    value: string[];
    filters: FilterOption[];
    onFilter: (value: string[]) => void;
    onClose: () => void;
}
type TableFilterDropdownProps = {
    filter: Filter;
    appliedFilters: FilterMap;
    onFilter: (value: FilterMap) => void;
}

const getFilterIcon = (fieldId: string) => {
    switch(fieldId) {
        case ApiRecord.CommonField.STATUS: 
            return <HistoryOutlined />;
        case ApiRecord.VendorRequestField.VENDOR_ID:
            return <ShopOutlined />;
        case ApiRecord.VendorField.VENDOR_CATEGORY:
            return <TagsOutlined />;
        case ApiRecord.UserField.ROLES:
            return <UserOutlined />;
    }
};

const TableFilterDropdownContent = ({ value, filters, onFilter, onClose }: TableFilterDropdownContentProps) => {
    const [checked, setChecked] = useState<string[]>([]);
    const [filterSearchValue, setFilterSearchValue] = useState<string>('');
    const container = useRef<HTMLDivElement>(null);

    const availableFilters = useMemo(() => filterSearchValue ? filters.filter(filter => 
        filter.label.toLowerCase().includes(filterSearchValue.toLowerCase())
    ) : filters, [filterSearchValue, filters]);

    const onSearch = useCallback(({ target: { value }}) => setFilterSearchValue(value), []);
    const onChange = useCallback((selected: string[]) => setChecked(selected), []);

    const onReset = useCallback(() => {
        container.current?.focus();
        setChecked([]);
    }, [container]);

    const onOk = useCallback(() => {
        onFilter(checked);
        onClose();
    }, [onFilter, checked, onClose]);

    const onBlur = useCallback((event) => {
        !event.currentTarget.contains(event.relatedTarget) && onClose();
    }, [onClose]);

    useEffect(() => setChecked(value), [value]);
    useEffect(() => container.current?.focus(), [container]);

    return <div className='zcvp-filter-dropdown bg-white p-2' tabIndex={1} ref={container} onBlur={onBlur}>
            <div className='zcvp-search-filter-input'>
                <Input
                prefix={<SearchOutlined/>}
                suffix={null}
                placeholder={getCommonT('search_filter')}
                onChange={onSearch}
                allowClear />
            </div>
            <Divider/>
            <div className='zcvp-search-filter-checkbox-group'>
                <Checkbox.Group
                    className='grid'
                    options={availableFilters}
                    onChange={onChange}
                    value={checked}
                />
            </div>
            <Divider/>
            <div className='zcvp-filter-dropdown-button-panel'>
                <Button size='small' type='text' onClick={onReset} disabled={!checked?.length} danger>{getCommonT('reset')}</Button>
                <Button size='small' type='primary' onClick={onOk}>{getCommonT('ok')}</Button>
            </div>
    </div>;
};

const TableFilterDropdown = ({ filter, appliedFilters, onFilter }: TableFilterDropdownProps) => {
    const [open, setOpen] = useState(false);
    const getOnClick = useCallback((value: boolean) => () => setOpen(value), []);

    return <Dropdown
        open={open}
        trigger={['click']}
        menu={{
            items: filter.filters.map(filter => ({
                key: filter.value,
                value: filter.value,
                label: filter.text 
            }))
        }}
        dropdownRender={(menu: React.ReactNode) => <TableFilterDropdownContent
            value={appliedFilters[filter.key]}
            filters={(menu as { props: { items: FilterOption[] }}).props.items}
            onFilter={(selected) => {
                onFilter({
                    ...appliedFilters,
                    [filter.key]: selected
                });
            }}
            onClose={getOnClick(false)}
        />}
        destroyPopupOnHide>
        <div>
            <Badge
                count={appliedFilters[filter.key]?.length || 0}
                size='small'
                showZero={true}>
            </Badge>
            <Tag
                icon={getFilterIcon(filter.key)}
                className={appliedFilters[filter.key]?.length ? 'zcvp-active-filter' : ''}
                onClick={getOnClick(true)}>
                {filter.title}
            </Tag>
        </div>
    </Dropdown>;
};

export default TableFilterDropdown;