import React from 'react';
import { FormElementDefinition } from '../../../@types/zcvp_form';

const InlineField = ({ label, value, type } : {label?: FormElementDefinition['label'], value?: FormElementDefinition['value'], type?: FormElementDefinition['type']}) => <div className={'flex flex-row'} key={`field_line_${label}_${type}`}>
    <div className={'basis-1/6 zcvp-inline-field-label'}>
        {label}
    </div>
    <div className={'basis-4/6 zcvp-inline-field-value'}>
        {value}
    </div>
</div>;

export default InlineField;