import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Avatar, Skeleton, TableProps, Tag } from 'antd';
import { FileOutlined } from '@ant-design/icons';

import VendorRequestDetails from './VendorRequestDetail';
import TableCard from '../../components/partials/TableCard';
import VendorDetails from '../vendor-management/VendorDetails';
import { LabelWithIcon } from '../../components/partials/StyledText';
import { TranslationNS } from '../../lib/TranslationHelper';
import { useGetAllVendorsQuery, useGetMetaDataQuery, useGetRequestsQuery } from '../../slice/APISlice';
import { getRequestVendorFilterOptions, getVendorRequestStatusFilterOptions, getVendorRequestTypeFilterOptions } from '../../use-case/getVendors/getVendorsUseCase';
import { GetVendorRequestsController } from '../../use-case/vendorRequests/getVendorRequestsController';
import { UserState } from '../../@types/zcvp_state';
import { FieldQueryData, ListMap, TableColumnType, TableRecord, TableRowValue } from '../../@types/list-details/zcvp_get_list_details';
import { ApiRecord, ApiRequest, ApiRequestStatus } from '../../@types/zcvp_api_enum';
import { ListId } from '../../@types/list-details/zcvp_get_list_details_enum';
import '../../styles/zcvp_vendor_details.scss';
import { RoutesUrls } from '../../@types/zcvp_routes_enum';
import { useNavigateToRecord } from '../../lib/CustomHooks';

type RequestTableProps = {
    filterValue?: string;
    tableProps?: TableProps;
    filter?: ApiRequest.FilterModel;
    lookup?: ListMap;
}

const RequestName: Function = (value: string) => <LabelWithIcon
    flexText={false}
    label={value}
    leftIcon={<Avatar
        size='small'
        className={'zcvp-request-avatar'}
        icon={<FileOutlined />}/>
    }
/>;
const RequestVendor: Function = (_value: TableRowValue, { vendorName }: TableRecord) => vendorName;
const RequestType: Function = (_value: TableRowValue, { requestTypeText }: TableRecord) => requestTypeText;
const RequestStatus: Function = (_value: TableRowValue, { color, statusText }: TableRecord) => <Tag color={color as string}>{statusText}</Tag>;
const getRenderFunction = (dataIndex: string): Function | undefined => {
    switch (dataIndex) {
        case ApiRecord.VendorRequestField.TITLE:
            return RequestName;
        case ApiRecord.VendorRequestField.VENDOR_ID:
            return RequestVendor;
        case ApiRecord.VendorRequestField.STATUS:
            return RequestStatus;
        case ApiRecord.VendorRequestField.TYPE:
            return RequestType;
    }
};

const VendorRequestTable: React.FC<RequestTableProps> = ({ filterValue, filters, setFilters, tableProps, filter = {} as ApiRequest.FilterModel, lookup = {} }: RequestTableProps) => {
    const { t } = useTranslation(TranslationNS.VENDOR, {
        keyPrefix: TranslationNS.VENDOR_REQUEST
    });
    const { requestId } = useParams();
    const navigate = useNavigateToRecord();
    const isVendor = useSelector((state: { user: UserState }) => state.user.isVendor);
    const [viewedRecord, setViewedRecord] = useState<TableRecord | undefined>();
    const [viewedVendorRecord, setIsViewedVendorRecord] = useState<TableRecord | undefined>();

    const columnQueryData: FieldQueryData = useGetMetaDataQuery({});
    const { data: vendorsList, isFetching } = useGetAllVendorsQuery({}, { skip: isVendor });
    const recordQueryData = useGetRequestsQuery(filter);
    const requestsList = useMemo(() => recordQueryData?.data, [recordQueryData?.data]);

    const listId = useMemo(() => isVendor ? ListId.VENDOR_REQUEST : ListId.REQUEST, [isVendor]);
    const lists = useMemo(() => ({
        [ApiRecord.VendorRequestField.STATUS]: getVendorRequestStatusFilterOptions(isVendor),
        [ApiRecord.VendorRequestField.TYPE]: getVendorRequestTypeFilterOptions(),
        [ApiRecord.VendorRequestField.VENDOR_ID]: getRequestVendorFilterOptions(vendorsList, requestsList),
        ...(lookup || {})
    }), [vendorsList, requestsList, lookup]);

    const requestData = useMemo(() => new GetVendorRequestsController(vendorsList)
        .executeGetRequestList({
            columnQueryData,
            recordQueryData,
            lists,
            listId,
            filterValue,
            filters,
            isVendor
        }), [vendorsList, listId, columnQueryData, recordQueryData, filterValue, lists, filters, isVendor]);

    const columns = useMemo<TableColumnType[]>(() => requestData.columns.map(column => ({
        ...column,
        render: getRenderFunction(column.dataIndex)
    })), [requestData.columns]);

    const onRequestClick = useCallback((record: TableRecord) => () => {
        lookup
            ? setViewedRecord(record)
            : navigate(RoutesUrls.REQUESTS, record?.id as unknown as number);
    }, [lookup, navigate]);

    const onClose = useCallback((setterFn) => () => {
        setterFn(undefined);
        navigate(RoutesUrls.REQUESTS);
    }, [navigate]);

    const onVendorDetailClick = useCallback((record: TableRecord) => {
        onClose(setViewedRecord)();
        setIsViewedVendorRecord(record);
    }, [onClose]);

    useEffect(() => {
        const requestRecord = requestId ? { id: requestId } : undefined;
        setViewedRecord(requestRecord);
    }, [requestId]);

    const actions = [{
        name: 'open-btn',
        key: 'open-btn',
        label: t('open'),
        show: (record: TableRecord) => isVendor && record[ApiRecord.VendorRequestField.STATUS] === ApiRequestStatus.NEW,
        onClick: (_event: MouseEvent, record: TableRecord) => onRequestClick(record)()
    }, {
        name: 'review-btn',
        key: 'review-btn',
        label: t('review'),
        show: (record: TableRecord) => !isVendor && record[ApiRecord.VendorRequestField.STATUS] === ApiRequestStatus.REVIEW,
        onClick: (_event: MouseEvent, record: TableRecord) => onRequestClick(record)()
    }];

    return <Skeleton loading={isFetching}>
        {viewedVendorRecord ? <VendorDetails record={viewedVendorRecord} onClose={onClose(setIsViewedVendorRecord)} /> : null}
        {viewedRecord ? <VendorRequestDetails record={viewedRecord!} onClose={onClose(setViewedRecord)} onVendorDetailClick={onVendorDetailClick} /> : null}
        <TableCard
            dataSource={requestData.dataSource}
            columns={columns}
            actions={actions}
            onRow={(record: TableRecord) => ({
                onClick: onRequestClick(record)
            })}
            setFilters={setFilters}
            tableProps={tableProps}
            />
    </Skeleton>;
};

export default VendorRequestTable;