import React, { useMemo } from 'react';
import { Tag } from 'antd';
import { getVendorStatus, getVendorStatusColor } from '../../use-case/getVendors/getVendorsUseCase';

const VendorStatus = ({ status }: { status: string }) => {
    const color = useMemo(() => getVendorStatusColor(status), [status]);
    const statusText = useMemo(() => getVendorStatus(status), [status]);
    return status ? <Tag color={color}>{statusText}</Tag> : null;
}

export default VendorStatus;