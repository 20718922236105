export type ZCVPVerticalNavData = {
    id: string;
    title: string;
    path: string;
    icon: JSX.Element;
    subtitle?: string;
    children?: ZCVPVerticalNavCollection;
}
export type ZCVPVerticalNavCollection = ZCVPVerticalNavData[];

export type ZCVPVerticalNavStationaryLiProps = {
    path: string;
    title: string;
    target?: string;
}
//Allowed Menu Keys
export enum ZCVPMenuEntryKeys {
    MAIN = '',
    DASHBOARD = 'dashboard',
    VENDORS = 'vendors',
    PROFILE = 'profile',
    CONFIGURATION = 'configuration',
    USERS = 'users',
    HELP = 'help',
    REQUESTS = 'requests',
    COMPANY = 'company',
    HOME = 'home',
}

export type ZCVPRoles = 'customer' | 'vendor';
/**
 * Ordered by role, then page type
 */
export type ZCVPNavigationConfig = Object<ZCVPRoles, string[]>;