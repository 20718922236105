import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export type LoaderState = {
    isLoading?: boolean;
    isSwitchingProfile?: boolean;
    percentCompleted?: number
}
export type LoaderStateKeyTypes = keyof LoaderState;

export const LoaderSliceInitialState = {
    isLoading: false,
    isSwitchingProfile: false,
    percentCompleted: 100
} as LoaderState;

const loaderSlice = createSlice({
    name: 'loader',
    initialState: LoaderSliceInitialState,
    reducers: {
        updateLoaderInfo(state, action: PayloadAction<LoaderState>) {
            const payloadKeys = Object.keys(action.payload) as LoaderStateKeyTypes[];
            payloadKeys.forEach((key : LoaderStateKeyTypes) => {
                const value = action.payload[key];
                if (state[key] === value) {
                    return;
                }
                (state as Record<typeof key, typeof value>)[key] = value;
            });
        },
    },
});

export const { updateLoaderInfo } = loaderSlice.actions;
export default loaderSlice.reducer;