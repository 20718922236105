import React from 'react';

import { useGetAuthentication } from '../../lib/AuthenticationHelper';

const GlobalFooter = () => {
    const authentication = useGetAuthentication();

    return !authentication ? (
        <div className="bottom-0 flex items-center justify-center w-full mb-3">
            <small className={'mr-2'}>Powered by </small><img src={'/zone-capture.png'}
                alt={'Zone Capture Logo'} />
        </div>
    ) : null;
};

export default GlobalFooter;