import { Typography } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';

import GenericActionButton from '../../components/form/form-action/GenericActionButton';
import TableCard from '../../components/partials/TableCard';
import { useGetAllEmployeesQuery, useGetMetaDataQuery } from '../../slice/APISlice';
import { GetListDetailsController } from '../../use-case/getListDetails/getListDetailsController';
import InviteUserModal from './InviteUsersModal';

import { useTranslation } from 'react-i18next';
import { FieldQueryData, TableData, TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import { ListId } from '../../@types/list-details/zcvp_get_list_details_enum';
import { AdditionalField } from '../../@types/role-permissions/zcvp_role_permissions_enum';
import { TranslationNS } from '../../lib/TranslationHelper';

const { Text } = Typography;

type NetSuiteUsersTableProps = {
    filterValue: string;
    onRowClick: Function;
}
const NetSuiteUsersTable: React.FC<NetSuiteUsersTableProps> = ({ filterValue, filters, setFilters, onRowClick }) => {
    const { t } = useTranslation(TranslationNS.USER, { keyPrefix: TranslationNS.USER_MANAGEMENT });
    const columnQueryData: FieldQueryData = useGetMetaDataQuery({});
    const recordQueryData = useGetAllEmployeesQuery({});
    const tableData = useMemo<TableData>(
        () => new GetListDetailsController(columnQueryData, recordQueryData).execute(ListId.NETSUITE_USER, filterValue, filters)
    , [columnQueryData, recordQueryData, filterValue, filters]);
    const users = useMemo(() => tableData.dataSource.map(tableRow => ({
        ...tableRow,
        label: tableRow.fullName || '(Unknown)',
        value: tableRow.id,
        key: tableRow.id,
        registered: tableRow.registered
    })), [tableData.dataSource]);

    const columns = tableData.columns.map((column) => {
        if (column.dataIndex === AdditionalField.STATUS) {
            return {
                ...column,
                render: (status: string) => <Text type="secondary">{status}</Text>
            };
        }
        return column;
    });

    const actions = [{
        name: 'table-action1',
        key: 'table-action1',
        label: 'Action 1',
        show: (_: TableRecord) => false,
        onClick: () => alert('Action 1 is clicked')
    }, {
        name: 'table-action2',
        key: 'table-action2',
        label: 'Action 2',
        show: (_: TableRecord) => false,
        onClick: () => alert('Action 2 is clicked')
    }];

    const [selectedRows, setSelectedRows] = useState<TableRecord[]>([]);
    const [openInviteUser, setOpenInviteUser] = useState(false);

    const userIds = useMemo(() => selectedRows.map(tableRow => tableRow.id), [selectedRows]);

    const closeInviteUser = useCallback(() => setOpenInviteUser(!openInviteUser), [openInviteUser]);
    const onSelect = useCallback((newRows: TableRecord[]) => {
        newRows = newRows.filter(row => !(row.invitationPending || row.registered));
        return setSelectedRows(newRows)
    }, []);
    const onInvite = useCallback(() => setOpenInviteUser(true), []);
    const renderCell = useCallback((_checked, record, _index, _originNode) => 
        !(record.invitationPending || record.registered) && record.fullName && record.email
            ? _originNode : null, [])
    const onSelectAll = useCallback((_selected, selectedRows, _changeRows) => {
        return setSelectedRows(selectedRows.filter(row => !(row.invitationPending || row.registered)))
    }, []);

    return <TableCard
        columns={columns}
        dataSource={tableData.dataSource}
        renderCell={renderCell}
        actions={actions}
        onRow={(record: TableRecord) => ({
            onClick: () => onRowClick && onRowClick(record)
        })}
        onSelect={onSelect}
        onSelectAll={onSelectAll}
        setFilters={setFilters}>
        {openInviteUser ? <InviteUserModal open={openInviteUser} onClose={closeInviteUser} userIds={userIds as string[]} users={users} /> : null}
        <div className='px-3 pb-3'>
            <GenericActionButton
                key="ns-users-tbl-invite-btn"
                name="ns-users-tbl-invite-btn"
                type='primary'
                label={t('invite_with_count', { count: selectedRows.length })}
                onClick={onInvite} />
        </div>
    </TableCard>;
};

export default NetSuiteUsersTable;