import i18next from 'i18next';
import i18n from '../i18n';

export const TranslationNS = {
    // namespaces
    AUTHENTICATION: 'authentication',
    COMMON: 'common',
    NAVIGATION: 'navigation',
    ONBOARDING: 'onboarding',
    USER: 'user',
    VENDOR: 'vendor',
    // key prefixes
    CONFIGURATION: 'configuration',
    APPROVALS: 'approvals',
    INTEGRATION: 'integration',
    USER_MANAGEMENT: 'user_management',
    USER_PROFILE: 'user_profile',
    VENDOR_REQUEST: 'vendor_request',
    DASHBOARD: 'dashboard',
    HISTORY: 'history',
    NOTIFICATION: 'notification'
};

/**
 * Returns the translation for the given key from the common context (Save, Cancel, etc.)
 * 
 * @param key the key to translate
 * @returns the translated string
 */
export const getCommonT = (key: string) => i18n.t(key, { ns: TranslationNS.COMMON });

/**
 * Returns the translation for the given key
 * 
 * @param key the key to translate
 * @param params the parameters to pass to the translation
 * @returns the translated string
 */
export const getFixedT = (key: string, params?: object) => {
    const t = i18n.getFixedT(i18next.language, null, '');

    return t(key, { ...params });
};