import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Input, List } from 'antd';
import { CommentOutlined, SendOutlined, UserOutlined } from '@ant-design/icons';

import { usePostCommentMutation } from '../../slice/APISlice';
import { TranslationNS } from '../../lib/TranslationHelper';
import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import '../../styles/zcvp_vendor_details.scss';
import { isEmpty } from '../../lib/Utils';

enum CharacterKey {
    ENTER = 'Enter'
}
const VendorRequestCommentEditor = ({ requestId }: { requestId: number }) => {
    const { t } = useTranslation(TranslationNS.VENDOR);
    const [comment, setComment] = useState('');

    const [postComment] = usePostCommentMutation();

    const onSubmit = useCallback(() => {
        const trimmedComment = comment.trim();
        trimmedComment && postComment({
            requestId, text: trimmedComment
        });
        setComment(trimmedComment);
        setComment('');
    }, [comment, requestId]);

    const onChange = useCallback((event: { target: { value: string }}) => {
        setComment(event.target.value)
    }, []);

    const onKeyDown = useCallback((event: { key: string, shiftKey: boolean }) => {
        if(event.key === CharacterKey.ENTER && !event.shiftKey) {
            onSubmit();
        }
    }, [onSubmit]);

    const disabledClassName = useMemo(() => !comment.trim() ? 'disabled' : '', [comment]);
    const className = useMemo(() => ['zcvp-send-btn', disabledClassName].join(' '), [disabledClassName]);
    const value = useMemo(() => isEmpty(comment.trim()) ? comment.trim() : comment, [comment]);
    return <div className='zcvp-comment-editor flex items-start'>
        <CommentOutlined  />
        <Input.TextArea
            value={value}
            placeholder={t('write_comment', { keyPrefix: TranslationNS.VENDOR_REQUEST })}
            onChange={onChange}
            onKeyDown={onKeyDown}/>
        <SendOutlined onClick={onSubmit} disabled={!comment} className={className}/>
    </div>;
};

const VendorRequestCommentList = ({ records }: { records: TableRecord[] }) => {
    return <List
        className='zcvp-request-comment-list'
        dataSource={records}
        renderItem={(item) => (
            <List.Item key={item.id}>
                <List.Item.Meta
                    avatar={<Avatar size="small" icon={<UserOutlined />} />}
                    title={(item.createdBy as unknown as TableRecord)?.name as string}
                    description={item.text}
                />
            </List.Item>
        )}
    />
}

const VendorRequestComments = ({ request, comments }: { request: TableRecord, comments: TableRecord[] }) => <div className='pt-4 pb-4'>
    <VendorRequestCommentEditor requestId={request?.id} />
    <VendorRequestCommentList records={comments}/>
</div>;

export default VendorRequestComments;