import { PortalFeature } from "../../@types/role-permissions/zcvp_role_permissions_enum";

type FeatureTooltipMap = {
    [key:string]: string;
}
export class DashboardPresenter {
    private featureTooltipMap: FeatureTooltipMap = {
        [PortalFeature.INVITE_CONTACT]: 'Ask an account owner to manage contacts',
        [PortalFeature.EDIT_VENDOR]: 'Ask an account owner to update your company profile'
    }
    convert(hasPermission: boolean, feature: PortalFeature): string {
        return hasPermission ? '' : this.featureTooltipMap[feature];
    }
}