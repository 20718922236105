import { OptionsGateway } from "../../@types/get-options/zcvp_get_options";
import { TableRecord } from "../../@types/list-details/zcvp_get_list_details";
import { RecordQueryData } from "../../@types/zcvp_base_types";
import { FormFieldOption } from "../../@types/zcvp_form";

export class GetOptionsGateway implements OptionsGateway {
    private records;
    constructor(records: RecordQueryData) {
        this.records = records;
    }
    getOptions(nameProp = 'name'): FormFieldOption[] {
        const optionsList = (this.records?.data || []) as TableRecord[];
        return optionsList.map((record: TableRecord) => ({
            ...record,
            id: record.id,
            key: record.id,
            value: record.id,
            label: record[nameProp] || record.text,
        } as FormFieldOption));
    }
}