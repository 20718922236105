import { Button, Form, Input, Typography } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import { ZCVPUserLoginData } from '../../@types/zcvp_api';
import PasswordField from '../../components/form/form-field/PasswordField';
import SingleColumnContent from '../../components/page/SingleColumnContent';
import { usePostUserLoginQuery } from '../../slice/APISlice';

import { MessageInstance } from 'antd/es/message/interface';
import { Trans, useTranslation } from 'react-i18next';
import { login } from '../../lib/AuthenticationHelper';
import { showErrorMessage } from '../../lib/CustomHooks';
import { TranslationNS, getCommonT } from '../../lib/TranslationHelper';

const { Text } = Typography;

const LoginForm: React.FC = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const { messageApi } = useOutletContext<{ messageApi: MessageInstance }>();
    const [captchaToken, setCaptchaToken] = useState<string | null>(null);
    const { t } = useTranslation(TranslationNS.AUTHENTICATION);

    const [form] = Form.useForm();
    const [{ email, password }, setSubmitValues] = React.useState({ email: '', password: '', captchaToken: '' });

    const {
        refetch: refetchLogin,
        data: {
            id: userId,
            token
        } = { token: undefined },
        error: {
            details: errorTitle,
            message: errorMessage,
            errorCode
        } = { error: { details: '', message: '' } },
    } = usePostUserLoginQuery({
        email, password, captchaToken
    }, { skip: !email || !password || !captchaToken });

    const triggerSubmitUserLogin = useCallback(async () => {
        if (!executeRecaptcha) {
            showErrorMessage(getCommonT('invalid_data') , messageApi);
            return;
        }

        const newToken = await executeRecaptcha('loginFormSubmit')
        if (newToken) {
            setCaptchaToken(newToken);
            const fieldValues: ZCVPUserLoginData = form.getFieldsValue();
            if (fieldValues.email === email && fieldValues.password === password) {
                refetchLogin();
            } else {
                setSubmitValues({
                    email: fieldValues.email,
                    password: fieldValues.password,
                    captchaToken: newToken,
                });
            }
        }
    }, [executeRecaptcha, email, password, refetchLogin, form, messageApi]);

    useEffect(() => {
        if (token) {
            login(token, userId);
        }
    }, [token, userId]);

    return (
        <SingleColumnContent
            h2={t('sign_in_heading')}
        >
            {/* <Button size="large" block> <GoogleOutlined /> Sign in with Google</Button>
            <Divider plain>or use Email</Divider> */}
            <Form
                name={'zcvp_log_in_form'}
                layout="vertical"
                form={form}
                requiredMark={false}
                onFinish={triggerSubmitUserLogin}
                validateTrigger={'onSubmit'}
            >
                <Form.Item label={getCommonT('email')} id={'zcvp_email'} name={'email'}
                    rules={[{
                        required: true,
                        type: 'email'
                    }]}>
                    <Input placeholder="kate@company.com" />
                </Form.Item>
                <PasswordField
                    name={'password'}
                    label={t('password')}
                    skipValidationRule={true}
                    extra={<Link to={'/forgot-password'} className={'float-right'}>{t('forgot_password')}</Link>}
                />
                <Form.Item>
                    {errorCode === 'INVALID_USERNAME_OR_PASSWORD' ? <Text type='danger'>{t('invalid_login')}</Text> : <Text type='danger'>{errorMessage}</Text>}
                    <Button size="large" type="primary" block htmlType={'submit'}>{t('log_me_in')}</Button>
                </Form.Item>
                <p className={'text-center text-gray-400'}>
                    <Trans i18nKey="authentication:captcha_notice">
                        This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank" rel="noreferrer">Terms of Service</a> apply.
                    </Trans>
                </p>
                <p className={'text-center'}>
                    <Trans i18nKey="authentication:privacy_notice">
                        By clicking "Log me in" you agree with our <a href="https://www.zoneandco.com/legal/privacy" target="_blank" rel="noreferrer">Privacy Notice</a>
                    </Trans>
                </p>
            </Form>
        </SingleColumnContent>
    );
};

export default LoginForm;