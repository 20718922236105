import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Avatar, Button, List, Tabs, Tag } from 'antd';
import { FileOutlined } from '@ant-design/icons';

import HistoryDetails from '../history/HistoryDetails';
import { useGetRequestsQuery, useGetVendorHistoryQuery } from '../../slice/APISlice';
import { GetVendorRequestsController } from '../../use-case/vendorRequests/getVendorRequestsController';
import { GetRecordHistoryUseCase } from '../../use-case/history/getRecordHistoryUseCase';
import { GetRecordHistoryPresenter } from '../../use-case/history/getRecordHistoryPresenter';
import { TranslationNS } from '../../lib/TranslationHelper';
import { UserState } from '../../@types/zcvp_state';
import { TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import { ApiRecord, ApiRequest } from '../../@types/zcvp_api_enum';
import '../../styles/zcvp_vendor_details.scss';

type ListItemProps = {
    title: string;
    value: string|React.ReactNode;
    avatar?: React.ReactNode;
    className?: string;
    onClick?: () => void;
}

const ListItem = ({ title, value, avatar, className, onClick }: ListItemProps) => <List.Item extra={[value]} className={className} onClick={onClick}>
    <List.Item.Meta
        avatar={avatar}
        title={title}
    />
</List.Item>;

const VendorDetailsTabs = ({ record, onRequestClick }: { record: TableRecord, onRequestClick: (requestRecord: TableRecord) => void }) => {
    const [showInactive, setShowInactive] = useState(false);
    const isVendor = useSelector((state: { user: UserState }) => state.user.isVendor);

    const { t } = useTranslation(TranslationNS.VENDOR, {
        keyPrefix: `${TranslationNS.VENDOR_REQUEST}`
    });
    const {
        data: requests
    } = useGetRequestsQuery({
        filterModel: {
            [ApiRecord.VendorRequestField.VENDOR_ID]: {
                values: [record?.id],
                filterType: ApiRequest.FilterType.SET
            }
        }
    }, { skip: !record?.id });
    const { data: history } = useGetVendorHistoryQuery({ vendorId: record?.id }, { skip: !record?.id });

    const vendorRequestsController = useMemo(() => new GetVendorRequestsController(), []);
    const vendorRequests = useMemo(() => vendorRequestsController.executeGetRequests(requests, isVendor), [vendorRequestsController, requests]);
    const filteredVendorRequests = useMemo(() => vendorRequestsController.executeFilterRequestsByStatus(vendorRequests), [vendorRequestsController, vendorRequests]);
    const vendorHistory = useMemo(() => new GetRecordHistoryUseCase(new GetRecordHistoryPresenter()).execute(history), [history]);
    const requestListText = useMemo(() => showInactive ? t('hide_inactive'): t('show_inactive'), [t, showInactive]);

    return <Tabs
        type='card'
        items={[
        {
            key: 'vendor-requests',
            label: 'Requests',
            children: <>
                <List
                    className='zcvp-request-list'
                    dataSource={showInactive ? vendorRequests : filteredVendorRequests}
                    renderItem={(item, index) => <ListItem
                        title={item.title as string}
                        onClick={() => onRequestClick({
                            ...item,
                            requestId: item.id,
                            vendorName: record.companyName
                        })}
                        value={<Tag
                            key={`vendor-listitem-reqtag-${index}`}
                            color={item.color as string}
                        >
                            {item.statusText}
                        </Tag>}
                        avatar={<Avatar size='small' icon={<FileOutlined />}/>}
                        className='zcvp-vendor-request'/>
                    }/>
                {vendorRequests.length > filteredVendorRequests.length
                    ? <Button
                        type='link'
                        className='zcvp-show-inactive mb-1'
                        onClick={() => setShowInactive(!showInactive)}>
                            {requestListText}
                        </Button>
                    : null}
            </>
        },
        {
            key: 'vendor-history',
            label: 'History',
            children: <HistoryDetails records={vendorHistory} />
        }
    ]}
    />;
};

export default VendorDetailsTabs;