import { ApiRecord } from '../../@types/zcvp_api_enum';
import {
    ConfigurationData,
    Field,
    FieldKey,
    IConfigurationDataGateway,
    RecordData,
    RecordQueryData
} from '../../@types/zcvp_configuration';
import { BaseDataUseCase } from '../BaseDataUseCase';
import { GetConfigurationDataPresenter } from './getConfigurationDataPresenter';

export class GetConfigurationDataUseCase extends BaseDataUseCase<IConfigurationDataGateway, GetConfigurationDataPresenter> {
    public execute(configuration?: RecordQueryData): ConfigurationData {
        const response: ConfigurationData = {
            editableFields: [],
            viewFields: [],
            logoFields: [],
            id: configuration?.data?.id || ''
        };

        if (configuration?.data) {
            response.editableFields = this.presenter.convertFields(this.presenter.companyAccountFieldsLayout);
            response.editableValues = this.getValues(this.gateway?.editableFields, configuration.data);
            response.viewFields = this.presenter.convertFields(this.presenter.viewFieldsLayout);
            response.viewValues = this.getViewableValues(configuration.data);
            response.logoFields = this.presenter.convertFields(this.presenter.logoFieldsLayout);
            response.logoValues = this.getValues(this.gateway?.logoFields, configuration.data);
        }
        return response;
    }

    private getViewableValues(data: RecordData) {
        const viewValues = this.getValues(this.gateway?.viewFields, data);
        const senderSupportEmail = this.joinValue([ApiRecord.CompanyField.SENDER_EMAIL, ApiRecord.CompanyField.SUPPORT_EMAIL], data);
        const address = viewValues[Field.ADDRESS]?.replaceAll(/(\r\n)|(\r)|(\n)/g, ', ');
        return {
            ...viewValues,
            [Field.ADDRESS]: address,
            [Field.SENDER_SUPPORT_EMAIL]: senderSupportEmail
        };
    }

    private joinValue(fieldIds: string[], data: RecordData) {
        const DELIMITER = ' / ';
        return fieldIds.map(fieldId => (data[fieldId as FieldKey])).join(DELIMITER);
    }
}
