import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Skeleton } from 'antd';

import DashboardCardTitle from './DashboardCardTitle';
import VendorRequestTable from '../vendor-requests/VendorRequestTable';
import CardComponent from '../../components/partials/CardComponent';
import { TranslationNS } from '../../lib/TranslationHelper';
import { useGetRequestsQuery } from '../../slice/APISlice';
import { CheckRolePermissionController } from '../../use-case/checkRolePermission/checkRolePermissionController';
import { UserState } from '../../@types/zcvp_state';
import { RoutesUrls } from '../../@types/zcvp_routes_enum';
import { PortalFeature } from '../../@types/role-permissions/zcvp_role_permissions_enum';
import { ApiRecord, ApiRequest, ApiRequestStatus } from '../../@types/zcvp_api_enum';
import { getPendingRequestStatusFilterOptions } from '../../use-case/getVendors/getVendorsUseCase';

export const PENDING_REQUEST_FILTER: ApiRequest.FilterModel = {
    filterModel: {
        [ApiRecord.VendorRequestField.STATUS]: {
            values: [ApiRequestStatus.NEW, ApiRequestStatus.REVIEW],
            filterType: ApiRequest.FilterType.SET
        }
    }
};

export const PendingRequestListCard = () => {
    const { t } = useTranslation(TranslationNS.DASHBOARD);
    const user = useSelector((state: { user: UserState }) => state.user);
    const canSeeRequests = useMemo(() => new CheckRolePermissionController(user).executeFeatureCheck(PortalFeature.REQUEST_LIST), [user]);

    const { data: requests, isLoading: isLoadingRequests } = useGetRequestsQuery(PENDING_REQUEST_FILTER);
    const requestCount = useMemo(() => requests?.length || 0, [requests]);

    const lookup = useMemo(() => ({
        [ApiRecord.VendorRequestField.STATUS]: getPendingRequestStatusFilterOptions(user.isVendor),
    }), []);

    return (
        canSeeRequests ? <Skeleton loading={isLoadingRequests}>
            <CardComponent
                title={<DashboardCardTitle
                    title={t('pending_requests', { count: requestCount })}
                    titleDetails={t('active_count', { count: requestCount })}
                />}
                subtitle={t('pending_requests_description', { count: requestCount })}
                titleNotifications={[
                    <Link to={RoutesUrls.REQUESTS} key='see-requests-btn'>{t('see_all_requests')}</Link>
                ]}
                className={'grow zcvp-dash-card-list zcvp-dash-card-parent'}
            >
                {requestCount
                    ? <VendorRequestTable
                        filter={PENDING_REQUEST_FILTER}
                        lookup={lookup}
                        tableProps={{ size: 'small'}}/>
                    : null}
            </CardComponent>
        </Skeleton> : null
    );
};

export default PendingRequestListCard;