export enum TutorialStepKey {
    MAIN = 'main',
    COMPANY_CONFIG = 'company-config',
    INTEGRATIONS = 'integrations',
    INTEGRATION_DETAILS = 'integration-details',
    APPROVALS = 'approvals',
    VENDOR_MANAGEMENT = 'vendor-management',
    USERS = 'users',
    NETSUITE_USERS = 'netsuite-users',
    NETSUITE_USERS_DETAILS = 'netsuite-users-details',
    COMPLETION = 'completion'
}

export enum TutorialLinkType {
    INTEGRATION = 'integration',
    ROLE_INFO = 'role-info',
    KNOWLEDGE_BASE = 'knowledge-base'
}