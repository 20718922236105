import { FieldValue, FormFieldDefinition, FormFieldOption } from '../zcvp_form';

/**
 * Get invite user details types
 */
export type RoleDetail = {
    name: string;
    description: string;
}
export type InitialValues = {
    [key: string]: FieldValue|FieldValue[]|undefined;
}
export type InviteVendorData = {
    nameFields: FormFieldDefinition[];
    roleField: FormFieldDefinition;
    roleDetails: RoleDetail[];
    initialValues: InitialValues;
}
export interface InviteVendorDataGateway {
    getUsers: () => FormFieldOption[]|undefined;
    getField: (fieldId: string) => FormFieldDefinition;
    getRoles: () => FormFieldOption[];
    getRoleDetails: (roleId?: string) => RoleDetail;
    getAllRoleDetails: () => RoleDetail[];
}

/**
 * Send invitation types
 */
export interface SendVendorInvitationGateway {
    inviteCallback: Function;
}

export enum Field {
    ID = 'id',
    COMPANY_NAME = 'companyname',
    INVITE_EMAILS = 'email',
    SUBSIDIARY_ID = 'subsidiary'
}
export type FieldKey = `${Field}`

export type submitFields = Record<FieldKey, string>;