import { FormInstance, RadioChangeEvent, FormProps as antDFormProps } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { valueType } from 'antd/es/statistic/utils';
import { Dayjs } from 'dayjs';
import React, { ChangeEvent } from 'react';
import { AddressValues } from './get-address/zcvp_get_address';
import { ActionType, FormElementType, FormFieldType } from './zcvp_form_enum';

export type CheckboxOnChange = (event: CheckboxChangeEvent) => void;
export type DateOnChange = (date: Dayjs | null, dateString: string) => void;
export type NumberOnChange = (value: valueType | null) => void;
export type RadioOnChange = (event: RadioChangeEvent) => void;
export type TextAreaOnChange = (event: ChangeEvent<HTMLTextAreaElement>) => void;
export type FieldOnChange = (value: SelectValue[], option: FormFieldOption) => void;
export type AddressOnChange = (value: ChangeEvent<HTMLElement>) => void;
export type InputOnChange = (React.ChangeEventHandler<HTMLInputElement> & React.ChangeEventHandler<HTMLTextAreaElement>);
export type OnChange = CheckboxOnChange | DateOnChange | FieldOnChange | NumberOnChange | TextAreaOnChange | InputOnChange | AddressOnChange;

export type SelectValue = ChangeEvent<HTMLInputElement>;
export type FieldValue = boolean | string | SelectValue | object;

export type FormFieldDefinition = {
    name: string;
    type: FormFieldType;
    label?: string | React.ReactElement;
    checkboxLabel?: string;
    placeholder?: string;
    subText?: string;
    value?: FieldValue | FieldValue[];
    required?: boolean | Function;
    disabled?: boolean | Function;
    onChange?: OnChange;
    options?: FormFieldOption[];
    hideHint?: boolean;
    skipValidationRule?: boolean;
    className?: string;
    colClassName?: string;
    colSpan?: number;
    maxTagCount?: number;
    extra?: React.ReactElement;
    form?: FormInstance;
    path?: string;
    maxLength?: number;
    otherProps: {[key: string]: string};
    element?: React.ReactElement;
}

export type FormElementDefinition = {
    type: FormElementType;
    colClassName?: string;
    colSpan?: number;
    name?: string;
    label?: string | React.ReactElement;
    value?: string | React.ReactElement;
}

export type FieldDefinitionType = {
    [type: string]: FormFieldDefinition;
}

export type BaseFieldKey<T extends string = string> = T

export interface FormFieldOption {
    name?: string;
    id: string | number;
    label: string;
    value: string | number | boolean;
    key?: string;
    disabled?: boolean;
}

export interface FieldProps {
    name: string;
    label?: string | React.ReactElement;
    placeholder?: string;
    required?: boolean;
    requiredMessage?: string;
    disabled?: boolean;
    children?: React.ReactNode;
    extra?: React.ReactNode;
    className?: string;
}

export interface GenericFieldProps extends FieldProps {
    defaultValue?: string;
    onChange?: InputOnChange;
    maxLength?: number;
}

export interface CheckboxFieldProps extends FieldProps {
    defaultValue?: boolean;
    checkboxLabel?: string;
    onChange?: CheckboxOnChange;
}

export interface DateFieldProps extends FieldProps {
    showTime?: boolean;
    defaultValue?: string;
    onChange?: DateOnChange;
}

export interface PasswordFieldProps extends GenericFieldProps {
    className?: string;
    hideHint?: boolean;
    matchToField?: {
        name: string;
        form: FormInstance;
    };
    extra?: React.ReactNode;
    skipValidationRule?: boolean
}

export interface NumberFieldProps extends FieldProps {
    defaultValue?: string;
    onChange?: NumberOnChange;
}

export interface PhoneNumberFieldProps extends FieldProps {
    defaultValue?: string;
    onChange?: InputOnChange;
}

export interface RadioFieldProps extends FieldProps {
    defaultValue?: string;
    options: FormFieldOption[];
    hideLabel?: boolean;
    onChange?: RadioOnChange;
}

export interface SelectFieldProps extends FieldProps {
    mode?: 'multiple' | 'tags';
    maxTagCount?: number;
    defaultValue?: SelectValue;
    options: FormFieldOption[];
    onChange?: FieldOnChange;
}

export interface TextAreaFieldProps extends FieldProps {
    defaultValue?: string;
    onChange?: TextAreaOnChange;
}

export interface EmailFieldProps extends FieldProps {
    isMultiple?: boolean;
    defaultValue?: string;
    onChange?: InputOnChange;
}

export interface AddressFieldProps extends FieldProps {
    defaultValue?: AddressValues;
    form?: FormInstance;
    onChange?: AddressOnChange;
}

export type FormFieldProps = GenericFieldProps | CheckboxFieldProps | DateFieldProps | PasswordFieldProps | RadioFieldProps | SelectFieldProps | TextAreaFieldProps | FieldProps;
export interface ActionButtonDefinition {
    name: string;
    key: string;
    label: string | React.ReactElement;
    type?: 'link' | 'text' | 'default' | 'primary' | 'dashed';
    icon?: React.ReactNode;
    tooltip?: string;
    onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    loading?: boolean;
    disabled?: boolean;
    disableSubmitButton?: boolean;
    block?: boolean;
    size?: 'small' | 'middle' | 'large';
}

export interface FormActionProps {
    type: ActionType;
    definition?: ActionButtonDefinition;
    disableSubmitButton?: boolean;
    element?: React.ReactNode;
}

export enum disableSubmitTrigger {
    ON_ANY_FIELD_CHANGE = 'onAnyFieldChange',
}

export type FieldValueMap = Record<string, string | number | boolean | null>;
export interface FormProps {
    fieldValues?: FieldValueMap;
    fields: FormFieldDefinition[][] | FormElementDefinition[][];
    actions: FormActionProps[];
    id?: string;
    validateTrigger?: string;
    disableSubmitTrigger?: `${disableSubmitTrigger}`;
    formProps?: antDFormProps;
}
