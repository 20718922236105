import { InvitationConfirmationGateway, InvitationConfirmationPresenter } from "../../@types/invite/zcvp_invite";
import { TableRecord } from "../../@types/list-details/zcvp_get_list_details";

export class ConfirmInvitationUseCase {
    private gateway: InvitationConfirmationGateway;
    private presenter: InvitationConfirmationPresenter;
    constructor(gateway: InvitationConfirmationGateway, presenter: InvitationConfirmationPresenter) {
        this.gateway = gateway;
        this.presenter = presenter;
    }
    execute(invitationType: string, params: TableRecord) {
        this.gateway.confirmInvitation({
            invitationType,
            params
        }).then(response => this.presenter.convert(response));
    }
}