import { HistoryItem, RecordHistoryPresenter } from "../../@types/history/zcvp_history";
import { TableRecord } from "../../@types/list-details/zcvp_get_list_details";

export class GetRecordHistoryUseCase {
    private presenter: RecordHistoryPresenter;
    constructor(presenter: RecordHistoryPresenter) {
        this.presenter = presenter;
    }
    public execute(history: TableRecord[] = []): HistoryItem[] {
        return this.presenter.convert(history);
    }
}