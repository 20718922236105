import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Alert, Button, Flex } from 'antd';

import PendingVendorListCard from './PendingVendorListCard';
import PendingRequestListCard from './PendingRequestListCard';
import BasicContentLayout from '../../components/layout/BasicContentLayout';
import { TranslationNS } from '../../lib/TranslationHelper';
import { useGetAllVendorsQuery } from '../../slice/APISlice';
import { RoutesUrls } from '../../@types/zcvp_routes_enum';

export const DashboardCustomer = ({ }) => {
    const { t } = useTranslation(TranslationNS.DASHBOARD);
    const { data: vendors, isFetching } = useGetAllVendorsQuery({});
    const hasVendors = useMemo(() => !!vendors?.length, [vendors]);

    return (
        <BasicContentLayout
            title={t('dashboard_title')}
        >
            <Flex
                vertical={true}
                gap={44}
                className={'zcvp-basic-content-centered zcvp-dashboard'}
            >
                {!isFetching && !hasVendors ? <Alert
                    type={'warning'}
                    description={t('no_vendor_warning')}
                    showIcon={true}
                    action={<Button size='small' danger>
                        <Link to={`${RoutesUrls.VENDORS}`}>{t('go_to_vendors')}</Link>
                    </Button>}
                /> : null}
                <PendingRequestListCard />
                <PendingVendorListCard />
            </Flex>
        </BasicContentLayout>
    );
};
