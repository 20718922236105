import React, { useMemo, useState } from 'react';

import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { FieldQueryData, TableRecord } from '../../@types/list-details/zcvp_get_list_details';
import { ListId } from '../../@types/list-details/zcvp_get_list_details_enum';
import { ApiRecord } from '../../@types/zcvp_api_enum';
import TableCard from '../../components/partials/TableCard';
import { TranslationNS, getCommonT } from '../../lib/TranslationHelper';
import { useGetAllRolesQuery, useGetAllVendorContactsQuery, useGetMetaDataQuery } from '../../slice/APISlice';
import { GetListDetailsController } from '../../use-case/getListDetails/getListDetailsController';
import InviteContactModal from './InviteContactModal';

type VendorContactTableProps = {
    filterValue: string;
    onRowClick: Function;
}

const VendorTypeChip = (role: string) => {
    const className = role === 'owner' ? 'zcvp-role-tag-primary' : 'zcvp-role-tag-user';
    return role ? <Tag className={className}>
        {role?.toUpperCase()}
    </Tag> : null;
}

const VendorContactTable: React.FC<VendorContactTableProps> = ({ filterValue, filters, setFilters, onRowClick }) => {
    const { t } = useTranslation(TranslationNS.VENDOR);
    const columnQueryData: FieldQueryData = useGetMetaDataQuery({});
    const recordQueryData = useGetAllVendorContactsQuery({});
    const rawRoles = useGetAllRolesQuery({});

    const [isOpenInvite, setIsOpenInvite] = useState(false);
    const [clickedRow, setClickedRow] = useState<TableRecord>();

    const roles = useMemo(() => new GetListDetailsController({}, rawRoles).execute(ListId.ROLE), [rawRoles]);
    const lists = useMemo(() => ({ roles: roles?.dataSource }), [roles]);
    const tableData = useMemo(() =>
        new GetListDetailsController(columnQueryData, recordQueryData, lists).execute(ListId.VENDOR_CONTACT, filterValue, filters)
        , [lists, columnQueryData, recordQueryData, filterValue, filters]);

    const columns = tableData.columns.map((column) => {
        if (column.dataIndex === ApiRecord.VendorContactField.ROLE) {
            return {
                ...column,
                render: VendorTypeChip
            };
        }
        return column;
    });

    const actions = [{
        name: 'invite-contact-btn',
        key: 'invite-contact-btn',
        label: (record: TableRecord) => record.invitedAt ? t('resend_invitation_button') : getCommonT('invite'),
        show: (record: TableRecord) => !record.registeredAt,
        onClick: (_event: MouseEvent, record: TableRecord) => {
            setClickedRow(record);
            setIsOpenInvite(true);
        }
    }];

    return <TableCard
        dataSource={tableData.dataSource}
        columns={columns}
        actions={actions}
        onRow={(record: TableRecord) => ({
            onClick: () => onRowClick && onRowClick(record)
        })}
        setFilters={setFilters}>
        <InviteContactModal open={isOpenInvite} onClose={() => setIsOpenInvite(false)} record={clickedRow} />
    </TableCard>;
};

export default VendorContactTable;