import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Theme from './components/Theme';
import { resetDeepLink, useGetAuthentication } from './lib/AuthenticationHelper';
import { routesConfig } from './lib/RoutesConfig';
import { apiSlice, TagType, useGetInfoQuery } from './slice/APISlice';
import { updateLoaderInfo } from './slice/LoaderSlice';
import { updateUserState } from './slice/UserSlice';


export default function App() {
    const authentication = useGetAuthentication();
    const dispatch = useDispatch();
    const results = useGetInfoQuery({}, { skip: !authentication });
    useEffect(() => {
        if(results?.data) {
            dispatch(updateUserState(results.data));
            dispatch(updateLoaderInfo({
                isSwitchingProfile: false
            }));
            dispatch(apiSlice.util.invalidateTags((Object.values(TagType))));
            resetDeepLink();
        }
    }, [dispatch, results?.data]);

    const router = createBrowserRouter(routesConfig);
    
    return (
        <div className={'zcvp-app w-auto'}>
            <Theme>
                <RouterProvider router={router} />
            </Theme>
        </div>
    );
}
