import { ApiVendorRequestType } from '../@types/zcvp_api_enum';

export const BACKEND_URL = (process.env.REACT_APP_API_URL || 'https://api.zoneportal.com') + '/';
export const KNOWLEDGE_BASE_URL = 'https://help.zoneandco.com/hc/en-us/sections/23088272688283-Vendor-Portal';

export enum CompanyType {
    INDIVIDUAL = 1,
    COMPANY = 2
}
export const COMPANY_SIZE_OPTIONS = [
    { id: '1', value: '1', label: 'Individual' },
    { id: '2', value: '2', label: '2 - 10 Employees' },
    { id: '3', value: '3', label: '11 - 50 Employees' },
    { id: '4', value: '4', label: '51 - 100 Employees' },
    { id: '5', value: '5', label: '101 - 200 Employees' },
    { id: '6', value: '6', label: '200+ Employees' },
];

export const BANK_ACCOUNT_TYPE_OPTIONS = [
    { id: '1', value: 'PRIMARY', label: 'PRIMARY' },
    { id: '2', value: 'SECONDARY', label: 'SECONDARY' },
];

export const VENDOR_REQUEST_TYPE = [
    { id: '1', value: ApiVendorRequestType.DOCUMENT, label: 'Document Request' },
    { id: '2', value: ApiVendorRequestType.GENERAL, label: 'General' },
    { id: '3', value: ApiVendorRequestType.RECORD_CHANGE, label: 'Record Change' }
];

export const ZCVP_CONSTANTS = {
    VALIDATIONS: {
        PHONE_NUMBER: /^(\+?\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
        URL: /^(http|https|file):\/\//
    }
};

export const PREFER_CACHE_VALUE = true;

export const URL_DOMAIN_PREFIX = '//';
export enum WebProtocol {
    HTTP = 'http://',
    HTTPS = 'https://',
}
