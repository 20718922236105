import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { MessageInstance } from 'antd/lib/message/interface';
import { useCallback, useState } from 'react';
import { useOutletContext } from 'react-router-dom';

import { TableRecord, TableRowValue } from '../../@types/list-details/zcvp_get_list_details';
import { Feature } from '../../@types/submit-record/zcvp_submit_record_enum';
import { ApiRecord } from '../../@types/zcvp_api_enum';
import { getCommonT } from '../../lib/TranslationHelper';
import { useApproveAfterOnboardMutation, useApproveToOnboardMutation } from '../../slice/APISlice';
import '../../styles/zcvp_modal.scss';
import '../../styles/zcvp_vendor_details.scss';
import { VendorOnboardingAction } from '../../use-case/onboarding/getVendorOnboardingActionUseCase';
import { SubmitRecordController } from '../../use-case/submitRecord/submitRecordController';
import VendorRequestModal from '../vendor-requests/VendorRequestModal';
import InviteExistingVendorModal from './InviteExistingVendorModal';

const VendorDetailsFooter = ({ type, record, onSubmit }: { type: VendorOnboardingAction, record: TableRecord, onSubmit: Function }) => {
    const [approveToOnboard] = useApproveToOnboardMutation();
    const [approveAfterOnboard] = useApproveAfterOnboardMutation();

    switch (type) {
        case VendorOnboardingAction.APPROVALS_TO_ONBOARD:
            return <VendorApprovalButtonPanel vendorId={record.id as unknown as number} action={approveToOnboard} onSubmit={onSubmit} />;
        case VendorOnboardingAction.APPROVALS_AFTER_ONBOARD:
            return <VendorApprovalButtonPanel vendorId={record.id as unknown as number} action={approveAfterOnboard} onSubmit={onSubmit} />
        case VendorOnboardingAction.CREATE_REQUEST:
            return <VendorRequestModal hideIcon={true} defaultValues={{ [ApiRecord.VendorRequestField.VENDOR_ID]: record.id as unknown as TableRowValue }}/>
    }
    return <VendorInvitationPanel record={record} onSubmit={onSubmit} />;
}

const VendorInvitationPanel = ({ record, onSubmit }: { record: TableRecord, onSubmit: Function }) => {
    const [isOpenInvite, setIsOpenInvite] = useState(false);
    const onClick = useCallback((event: MouseEvent) => {
        setIsOpenInvite(false);
        onSubmit(event)
    }, [onSubmit]);
    return <>
        <InviteExistingVendorModal open={isOpenInvite} onClose={onClick} record={record!} />
        <Button type='primary' onClick={() => setIsOpenInvite(true)}>{getCommonT('invite')}</Button>
    </>;
};

const VendorApprovalButtonPanel = ({ vendorId, action, onSubmit }: { vendorId: number, action: Function, onSubmit: Function }) => {
    const APPROVED = true;
    const { messageApi } = useOutletContext<{ messageApi: MessageInstance }>();
    const onClick = (isApproved: boolean) => new SubmitRecordController({
        onSubmit: action, onClose: onSubmit, messageApi
    }).execute(Feature.APPROVALS_ACTION, {
        approved: isApproved as unknown as TableRowValue,
        ids: [vendorId] as unknown as TableRowValue
    });
    return <>
        <Button className='mx-1' icon={<CloseOutlined />} onClick={() => onClick(!APPROVED)} danger>{getCommonT('reject')}</Button>
        <Button className='mx-1' icon={<CheckOutlined />} onClick={() => onClick(APPROVED)} type='primary'>{getCommonT('approve')}</Button>
    </>;
}

export default VendorDetailsFooter;