import { SubmitRecordPresenterInterface } from '../../@types/submit-record/zcvp_submit_record';
import { ShowMessageTypes } from '../../@types/zcvp_custom_hooks';
import { showMessage } from '../../lib/CustomHooks';
import { MessageInstance } from 'antd/lib/message/interface';
import { ZCVPApiResponse } from '../../@types/zcvp_api';

enum Message {
    SUCCESS = '{recordType} successfully submitted!',
    ERROR = 'Failed submitting {recordType}!'
}
export class SubmitRecordPresenter implements SubmitRecordPresenterInterface {
    private messageApi;
    private onClose;
    private onSuccess;
    constructor({ messageApi, onClose, onSuccess }: {messageApi: MessageInstance, onClose?: Function, onSuccess?: Function}) {
        this.messageApi = messageApi;
        this.onClose = onClose;
        this.onSuccess = onSuccess;
    }
    public convert(response: ZCVPApiResponse) {
        const { recordType, error } = response;
        if(!error) {
            if(this.onSuccess) {
                this.runSuccessCallback(response);
            } else {
                this.showSuccess(recordType!);
            }
        } else {
            this.showError(recordType!);
        }
        this.onClose && this.onClose();
    }
    private runSuccessCallback(response: ZCVPApiResponse) {
        const { recordType } = response;
        const successCallbackResults = this.onSuccess!(response);
        if(successCallbackResults) {
            const mainResponse = Array.isArray(successCallbackResults) ? Promise.all(successCallbackResults) : successCallbackResults;
            this.processResults(recordType!, mainResponse);
        } else {
            this.showSuccess(recordType!);
        }
    }
    private processResults(recordType: string, results: Promise<ZCVPApiResponse>): void {
        results.then((responses: ZCVPApiResponse) => {
            const allResponses = Array.isArray(responses) ? responses : [responses];
            const hasError = allResponses.some((response: ZCVPApiResponse) => typeof response?.error !== 'undefined');
            if (hasError) {
                this.showError(recordType!);
            } else {
                this.showSuccess(recordType!);
            }
        })
    }
    private showError(recordType: string) {
        showMessage(ShowMessageTypes.ERROR, this.getErrorMessage(recordType), this.messageApi);
    }
    private showSuccess(recordType: string) {
        showMessage(ShowMessageTypes.SUCCESS, this.getSuccessMessage(recordType), this.messageApi);
    }
    private getSuccessMessage(recordType: string): string {
        const convertedRecordType = this.convertToPascalCase(recordType);
        return Message.SUCCESS.replace('{recordType}', convertedRecordType);
    }
    private getErrorMessage(recordType: string): string {
        return Message.ERROR.replace('{recordType}', recordType);
    }
    private convertToPascalCase(stringToConvert: string): string {
        return stringToConvert ? `${stringToConvert.slice(0, 1).toUpperCase()}${stringToConvert.slice(1)}` : '';
    }
}