import { FieldQueryData, FilterMap, ListDetailsGateway, RecordQueryData, TableData } from '../../@types/list-details/zcvp_get_list_details';
import { GetListDetailsGateway } from './getListDetailsGateway';
import { GetListDetailsPresenter } from './getListDetailsPresenter';
import { GetListDetailsUseCase } from './getListDetailsUseCase';

/**
 * Executes logic for gettiing list details
 */
export class GetListDetailsController {
    private fieldQueryData;
    private recordQueryData;
    private lists;
    private postProcessor;
    constructor(fieldQueryData: FieldQueryData, recordQueryData: RecordQueryData, lists={}, postProcessor?: Function) {
        this.fieldQueryData = fieldQueryData;
        this.recordQueryData = recordQueryData;
        this.lists = lists;
        this.postProcessor = postProcessor;
    }
    public execute(listId: string, filterValue?: string, filters?: FilterMap): TableData {
        const getListDetailsGateway: ListDetailsGateway = new GetListDetailsGateway(this.fieldQueryData, this.recordQueryData, this.lists);
        const getListDetailsPresenter = new GetListDetailsPresenter();
        const getListDetailsUseCase = new GetListDetailsUseCase(getListDetailsGateway, getListDetailsPresenter, this.postProcessor);

        return getListDetailsUseCase.execute(listId, filterValue, filters);
    }
}