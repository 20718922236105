import { UserAddOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { VendorCompanyTabKey } from '../../@types/vendor-company/zcvp_vendor_company_enum';
import { TabItem } from '../../@types/zcvp_tabbed_page';
import GenericActionButton from '../../components/form/form-action/GenericActionButton';
import TabbedContentLayout from '../../components/layout/TabbedContentLayout';
import ListPageContent from '../../components/page/ListPageContent';
import { CompanyType } from '../../lib/Constants';
import { TranslationNS } from '../../lib/TranslationHelper';
import { useGetVendorQuery } from '../../slice/APISlice';
import InviteContactModal from '../vendor-contact/InviteContactModal';
import VendorContactDetails from '../vendor-contact/VendorContactDetails';
import VendorContactTable from '../vendor-contact/VendorContactTable';
import CompanyBankDetails from './CompanyBankDetails';
import CompanyGeneral from './CompanyGeneral';

export default function VendorCompany({ activeTab }: { activeTab?: string }) {
    const { t } = useTranslation(TranslationNS.VENDOR);
    const [isOpenInvite, setIsOpenInvite] = useState(false);
    const user = useSelector((state: AppState) => state.user);
    const vendorData = useGetVendorQuery({
        id: user.vendorId
    });
    const items: TabItem[] = [
        {
            key: VendorCompanyTabKey.GENERAL,
            label: t('general_tab_title'),
            children: <CompanyGeneral />,
        },
        {
            key: VendorCompanyTabKey.BANK_DETAILS,
            label: t('bank_details_tab_title'),
            children: <CompanyBankDetails />,
        }
    ];
    if (vendorData?.data?.size !== CompanyType.INDIVIDUAL) {
        items.push({
            key: VendorCompanyTabKey.VENDOR_CONTACT,
            label: t('contacts_tab_title'),
            children: <ListPageContent
                pageId={VendorCompanyTabKey.VENDOR_CONTACT}
                key={VendorCompanyTabKey.VENDOR_CONTACT}
                DataTable={VendorContactTable}
                SubPage={VendorContactDetails}
                title={t('contacts_tab_title')}
                searchPlaceHolder={t('find_user_placeholder')} />
        });
    }

    const actions = [{
        applyToTabs: [VendorCompanyTabKey.VENDOR_CONTACT],
        element: <GenericActionButton
            type='primary'
            key='invite-contact-btn'
            name={'invite-contact'}
            label={t('invite_contact_button')}
            tooltip={''}
            icon={<UserAddOutlined />}
            onClick={() => setIsOpenInvite(true)} />
    }];

    return <>
        <InviteContactModal open={isOpenInvite} onClose={() => setIsOpenInvite(false)} />
        <TabbedContentLayout
            activeKey={activeTab}
            title={t('company_page_title')}
            items={items}
            actions={actions}
        />
    </>;
}